import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import { LoadingButton } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import IntlTranslate from "../../helpers/IntlTranslate";
import dayjs from "dayjs";
import { Autocomplete, InputAdornment, Stack, TextField } from "@mui/material";
import SnackbarComponent from "../../components/Snackbar";
import { CreateSeasonalCard, GetCards } from "../../api/api";

function PeriodicTicketPriceAdd() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="periodicTicketPrice" />,
        path: "/periodic-ticket-price",
      },
      { name: <FormattedMessage id="periodicTicketPrice.add" />, active: true },
    ],
    []
  );

  const [cardsOption, setCardsOption] = useState([]);
  useEffect(() => {
    GetCards().then((resp) =>
      setCardsOption(
        resp.data.data.map((e) => ({
          id: e.Id,
          label: e.KartAdi,
        }))
      )
    );
  }, []);

  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  const [loading, setLoading] = useState(false);
  const schema = Yup.object().shape({
    periodName: Yup.string().required(IntlTranslate("periodName.required")),
    startDate: Yup.date().required(IntlTranslate("startDate.required")),
    endDate: Yup.date().required(IntlTranslate("endDate.required")),
    price: Yup.number()
      .min(0, IntlTranslate("price.min"))
      .required(IntlTranslate("price.required")),
    ticketType: Yup.string().required(IntlTranslate("ticketType.required")),
  });

  const formik = useFormik({
    initialValues: {
      periodName: "",
      startDate: dayjs()
        .subtract(7, "day")
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0),
      endDate: dayjs().set("hour", 23).set("minute", 59).set("second", 59),
      price: "",
      ticketType: "",
    },
    validationSchema: schema,
    onSubmit: async (datas, { resetForm }) => {
      setLoading(true);
      let response = await CreateSeasonalCard({
        DonemselBiletId: 0,
        PeriyotAdi: datas.periodName,
        BiletTipi: datas.ticketType,
        Fiyat: datas.price,
        BaslangicTarihi: dayjs(datas.startDate).toISOString(),
        BitisTarihi: dayjs(datas.endDate).toISOString(),
      });
      if (response.data.status === "success") {
        setSnackbarType("success");
        setSnackbarOpen(true);
        resetForm();
      } else {
        setSnackbarType("error");
        setSnackbarOpen(true);
      }
      setLoading(false);
    },
  });
  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;
  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Stack sx={{ mt: 1 }} spacing={3}>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
              <TextField
                fullWidth
                required
                label={IntlTranslate("periodName")}
                {...getFieldProps("periodName")}
                error={Boolean(touched.periodName && errors.periodName)}
                helperText={touched.periodName && errors.periodName}
              />
              <TextField
                required
                fullWidth
                type="number"
                label={IntlTranslate("price")}
                {...getFieldProps("price")}
                error={Boolean(touched.price && errors.price)}
                helperText={touched.price && errors.price}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">₺</InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={<FormattedMessage id="startDate" />}
                  mask="__.__.____ __:__"
                  inputFormat="DD.MM.YYYY HH:mm"
                  value={values.startDate}
                  onChange={(newValue) => {
                    setFieldValue("startDate", newValue);
                  }}
                  ampm={false}
                  maxDateTime={values.endDate}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={<FormattedMessage id="endDate" />}
                  value={values.endDate}
                  mask="__.__.____ __:__"
                  inputFormat="DD.MM.YYYY HH:mm"
                  onChange={(newValue) => {
                    setFieldValue("endDate", newValue);
                  }}
                  ampm={false}
                  minDateTime={values.startDate}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Stack>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
              <Autocomplete
                fullWidth
                options={cardsOption}
                getOptionLabel={(opt) => opt.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, data) => setFieldValue("ticketType", data.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={IntlTranslate("ticketType")}
                    required
                    error={Boolean(touched.ticketType && errors.ticketType)}
                    helperText={touched.ticketType && errors.ticketType}
                  />
                )}
              />
            </Stack>

            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="success"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
            >
              <FormattedMessage id="add" />
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate("periodicticketpricesuccessadd")}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(PeriodicTicketPriceAdd, "periodicTicketPrice.add");
