import {
  Autocomplete,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import {
  AgentsGetAll,
  GetCards,
  TicketDetailsGetAll,
  TicketLogsGetAll,
} from "../../api/api";
import CustomDataGrid from "../../components/CustomDataGrid";
import GetDataArea from "../../components/GetDataArea";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";
const STATUS = {
  activationStarted: { text: "Aktivasyon Başladı", color: "secondary" },
  serviceStarted: { text: "Hizmet Başladı", color: "success" },
  serviceFinished: { text: "Hizmet Bitti", color: "info" },
  cancellationPending: {
    text: "İptali Bekleniyor",
    color: "warning",
    variant: "outlined",
  },
  cancellationAccepted: {
    text: "İptali Onaylandı",
    color: "success",
    variant: "outlined",
  },
  cancellationRejected: {
    text: "İptali Reddedildi",
    color: "error",
    variant: "outlined",
  },
};
const DATE_FILTERS = [
  { id: "SatinAlmaTarihi", name: "Satın Alma Tarihi" },
  { id: "AktivasyonTarihi", name: "Aktivasyon Tarihi" },
  { id: "HizmetBaslangicTarihi", name: "Hizmet Başlangıç Tarihi" },
  { id: "SonKullanimTarihi", name: "Son Kullanım Tarihi" },
];

function TicketDetail() {
  const breadcrumb = useMemo(
    () => [
      { name: <FormattedMessage id="reports.ticketDetail" />, active: true },
    ],
    []
  );
  //table
  const columns = useMemo(
    () => [
      {
        field: "referanceNum",
        headerName: "Bilet Referans Numarası",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "sktDate",
        headerName: "Son Kullanma Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm");
        },
      },
      {
        field: "soldDate",
        headerName: "Satın Alım Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm");
        },
      },
      {
        field: "soldPerson",
        headerName: "Satan Kişi",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "agent",
        headerName: "Acente",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "activationStartDate",
        headerName: "Aktivasyon Başlangıç Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm");
        },
      },
      {
        field: "activationEndDate",
        headerName: "Aktivasyon Bitiş Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm");
        },
      },
      {
        field: "serviceStartDate",
        headerName: "Hizmet Başlangıç Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return params.value !== "-"
            ? dayjs(params.value).format("DD.MM.YYYY HH:mm")
            : "-";
        },
      },
      {
        field: "serviceEndDate",
        headerName: "Hizmet Bitiş Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return params.value !== "-"
            ? dayjs(params.value).format("DD.MM.YYYY HH:mm")
            : "-";
        },
      },
      {
        field: "activatedPerson",
        headerName: "Hizmeti Başlatan Kişi",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "customerNameSurname",
        headerName: "Müşteri Ad Soyad",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "cardType",
        headerName: "Kart Tipi",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "IUASTicketPrice",
        headerName: "IUAŞ Bilet Fiyatı",
        minWidth: 150,
        flex: 1,
        valueGetter: (params) => {
          return `${params.value} ₺`;
        },
      },
      {
        field: "agentTicketPrice",
        headerName: "Acente Bilet Fiyatı",
        minWidth: 150,
        flex: 1,
        valueGetter: (params) => {
          return `${params.value} ₺`;
        },
      },
      {
        field: "priceDifference",
        headerName: "Fiyat Farkı",
        minWidth: 150,
        flex: 1,
        valueGetter: (params) => {
          let result =
            Number(params.row.agentTicketPrice) -
            Number(params.row.IUASTicketPrice);

          let str =
            result >= 0 ? `+${result.toFixed(2)} ₺` : `-${result.toFixed(2)} ₺`;
          return str;
        },
        renderCell: (params) => {
          return <b>{params.value}</b>;
        },
      },
    ],
    []
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //GET DATA FILTERS
  const [agent, setAgent] = useState(null);
  const [searchCardType, setSearchCardType] = useState(null);
  const [dateFilterType, setDateFilterType] = useState(DATE_FILTERS[0].id);
  const [ticketNum, setTicketNum] = useState("");
  const [refCode, setRefCode] = useState("");

  const [agentsOption, setAgentsOption] = useState([]);
  const [cardsOption, setCardsOption] = useState([]);

  useEffect(() => {
    AgentsGetAll().then((resp) =>
      setAgentsOption(
        resp.data["data: "].map((e) => ({
          id: e.Id,
          label: e.Sirket,
        }))
      )
    );

    GetCards().then((resp) =>
      setCardsOption(
        resp.data.data.map((e) => ({
          id: e.Id,
          label: e.KartAdi,
        }))
      )
    );
  }, []);

  const HandleSearchClick = useCallback(
    async (start, end) => {
      setLoading(true);
      await TicketDetailsGetAll({
        DateStart: dayjs(start).toISOString(),
        DateEnd: dayjs(end).toISOString(),
        Datefilter: dateFilterType,
        AcenteId: agent?.id,
        KartId: searchCardType?.id,
        ...(ticketNum !== "" && {
          BiletReferansKodu: ticketNum,
        }),
        ...(refCode !== "" && {
          SepetReferansKodu: refCode,
        }),
      })
        .then((resp) =>
          setData(
            resp.data.data.map((e) => ({
              referanceNum: e.BiletReferansKodu,
              sktDate: e.SonKullanim,
              soldDate: e.SatinAlmaTarihi,
              soldPerson: e.SatanKisiAdSoyad,
              agent: e.Acente || "-",
              activationStartDate: e.AktivasyonTarihi,
              activationEndDate: e.AktivasyonBitisTarihi,
              serviceStartDate: e.HizmetTarihi ? e.HizmetTarihi : "-",
              serviceEndDate: e.HizmetBitisTarihi ? e.HizmetBitisTarihi : "-",
              activatedPerson: e.HizmetiBaslatanKisiAdSoyad || "-",
              customerNameSurname: e.MusteriAdSoyad,
              cardType: e.KartTipi,
              IUASTicketPrice: e.IUASFiyat,
              agentTicketPrice: e.AcenteFiyat,
            }))
          )
        )
        .finally(() => setLoading(false));
    },
    [agent, searchCardType, dateFilterType, ticketNum, refCode]
  );
  const HandleDeleteClick = useCallback(async () => {
    setAgent(null);
    setSearchCardType(null);
    setTicketNum("");
    setRefCode("");
    setDateFilterType(DATE_FILTERS[0].id);
  }, []);

  const [ticketLogs, setTicketLogs] = useState([]);
  //dialog settings
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const rightClickMenus = useMemo(
    () => [
      {
        translateName: "ticketMovements",
        clickMethod: async (selectedRow) => {
          TicketLogsGetAll({
            BiletReferansKodu: selectedRow.referanceNum,
            Filter: "BiletHareketleri",
          })
            .then((resp) =>
              setTicketLogs(
                resp.data.data.map(
                  ({
                    SatisId,
                    ModifyUserId,
                    SeferId,
                    RouteId,
                    StationId,
                    ...rest
                  }) => rest
                )
              )
            )
            .then(() => handleOpen());
        },
      },
    ],
    []
  );

  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <GetDataArea
        HandleSearchClick={HandleSearchClick}
        HandleDeleteClick={HandleDeleteClick}
      >
        <Grid xs={12} lg={2}>
          <TextField
            fullWidth
            size="small"
            select
            label="Tarih Filtresi Seçimi"
            value={dateFilterType}
            onChange={(e) => setDateFilterType(e.target.value)}
          >
            {DATE_FILTERS.map((e, index) => (
              <MenuItem key={index} value={e.id}>
                {e.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid xs={12} lg={2}>
          <Autocomplete
            fullWidth
            size="small"
            options={agentsOption}
            value={agent}
            onChange={(event, newValue) => {
              setAgent(newValue);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Acente" />
            )}
          />
        </Grid>
        <Grid xs={12} lg={2}>
          <Autocomplete
            fullWidth
            size="small"
            options={cardsOption}
            value={searchCardType}
            onChange={(event, newValue) => {
              setSearchCardType(newValue);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Kart Tipi" />
            )}
          />
        </Grid>
        <Grid xs={12} lg={2}>
          <TextField
            fullWidth
            size="small"
            label="Bilet Numarası"
            value={ticketNum}
            onChange={(e) => setTicketNum(e.target.value)}
          />
        </Grid>
        <Grid xs={12} lg={2}>
          <TextField
            fullWidth
            size="small"
            label="Referans Kodu"
            value={refCode}
            onChange={(e) => setRefCode(e.target.value)}
          />
        </Grid>
      </GetDataArea>
      <CustomDataGrid
        data={data}
        columns={columns}
        loading={loading}
        customRightClickMenus={rightClickMenus}
        getRowId={(row) => row.referanceNum}
        rowsUniqueId="referanceNum"
        allowEditDelete={false}
      />
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        scroll="paper"
      >
        <DialogContent>
          <DialogTitle sx={{ px: 0 }}>
            <FormattedMessage id="reports.ticketLog" />
          </DialogTitle>
          <TableContainer component={Paper}>
            <Table size="small" sx={{ minWidth: 650 }} aria-label="ticket logs">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Bilet Numarası</TableCell>
                  <TableCell>Sepet Referans Kodu</TableCell>
                  <TableCell>İşlem Tarihi</TableCell>
                  <TableCell>Düzenleyen Kişi</TableCell>
                  <TableCell>Durum</TableCell>
                  <TableCell>Sefer Tarihi</TableCell>
                  <TableCell>Otobüs Plakası</TableCell>
                  <TableCell>Güzergah</TableCell>
                  <TableCell>Durak</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ticketLogs.map((e) => (
                  <TableRow key={e.Id}>
                    <TableCell>{e.Id}</TableCell>
                    <TableCell>{e.BiletReferansKodu}</TableCell>
                    <TableCell>{e.SepetReferansKodu}</TableCell>
                    <TableCell>
                      {dayjs(e.IslemTarihi).format("DD.MM.YYYY HH:mm")}
                    </TableCell>
                    <TableCell>{e.ModifyUser}</TableCell>
                    <TableCell>
                      <Chip
                        size="small"
                        label={STATUS[e.Durum].text}
                        color={STATUS[e.Durum].color}
                        variant={STATUS[e.Durum].variant || "filled"}
                      />
                    </TableCell>
                    <TableCell>
                      {dayjs(e.SeferTarihi).format("DD.MM.YYYY HH:mm")}
                    </TableCell>
                    <TableCell>{e.OtobusPlakasi}</TableCell>
                    <TableCell>{e.Route}</TableCell>
                    <TableCell>{e.Station}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </AppContainer>
  );
}

export default withTitle(TicketDetail, "reports.ticketDetail");
