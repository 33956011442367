import DashboardIcon from "@mui/icons-material/Dashboard";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import CampaignIcon from "@mui/icons-material/Campaign";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import TabletAndroidIcon from "@mui/icons-material/TabletAndroid";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import TimelineIcon from "@mui/icons-material/Timeline";

//reports
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import SpeedIcon from "@mui/icons-material/Speed";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import FoodBankIcon from "@mui/icons-material/FoodBank";
import HailIcon from "@mui/icons-material/Hail";
//definitions
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import AddCardIcon from "@mui/icons-material/AddCard";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import GarageIcon from "@mui/icons-material/Garage";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import SellIcon from "@mui/icons-material/Sell";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ListAltIcon from "@mui/icons-material/ListAlt";

export const FilteredDefinitionLinks = (pages) => {
  var definitions = DefinitionLinks.filter((e) => pages.includes(e.name));
  return definitions;
};

export const GetFirstPagesPath = (pageTitle) => {
  return (
    [...MenuLinks, ...ReportLinks, ...DefinitionLinks].find(
      (e) => e.name === pageTitle
    )?.path || "/profile"
  );
};
export const MenuLinks = [
  {
    path: "/",
    name: "dashboard",
    icon: <DashboardIcon />,
  },
  {
    id: "16",
    path: "/qr-debit",
    name: "QRDebit",
    icon: <QrCode2Icon />,
  },
  {
    id: "17",
    path: "/agents",
    name: "agents",
    icon: <ApartmentIcon />,
  },
  {
    id: "11",
    path: "/users",
    name: "users",
    icon: <PeopleAltIcon />,
  },
  {
    id: "13",
    path: "/tickets",
    name: "tickets",
    icon: <ConfirmationNumberIcon />,
  },
  {
    id: "14",
    path: "/buses",
    name: "buses",
    icon: <DirectionsBusIcon />,
  },
  {
    id: "27",
    path: "/bus-routes",
    name: "busStops",
    icon: <TransferWithinAStationIcon />,
  },
  {
    id: "29",
    path: "/expeditions",
    name: "expeditions",
    icon: <DepartureBoardIcon />,
  },
  {
    id: "19",
    path: "/cards",
    name: "cards",
    icon: <CreditCardIcon />,
  },
  {
    id: "21",
    path: "/additional-services",
    name: "additionalServices",
    icon: <FastfoodIcon />,
  },
  {
    id: "25",
    path: "/devices",
    name: "devices",
    icon: <TabletAndroidIcon />,
  },
  {
    id: "22",
    path: "/announcements",
    name: "announcements",
    icon: <CampaignIcon />,
  },
  {
    id: "31",
    path: "/cancellation-requests",
    name: "cancellationRequests",
    icon: <CancelScheduleSendIcon />,
  },
  {
    id: "38",
    path: "/cancellation-reasons",
    name: "cancelReasons",
    icon: <ListAltIcon />,
  },
  {
    id: "35",
    path: "/roles",
    name: "roles",
    icon: <ContactEmergencyIcon />,
  },
  {
    id: "37",
    path: "/periodic-ticket-price",
    name: "periodicTicketPrice",
    icon: <PriceChangeIcon />,
  },
  {
    id: "33",
    path: "/driver-stop-notification",
    name: "driverStopNotification",
    icon: <ShareLocationIcon />,
  },
  {
    id: "34",
    path: "/sellTickets",
    name: "sellTickets",
    icon: <SellIcon />,
  },
  {
    id: "40",
    path: "/defineAdditionalService",
    name: "defineAdditionalService",
    icon: <SellIcon />,
  },
];

export const ReportLinks = [
  {
    id: "1",
    path: "/reports/ticket-detail",
    name: "reports.ticketDetail",
    icon: <ContentPasteSearchIcon />,
  },
  {
    id: "2",
    path: "/reports/ticket-progress",
    name: "reports.ticketProgress",
    icon: <LocalActivityIcon />,
  },
  {
    id: "3",
    path: "/reports/cancellation-requested-tickets",
    name: "reports.cancellationRequestedTickets",
    icon: <CancelPresentationIcon />,
  },
  {
    id: "9",
    path: "/reports/additional-service-tickets",
    name: "reports.additionalServiceTickets",
    icon: <FoodBankIcon />,
  },
  {
    id: "4",
    path: "/reports/service-track",
    name: "reports.serviceTrack",
    icon: <TroubleshootIcon />,
  },
  {
    id: "5",
    path: "/reports/login-logout",
    name: "reports.login-out",
    icon: <LockOpenIcon />,
  },
  {
    id: "6",
    path: "/reports/performance-track",
    name: "reports.performanceTrack",
    icon: <SpeedIcon />,
  },
  {
    id: "7",
    path: "/reports/card-sales",
    name: "reports.cardSales",
    icon: <LocalAtmIcon />,
  },
  {
    id: "8",
    path: "/reports/active-vehicles",
    name: "reports.activeVehicles",
    icon: <MinorCrashIcon />,
  },
  {
    id: "10",
    path: "/reports/passanger-trends",
    name: "reports.passangerTrends",
    icon: <HailIcon />,
  },
  {
    id: "39",
    path: "/reports/ticket-log",
    name: "reports.ticketLog",
    icon: <TimelineIcon />,
  },
];

export const DefinitionLinks = [
  { id: "12", path: "/users/add", name: "users.add", icon: <PersonAddIcon /> },
  {
    id: "36",
    path: "/roles/add",
    name: "roles.add",
    icon: <SupervisorAccountIcon />,
  },
  { id: "20", path: "/cards/add", name: "cards.add", icon: <AddCardIcon /> },
  {
    id: "21",
    path: "/additional-services/add",
    name: "additionalServices.add",
    icon: <FastfoodIcon />,
  },
  {
    id: "26",
    path: "/devices/add",
    name: "devices.add",
    icon: <AddToQueueIcon />,
  },
  {
    id: "28",
    path: "/bus-routes/add",
    name: "busStops.add",
    icon: <AddLocationAltIcon />,
  },
  { id: "15", path: "/buses/add", name: "buses.add", icon: <GarageIcon /> },
  {
    id: "30",
    path: "/expeditions/add",
    name: "expeditions.add",
    icon: <AddRoadIcon />,
  },
  {
    id: "18",
    path: "/agents/add",
    name: "agents.add",
    icon: <DomainAddIcon />,
  },
  {
    id: "32",
    path: "/periodic-ticket-price/add",
    name: "periodicTicketPrice.add",
    icon: <PriceChangeIcon />,
  },
  {
    id: "38",
    path: "/cancellation-reasons",
    name: "cancelReasons",
    icon: <ListAltIcon />,
  },
  {
    id: "24",
    path: "/announcements/add-group",
    name: "groups",
    icon: <GroupAddIcon />,
  },
  {
    id: "23",
    path: "/announcements/add",
    name: "announcements.add",
    icon: <NotificationAddIcon />,
  },
];
