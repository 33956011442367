import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import * as Yup from "yup";
import IntlTranslate from "../../helpers/IntlTranslate";
import { useFormik, Form, FormikProvider } from "formik";
import {
  Autocomplete,
  Box,
  FormGroup,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";
import withTitle from "../../helpers/hoc/withTitle";
import SnackbarComponent from "../../components/Snackbar";
import { useAuth } from "../../context/AuthContext";
import {
  GetUsersByAgentsId,
  AgentsGetAll,
  CreateQRDebit,
  GetCards,
  GetQRList,
} from "../../api/api";
import QrCodeIcon from "@mui/icons-material/QrCode";
import SellIcon from "@mui/icons-material/Sell";
function QRCodeDebit() {
  const breadcrumb = useMemo(
    () => [{ name: <FormattedMessage id="QRDebit" />, active: true }],
    []
  );
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  const [loading, setLoading] = useState(false);

  const { role, user } = useAuth();
  const [agents, setAgents] = useState([]);
  const [cardsOption, setCardsOption] = useState([]);

  const [QRCounts, setQRCounts] = useState([]);
  const [agentLimits, setAgentLimits] = useState({ price: 999999999, count: 999999999 });

  useEffect(() => {
    GetCards().then((resp) => {
      let cards = resp.data.data;
      if (role.isFullAgent && !role.roleData.some((t) => t.roleId === "5")) {
        let avaibleCards = [...new Set(QRCounts.map((t) => t[0].BiletTipi))];
        setCardsOption(
          cards
            .filter((e) => avaibleCards.includes(e.Id))
            .map((t) => ({
              id: t.Id,
              label: t.KartAdi,
              price: t.IsSeason !== null ? t.DonemselFiyat : t.Fiyat,
            }))
        );
      } else {
        setCardsOption(
          cards.map((e) => ({
            id: e.Id,
            label: e.KartAdi,
          }))
        );
      }
    });
  }, [role, QRCounts]);
  useEffect(() => {
    if (role.isFullAgent && !role.roleData.some((t) => t.roleId === "5")) {
      GetQRList({
        AcenteId: role.agentData.agentId,
      }).then((resp) => {
        let groupedByCards = groupBy(resp.data["data: "], "KartAdi");
        for (let i = 0; i < groupedByCards.length; i++) {
          groupedByCards[i] = groupedByCards[i].sort((a, b) => {
            return (
              (a.AcenteUser === null) + (b.AcenteUser === null) ||
              +(a.AcenteUser > b.AcenteUser) ||
              -(a.AcenteUser < b.AcenteUser)
            );
          });
        }
        setQRCounts(groupedByCards);
      });
    }
    if (role.isFullAgent) {
      GetUsersByAgentsId(role.agentData.agentId).then((resp) => {
        let filtered = resp.data["data: "]
          .filter(
            (v, i, a) =>
              a.findIndex((v2) => v2.AgencyUserId === v.AgencyUserId) === i
          )
          .filter((t) => t.Role !== "Agent")
          .map((e) => ({
            id: e.AgencyUserId,
            label: `${e.Name} ${e.Surname}`,
          }));
        setAgents(filtered);
      });
    } else {
      //IUAS
      AgentsGetAll().then((resp) =>
        setAgents(
          resp.data["data: "].map((e) => ({
            id: e.Id,
            label: `${e.Sirket}`,
          }))
        )
      );
    }
  }, [role]);

  const schema = Yup.object().shape({
    agent: Yup.string().required(IntlTranslate("agents.required")),
    price: Yup.number()
      .min(0, IntlTranslate("price.min"))
      .max(
        agentLimits.price,
        IntlTranslate("price.max", { maxPrice: agentLimits.price })
      )
      .required(IntlTranslate("price.required")),
    cardType: Yup.string(),
    skt: Yup.date()
      .min(dayjs(), IntlTranslate("skt.min"))
      .required(IntlTranslate("skt.required")),
    count: Yup.number()
      .min(0, IntlTranslate("count.min"))
      .max(
        agentLimits.count,
        IntlTranslate("count.max", { maxCount: agentLimits.count })
      )
      .required(IntlTranslate("count.required")),
    activationTime: Yup.number()
      .min(0, IntlTranslate("activationTime.min"))
      .required(IntlTranslate("activationTime.required")),
    usageTime: Yup.number()
      .min(0, IntlTranslate("usageTime.min"))
      .required(IntlTranslate("usageTime.required")),
  });

  const formik = useFormik({
    initialValues: {
      agent: null,
      cardType: "",
      price: "",
      skt: dayjs().year(dayjs().year() + 1),
      count: "",
      activationTime: "72",
      usageTime: "24",
    },
    validationSchema: schema,
    onSubmit: async (datas, { resetForm }) => {
      setLoading(true);
      datas.activationTime =
        timeSettings.activationTimeFreq === "days"
          ? datas.activationTime * 24
          : timeSettings.activationTimeFreq === "minutes"
          ? datas.activationTime / 60
          : datas.activationTime;
      datas.usageTime =
        timeSettings.usageTimeFreq === "days"
          ? datas.usageTime * 24
          : timeSettings.usageTimeFreq === "minutes"
          ? datas.usageTime / 60
          : datas.usageTime;

      var response = await CreateQRDebit({
        Id: 0,
        AcenteId: role.isFullAgent ? role.agentData.agentId : datas.agent,
        ...(role.isFullAgent &&
          role.roleData.some((t) => t.roleId === "2") && {
            AcenteUserId: datas.agent,
          }),
        BiletTipi: datas.cardType,
        Fiyat: datas.price,
        Miktar: datas.count,
        SonKullanmaTarihi: role.isFullAgent
          ? dayjs(role.agentData.skt, "DD-MM-YYYY HH:mm:ss").toISOString()
          : datas.skt.toISOString(),
        AktivasyonSuresi: role.isFullAgent
          ? role.agentData.activationHour
          : datas.activationTime,
        KullanimSuresi: role.isFullAgent
          ? role.agentData.usageHour
          : datas.usageTime,
        ModifyUser: user[0].Id,
        DeletedFlag: 0,
      });
      if (response.data.status === "success") {
        resetForm();
        setSnackbarType("success");
        setSnackbarOpen(true);
      } else {
        setSnackbarType("error");
        setSnackbarOpen(true);
      }
      setLoading(false);
    },
  });
  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;
  const [timeSettings, setTimeSettings] = useState({
    activationTimeFreq: "hours",
    usageTimeFreq: "hours",
  });
  const TimeSettingsOnChange = (e) => {
    const { name, value } = e.target;
    setTimeSettings({
      ...timeSettings,
      [name]: value,
    });
  };

  useEffect(() => {
    if (
      role.isFullAgent &&
      !role.roleData.some((t) => t.roleId === "5") &&
      values.cardType !== ""
    ) {
      var selectedCardCountLimit = QRCounts.find(
        (t) => t[0].BiletTipi === values.cardType
      )[0]?.ZimmetSum;
      var selectedCardPriceLimit = cardsOption.find(
        (t) => t.id === values.cardType
      )?.price;
      setAgentLimits({
        price: selectedCardPriceLimit,
        count: selectedCardCountLimit,
      });
    }
  }, [values.cardType, QRCounts, role, cardsOption]);

  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Stack sx={{ mt: 1 }} spacing={3}>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
              <Stack
                direction="row"
                spacing={2}
                sx={{ width: { xs: "100%", lg: "50%" } }}
              >
                <Autocomplete
                  fullWidth
                  options={agents}
                  getOptionLabel={(opt) => opt.label}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, data) => setFieldValue("agent", data.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={IntlTranslate(
                        role.isFullAgent &&
                          role.roleData.some((t) => t.roleId === "2")
                          ? "subagents"
                          : "agents"
                      )}
                      required
                      error={Boolean(touched.agent && errors.agent)}
                      helperText={touched.agent && errors.agent}
                    />
                  )}
                />
                <Autocomplete
                  fullWidth
                  options={cardsOption}
                  getOptionLabel={(opt) => opt.label}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, data) => setFieldValue("cardType", data.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={IntlTranslate("cardName")}
                      required
                      error={Boolean(touched.cardType && errors.cardType)}
                      helperText={touched.cardType && errors.cardType}
                    />
                  )}
                />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ width: { xs: "100%", lg: "50%" } }}
              >
                <TextField
                  required
                  fullWidth
                  type="number"
                  label={IntlTranslate("price")}
                  {...getFieldProps("price")}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  error={Boolean(touched.price && errors.price)}
                  helperText={touched.price && errors.price}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">₺</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  required
                  fullWidth
                  type="number"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  label={IntlTranslate("count")}
                  {...getFieldProps("count")}
                  placeholder="100"
                  error={Boolean(touched.count && errors.count)}
                  helperText={touched.count && errors.count}
                />
              </Stack>
            </Stack>
            {!role.isFullAgent && (
              <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ width: { xs: "100%", lg: "50%" } }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      required
                      label={IntlTranslate("skt")}
                      minDate={dayjs()}
                      value={values.skt}
                      onChange={(newValue) => setFieldValue("skt", newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={Boolean(touched.skt && errors.skt)}
                          helperText={
                            touched.skt
                              ? errors.skt
                              : params?.inputProps?.placeholder
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
                <Stack
                  direction={{ xs: "columnn", lg: "row" }}
                  spacing={2}
                  sx={{ width: { xs: "100%", lg: "50%" } }}
                >
                  <FormGroup row sx={{ width: { xs: "100%", lg: "50%" } }}>
                    <TextField
                      required
                      sx={{
                        width: "70%",
                        "& fieldset": {
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          borderRight: 0,
                        },
                      }}
                      type="number"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      label={IntlTranslate("activationTime")}
                      {...getFieldProps("activationTime")}
                      placeholder="72"
                      error={Boolean(
                        touched.activationTime && errors.activationTime
                      )}
                      helperText={
                        touched.activationTime && errors.activationTime
                      }
                    />
                    <TextField
                      select
                      sx={{
                        width: "30%",
                        "& fieldset": {
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderLeft: 0,
                        },
                      }}
                      helperText=" "
                      name="activationTimeFreq"
                      value={timeSettings.activationTimeFreq}
                      onChange={TimeSettingsOnChange}
                    >
                      <MenuItem value="minutes">
                        <FormattedMessage id="minutes" />
                      </MenuItem>
                      <MenuItem value="hours">
                        <FormattedMessage id="hours" />
                      </MenuItem>
                      <MenuItem value="days">
                        <FormattedMessage id="days" />
                      </MenuItem>
                    </TextField>
                  </FormGroup>
                  <FormGroup row sx={{ width: { xs: "100%", lg: "50%" } }}>
                    <TextField
                      required
                      sx={{
                        width: "70%",
                        "& fieldset": {
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          borderRight: 0,
                        },
                      }}
                      type="number"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      label={IntlTranslate("usageTime")}
                      {...getFieldProps("usageTime")}
                      placeholder="24"
                      error={Boolean(touched.usageTime && errors.usageTime)}
                      helperText={touched.usageTime && errors.usageTime}
                    />
                    <TextField
                      select
                      sx={{
                        width: "30%",
                        "& fieldset": {
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderLeft: 0,
                        },
                      }}
                      helperText=" "
                      name="usageTimeFreq"
                      value={timeSettings.usageTimeFreq}
                      onChange={TimeSettingsOnChange}
                    >
                      <MenuItem value="minutes">
                        <FormattedMessage id="minutes" />
                      </MenuItem>
                      <MenuItem value="hours">
                        <FormattedMessage id="hours" />
                      </MenuItem>
                      <MenuItem value="days">
                        <FormattedMessage id="days" />
                      </MenuItem>
                    </TextField>
                  </FormGroup>
                </Stack>
              </Stack>
            )}
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="success"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
            >
              <FormattedMessage id="assign" />
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      <Grid container sx={{ mt: 3 }} spacing={3}>
        {QRCounts.map((e, i) => (
          <Grid key={i} xs={12} md={6} lg={4} xl={3}>
            <Paper variant="outlined" sx={{ px: 2, py: 3 }}>
              <Typography
                variant="h4"
                sx={{ letterSpacing: ".5px", fontWeight: "100" }}
              >
                {e[0].KartAdi}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #000",
                  my: 2,
                  color: "success.main",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <QrCodeIcon />
                  <Typography variant="h6" sx={{ ml: 1 }}>
                    KALAN QR
                  </Typography>
                </Box>
                <Typography variant="h6">
                  <b>{e[0].ZimmetSum}</b>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #000",
                  mb: 3,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <SellIcon />
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    Satılan QR
                  </Typography>
                </Box>
                <Typography variant="body1">
                  <b>{e[0].SatisSum}</b>
                </Typography>
              </Box>
              {e.length > 1 && (
                <>
                  <Typography variant="h6" sx={{ textAlign: "center", mb: 1 }}>
                    Alt Acente Zimmetleri
                  </Typography>
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 650, "& th": { p: 0 } }}
                      size="small"
                      aria-label="sub agents qr infos"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>Alt Acente</TableCell>
                          <TableCell>Satılan QR</TableCell>
                          <TableCell align="right">Kalan QR</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {e.slice(1).map((a, ins) => (
                          <TableRow
                            key={ins}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              "& td": { p: 0 },
                            }}
                          >
                            <TableCell>
                              <b>{ins + 1}</b>
                            </TableCell>
                            <TableCell>{a.AcenteUser}</TableCell>
                            <TableCell>{a.SatisSum}</TableCell>
                            <TableCell align="right">{a.ZimmetSum}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter
                        sx={{
                          borderTop: "2px solid #000",
                        }}
                      >
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                            "& td": { px: 0 },
                          }}
                        >
                          <TableCell>TOPLAM</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            {e
                              .slice(1)
                              .reduce(
                                (acc, val) => acc + Number(val.SatisSum),
                                0
                              )}
                          </TableCell>
                          <TableCell align="right">
                            {e
                              .slice(1)
                              .reduce(
                                (acc, val) => acc + Number(val.ZimmetSum),
                                0
                              )}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate("qrdebitsuccessadd")}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(QRCodeDebit, "QRDebit");

function groupBy(collection, property) {
  var i = 0,
    val,
    index,
    values = [],
    result = [];
  for (; i < collection.length; i++) {
    val = collection[i][property];
    index = values.indexOf(val);
    if (index > -1) result[index].push(collection[i]);
    else {
      values.push(val);
      result.push([collection[i]]);
    }
  }
  return result;
}
