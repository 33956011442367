import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import {
  AgentsGetAll,
  TicketProgressGetAll,
  SubAgencyPaymentsSelectAll,
} from "../../api/api";
import CustomDataGrid from "../../components/CustomDataGrid";
import GetDataArea from "../../components/GetDataArea";
import {
  Autocomplete,
  Dialog,
  DialogContent,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";

function TicketProgress() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="reports.ticketProgress" />,
        active: true,
      },
    ],
    []
  );

  const zimmetColumns = useMemo(
    () => [
      {
        field: "Id",
        headerName: "ID",
        minWidth: 75,
      },
      {
        field: "AcenteId",
        headerName: "Acente ID",
        minWidth: 75,
        hideable: false,
      },
      {
        field: "ZimmetTarihi",
        headerName: "Tarih",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm");
        },
      },
      {
        field: "Acente",
        headerName: "Acente",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "KartAdi",
        headerName: "Kart Tipi",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "ZimmetMiktari",
        headerName: "Zimmetlenen QR Sayısı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "ZimmetFiyati",
        headerName: "QR Adet Fiyatı",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          return `${params.value} ₺`;
        },
      },
      {
        field: "ZimmetToplamMaliyet",
        headerName: "QR Maliyet Fiyatı",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          return `${params.value} ₺`;
        },
      },
      {
        field: "IUASFiyat",
        headerName: "IUAŞ Kart Adet Fiyatı",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          return `${params.value} ₺`;
        },
      },
      {
        field: "ToplamSatisFiyati",
        headerName: "IUAŞ Toplam Satış",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          return `${params.value} ₺`;
        },
      },
    ],
    []
  );

  const acenteHakedisColumns = useMemo(
    () => [
      {
        field: "Id",
        headerName: "ID",
        minWidth: 75,
      },
      {
        field: "AcenteId",
        headerName: "Acente ID",
        minWidth: 75,
        hideable: false,
      },
      {
        field: "Acente",
        headerName: "Acente",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "KartAdi",
        headerName: "Kart Tipi",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "ToplamZimmetMiktari",
        headerName: "Toplam Zimmet Miktarı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "AltAcenteZimmetMiktari",
        headerName: "Alt Acente Zimmet Miktarı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "ToplamSatisAdet",
        headerName: "Toplam Satış Miktarı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "ToplamAktiveAdet",
        headerName: "Toplam Aktivasyon Miktarı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "KalanZimmetMiktari",
        headerName: "Kalan Zimmet Miktarı",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => <b>{params.value}</b>,
      },
    ],
    []
  );

  const [currentColumn, setCurrentColumn] = useState(zimmetColumns);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //GET DATA FILTERS
  const [agents, setAgents] = useState(null);
  const [progressType, setProgressType] = useState("ZimmetHakedis");
  const [agentsOption, setAgentsOption] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  useEffect(() => {
    AgentsGetAll().then((resp) =>
      setAgentsOption(
        resp.data["data: "].map((e) => ({
          id: e.Id,
          label: `${e.Acente} ${e.Sirket}`,
        }))
      )
    );
  }, []);
  const [dates, setDates] = useState({ start: "", end: "" });
  const HandleSearchClick = useCallback(
    async (start, end) => {
      setDates({ start, end });
      setLoading(true);
      setIsLoaded(false);
      await TicketProgressGetAll({
        DateStart: dayjs(start).toISOString(),
        DateEnd: dayjs(end).toISOString(),
        Filter: progressType,
        AcenteId: agents?.id,
      })
        .then((resp) => {
          if (progressType === "ZimmetHakedis") {
            setCurrentColumn(zimmetColumns);
            setData(
              resp.data.data.map(({ BiletTipi, IsSeason, ...rest }) => rest)
            );
          } else {
            setCurrentColumn(acenteHakedisColumns);
            setData(
              resp.data.data.map((e, i) => ({
                Id: i,
                AcenteId: e.AcenteId,
                Acente: e.Acente,
                KartAdi: e.KartAdi,
                ToplamZimmetMiktari: e.ToplamZimmetMiktari || 0,
                AltAcenteZimmetMiktari: e.AltAcenteZimmetMiktari || 0,
                ToplamSatisAdet: e.ToplamSatisAdet || 0,
                ToplamAktiveAdet: e.ToplamAktiveAdet || 0,
                KalanZimmetMiktari: e.KalanZimmetMiktari || 0,
              }))
            );
          }
        })
        .finally(() => {
          setLoading(false);
          setIsLoaded(true);
        });
    },
    [agents, progressType, zimmetColumns, acenteHakedisColumns]
  );
  const HandleDeleteClick = useCallback(async () => {
    setAgents(null);
    setProgressType("ZimmetHakedis");
  }, []);

  //right click
  const [subAgentsDebits, setSubAgentsDebits] = useState([]);
  //dialog settings
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const rightClickMenus = useMemo(
    () => [
      {
        translateName: "subAgentDebits",
        clickMethod: async (selectedRow) => {
          SubAgencyPaymentsSelectAll({
            DateStart: dayjs(dates.start).toISOString(),
            DateEnd: dayjs(dates.end).toISOString(),
            AcenteId: selectedRow.AcenteId,
          })
            .then((resp) =>
              setSubAgentsDebits(
                resp.data.data.map(
                  ({ AcenteId, AcenteUserId, BiletTipi, ...rest }) => rest
                )
              )
            )
            .then(() => handleOpen());
        },
      },
    ],
    [dates]
  );
  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <GetDataArea
        HandleSearchClick={HandleSearchClick}
        HandleDeleteClick={HandleDeleteClick}
      >
        <Grid xs={12} lg={2}>
          <Autocomplete
            fullWidth
            size="small"
            options={agentsOption}
            value={agents}
            onChange={(event, newValue) => {
              setAgents(newValue);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Acente" />
            )}
          />
        </Grid>
        <Grid xs={12} lg={2}>
          <TextField
            fullWidth
            size="small"
            select
            label="Hakediş Tipi"
            value={progressType}
            onChange={(e) => setProgressType(e.target.value)}
          >
            <MenuItem value="ZimmetHakedis">Zimmet Hakediş</MenuItem>
            <MenuItem value="AcenteHakedis">Acente Hakediş</MenuItem>
          </TextField>
        </Grid>
      </GetDataArea>
      {isLoaded && (
        <CustomDataGrid
          data={data}
          columns={currentColumn}
          loading={loading}
          allowEditDelete={false}
          getRowId={(row) => row.Id}
          rowsUniqueId="Id"
          customRightClickMenus={rightClickMenus}
          initialState={{
            columns: {
              columnVisibilityModel: {
                AcenteId: false,
                traderName: false,
              },
            },
          }}
        />
      )}
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        scroll="paper"
      >
        <DialogContent>
          <TableContainer component={Paper}>
            <Table
              size="small"
              sx={{ minWidth: 650 }}
              aria-label="sub agents qr debits"
            >
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Zimmet Tarihi</TableCell>
                  <TableCell>Acente</TableCell>
                  <TableCell>Alt Acente</TableCell>
                  <TableCell>Kart Tipi</TableCell>
                  <TableCell>Zimmetlenen QR Sayısı</TableCell>
                  <TableCell>QR Adet Fiyatı</TableCell>
                  <TableCell>QR Maliyet Fiyatı</TableCell>
                  <TableCell>IUAŞ Kart Adet Fiyatı</TableCell>
                  <TableCell>IUAŞ Toplam Satış</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subAgentsDebits.map((e) => (
                  <TableRow key={e.Id}>
                    <TableCell>{e.Id}</TableCell>
                    <TableCell>
                      {dayjs(e.ZimmetTarihi).format("DD.MM.YYYY HH:mm")}
                    </TableCell>
                    <TableCell>{e.Acente}</TableCell>
                    <TableCell>{e.AcenteUser}</TableCell>
                    <TableCell>{e.KartAdi}</TableCell>
                    <TableCell>{e.ZimmetMiktari}</TableCell>
                    <TableCell>{e.ZimmetFiyati} ₺</TableCell>
                    <TableCell>{e.ZimmetToplamMaliyet} ₺</TableCell>
                    <TableCell sx={{ fontWeight: e.IsSeason && "bold" }}>
                      {e.IUASFiyat} ₺{" "}
                      {e.IsSeason && (
                        <Tooltip
                          title={
                            <FormattedMessage id="periodicTicketPriceDefined" />
                          }
                          placement="bottom"
                        >
                          <OnlinePredictionIcon
                            sx={{ ml: 1, verticalAlign: "inherit" }}
                            color="secondary"
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>{e.ToplamSatisFiyati} ₺</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell align="center">
                    <b>{subAgentsDebits.length}</b> Adet
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    <>
                      {Object.entries(
                        subAgentsDebits.reduce((acc, item) => {
                          return {
                            ...acc,
                            [item.KartAdi]:
                              (acc[item.KartAdi] || 0) + item.ZimmetMiktari,
                          };
                        }, {})
                      ).map(([key, value]) => (
                        <p key={key}>
                          {key} : {value}
                        </p>
                      ))}
                    </>
                    <b>
                      <p>
                        Toplam:{" "}
                        {subAgentsDebits.reduce(
                          (acc, item) => acc + item.ZimmetMiktari,
                          0
                        )}
                      </p>
                    </b>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </AppContainer>
  );
}

export default withTitle(TicketProgress, "reports.ticketProgress");
