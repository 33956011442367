import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import IntlTranslate from "../../helpers/IntlTranslate";
import withTitle from "../../helpers/hoc/withTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SnackbarComponent from "../../components/Snackbar";
import {
  AddUserToGroup,
  CreateAnnouncementGroup,
  DeleteAnnouncementGroup,
  DeleteUserFromGroup,
  GetAllUsers,
  GetAnnouncementGroups,
  GroupRename,
} from "../../api/api";
import dayjs from "dayjs";
import GroupUpdateDialog from "./GroupUpdateDialog";
function AnnouncementsGroups() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="announcements" />,
        path: "/announcements",
      },
      { name: <FormattedMessage id="groups" />, active: true },
    ],
    []
  );
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props

  //getdata
  const [data, setData] = useState([]);
  const [refreshData, setRefreshData] = useState(new Date().getTime());
  useEffect(() => {
    GetAnnouncementGroups().then((resp) => {
      var result = resp.data.data.reduce(function (r, a) {
        r[a.GrupId] = r[a.GrupId] || [];
        r[a.GrupId].push(a);
        return r;
      }, Object.create(null));
      setData(Object.values(result));
    });
  }, [refreshData]);
  //getdata

  //formik
  const [loading, setLoading] = useState(false);
  const schema = Yup.object().shape({
    groupName: Yup.string().required(IntlTranslate("groupName.required")),
  });
  const formik = useFormik({
    initialValues: {
      groupName: "",
    },
    validationSchema: schema,
    onSubmit: async (datas, { resetForm }) => {
      setLoading(true);
      let response = await CreateAnnouncementGroup({
        Id: 0,
        GroupName: datas.groupName,
        Users: persons.map((e) => e.id),
      });
      if (response.data.status === "success" && response.data.Id) {
        resetForm();
        setPersons([]);
        setRefreshData(new Date().getTime());
        setSnackbarType("success");
        setSnackbarOpen(true);
      }
      setLoading(false);
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  const [persons, setPersons] = useState([]);
  const [usersOption, setUsersOption] = useState([]);
  useEffect(() => {
    GetAllUsers().then((resp) =>
      setUsersOption(
        resp.map((e) => ({ id: e.Id, name: `${e.Name} ${e.Surname}` }))
      )
    );
  }, []);

  const [selectedRow, setSelectedRow] = useState("");
  const [openDialog, setOpenDialog] = useState({
    operation: "",
    open: false,
    data: "",
  });
  const handleClickOpen = (type) => {
    setOpenDialog({ operation: type, open: true });
  };
  const handleCloseDialog = () => {
    setOpenDialog({
      operation: "",
      open: false,
    });
    setSelectedRow("");
    setOldUsers([]);
  };

  const onDelete = () => {
    DeleteAnnouncementGroup(selectedRow).then((resp) => {
      if (resp.data.status === "success" && resp.data.Id === 0) {
        setSnackbarType("success");
        setSnackbarOpen(true);
        setOpenDialog(prev => ({
          operation: prev.operation,
          open: false,
        }));
        setSelectedRow("");
        setRefreshData(new Date().getTime());
      }
    });
  };

  const [updateInputs, setUpdateInputs] = useState({
    GroupName: "",
    Users: "",
  });
  useEffect(() => {
    if (openDialog.operation === "update") {
      setUpdateInputs({
        GroupName:
          data.find((e) => e[0].GrupId === selectedRow) &&
          data.find((e) => e[0].GrupId === selectedRow)[0].GroupName,
        Users:
          data.find((e) => e[0].GrupId === selectedRow) &&
          data
            .find((e) => e[0].GrupId === selectedRow)
            .map((t) => ({ id: t.Users, name: t.UsersName })),
      });
    }
  }, [data, selectedRow, openDialog]);
  const [oldUsers, setOldUsers] = useState([]);
  useEffect(() => {
    if (openDialog.operation === "update") {
      let temp =
        data.find((e) => e[0].GrupId === selectedRow) &&
        data
          .find((e) => e[0].GrupId === selectedRow)
          .map((t) => ({ id: t.Users, name: t.UsersName }));
      setOldUsers(temp);
    }
  }, [selectedRow, data, openDialog]);
  const onUpdate = useCallback(async () => {
    await GroupRename({
      GrupId: selectedRow,
      GroupName: updateInputs.GroupName,
    });

    //user update
    let servicesObj = { add: [], remove: [] };
    let oldIds = oldUsers.map(({ id }) => id);
    let newIds = updateInputs.Users.map(({ id }) => id);
    for (let a = 0; a < newIds.length; a++) {
      if (!oldIds.includes(newIds[a])) {
        servicesObj.add.push(newIds[a]);
      }
    }
    for (let b = 0; b < oldIds.length; b++) {
      if (!newIds.includes(oldIds[b])) {
        servicesObj.remove.push(oldIds[b]);
      }
    }
    let k = 0;
    while (k < servicesObj.add.length) {
      let resp = await AddUserToGroup({
        GrupId: selectedRow,
        UserId: servicesObj.add[k],
      });
      if (resp.data.status === "success" && resp.data.Id) {
        k++;
      } else {
        break;
      }
    }
    k = 0;
    while (k < servicesObj.remove.length) {
      let resp = await DeleteUserFromGroup({
        GrupId: selectedRow,
        UserId: servicesObj.remove[k],
      });
      if (resp.data.status === "success" && resp.data.Id === 0) {
        k++;
      } else {
        break;
      }
    }
    //users update

    setSnackbarType("success");
    setSnackbarOpen(true);
    setOpenDialog(prev => ({
      operation: prev.operation,
      open: false,
    }));
    setSelectedRow("");
    setOldUsers([]);
    setRefreshData(new Date().getTime());
  }, [selectedRow, updateInputs, oldUsers]);

  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Stack sx={{ mt: 1, mb: 2 }} spacing={3}>
            <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
              <TextField
                required
                fullWidth
                type="text"
                label={IntlTranslate("groupName")}
                {...getFieldProps("groupName")}
                error={Boolean(touched.groupName && errors.groupName)}
                helperText={touched.groupName && errors.groupName}
              />
              <Autocomplete
                fullWidth
                multiple
                disableCloseOnSelect
                options={usersOption}
                getOptionLabel={(opt) => opt.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, data) => setPersons(data)}
                value={persons}
                renderInput={(params) => (
                  <TextField {...params} label={IntlTranslate("persons")} />
                )}
              />
            </Stack>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="success"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
            >
              <FormattedMessage id="add" />
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      <TableContainer component={Paper}>
        <Table aria-label="groups table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Oluşturma Tarihi</TableCell>
              <TableCell>Grup Adı</TableCell>
              <TableCell>Kişi Sayısı</TableCell>
              <TableCell align="right">Seçenekler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row[0].GrupId}</TableCell>
                <TableCell>
                  {dayjs(row[0].CreateDate).format("DD/MM/YY HH:mm")}
                </TableCell>
                <TableCell>{row[0].GroupName}</TableCell>
                <TableCell>{row.length}</TableCell>
                <TableCell align="right">
                  <IconButton
                    color="warning"
                    aria-label="edit"
                    onClick={() => {
                      handleClickOpen("update");
                      setSelectedRow(row[0].GrupId);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    sx={{ ml: 1 }}
                    onClick={() => {
                      handleClickOpen("delete");
                      setSelectedRow(row[0].GrupId);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate(
          openDialog.operation === "delete"
            ? "deleteSuccess"
            : openDialog.operation === "update"
            ? "updateSuccess"
            : "groupsuccessadd"
        )}
        alertType={snackbarType}
        onClose={handleClose}
      />
      <Dialog
        open={
          openDialog.operation === "delete" && selectedRow !== "" ? true : false
        }
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          <FormattedMessage id="delete_item" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id="deleteitemmessage" />
            {data.find((e) => e[0].GrupId === selectedRow) &&
              data.find((e) => e[0].GrupId === selectedRow)[0].GroupName}
            {data.find((e) => e[0].GrupId === selectedRow) &&
              data.find((e) => e[0].GrupId === selectedRow)[0].GrupId}
            ? <FormattedMessage id="deleteitemmessage2" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            <FormattedMessage id="cancelbtn" />
          </Button>
          <Button onClick={onDelete} color="secondary" autoFocus>
            <FormattedMessage id="deletebtn" />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={
          openDialog.operation === "update" && selectedRow !== "" ? true : false
        }
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          <FormattedMessage id="update_item" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id="updateitemmessage" />
            {data.find((e) => e[0].GrupId === selectedRow) &&
              data.find((e) => e[0].GrupId === selectedRow)[0].GroupName}
            {data.find((e) => e[0].GrupId === selectedRow) &&
              data.find((e) => e[0].GrupId === selectedRow)[0].GrupId}
            {" ?"}
          </DialogContentText>
          <GroupUpdateDialog
            updateInputs={{
              GroupName:
                data.find((e) => e[0].GrupId === selectedRow) &&
                data.find((e) => e[0].GrupId === selectedRow)[0].GroupName,
              Users:
                data.find((e) => e[0].GrupId === selectedRow) &&
                data
                  .find((e) => e[0].GrupId === selectedRow)
                  .map((t) => ({ id: t.Users, name: t.UsersName })),
            }}
            setUpdateInputs={setUpdateInputs}
            usersOption={usersOption}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            <FormattedMessage id="cancelbtn" />
          </Button>
          <Button onClick={onUpdate} color="secondary" autoFocus>
            <FormattedMessage id="updatebtn" />
          </Button>
        </DialogActions>
      </Dialog>
    </AppContainer>
  );
}

export default withTitle(AnnouncementsGroups, "groups");
