import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { AdditionalServicesSelectAll, UseOrAddService } from "../../api/api";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import IntlTranslate from "../../helpers/IntlTranslate";
import {
  Button,
  Paper,
  Stack,
  TextField,
  TableContainer,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Chip,
  Tooltip,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SnackbarComponent from "../../components/Snackbar";
import { useAuth } from "../../context/AuthContext";
const COLORS = [
  {
    bg: "#32a852",
    text: "#fff",
  },
  {
    bg: "#212121",
    text: "#fff",
  },
  {
    bg: "#5B3A29",
    text: "#fff",
  },
  {
    bg: "#1D1E33",
    text: "#fff",
  },
  {
    bg: "#C51D34",
    text: "#fff",
  },
  {
    bg: "#102C54",
    text: "#fff",
  },
  {
    bg: "#E55137",
    text: "#fff",
  },
];
function SellAdditionalService() {
  const breadcrumb = useMemo(
    () => [
      { name: <FormattedMessage id="defineAdditionalService" />, active: true },
    ],
    []
  );
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  const { user, role } = useAuth();
  const [additionalServiceOption, setAdditionalServiceOption] = useState([]);
  useEffect(() => {
    AdditionalServicesSelectAll().then((resp) => {
      if (role.isFullAgent) {
        setAdditionalServiceOption(
          resp.data.data.filter(
            (e) => e.EkHizmetFiyati !== null && e.AcenteSatabilir === true
          )
        );
      } else {
        setAdditionalServiceOption(
          resp.data.data.filter((e) => e.EkHizmetFiyati !== null)
        );
      }
    });
  }, [role.isFullAgent]);
  const [additionalServices, setAdditionalServices] = useState([]);
  const [ticketNo, setTicketNo] = useState("");

  const AddService = (
    serviceId,
    serviceName,
    servicePrice,
    serviceExtraTime,
    additionalSerivcesId = ""
  ) => {
    setAdditionalServices((prev) => [
      ...prev,
      {
        id: prev.length > 0 ? prev[prev.length - 1].id + 1 : 1,
        additionalSerivcesId,
        serviceId,
        serviceName,
        servicePrice,
        serviceExtraTime,
        isUsed: additionalSerivcesId === "" ? false : true,
      },
    ]);
  };
  const ServiceDeleteFromList = (id) => {
    let temp = JSON.parse(JSON.stringify(additionalServices));
    temp.splice(
      temp.findIndex((a) => a.id === id),
      1
    );
    setAdditionalServices(temp);
  };

  const ServiceSetUsed = (id) => {
    let temp = JSON.parse(JSON.stringify(additionalServices));
    temp.find((e) => e.id === id).isUsed = true;
    setAdditionalServices(temp);
  };

  const [errors, setErrors] = useState("");
  const handleChange = (e) => {
    if (e.target.value === "") {
      setErrors("Bilet numarası giriniz.");
    } else {
      setErrors("");
    }
    setTicketNo(e.target.value);
  };
  const submitAdditionalServices = async () => {
    let i = 0;
    if (ticketNo === "") {
      setErrors("Bilet numarası giriniz.");
    } else {
      while (i < additionalServices.length) {
        if (additionalServices[i].additionalSerivcesId) {
          let result = await UseOrAddService({
            Id: additionalServices[i].additionalSerivcesId,
            IsUsed: additionalServices[i].isUsed,
            ModifyUserId: user[0].Id,
          });
          if (result.data.status === "success") {
            i++;
          } else {
            break;
          }
        } else {
          let result = await UseOrAddService({
            Id: 0,
            BiletReferansKodu: ticketNo,
            EkHizmetId: additionalServices[i].serviceId,
            IsUsed: additionalServices[i].isUsed,
            ModifyUserId: user[0].Id,
          });
          if (result.data.status === "success") {
            i++;
          } else {
            break;
          }
        }
      }
      setSnackbarType("success");
      setSnackbarOpen(true);
      setAdditionalServices([]);
    }
  };

  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <Stack sx={{ mt: 1 }} spacing={3}>
        <TextField
          fullWidth
          required
          label={IntlTranslate("ticketNo")}
          value={ticketNo}
          onChange={handleChange}
          error={errors !== ""}
          helperText={errors !== "" && errors}
        />
        <Typography variant="h6" component="h2">
          <FormattedMessage id="additionalServices2" />
        </Typography>
        <Divider />
        <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
          {additionalServiceOption.map((e, i) => (
            <Button
              key={i}
              variant="outlined"
              endIcon={<AddIcon />}
              onClick={() =>
                AddService(e.Id, e.EkHizmetAdi, e.EkHizmetFiyati, e.EkSure)
              }
              disabled={!Boolean(e.Satilabilir)}
            >
              {e.EkHizmetAdi}
              {e.EkHizmetFiyati !== null && e.EkSure !== null
                ? ` | ${e.EkHizmetFiyati} TL | +${e.EkSure} saat`
                : ""}
            </Button>
          ))}
        </Stack>
        <TableContainer component={Paper}>
          <Table aria-label="additional services table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Hizmet Adı</TableCell>
                <TableCell>Hizmet Fiyatı</TableCell>
                <TableCell>Hizmet Ek Süresi</TableCell>
                <TableCell align="right">Seçenekler</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {additionalServices.map((t, i) => {
                if (t.additionalSerivcesId === "") {
                  return (
                    <TableRow key={i}>
                      <TableCell>
                        <Chip
                          disabled={
                            role.isFullAgent
                              ? t.AcenteEkHizmetKullandirabilir
                                ? false
                                : true
                              : t.isUsed
                          }
                          label={t.serviceName}
                          size="small"
                          sx={{
                            px: 2,
                            backgroundColor: COLORS[i % COLORS.length].bg,
                            color: COLORS[i % COLORS.length].text,
                            fontSize: "1rem",
                          }}
                        />
                      </TableCell>
                      <TableCell>{t.servicePrice}</TableCell>
                      <TableCell>{t.serviceExtraTime}</TableCell>
                      <TableCell align="right">
                        <Tooltip
                          placement="left"
                          title={
                            <FormattedMessage
                              id={t.isUsed ? "usedService" : "useService"}
                            />
                          }
                        >
                          <span>
                            <IconButton
                              color="info"
                              aria-label="Use Service"
                              disabled={
                                role.isFullAgent
                                  ? t.AcenteEkHizmetKullandirabilir
                                    ? false
                                    : true
                                  : t.isUsed
                              }
                              onClick={() => ServiceSetUsed(t.id)}
                            >
                              <TouchAppIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip
                          placement="left"
                          title={<FormattedMessage id="deletebtn" />}
                        >
                          <IconButton
                            color="error"
                            aria-label="Delete Service"
                            onClick={() => ServiceDeleteFromList(t.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  return null;
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Button
        fullWidth
        variant="contained"
        color="success"
        sx={{ mt: 3 }}
        onClick={submitAdditionalServices}
      >
        <FormattedMessage id="updatebtn" />
      </Button>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate("additionalServices2.success")}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(SellAdditionalService, "defineAdditionalService");
