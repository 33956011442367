import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import FlagIcon from "@mui/icons-material/Flag";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
const draggingStyles = {
  backgroundColor: "#f2f2f2",
  border: "1px solid #000",
};
function DraggableListItem({ item, index, size, handleDelete }) {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{ mt: 1, ...(snapshot.isDragging && draggingStyles) }}
          component={Paper}
        >
          {index === 0 ? (
            <ListItemIcon>
              <DirectionsBusIcon color="info" sx={{ width: 24, height: 24 }} />
            </ListItemIcon>
          ) : index === size - 1 ? (
            <ListItemIcon>
              <FlagIcon color="success" sx={{ width: 24, height: 24 }} />
            </ListItemIcon>
          ) : (
            <ListItemAvatar>
              <Avatar sx={{ width: 24, height: 24 }} alt={item.name}>
                {index + 1}
              </Avatar>
            </ListItemAvatar>
          )}

          <ListItemText primary={item.name} />
          <IconButton
            size="small"
            color="error"
            aria-label="delete"
            onClick={() => handleDelete(item.id)}
          >
            <DeleteIcon />
          </IconButton>
          <DragIndicatorIcon />
        </ListItem>
      )}
    </Draggable>
  );
}

export default DraggableListItem;
