import {
  Autocomplete,
  Chip,
  FormGroup,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import CustomDataGrid from "../../components/CustomDataGrid";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import GetDataArea from "../../components/GetDataArea";
import {
  AdditionalServicesSelectAll,
  GetCards,
  TicketsServicesSelectAll,
} from "../../api/api";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";
import {
  CalculateRemainingTime,
  CalculateBeforeDate,
} from "../../helpers/CalculateRemainingTime";
const COLORS = ["primary", "secondary", "warning", "info", "error"];
function AdditionalServiceDefinedTickets() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="reports.additionalServiceTickets" />,
        active: true,
      },
    ],
    []
  );
  const columns = useMemo(
    () => [
      {
        field: "referanceNum",
        headerName: "Referans Numarası",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "nameSurname",
        headerName: "Ad Soyad",
        minWidth: 150,
        flex: 1,
      },
      { field: "cardType", headerName: "Kart Tipi", minWidth: 150, flex: 1 },
      {
        field: "date",
        headerName: "Satın Alma Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm");
        },
      },
      {
        field: "usageTimeRemaining",
        headerName: "Kalan Hizmet Süresi",
        width: 150,
      },
      {
        field: "activationTimeRemaining",
        headerName: "Kalan Aktivasyon Süresi",
        width: 150,
      },
      {
        field: "additionalServiceList",
        headerName: "Ek Hizmetler",
        flex: 1,
        renderCell: (params) => {
          if (params.value) {
            let serviceList = params.value.split(",");
            return serviceList.map((item, index) => (
              <Chip
                key={index}
                sx={{ marginRight: 1 }}
                label={item}
                color={COLORS[index % COLORS.length]}
                size="small"
              />
            ));
          } else {
            return "-";
          }
        },
      },
    ],
    []
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //GET DATA FILTERS
  const [searchCardType, setSearchCardType] = useState(null);
  const [additionalServices, setAdditionalServices] = useState(null);

  const [timeRemainings, setTimeRemainings] = useState([
    { name: "utrMin", value: "" },
    { name: "utrMax", value: "" },
    { name: "atrMin", value: "" },
    { name: "atrMax", value: "" },
  ]);
  const handleNumberInputsChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      let temp = JSON.parse(JSON.stringify(timeRemainings));
      temp.find((t) => t.name === name).value = value;
      setTimeRemainings(temp);
    },
    [timeRemainings]
  );

  const [cardsOption, setCardsOption] = useState([]);
  const [servicesOption, setServicesOption] = useState([]);
  useEffect(() => {
    GetCards().then((resp) =>
      setCardsOption(
        resp.data.data.map((e) => ({
          id: e.Id,
          label: e.KartAdi,
        }))
      )
    );
    AdditionalServicesSelectAll().then((resp) =>
      setServicesOption(
        resp.data.data.map((e) => ({ id: e.Id, label: e.EkHizmetAdi }))
      )
    );
  }, []);
  const HandleSearchClick = useCallback(
    async (start, end) => {
      setLoading(true);
      await TicketsServicesSelectAll({
        DateStart: dayjs(start).toISOString(),
        DateEnd: dayjs(end).toISOString(),
        KartTipi: searchCardType?.id,
        EkHizmet: additionalServices?.id,
        ...(timeRemainings[0].value !== "" &&
          timeRemainings[0].value !== "0" && {
            KullanimMin: CalculateBeforeDate(timeRemainings[0].value),
          }),
        ...(timeRemainings[1].value !== "" &&
          timeRemainings[1].value !== "0" && {
            KullanimMax: CalculateBeforeDate(timeRemainings[1].value),
          }),
        ...(timeRemainings[2].value !== "" &&
          timeRemainings[2].value !== "0" && {
            AktivasyonMin: CalculateBeforeDate(timeRemainings[2].value),
          }),
        ...(timeRemainings[3].value !== "" &&
          timeRemainings[3].value !== "0" && {
            AktivasyonMax: CalculateBeforeDate(timeRemainings[3].value),
          }),
      })
        .then((resp) => {
          let uniqueDatas = resp.data.data.filter(
            (v, i, a) =>
              a.findIndex(
                (v2) => v2.BiletReferansKodu === v.BiletReferansKodu
              ) === i
          );
          setData(
            uniqueDatas.map((e) => ({
              referanceNum: e.BiletReferansKodu,
              nameSurname: e.AdSoyad,
              cardType: e.KartTipi,
              date: e.SatinAlmaTarihi,
              activationTimeRemaining:
                dayjs(e.AktivasyonTarihi).unix() < dayjs().unix()
                  ? CalculateRemainingTime(e.AktivasyonBitisTarihi)
                  : "aktivasyon başlamadı",
              usageTimeRemaining: e.HizmetTarihi
                ? dayjs(e.HizmetTarihi).unix() < dayjs().unix()
                  ? CalculateRemainingTime(e.HizmetBitisTarihi)
                  : "hizmet başlamadı"
                : "hizmet başlamadı",
              additionalServiceList: e.SatisEkHizmetAdi,
            }))
          );
        })
        .finally(() => setLoading(false));
    },
    [searchCardType, timeRemainings, additionalServices]
  );
  const HandleDeleteClick = useCallback(async () => {
    setSearchCardType(null);
    setAdditionalServices([]);
    setTimeRemainings([
      { name: "utrMin", value: "" },
      { name: "utrMax", value: "" },
      { name: "atrMin", value: "" },
      { name: "atrMax", value: "" },
    ]);
  }, []);

  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <GetDataArea
        HandleSearchClick={HandleSearchClick}
        HandleDeleteClick={HandleDeleteClick}
        timeSelect={true}
      >
        <Grid xs={12} lg={2}>
          <Autocomplete
            fullWidth
            size="small"
            options={cardsOption}
            value={searchCardType}
            onChange={(event, newValue) => {
              setSearchCardType(newValue);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Kart Tipi" />
            )}
          />
        </Grid>
        <Grid
          xs={12}
          lg={2}
          sx={{ position: "relative", pt: { xs: "16px", lg: "8px" } }}
        >
          <Typography
            component={FormLabel}
            variant="span"
            sx={{
              height: "16px",
              fontSize: "12px",
              position: "absolute",
              left: "16px",
              top: { xs: "0", lg: "-8px" },
            }}
          >
            Hizmet Süresi Aralığı
          </Typography>
          <FormGroup sx={{ flexDirection: "row" }}>
            <TextField
              size="small"
              sx={{ width: "50%" }}
              placeholder="min"
              name={timeRemainings[0].name}
              value={timeRemainings[0].value}
              inputProps={{
                max: timeRemainings[1].value,
              }}
              onChange={handleNumberInputsChange}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <TextField
              size="small"
              sx={{ width: "50%" }}
              placeholder="max"
              name={timeRemainings[1].name}
              value={timeRemainings[1].value}
              inputProps={{
                min: timeRemainings[0].value,
              }}
              onChange={handleNumberInputsChange}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </FormGroup>
        </Grid>
        <Grid
          xs={12}
          lg={2}
          sx={{ position: "relative", pt: { xs: "16px", lg: "8px" } }}
        >
          <Typography
            component={FormLabel}
            variant="span"
            sx={{
              height: "16px",
              fontSize: "12px",
              position: "absolute",
              left: "16px",
              top: { xs: "0", lg: "-8px" },
            }}
          >
            Aktivasyon Süresi Aralığı
          </Typography>
          <FormGroup sx={{ flexDirection: "row" }}>
            <TextField
              size="small"
              sx={{ width: "50%" }}
              placeholder="min"
              name={timeRemainings[2].name}
              value={timeRemainings[2].value}
              inputProps={{
                max: timeRemainings[3].value,
              }}
              onChange={handleNumberInputsChange}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <TextField
              size="small"
              sx={{ width: "50%" }}
              placeholder="max"
              name={timeRemainings[3].name}
              value={timeRemainings[3].value}
              inputProps={{
                min: timeRemainings[2].value,
              }}
              onChange={handleNumberInputsChange}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </FormGroup>
        </Grid>
        <Grid xs={12} lg={2}>
          <Autocomplete
            fullWidth
            size="small"
            options={servicesOption}
            value={additionalServices}
            onChange={(event, newValue) => {
              setAdditionalServices(newValue);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Ek Hizmetler" />
            )}
          />
        </Grid>
      </GetDataArea>
      <CustomDataGrid
        data={data}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.referanceNum}
        rowsUniqueId="referanceNum"
        allowEditDelete={false}
      />
    </AppContainer>
  );
}

export default withTitle(
  AdditionalServiceDefinedTickets,
  "reports.additionalServiceTickets"
);
