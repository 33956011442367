import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import * as Yup from "yup";
import IntlTranslate from "../../helpers/IntlTranslate";
import { useFormik, Form, FormikProvider } from "formik";
import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import withTitle from "../../helpers/hoc/withTitle";
import Autocomplete from "@mui/material/Autocomplete";
import SnackbarComponent from "../../components/Snackbar";
import {
  CreateExpedition,
  RoutesSelectAll,
  BusSelectAll,
  UserRolesSelectByUserRoleID,
} from "../../api/api";

function ExpeditionsAdd() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="expeditions" />,
        path: "/expeditions",
      },
      { name: <FormattedMessage id="expeditions.add" />, active: true },
    ],
    []
  );
  const [loading, setLoading] = useState(false);
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  const schema = Yup.object().shape({
    expeditionDate: Yup.date()
      .min(dayjs(), IntlTranslate("expeditionDate.min"))
      .required(IntlTranslate("expeditionDate.required")),
    busRoute: Yup.string().required(IntlTranslate("busRoute.required")),
    busPlate: Yup.string().required(IntlTranslate("busPlate.required")),
    driver: Yup.string().required(IntlTranslate("driver.required")),
    personels: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      expeditionDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      busRoute: null,
      busPlate: null,
      driver: null,
      personels: null,
    },
    validationSchema: schema,
    onSubmit: async (datas, { resetForm }) => {
      setLoading(true);
      let response = await CreateExpedition({
        SeferId: 0,
        BusRoute: datas.busRoute,
        BusPlate: datas.busPlate,
        UserDriver: datas.driver,
        Employee: datas.personels,
        Date: dayjs(datas.expeditionDate).toISOString(),
      });
      if (response.data.status === "success") {
        setSnackbarType("success");
        setSnackbarOpen(true);
        resetForm();
      } else {
        setSnackbarType("error");
        setSnackbarOpen(true);
      }
      setLoading(false);
    },
  });
  const { errors, touched, handleSubmit, values, setFieldValue } = formik;

  const [routes, setRoutes] = useState([]);
  const [buses, setBuses] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [personels, setPersonels] = useState([]);
  useEffect(() => {
    RoutesSelectAll().then((response) => {
      const ROUTES = [];
      for (const item of response.data["data: "]) {
        var route = ROUTES.find((r) => r.id === item.Id);
        if (!route) {
          ROUTES.push({
            id: item.Id,
            label: item.Route,
          });
        }
      }
      setRoutes(ROUTES);
      BusSelectAll().then((resp) =>
        setBuses(
          resp.data["data: "].map((e) => ({
            id: e.Id,
            label: `${e.Plate} ${e.Name}`,
          }))
        )
      );
    });

    UserRolesSelectByUserRoleID(3).then((resp) => {
      var drivers = resp.map((e) => ({
        id: e.Id,
        label: `${e.Name} ${e.Surname}`,
      }));
      const ids = drivers.map((o) => o.id);
      const filtered = drivers.filter(
        ({ id }, index) => !ids.includes(id, index + 1)
      );
      setDrivers(filtered);
    });

    UserRolesSelectByUserRoleID(4).then((resp) => {
      var drivers = resp.map((e) => ({
        id: e.Id,
        label: `${e.Name} ${e.Surname}`,
      }));
      const ids = drivers.map((o) => o.id);
      const filtered = drivers.filter(
        ({ id }, index) => !ids.includes(id, index + 1)
      );
      setPersonels(filtered);
    });
  }, []);

  //submitten sonra autocomplete resetlemeye bak
  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Stack sx={{ mt: 1 }} spacing={3}>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
              <Autocomplete
                fullWidth
                options={routes}
                getOptionLabel={(opt) => opt.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, data) => setFieldValue("busRoute", data.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={IntlTranslate("busRoute")}
                    required
                    error={Boolean(touched.busRoute && errors.busRoute)}
                    helperText={touched.busRoute && errors.busRoute}
                  />
                )}
              />
              <Autocomplete
                fullWidth
                options={buses}
                getOptionLabel={(opt) => opt.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, data) => setFieldValue("busPlate", data.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={IntlTranslate("busPlate")}
                    required
                    error={Boolean(touched.busPlate && errors.busPlate)}
                    helperText={touched.busPlate && errors.busPlate}
                  />
                )}
              />
            </Stack>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
              <Autocomplete
                fullWidth
                options={drivers}
                getOptionLabel={(opt) => opt.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, data) => setFieldValue("driver", data.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={IntlTranslate("driver")}
                    required
                    error={Boolean(touched.driver && errors.driver)}
                    helperText={touched.driver && errors.driver}
                  />
                )}
              />
              <Autocomplete
                fullWidth
                options={personels}
                getOptionLabel={(opt) => opt.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, data) => setFieldValue("personels", data.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={IntlTranslate("personels")}
                    required
                    error={Boolean(touched.personels && errors.personels)}
                    helperText={touched.personels && errors.personels}
                  />
                )}
              />
            </Stack>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  required
                  label={IntlTranslate("expeditionDate")}
                  minDateTime={dayjs()}
                  mask="__.__.____ __:__"
                  inputFormat="DD.MM.YYYY HH:mm"
                  value={values.expeditionDate}
                  onChange={(newValue) =>
                    setFieldValue("expeditionDate", newValue)
                  }
                  ampm={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={Boolean(
                        touched.expeditionDate && errors.expeditionDate
                      )}
                      helperText={
                        touched.expeditionDate && errors.expeditionDate
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="success"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
            >
              <FormattedMessage id="add" />
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate("expeditionssuccessadd")}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(ExpeditionsAdd, "expeditions.add");
