import { Alert, Snackbar } from "@mui/material";
import React from "react";
import IntlTranslate from "../helpers/IntlTranslate";

const SnackbarComponent = (props) => {
  const vertical = "bottom";
  const horizontal = "center";

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.onClose(false);
  };

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
      key={vertical + horizontal}
    >
      <Alert
        onClose={handleClose}
        severity={props.alertType}
        sx={{ width: "100%" }}
      >
        {props.alertType === "success"
          ? props.successMessage
          : props.alertType === "warning"
          ? props.warningMessage
          : props.errorMessage
          ? props.errorMessage
          : IntlTranslate("somethingwentwrong")}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
