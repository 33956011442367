import React from "react";
import * as Yup from "yup";
import IntlTranslate from "../../helpers/IntlTranslate";
import { useFormik } from "formik";
import { Autocomplete, Stack, TextField } from "@mui/material";
function GroupUpdateDialog({ updateInputs, setUpdateInputs, usersOption }) {
  const schema = Yup.object().shape({
    GroupName: Yup.string().required(IntlTranslate("groupName.required")),
  });
  const formik = useFormik({
    initialValues: {
      GroupName: updateInputs.GroupName,
      Users: updateInputs.Users,
    },
    validationSchema: schema,
  });
  const { errors, touched, values, setFieldValue } = formik;
  return (
    <Stack sx={{ mt: 1 }} spacing={3}>
      <TextField
        required
        fullWidth
        label={IntlTranslate("groupName")}
        name="GroupName"
        value={values.GroupName}
        onChange={(e) => {
          setFieldValue("GroupName", e.target.value);
          setUpdateInputs((prev) => ({ ...prev, GroupName: e.target.value }));
        }}
        error={Boolean(touched.GroupName && errors.GroupName)}
        helperText={touched.GroupName && errors.GroupName}
      />
      <Autocomplete
        fullWidth
        multiple
        disableCloseOnSelect
        options={usersOption}
        getOptionLabel={(opt) => opt.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event, data) => {
          setFieldValue("Users", data);
          setUpdateInputs((prev) => ({
            ...prev,
            Users: data,
          }));
        }}
        value={values.Users}
        renderInput={(params) => (
          <TextField {...params} label={IntlTranslate("persons")} />
        )}
      />
    </Stack>
  );
}

export default GroupUpdateDialog;
