import React from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import IntlTranslate from "../helpers/IntlTranslate";

function UpdateDialog2(props) {
  const {
    open,
    onClose,
    onUpdate,
    nameChange,
    priceChange,
    colorChange,
    activationChange,
    usageChange,
    serviceChange,
    itemName,
    itemColor,
    itemActivationTime,
    itemUsageTime,
    itemService,
    itemPrice,
    itemId,
    handleDelete,
    services,
  } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id="update_item" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id="updateitemmessage" /> {itemName} / {itemPrice} (
          {itemId})?
        </DialogContentText>
        <TextField
          label={<FormattedMessage id="cardName" />}
          value={itemName}
          onChange={nameChange}
          style={{ width: "100%", marginTop: "1rem" }}
        />
        <TextField
          label={<FormattedMessage id="price" />}
          value={itemPrice}
          onChange={priceChange}
          style={{ width: "100%", marginTop: "1rem" }}
        />
        <TextField
          label={<FormattedMessage id="cardColor" />}
          value={itemColor || ""}
          onChange={colorChange}
          style={{ width: "100%", marginTop: "1rem" }}
          select
        >
          <MenuItem value="">Renk Seçiniz</MenuItem>
          <MenuItem value="red">Kırmızı</MenuItem>
          <MenuItem value="blue">Mavi</MenuItem>
          <MenuItem value="green">Yeşil</MenuItem>
          <MenuItem value="orange">Turuncu</MenuItem>
          <MenuItem value="gray">Gri</MenuItem>
        </TextField>
        <TextField
          label={<FormattedMessage id="activationTime" />}
          value={itemActivationTime}
          onChange={activationChange}
          style={{ width: "100%", marginTop: "1rem" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FormattedMessage id="hours" />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={<FormattedMessage id="usageTime" />}
          value={itemUsageTime}
          onChange={usageChange}
          style={{ width: "100%", marginTop: "1rem" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FormattedMessage id="hours" />
              </InputAdornment>
            ),
          }}
        />
        <Autocomplete
          style={{ width: "100%", marginTop: "1rem" }}
          multiple
          options={services}
          getOptionLabel={(opt) => opt.EkHizmetAdi}
          isOptionEqualToValue={(option, value) => option.Id === value.Id}
          onChange={(event, data) => serviceChange(data)}
          value={itemService}
          renderInput={(params) => (
            <TextField
              {...params}
              label={IntlTranslate("additionalServices")}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete} color="primary">
          <FormattedMessage id="deletebtn" />
        </Button>
        <Button onClick={onClose} color="primary">
          <FormattedMessage id="cancelbtn" />
        </Button>
        <Button onClick={onUpdate} color="secondary" autoFocus>
          <FormattedMessage id="updatebtn" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateDialog2;
