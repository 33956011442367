import { Autocomplete, TextField } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import { GetAllUsers, UserLoginLogoutGetAll } from "../../api/api";
import CustomDataGrid from "../../components/CustomDataGrid";
import GetDataArea from "../../components/GetDataArea";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";

function LoginLogoutDetail() {
  const breadcrumb = useMemo(
    () => [{ name: <FormattedMessage id="reports.login-out" />, active: true }],
    []
  );

  const columns = useMemo(
    () => [
      {
        field: "Id",
        headerName: "ID",
        minWidth: 75,
      },
      {
        field: "UserName",
        headerName: "Kullanıcı Adı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "LoginDate",
        headerName: "Giriş Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm");
        },
      },
      {
        field: "Location",
        headerName: "Giriş Koordinatları",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          if (params.value) {
            return (
              <a
                href={`https://www.google.com/maps/@${
                  params.value.split(",")[0]
                },${params.value.split(",")[1]},15z`}
                target="_blank"
                rel="noreferrer"
              >
                {params.value}
              </a>
            );
          } else {
            return "-";
          }
        },
      },
      {
        field: "LogoutDate",
        headerName: "Çıkış Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return params.value !== "-"
            ? dayjs(params.value).format("DD.MM.YYYY HH:mm")
            : "-";
        },
      },
    ],
    []
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //GET DATA FILTERS
  const [username, setUserName] = useState(null);

  const [usersOption, setUsersOption] = useState([]);
  useEffect(() => {
    GetAllUsers().then((resp) =>
      setUsersOption(resp.map((e) => ({ id: e.Id, label: e.UserName })))
    );
  }, []);

  const HandleSearchClick = useCallback(
    async (start, end) => {
      setLoading(true);
      await UserLoginLogoutGetAll({
        DateStart: dayjs(start).toISOString(),
        DateEnd: dayjs(end).toISOString(),
        UserId: username?.id,
      })
        .then((resp) =>
          setData(
            resp.data.data.map(({ LogoutDate, ...rest }) => ({
              LogoutDate: LogoutDate ? LogoutDate : "-",
              ...rest,
            }))
          )
        )
        .finally(() => setLoading(false));
    },
    [username]
  );
  const HandleDeleteClick = useCallback(async () => {
    setUserName(null);
  }, []);
  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <GetDataArea
        HandleSearchClick={HandleSearchClick}
        HandleDeleteClick={HandleDeleteClick}
      >
        <Grid xs={12} lg={2}>
          <Autocomplete
            fullWidth
            size="small"
            options={usersOption}
            value={username}
            onChange={(event, newValue) => {
              setUserName(newValue);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Kullanıcı Adı" />
            )}
          />
        </Grid>
      </GetDataArea>
      <CustomDataGrid
        data={data}
        columns={columns}
        loading={loading}
        allowEditDelete={false}
        getRowId={(row) => row.Id}
        rowsUniqueId="Id"
      />
    </AppContainer>
  );
}

export default withTitle(LoginLogoutDetail, "reports.login-out");
