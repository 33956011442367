import React from "react";
import * as Yup from "yup";
import IntlTranslate from "../../helpers/IntlTranslate";
import { useFormik } from "formik";
import { Autocomplete, Stack, TextField } from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers";

function ExpeditionsUpdateDialog({
  updateInputs,
  setUpdateInputs,
  busesData,
  routesData,
  driversData,
  personelsData,
}) {
  const schema = Yup.object().shape({
    expeditionDate: Yup.date()
      .min(dayjs(), IntlTranslate("expeditionDate.min"))
      .required(IntlTranslate("expeditionDate.required")),
    busRoute: Yup.string().required(IntlTranslate("busRoute.required")),
    busPlate: Yup.string().required(IntlTranslate("busPlate.required")),
    driver: Yup.string().required(IntlTranslate("driver.required")),
    personels: Yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      expeditionDate: updateInputs.date,
      busRoute: routesData.find((e) => e.id === updateInputs.busRoute),
      busPlate: busesData.find((e) => e.id === updateInputs.busPlate),
      driver: driversData.find((e) => e.id === updateInputs.userDriver),
      personels: personelsData.find((e) => e.id === updateInputs.personels),
    },
    validationSchema: schema,
  });
  const { errors, touched, values, setFieldValue } = formik;
  return (
    <Stack sx={{ mt: 1 }} spacing={3}>
      <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
        <Autocomplete
          fullWidth
          options={routesData}
          getOptionLabel={(opt) => opt.label}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={values.busRoute}
          onChange={(event, data) => {
            setFieldValue("busRoute", data);
            setUpdateInputs((prev) => ({
              ...prev,
              busRoute: data?.id,
            }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={IntlTranslate("busRoute")}
              required
              error={Boolean(touched.busRoute && errors.busRoute)}
              helperText={touched.busRoute && errors.busRoute}
            />
          )}
        />
        <Autocomplete
          fullWidth
          options={busesData}
          getOptionLabel={(opt) => opt.label}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={values.busPlate}
          onChange={(event, data) => {
            setFieldValue("busPlate", data);
            setUpdateInputs((prev) => ({
              ...prev,
              busPlate: data?.id,
            }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={IntlTranslate("busPlate")}
              required
              error={Boolean(touched.busPlate && errors.busPlate)}
              helperText={touched.busPlate && errors.busPlate}
            />
          )}
        />
      </Stack>
      <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
        <Autocomplete
          fullWidth
          options={driversData}
          getOptionLabel={(opt) => opt.label}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={values.driver}
          onChange={(event, data) => {
            setFieldValue("driver", data);
            setUpdateInputs((prev) => ({
              ...prev,
              userDriver: data?.id,
            }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={IntlTranslate("driver")}
              required
              error={Boolean(touched.driver && errors.driver)}
              helperText={touched.driver && errors.driver}
            />
          )}
        />
        <Autocomplete
          fullWidth
          options={personelsData}
          getOptionLabel={(opt) => opt.label}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={values.personels}
          onChange={(event, data) => {
            setFieldValue("personels", data);
            setUpdateInputs((prev) => ({
              ...prev,
              personels: data?.id,
            }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={IntlTranslate("personels")}
              required
              error={Boolean(touched.personels && errors.personels)}
              helperText={touched.personels && errors.personels}
            />
          )}
        />
      </Stack>
      <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            required
            label={IntlTranslate("expeditionDate")}
            minDateTime={dayjs()}
            value={values.expeditionDate}
            onChange={(newValue) => {
              setFieldValue("expeditionDate", newValue);
              setUpdateInputs((prev) => ({
                ...prev,
                date: newValue,
              }));
            }}
            ampm={false}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={Boolean(touched.expeditionDate && errors.expeditionDate)}
                helperText={
                  touched.expeditionDate
                    ? errors.expeditionDate
                    : params?.inputProps?.placeholder
                }
              />
            )}
          />
        </LocalizationProvider>
      </Stack>
    </Stack>
  );
}

export default ExpeditionsUpdateDialog;
