import axios from "axios";
import dayjs from "dayjs";
axios.interceptors.request.use(
  function (config) {
    const { origin } = new URL(config.url);
    const allowedOrigins = [process.env.REACT_APP_SITE_URL];
    const token = localStorage.getItem("jwt");
    if (allowedOrigins.includes(origin)) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.Accept = "application/json";
      config.headers.ContentType = "application/x-www-form-urlencoded";
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log("ERROR", error);
    if (error.response.status === 403) {
      window.location.pathname = "/login";
    }
  }
);

export const GetQRsByUserId = async (userId) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/QRZimmetleSelectByUserId/${userId}`
  );
  return data;
};

export const GetQRList = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/QrSatisSelectByAcenteAndUserId`,
    input
  );
  return data;
};

// export const GetSubAgents = async (agentId)=>{
//   const { data } = await axios.post(
//     `${process.env.REACT_APP_SITE_URL}/SubAgenciesSelectByAgenciesID/${agentId}`
//   );
//   return data;
// }
// export const fetchRegister = async (input) => {
//   const { data } = await axios.post(
//     `${process.env.REACT_APP_SITE_URL}/auth/register`,
//     input
//   );
//   return data;
// };

export const fetchLogin = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/SignIn`,
    input
  );
  return data;
};

export const SetLoginDetails = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/SetLoginDetails`,
    input
  );
  return data;
};

export const AddUser = async (user) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateUser`,
    user
  );
  return data;
};
export const DeleteUser = async (userId) => {
  const { data } = await axios.delete(
    `${process.env.REACT_APP_SITE_URL}/DeleteUser/${userId}`
  );
  return data;
};
// export const fetchMe = async () => {
//   const { data } = await axios.get(`${process.env.REACT_APP_SITE_URL}/auth/me`);
//   return data;
// };

// export const fetchLogout = async () => {
//   const { data } = await axios.post(
//     `${process.env.REACT_APP_SITE_URL}/auth/logout`,
//     {
//       refresh_token: localStorage.getItem("refresh-token"),
//     }
//   );
//   return data;
// };

export const GetPages = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/pageselectall`
  );
  return data;
};

export const GetUserRole = async (userId) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/UserRolesSelectByUserID/${userId}`
  );
  return data;
};

export const GetAgents = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/AgenciesSelectall`
  );
  return data;
};

export const DeleteAgent = async (id) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/DeleteAgencies`,
    { Id: id }
  );
  return data;
};

export const UserSetAllowedPages = async (Id, UserID, RoleID, PageID) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateUserRole`,
    {
      UserRoleID: Id,
      UserID,
      RoleID,
      PageID,
    }
  );
  return data;
};

export const UserRemoveAllowedPages = async (UserId, RoleId, PageID) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/UserRolesDeleteByUserIdAndRoleId`,
    {
      UserId,
      RoleId,
      PageID,
    }
  );
  return data;
};

export const GetAgentsUser = async (userId) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/AgenciesAndUsersSelectByUserId/${userId}`
  );
  return data;
};

//for IUAS
export const CreateAgencies = async (agent) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateAgencies`,
    agent
  );
  return data;
};

//for agents. after CreateUser
export const CreateAgentUser = async (agent) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateAgenciesAndUsers`,
    agent
  );
  return data;
};

export const AgentsGetAll = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/AgenciesSelectall`
  );
  return data;
};

export const GetUsersByAgentsId = async (agentId) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/AgenciesAndUsersSelectById/${agentId}`
  );
  return data;
};

export const GetAllUsers = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/SelectAllUsers`
  );
  return data;
};

//roles START
export const GetRoles = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/SelectAllRole`
  );
  return data;
};
export const RoleAdd = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/createrole`,
    input
  );
  return data;
};

export const RoleDelete = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/deleterole`,
    input
  );
  return data;
};

export const SelectAllUserRole = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/SelectAllUserRole`
  );
  return data;
};

export const UserRolesSelectByUserRoleID = async (id) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/UserRolesSelectByUserRoleID/${id}`
  );
  return data;
};

//roles END

export const GetDashboardData = async (start, end) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/Dashboard`,
    {
      DateStart: start,
      DateEnd: end,
    }
  );
  return data;
};

export const UserRoleUpdate = async (userId, roleId) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/UserRoleUpdate`,
    {
      UserID: userId,
      RoleID: roleId,
    }
  );
  return data;
};
//
// Bus
export const CreateBus = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateBuses`,
    input
  );
  return data;
};

export const BusSelectAll = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/BusSelectAll`
  );
  return data;
};

export const DeleteBuses = async (id) => {
  const { data } = await axios.delete(
    `${process.env.REACT_APP_SITE_URL}/DeleteBuses/${id}`
  );
  return data;
};

export const UpdateCancelRequest = async (id, status, userId) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateCancelRequest`,
    {
      IptalId: id,
      Status: status,
      TalepDegerlendiren: userId,
    }
  );
  return data;
};

export const CancelReasonsSelectAll = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/CancelReasonsSelectAll`
  );
  return data;
};

// Bus
//

//
// Expeditions
export const CreateExpedition = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateExpedition`,
    input
  );
  return data;
};

export const ExpeditionsSelectAll = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/ExpeditionsSelectAll`,
    input
  );
  return data;
};

export const DeleteExpedition = async (id) => {
  const { data } = await axios.delete(
    `${process.env.REACT_APP_SITE_URL}/DeleteExpedition/${id}`
  );
  return data;
};
// Expeditions
//

//
// Routes and Stations
export const CreateRoutes = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateRoutes`,
    input
  );
  return data;
};

export const DeleteRoutes = async (id) => {
  const { data } = await axios.delete(
    `${process.env.REACT_APP_SITE_URL}/RoutesDelete/${id}`
  );
  return data;
};

export const CreateStations = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateStations`,
    input
  );
  return data;
};

export const RoutesSelectAll = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/RoutesSelectAll`
  );
  return data;
};

export const StationsSelectAll = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/StationsSelectAll`
  );
  return data;
};

export const RoutesSelectById = async (id) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/RoutesSelectById/${id}`
  );
  return data;
};

export const deleteStation = async (id) => {
  const { data } = await axios.delete(
    `${process.env.REACT_APP_SITE_URL}/StationsDelete/${id}`
  );
  return data;
};
// Routes and Stations
//

export const CreateQRDebit = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateQRZimmet`,
    input
  );
  return data;
};

//
// Additional Services Start
export const CreateAdditionalServices = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateAdditionalService`,
    input
  );
  return data;
};

export const AdditionalServicesSelectAll = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/AdditionalServicesSelectAll`
  );
  return data;
};

export const AdditionalServicesSelectById = async (id) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/AdditionalServicesSelectById/${id}`
  );
  return data;
};

export const DeleteAdditionalService = async (id) => {
  const { data } = await axios.delete(
    `${process.env.REACT_APP_SITE_URL}/DeleteAdditionalService/${id}`
  );
  return data;
};
// Additional Services End
//

//
// Devices Start
export const CreateDevices = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateDevices`,
    input
  );
  return data;
};

export const DevicesSelectAll = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/DevicesSelectAll`
  );
  return data;
};

export const DevicesSelectById = async (id) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/DevicesSelectById/${id}`
  );
  return data;
};

export const DeleteDevices = async (id) => {
  const { data } = await axios.delete(
    `${process.env.REACT_APP_SITE_URL}/DeleteDevices/${id}`
  );
  return data;
};
// Devices End
//

//
// Cards Start
export const GetCards = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/CardsSelectAll`
  );
  return data;
};

export const GetCardById = async (id) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/CardsSelectById/${id}`
  );
  return data;
};

export const DeleteCards = async (id) => {
  const { data } = await axios.delete(
    `${process.env.REACT_APP_SITE_URL}/DeleteCards/${id}`
  );
  return data;
};

export const CreateCards = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateCards`,
    input
  );
  return data;
};

export const CreateCardsAdditionalServices = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateCardsAdditionalServices`,
    input
  );
  return data;
};

export const CardsAdditionalServicesSelectAll = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/CardsAdditionalServicesSelectAll`
  );
  return data;
};

export const DeleteCardsAdditionalServices = async (id) => {
  const { data } = await axios.delete(
    `${process.env.REACT_APP_SITE_URL}/DeleteCardsAdditionalServices/${id}`
  );
  return data;
};

export const CardsAdditionalServicesSelectById = async (id) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/CardsAdditionalServicesSelectById/${id}`
  );
  return data;
};
export const CardsAdditionalServicesSelectByCardId = async (id) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/CardsAdditionalServicesSelectByCardId/${id}`
  );
  return data;
};
// Cards End
//

export const SoldTicket = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateSale`,
    input
  );
  return data;
};

export const SalesSelectAll = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/SalesSelectAll`
  );
  return data;
};

export const SaleUpdate = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/SaleUpdate`,
    input
  );
  return data;
};

//reports
export const TicketDetailsGetAll = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/TicketsDetailsSelectAll`,
    input
  );
  return data;
};

export const TicketProgressGetAll = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/TicketsPaymentsSelectAll`,
    input
  );
  return data;
};

export const SubAgencyPaymentsSelectAll = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/SubAgencyPaymentsSelectAll`,
    input
  );
  return data;
};

export const CanceledTicketsGetAll = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CanceledTicketsSelectAll`,
    input
  );
  return data;
};

export const ServiceTrackingGetAll = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/ServiceTrackingSelectAll`,
    input
  );
  return data;
};

export const UserLoginLogoutGetAll = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/UserLoginLogoutSelectAll`,
    input
  );
  return data;
};

export const CardSalesGetAll = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CardSalesSelectAll`,
    input
  );
  return data;
};

export const ActiveVehiclesGetAll = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/ActiveVehiclesSelectAll`,
    input
  );
  return data;
};
export const TicketsServicesSelectAll = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/TicketsServicesSelectAll`,
    input
  );
  return data;
};

export const SoforDurakSelectByUserDriverId = async (userId) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/SoforDurakSelectByUserDriverId/${userId}`
  );
  return data;
};

export const SoforDurakInsertUpdate = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/SoforDurakInsertUpdate`,
    input
  );
  return data;
};

export const CardSalesSelectAll = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CardSalesSelectAll`,
    input
  );
  return data;
};

export const CreateSeasonalCard = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateSeasonalCard`,
    input
  );
  return data;
};

export const SeasonalCardSelectAll = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/SeasonalCardSelectAll`
  );
  return data;
};

export const SeasonalCardDelete = async (id) => {
  const { data } = await axios.delete(
    `${process.env.REACT_APP_SITE_URL}/DeleteSeasonalCard/${id}`
  );
  return data;
};

export const CreateCancelReason = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateCancelReason`,
    input
  );
  return data;
};

export const DeleteCancelReason = async (id) => {
  const { data } = await axios.delete(
    `${process.env.REACT_APP_SITE_URL}/DeleteCancelReason/${id}`
  );
  return data;
};

export const CancelReasonsGetAll = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/CancelReasonsSelectAll`
  );
  return data;
};

export const TicketLogsGetAll = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/SalesLog`,
    input
  );
  return data;
};

export const GetAdditionalServicesByTicketNum = async (ticketId) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/SalesAdditionalServicesSelectByRefNo`,
    {
      BiletReferansKodu: ticketId,
    }
  );
  return data;
};

export const UseOrAddService = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/SalesAdditionalServicesUpdate`,
    input
  );
  return data;
};

export const FakePassangerTrendsMethod = async () => {
  return [
    {
      id: 1,
      date: dayjs().add(-6, "day").format("DD.MM.YYYY"),
      averageVehicleChange: 4.2,
      totalVehicleChange: 78,
    },
    {
      id: 2,
      date: dayjs().add(-5, "day").format("DD.MM.YYYY"),
      averageVehicleChange: 5.7,
      totalVehicleChange: 71,
    },
    {
      id: 3,
      date: dayjs().add(-4, "day").format("DD.MM.YYYY"),
      averageVehicleChange: 6.5,
      totalVehicleChange: 77,
    },
    {
      id: 4,
      date: dayjs().add(-3, "day").format("DD.MM.YYYY"),
      averageVehicleChange: 6.2,
      totalVehicleChange: 82,
    },
    {
      id: 5,
      date: dayjs().add(-2, "day").format("DD.MM.YYYY"),
      averageVehicleChange: 8.5,
      totalVehicleChange: 88,
    },
    {
      id: 6,
      date: dayjs().add(-1, "day").format("DD.MM.YYYY"),
      averageVehicleChange: 7.9,
      totalVehicleChange: 102,
    },
    {
      id: 7,
      date: dayjs().format("DD.MM.YYYY"),
      averageVehicleChange: 4.2,
      totalVehicleChange: 67,
    },
  ];
};

export const FakePerformanceTrackMethod = async () => {
  return [
    {
      id: 1,
      personelName: "Ali Veli",
      personType: "İptal İstek Personeli",
      averageWorkTime: 8.1,
      acceptedTickets: 12,
      rejectedTickets: 7,
    },
    {
      id: 2,
      personelName: "Ahmet Mehmet",
      personType: "Şoför",
      averageWorkTime: 8.2,
      averageRoute: 4,
      totalRoute: 53,
    },
    {
      id: 3,
      personelName: "Ayşe Oya",
      personType: "Saha Personeli",
      averageWorkTime: 9.2,
      averageServiceStartPassangerCount: 54,
      totalServiceStartPassangerCount: 596,
    },
  ];
};

//ANNOUNCEMENTS
export const GetAnnouncements = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/AnnouncementsSelectAll`
  );
  return data;
};
export const GetAnnouncementsByUserId = async (id) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/GetAnnouncementByUserId/${id}`
  );
  return data;
};
export const CreateAnnouncement = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateAnnouncement`,
    input
  );
  return data;
};
export const DeleteAnnouncement = async (id) => {
  const { data } = await axios.delete(
    `${process.env.REACT_APP_SITE_URL}/DeleteAnnouncement/${id}`
  );
  return data;
};
//ANNOUNCEMENTS
//ANNOUNCEMENT GROUP
export const GetAnnouncementGroups = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/AnnouncementGroupSelectAll`
  );
  return data;
};
export const AnnouncementGroupSelectById = async (id) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SITE_URL}/AnnouncementGroupSelectById/${id}`
  );
  return data;
};
export const CreateAnnouncementGroup = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/CreateAnnouncementGroup`,
    input
  );
  return data;
};
export const DeleteAnnouncementGroup = async (id) => {
  const { data } = await axios.delete(
    `${process.env.REACT_APP_SITE_URL}/DeleteAnnouncementGroup/${id}`
  );
  return data;
};
export const AddUserToGroup = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/AddUserToGroup`,
    input
  );
  return data;
};
export const DeleteUserFromGroup = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/DeleteUserFromGroup`,
    input
  );
  return data;
};
export const GroupRename = async (input) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SITE_URL}/GroupRename`,
    input
  );
  return data;
};
//ANNOUNCENEMT GROUP
