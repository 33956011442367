import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import CustomDataGrid from "../../components/CustomDataGrid";
import AppContainer from "../../components/layout/AppContainer";
import CheckIcon from "@mui/icons-material/Check";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import BlockIcon from "@mui/icons-material/Block";
import withTitle from "../../helpers/hoc/withTitle";
import GetDataArea from "../../components/GetDataArea";
import {
  CancelReasonsSelectAll,
  CanceledTicketsGetAll,
  UpdateCancelRequest,
} from "../../api/api";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { useAuth } from "../../context/AuthContext";
const StatusTypes = {
  cancellationAccepted: {
    label: <FormattedMessage id="status.accepted" />,
    color: "success",
    icon: <CheckIcon />,
  },
  cancellationPending: {
    label: <FormattedMessage id="status.pending" />,
    color: "warning",
    icon: <HourglassBottomIcon />,
  },
  cancellationRejected: {
    label: <FormattedMessage id="status.denied" />,
    color: "error",
    icon: <BlockIcon />,
  },
};

function CancellationRequests() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="cancellationRequests" />,
        active: true,
      },
    ],
    []
  );
  const btn = useRef();

  const { user } = useAuth();
  const handleStatusUpdate = useCallback(
    (id, status) => {
      UpdateCancelRequest(id, status, user[0].Id).then((resp) => {
        if (resp.data.status === "success" && resp.data.Id) {
          btn.current.click();
        }
      });
    },
    [user]
  );

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", minWidth: 75 },
      {
        field: "referanceNum",
        headerName: "Bilet Referans Numarası",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "createDate",
        headerName: "Oluşturma Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm");
        },
      },
      {
        field: "date",
        headerName: "İşlem Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return params.value !== "-"
            ? dayjs(params.value).format("DD.MM.YYYY HH:mm")
            : "-";
        },
      },
      { field: "name", headerName: "Müşteri Adı", minWidth: 150, flex: 1 },
      {
        field: "paymentType",
        headerName: "Ödeme Tipi",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "cancelReason",
        headerName: "İptal Nedeni",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "status",
        headerName: "Durum",
        minWidth: 150,
        flex: 1,
        align: "center",
        renderCell: (params) => {
          if (params.value && StatusTypes[params.value]) {
            return (
              <Chip
                sx={{ width: "100%" }}
                label={StatusTypes[params.value].label}
                size="small"
                color={StatusTypes[params.value].color}
                icon={StatusTypes[params.value].icon}
              />
            );
          } else {
            return (
              <Chip
                sx={{ width: "100%" }}
                label={StatusTypes.cancellationPending.label}
                size="small"
                color={StatusTypes.cancellationPending.color}
                icon={StatusTypes.cancellationPending.icon}
              />
            );
          }
        },
      },
      {
        field: "Dokuman",
        headerName: "Döküman",
        minWidth: 150,
        align: "center",
        flex: 1,
        renderCell: (params) => {
          return (
            <IconButton
              aria-label="see-document"
              onClick={() => {
                setDoc(params.value);
                handleOpen();
              }}
              color="info"
              size="small"
            >
              <FindInPageIcon />
            </IconButton>
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        minWidth: 150,
        flex: 1,
        getActions: (params) => [
          <Button
            sx={{ width: "50%" }}
            color="success"
            variant="outlined"
            size="small"
            onClick={() =>
              handleStatusUpdate(params.id, "cancellationAccepted")
            }
          >
            <FormattedMessage id="accept" />
          </Button>,
          <Button
            sx={{ width: "50%" }}
            color="error"
            variant="outlined"
            size="small"
            onClick={() =>
              handleStatusUpdate(params.id, "cancellationRejected")
            }
          >
            <FormattedMessage id="denied" />
          </Button>,
        ],
      },
    ],
    [handleStatusUpdate]
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //GET DATA FILTERS
  const [status, setStatus] = useState("all");
  const [paymentType, setPaymentType] = useState("all");
  const [cancellationRequestReason, setCancellationRequestReason] =
    useState("all");

  const [cancelReasonOption, setCancelReasonOption] = useState([]);
  useEffect(() => {
    CancelReasonsSelectAll().then((resp) =>
      setCancelReasonOption(resp.data.data)
    );
  }, []);

  const HandleSearchClick = useCallback(
    async (start, end) => {
      setLoading(true);
      await CanceledTicketsGetAll({
        DateStart: dayjs(start).toISOString(),
        DateEnd: dayjs(end).toISOString(),
        ...(status !== "all" && { Status: status }),
        ...(paymentType !== "all" && { PaymentType: paymentType }),
        ...(cancellationRequestReason !== "all" && {
          CancelReason: cancellationRequestReason,
        }),
      })
        .then((resp) =>
          setData(
            resp.data.data.map((e) => ({
              id: e.IptalId,
              referanceNum: e.TicketReferanceNum,
              createDate: e.CreateDate,
              date: e.UpdateDate !== null ? e.UpdateDate : "-",
              name: e.MusteriAdi,
              paymentType: e.OdemeTipi,
              cancelReason: e.IptalNedeni,
              status: e.Durum,
              Dokuman: e.Dokuman,
            }))
          )
        )
        .finally(() => setLoading(false));
    },
    [status, paymentType, cancellationRequestReason]
  );
  const HandleDeleteClick = useCallback(async () => {
    setStatus("all");
    setPaymentType("all");
    setCancellationRequestReason("all");
  }, []);

  const [doc, setDoc] = useState("");
  //dialog settings
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <GetDataArea
        HandleSearchClick={HandleSearchClick}
        HandleDeleteClick={HandleDeleteClick}
        btnRef={btn}
      >
        <Grid xs={12} lg={2}>
          <TextField
            fullWidth
            size="small"
            select
            label="Durum"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value="all" sx={{ fontStyle: "italic" }}>
              <FormattedMessage id="all" />
            </MenuItem>
            <MenuItem value="accepted">
              <FormattedMessage id="status.accepted" />
            </MenuItem>
            <MenuItem value="pending">
              <FormattedMessage id="status.pending" />
            </MenuItem>
            <MenuItem value="denied">
              <FormattedMessage id="status.denied" />
            </MenuItem>
          </TextField>
        </Grid>
        <Grid xs={12} lg={2}>
          <TextField
            fullWidth
            size="small"
            select
            label="Ödeme Tipi"
            value={paymentType}
            onChange={(e) => setPaymentType(e.target.value)}
          >
            <MenuItem value="all" sx={{ fontStyle: "italic" }}>
              <FormattedMessage id="all" />
            </MenuItem>
            <MenuItem value="kredikarti">Kredi Kartı</MenuItem>
            <MenuItem value="nakit">Nakit</MenuItem>
            <MenuItem value="mobil3d">Mobil 3D</MenuItem>
          </TextField>
        </Grid>
        <Grid xs={12} lg={2}>
          <TextField
            fullWidth
            size="small"
            select
            label="İptal Nedeni"
            value={cancellationRequestReason}
            onChange={(e) => setCancellationRequestReason(e.target.value)}
          >
            <MenuItem value="all" sx={{ fontStyle: "italic" }}>
              <FormattedMessage id="all" />
            </MenuItem>
            {cancelReasonOption.map((e) => (
              <MenuItem value={e.Id} key={e.Id}>
                {e.Message}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </GetDataArea>
      <CustomDataGrid
        data={data}
        columns={columns}
        loading={loading}
        allowEditDelete={false}
      />
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogContent>
          <img
            src={`data:image/jpeg;base64,${doc}`}
            alt="document"
            className="responsiveImg"
          />
        </DialogContent>
      </Dialog>
    </AppContainer>
  );
}

export default withTitle(CancellationRequests, "cancellationRequests");
