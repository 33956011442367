import { Box, Paper, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import Grid from "@mui/material/Unstable_Grid2";
import withTitle from "../../helpers/hoc/withTitle";
import {
  GetCards,
  CreateCards,
  DeleteCards,
  CreateCardsAdditionalServices,
  DeleteCardsAdditionalServices,
  AdditionalServicesSelectAll,
} from "../../api/api";
import UpdateDialog3 from "../../components/UpdateDialog3";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";

function Cards() {
  const breadcrumb = useMemo(
    () => [{ name: <FormattedMessage id="cards" />, active: true }],
    []
  );

  const [data, setData] = useState([]);

  // Delete Dialog Props

  const [itemName, setItemName] = useState("");
  const [itemId, setItemId] = useState("");
  const [itemPrice, setItemPrice] = useState(0);
  const [itemColor, setItemColor] = useState("");
  const [itemActivationTime, setItemActivationTime] = useState("");
  const [itemUsageTime, setItemUsageTime] = useState("");
  const [itemServices, setItemServices] = useState([]);
  const [oldCardService, setOldCardService] = useState([]);
  const handleDelete = async () => {
    await DeleteCards(itemId)
      .then((res) => {
        setUpdateOpen(false);
        handleGetCards();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCloseDialog = () => {
    setUpdateOpen(false);
  };
  // Delete Dialog Props

  // Update Dialog Props
  const [updateOpen, setUpdateOpen] = useState(false);

  const handleUpdate = async () => {
    await CreateCards({
      Id: itemId,
      KartAdi: itemName,
      Fiyat: itemPrice,
      KartRengi: itemColor,
      AktivasyonSuresi: itemActivationTime,
      HizmetSuresi: itemUsageTime,
    });
    let servicesObj = { add: [], remove: [] };

    let oldIds = oldCardService.map(({ Id }) => Id);
    let newIds = itemServices.map(({ Id }) => Id);
    for (let a = 0; a < newIds.length; a++) {
      if (!oldIds.includes(newIds[a])) {
        servicesObj.add.push(newIds[a]);
      }
    }
    for (let b = 0; b < oldIds.length; b++) {
      if (!newIds.includes(oldIds[b])) {
        servicesObj.remove.push(
          oldCardService.find((t) => t.Id === oldIds[b]).deleteId
        );
      }
    }
    let k = 0;
    while (k < servicesObj.add.length) {
      let resp = await CreateCardsAdditionalServices({
        Id: 0,
        KartId: itemId,
        EkHizmetId: servicesObj.add[k],
      });
      if (resp.data.status === "success" && resp.data.Id) {
        k++;
      } else {
        break;
      }
    }
    k = 0;
    while (k < servicesObj.remove.length) {
      let resp = await DeleteCardsAdditionalServices(servicesObj.remove[k]);
      if (resp.data.status === "success" && resp.data.Id === 0) {
        k++;
      } else {
        break;
      }
    }
    setUpdateOpen(false);
    handleGetCards();
  };

  const nameChange = (e) => {
    setItemName(e.target.value);
  };

  const priceChange = (e) => {
    setItemPrice(e.target.value);
  };

  const colorChange = (e) => {
    setItemColor(e.target.value);
  };
  const activationChange = (e) => {
    setItemActivationTime(e.target.value);
  };
  const usageChange = (e) => {
    setItemUsageTime(e.target.value);
  };
  const serviceChange = (item) => {
    setItemServices(item);
  };
  // Update Dialog Props

  const handleGetCards = async () => {
    let response = await GetCards();
    setData(response.data.data);
  };

  useEffect(() => {
    handleGetCards();
  }, []);

  const [services, setServices] = useState([]);
  useEffect(() => {
    AdditionalServicesSelectAll().then((response) =>
      setServices(response.data.data)
    );
  }, []);
  return (
    <AppContainer breadcrumbs={breadcrumb} addButton={{ name: "cards.add" }}>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid
            key={index}
            xs={12}
            sm={6}
            md={4}
            lg={3}
            onClick={() => {
              setItemName(item.KartAdi);
              setItemId(item.Id);
              setItemPrice(item.Fiyat);
              setItemColor(item.KartRengi);
              setItemActivationTime(item.AktivasyonSuresi);
              setItemUsageTime(item.HizmetSuresi);
              var tempservices = [];
              if (item.EkHizmetId !== null && item.EkHizmetAdi !== null) {
                let ids = item.EkHizmetId.replace(/ /g, "").split(",");
                let names = item.EkHizmetAdi.replace(/ /g, "").split(",");
                let deleteIds = item.KartEkHizmetId.replace(/ /g, "").split(
                  ","
                );
                for (let t = 0; t < ids.length; t++) {
                  tempservices.push({
                    Id: ids[t],
                    EkHizmetAdi: names[t],
                    deleteId: deleteIds[t],
                  });
                }
              }
              setOldCardService(tempservices);
              setItemServices(tempservices);
              setUpdateOpen(true);
            }}
          >
            <Paper
              className={`bussCard Gradient-${item.KartRengi || "red"}`}
              sx={{
                width: "100%",
                height: "180px",
              }}
            >
              <Box
                sx={{
                  mt: ".75rem",
                  ml: ".5rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {item.IsSeason && (
                  <Tooltip
                    title={<FormattedMessage id="periodicTicketPriceDefined" />}
                    placement="bottom"
                  >
                    <OnlinePredictionIcon sx={{ mr: 1 }} />
                  </Tooltip>
                )}
                <span>
                  {item.IsSeason !== null ? item.DonemselFiyat : item.Fiyat}₺
                </span>
              </Box>

              <span className="bussCardText textRight">{item.KartAdi}</span>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <UpdateDialog3
        open={updateOpen}
        onClose={handleCloseDialog}
        onUpdate={handleUpdate}
        nameChange={nameChange}
        priceChange={priceChange}
        colorChange={colorChange}
        activationChange={activationChange}
        usageChange={usageChange}
        itemName={itemName}
        itemPrice={itemPrice}
        itemId={itemId}
        itemColor={itemColor}
        itemActivationTime={itemActivationTime}
        itemUsageTime={itemUsageTime}
        itemService={itemServices}
        serviceChange={serviceChange}
        handleDelete={handleDelete}
        services={services}
      />
    </AppContainer>
  );
}

export default withTitle(Cards, "cards");
