import {
  Box,
  Menu,
  MenuItem,
  TablePagination,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridPrintExportMenuItem,
  trTR,
  enUS,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnDefinitionsSelector,
} from "@mui/x-data-grid";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GetCurrentLanguage } from "../context/LanguageContext";
import { FormattedMessage } from "react-intl";
import InitFilterObj from "../helpers/InitFilterObj";
import Filter from "./DataGridColumnFilter";
//excel export
import XLSX from "sheetjs-style";
import { saveAs } from "file-saver";
import { useAuth } from "../context/AuthContext";
function CustomDataGrid({
  data,
  columns,
  loading,
  customRightClickMenus = null,
  rowsUniqueId = "id",
  rowsTotalDatas = [],
  title = "",
  setEditDeleteSelectedRow = () => {},
  allowEditDelete = true,
  ...rest
}) {
  const currentLang = GetCurrentLanguage();
  //selection
  const [selectionModel, setSelectionModel] = useState([]);
  //right click context menu
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedRow, setSelectedRow] = useState();
  //filters
  const [filters, setFilters] = useState(InitFilterObj(columns));
  const FiltersSet = useCallback((filterData) => {
    setFilters(filterData);
  }, []);
  const ClearFilterInputs = useCallback(
    (e) => {
      e.stopPropagation();
      setFilters(InitFilterObj(columns));
    },
    [columns]
  );
  //pdf export
  const exportPDF = (apiRef) => {
    //set data for pdf format
    // Select rows and columns
    let filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleColumns = gridVisibleColumnDefinitionsSelector(apiRef);
    var columnsWithoutCheckbox = visibleColumns.filter(
      (e) => e.field !== "__check__"
    );
    if (selectionModel.length > 0) {
      filteredSortedRowIds = JSON.parse(
        JSON.stringify(
          filteredSortedRowIds.filter((e) => selectionModel.includes(e))
        )
      );
    }
    const exportData = filteredSortedRowIds.map((id) => {
      const row = [];
      columnsWithoutCheckbox.forEach((item) => {
        row.push(apiRef.current.getCellParams(id, item.field).value);
      });
      return row;
    });
    //pdf settings
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";
    const marginXaxis = 40;
    //init pdf
    const doc = new jsPDF(orientation, unit, size);
    doc.setLanguage("tr");

    doc.setFontSize(16);

    const title = "Rapor";
    const headers = [columnsWithoutCheckbox.map((e) => e.headerName)];
    let content = {
      startY: 50,
      showHead: "firstPage",
      head: headers,
      body: exportData,
    };

    doc.text(title, marginXaxis, marginXaxis);
    doc.autoTable(content);
    doc.save("rapor.pdf");
  };

  const PDFExportMenuItem = ({ hideMenu }) => {
    const apiRef = useGridApiContext();

    return (
      <MenuItem
        onClick={() => {
          exportPDF(apiRef);
          hideMenu?.();
        }}
      >
        <FormattedMessage id="exportPDF" />
      </MenuItem>
    );
  };

  //excel export
  const exportExcel = (apiRef) => {
    //set data for pdf format
    // Select rows and columns
    let filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleColumns = gridVisibleColumnDefinitionsSelector(apiRef);
    var columnsWithoutCheckbox = visibleColumns.filter(
      (e) => e.field !== "__check__"
    );
    if (selectionModel.length > 0) {
      filteredSortedRowIds = JSON.parse(
        JSON.stringify(
          filteredSortedRowIds.filter((e) => selectionModel.includes(e))
        )
      );
    }
    const exportData = filteredSortedRowIds.map((id) => {
      const row = {};
      columnsWithoutCheckbox.forEach((item) => {
        row[item.headerName] = apiRef.current.getCellParams(
          id,
          item.field
        ).value;
      });
      return row;
    });

    //export
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "rapor.xlsx");
  };
  const ExcelExportMenuItem = ({ hideMenu }) => {
    const apiRef = useGridApiContext();

    return (
      <MenuItem
        onClick={() => {
          exportExcel(apiRef);
          hideMenu?.();
        }}
      >
        <FormattedMessage id="exportExcel" />
      </MenuItem>
    );
  };
  const CustomExportButton = () => (
    <GridToolbarExportContainer>
      <ExcelExportMenuItem />
      <PDFExportMenuItem />
      <GridPrintExportMenuItem />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <CustomExportButton />
      <Box sx={{ flexGrow: 1 }} />
    </GridToolbarContainer>
  );
  //right click event
  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(
      data.find(
        (e) =>
          e[rowsUniqueId]?.toString() ===
          event.currentTarget.getAttribute("data-id")
      )
    );
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };
  const handleClose = () => {
    setContextMenu(null);
  };
  const contextSelected = (operation) => {
    setEditDeleteSelectedRow({
      operation,
      rows:
        selectionModel.length > 0
          ? selectionModel
          : [selectedRow[rowsUniqueId]],
    });
    handleClose();
  };
  const CopyRow = () => {
    navigator.clipboard.writeText(JSON.stringify(selectedRow, null, 2));
    handleClose();
  };

  //pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function CustomPagination() {
    return (
      <TablePagination
        rowsPerPageOptions={[50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton
        showLastButton
        labelRowsPerPage={
          currentLang.code === "tr" ? "Sayfa başına satır:" : "Rows per page:"
        }
        labelDisplayedRows={({ from, to, count }) => {
          return currentLang.code === "tr"
            ? `${
                count !== -1 ? `${count} taneden` : `${to} üzeri`
              } ${from}–${to} arası`
            : `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
        }}
      />
    );
  }

  //filtered DATAS
  const [filtered, setFiltered] = useState(data);

  useEffect(() => {
    let temp = JSON.parse(JSON.stringify(data));
    //filter
    temp = temp.filter((item) => {
      return Object.keys(item).every((key) => {
        return item[key]
          ?.toString()
          .toLowerCase()
          .startsWith(filters.find((e) => e.name === key).value.toLowerCase());
      });
    });
    setPage(0);
    setFiltered(temp);
  }, [filters, data]);
  useEffect(() => {
    let temp = JSON.parse(JSON.stringify(data));
    //pagination
    let result = temp.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    setFiltered(result);
  }, [page, rowsPerPage, data]);

  function CustomFooter({ selectedRowCount, totalDatas }) {
    return (
      <>
        {totalDatas.length > 0 && (
          <Box
            sx={{
              backgroundColor: "#c4c4c4",
              px: 2,
              py: 1,
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              boxShadow: 2,
            }}
          >
            <Box
              sx={{ flexGrow: 1, fontWeight: "bold", letterSpacing: ".05rem" }}
            >
              <FormattedMessage id="total" />
            </Box>
            {totalDatas.map((e, index) => (
              <Box
                key={index}
                sx={{ display: "flex", flexDirection: "column", ml: 2 }}
              >
                <Box sx={{ fontSize: ".75rem", color: "#fff" }}>
                  {e.headerName}
                </Box>
                <Box sx={{ fontWeight: "bold" }}>{e.total}</Box>
              </Box>
            ))}
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ mx: selectedRowCount > 0 ? 2 : "" }}>
            {selectedRowCount > 0 ? `${selectedRowCount} satır seçildi` : ""}
          </Box>
          <CustomPagination />
        </Box>
      </>
    );
  }

  const { role } = useAuth();
  const canEditeDeleteRow = useMemo(() => {
    return !role.isFullAgent;
  }, [role]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          mt: 3,
        }}
      >
        {title !== "" && <Typography variant="h6">{title}</Typography>}
        <DataGrid
          columns={columns}
          rows={filtered}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooter,
          }}
          componentsProps={{
            row: {
              onContextMenu: handleContextMenu,
              style: { cursor: "context-menu" },
            },
            footer: {
              selectedRowCount: selectionModel.length,
              totalDatas: rowsTotalDatas,
            },
          }}
          sx={{ height: 480, backgroundColor: "#fff" }}
          checkboxSelection
          disableSelectionOnClick
          disableColumnFilter
          selectionModel={selectionModel}
          onSelectionModelChange={(model) => setSelectionModel(model)}
          localeText={
            currentLang.code === "tr"
              ? trTR.components.MuiDataGrid.defaultProps.localeText
              : enUS.components.MuiDataGrid.defaultProps.localeText
          }
          loading={loading}
          {...rest}
        />
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          componentsProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
              },
            },
          }}
          MenuListProps={{
            "aria-labelledby": "row-context-menu",
          }}
        >
          <MenuItem onClick={CopyRow}>
            <FormattedMessage id="copyRow" />
          </MenuItem>
          {canEditeDeleteRow && allowEditDelete && (
            <MenuItem onClick={() => contextSelected("edit")}>
              <FormattedMessage id="edit" />
            </MenuItem>
          )}
          {canEditeDeleteRow && allowEditDelete && (
            <MenuItem onClick={() => contextSelected("delete")}>
              <FormattedMessage id="delete" />
            </MenuItem>
          )}
          {customRightClickMenus !== null &&
            customRightClickMenus.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() =>
                  item.clickMethod(selectedRow).then(() => handleClose())
                }
              >
                <FormattedMessage id={item.translateName} />
              </MenuItem>
            ))}
        </Menu>
      </Box>
      <Filter
        filters={filters}
        setFilters={FiltersSet}
        ClearFilterInputs={ClearFilterInputs}
      />
    </>
  );
}
export default CustomDataGrid;
