import React, { useEffect, useMemo, useState } from "react";
import { IconButton, Table } from "@mui/material";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { BusSelectAll, CreateBus, DeleteBuses } from "../../api/api";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteDialog from "../../components/DeleteDialog";
import UpdateDialog2 from "../../components/UpdateDialog2";

function Buses() {
  const breadcrumb = useMemo(
    () => [{ name: <FormattedMessage id="buses" />, active: true }],
    []
  );
  // DELETE / UPDATE
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [itemName, setItemName] = useState("");
  const [itemInfo, setItemInfo] = useState("");
  const [itemId, setItemId] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    if (openDelete) {
      setOpenDelete(false);
    } else if (openUpdate) {
      setOpenUpdate(false);
    }
  };

  const nameChange = (e) => {
    setItemName(e.target.value);
  };

  const infoChange = (e) => {
    setItemInfo(e.target.value);
  };

  const handleUpdate = async () => {
    setLoading(true);
    await CreateBus({
      Id: itemId,
      Plate: itemName,
      Name: itemInfo,
    });
    setOpenUpdate(false);
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    await DeleteBuses(itemId);
    setOpenDelete(false);
    setLoading(false);
  };

  // DELETE / UPDATE

  const [allBuses, setAllBuses] = useState([]);

  const getBuses = async () => {
    const response = await BusSelectAll();
    setAllBuses(response.data["data: "]);
  };

  useEffect(() => {
    getBuses();
  }, [loading]);

  return (
    <AppContainer breadcrumbs={breadcrumb} addButton={{ name: "buses.add" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 400 }} aria-label="buses table">
          <TableHead>
            <TableRow>
              <TableCell>Otobüs Plakası</TableCell>
              <TableCell>Otobüs Adı</TableCell>
              <TableCell align="right">Seçenekler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allBuses.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.Plate}
                </TableCell>
                <TableCell>{row.Name}</TableCell>
                <TableCell align="right">
                  <IconButton
                    color="warning"
                    aria-label="edit"
                    onClick={() => {
                      setOpenUpdate(true);
                      setItemName(row.Plate);
                      setItemInfo(row.Name);
                      setItemId(row.Id);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    sx={{ ml: 1 }}
                    onClick={() => {
                      setOpenDelete(true);
                      setItemName(row.Plate);
                      setItemInfo(row.Name);
                      setItemId(row.Id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <UpdateDialog2
        open={openUpdate}
        onClose={handleClose}
        onUpdate={handleUpdate}
        nameChange={nameChange}
        infoChange={infoChange}
        itemName={itemName}
        itemInfo={itemInfo}
        itemId={itemId}
      />
      <DeleteDialog
        open={openDelete}
        onClose={handleClose}
        onDelete={handleDelete}
        itemName={itemName}
        itemId={itemId}
      />
    </AppContainer>
  );
}

export default withTitle(Buses, "buses");
