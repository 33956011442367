import React, { useEffect, useMemo, useState } from "react";
import { IconButton, Stack, Table, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import IntlTranslate from "../../helpers/IntlTranslate";
import SnackbarComponent from "../../components/Snackbar";
import DeleteDialog from "../../components/DeleteDialog";
import {
  CancelReasonsGetAll,
  CreateCancelReason,
  DeleteCancelReason,
} from "../../api/api";
import UpdateDialog from "../../components/UpdateDialog";

function CancelReasons() {
  const breadcrumb = useMemo(
    () => [{ name: <FormattedMessage id="cancelReasons" />, active: true }],
    []
  );

  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props

  // Delete Dialog Props
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [itemName, setItemName] = useState("");
  const [itemId, setItemId] = useState("");
  const handleDelete = async () => {
    setLoading(true);
    await DeleteCancelReason(itemId)
      .then((res) => {
        setDeleteOpen(false);
        GetData();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCloseDialog = () => {
    if (deleteOpen) {
      setDeleteOpen(false);
    } else if (updateOpen) {
      setUpdateOpen(false);
    }
  };
  // Delete Dialog Props

  // Update Dialog Props
  const [updateOpen, setUpdateOpen] = useState(false);

  const handleUpdate = async () => {
    setLoading(true);
    await CreateCancelReason({
      Id: itemId,
      Message: itemName,
    });
    setUpdateOpen(false);
    GetData();
    setLoading(false);
  };

  const handleChange = (e) => {
    setItemName(e.target.value);
  };
  // Update Dialog Props

  const [data, setData] = useState([]);

  const GetData = async () => {
    setLoading(true);
    let response = await CancelReasonsGetAll();
    setData(response.data.data);
    setLoading(false);
  };

  useEffect(() => {
    GetData();
  }, []);

  //formik
  const [loading, setLoading] = useState(false);
  const schema = Yup.object().shape({
    cancelReasonMessage: Yup.string().required(
      IntlTranslate("cancelReasonMessage.required")
    ),
  });
  const formik = useFormik({
    initialValues: {
      cancelReasonMessage: "",
    },
    validationSchema: schema,
    onSubmit: async (datas, { resetForm }) => {
      setLoading(true);
      let response = await CreateCancelReason({
        Id: 0,
        Message: datas.cancelReasonMessage,
      });
      if (response.data.status === "success" && response.data.Id) {
        resetForm();
        setSnackbarType("success");
        setSnackbarOpen(true);
        GetData();
      } else {
        setSnackbarType("error");
        setSnackbarOpen(true);
      }
      setLoading(false);
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Stack sx={{ mt: 1, mb: 2 }} spacing={3}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                required
                sx={{ width: { xs: "100%", sm: "80%" } }}
                label={IntlTranslate("message")}
                {...getFieldProps("cancelReasonMessage")}
                error={Boolean(
                  touched.cancelReasonMessage && errors.cancelReasonMessage
                )}
                helperText={
                  touched.cancelReasonMessage && errors.cancelReasonMessage
                }
              />
              <LoadingButton
                type="submit"
                variant="contained"
                color="success"
                sx={{
                  mt: 3,
                  mb: 2,
                  width: { xs: "100%", sm: "20%" },
                  height: "56px",
                }}
                loading={loading}
              >
                <FormattedMessage id="add" />
              </LoadingButton>
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 400 }}
          aria-label="cancel reason  table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>İptal Nedeni</TableCell>
              <TableCell align="right">Seçenekler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.Id}</TableCell>
                <TableCell>{row.Message}</TableCell>
                <TableCell align="right">
                  <IconButton
                    color="warning"
                    aria-label="edit"
                    onClick={() => {
                      setItemId(row.Id);
                      setItemName(row.Message);
                      setUpdateOpen(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    sx={{ ml: 1 }}
                    onClick={() => {
                      setItemId(row.Id);
                      setItemName(row.Message);
                      setDeleteOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate("cancelreasonsuccessadd")}
        alertType={snackbarType}
        onClose={handleClose}
      />
      <DeleteDialog
        open={deleteOpen}
        onClose={handleCloseDialog}
        onDelete={handleDelete}
        itemName={itemName}
        itemId={itemId}
      />
      <UpdateDialog
        open={updateOpen}
        onClose={handleCloseDialog}
        onUpdate={handleUpdate}
        onChange={handleChange}
        itemName={itemName}
        itemId={itemId}
      />
    </AppContainer>
  );
}

export default withTitle(CancelReasons, "cancelReasons");
