import dayjs from "dayjs";

export const USAGE_TIME = 24;
export const ACTIVATION_TIME = 72;
export const CalculateRemainingTime = (endDate) => {
  if (dayjs(endDate).unix() > dayjs().unix()) {
    var diff = dayjs(endDate).diff(dayjs(), "minute");
    return `${Math.floor(diff / 60)} saat ${diff % 60} dakika`;
  } else {
    return "Süre Bitti";
  }
};

export const CalculateBeforeDate = (hour) => {
  return dayjs()
    .add(Number(hour), "hour")
    .toISOString();
};
