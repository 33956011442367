import { useState, createContext, useContext, useMemo } from "react";
import { decryptData, encryptData } from "../helpers/secureData";
import { SetLoginDetails } from "../api/api";
import dayjs from "dayjs";
const AuthContext = createContext();
const salt = process.env.REACT_APP_CRYPTO_SECRET;

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(
    localStorage.getItem("jwt") && localStorage.getItem("user")
      ? decryptData(localStorage.getItem("user"), salt)
      : null
  );
  const [role, setRole] = useState(
    localStorage.getItem("role")
      ? decryptData(localStorage.getItem("role"), salt)
      : null
  );

  const pages = useMemo(() => {
    let temp = JSON.parse(JSON.stringify(user));
    if (temp) {
      temp.shift();
      let pageTitles = getPageTitles(temp);
      return pageTitles;
    } else {
      return null;
    }
  }, [user]);

  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("jwt") ? true : false
  );

  const login = (data) => {
    setLoggedIn(true);
    const originalData = data.data.data;
    const encryptedData = encryptData(originalData, salt);
    localStorage.setItem("user", encryptedData);
    setUser(data.data.data);
    localStorage.setItem("jwt", data.data.token);
  };

  const RolesWrite = (arr, agent) => {
    var unique = GetRolesUnique(arr);
    var parsedAgentInfos = agent.length > 0 ? GetAgentInfos(agent) : [];
    var isFullAgent = false;
    if (
      unique.some((e) => e.roleId === "2" || e.roleId === "5") &&
      parsedAgentInfos.agentId
    ) {
      isFullAgent = true;
    }
    const encryptedData = encryptData(
      { roleData: unique, agentData: parsedAgentInfos, isFullAgent },
      salt
    );
    localStorage.setItem("role", encryptedData);
    setRole({ roleData: unique, agentData: parsedAgentInfos, isFullAgent });
  };

  const logout = async (callback) => {
    await SetLoginDetails({
      Id: user[0].LoginDetailId,
      LogoutDate: dayjs().toISOString(),
    });
    localStorage.removeItem("jwt");
    localStorage.removeItem("user");
    localStorage.removeItem("role");
    
    setLoggedIn(false);
    setUser(null);

    callback();
  };

  const values = {
    user,
    role,
    loggedIn,
    pages,
    login,
    RolesWrite,
    logout,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };

const getPageTitles = (arr) => {
  let titles = arr.map((e) => e.PageTitle);
  return titles;
};

const GetRolesUnique = (arr) => {
  const uniqueRoleIds = [...new Set(arr.map((e) => e.RoleId))];
  const uniqueRoles = [];
  for (let i = 0; i < uniqueRoleIds.length; i++) {
    let temp = arr.find((e) => e.RoleId === uniqueRoleIds[i]);
    uniqueRoles.push({
      roleId: temp.RoleId,
      role: temp.Role,
      roleTitle: temp.RoleTitle,
    });
  }
  return uniqueRoles;
};

const GetAgentInfos = (arr) => {
  return {
    agent: arr[0].Acente,
    company: arr[0].Sirket,
    agentId: arr[0].AgencyId,
    qrCount: arr[0].Miktar,
    price: arr[0].Fiyat,
    skt: arr[0].SonKullanimTarihi,
    qrAgentId: arr[0].QrAcenteId,
    qrZimmetId: arr[0].QrZimmetId,
    activationHour: arr[0].AktivasyonSuresi,
    usageHour: arr[0].KullanimSuresi,
  };
};
