import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import withTitle from "../../helpers/hoc/withTitle";
import CustomDataGrid from "../../components/CustomDataGrid";
import GetDataArea from "../../components/GetDataArea";
import { CreateAgencies, GetAgents } from "../../api/api";
import Grid from "@mui/material/Unstable_Grid2";
import SnackbarComponent from "../../components/Snackbar";

import { DeleteAgent } from "../../api/api";
import IntlTranslate from "../../helpers/IntlTranslate";
import AgentsUpdateDialog from "./AgentsUpdateDialog";

//satırı boyamak için gerekli method ve importlar
// import { lighten } from "@mui/material/styles";
// const getBackgroundColor = (color) => lighten(color, 0.25);
// const getHoverBackgroundColor = (color) => lighten(color, 0.3);

function Agents() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="agents" />,
        active: true,
      },
    ],
    []
  );
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  const columns = useMemo(
    () => [
      { field: "Id", headerName: "ID", minWidth: 75 },
      { field: "Acente", headerName: "Acente Adı", minWidth: 150, flex: 1 },
      { field: "Sirket", headerName: "Şirket", minWidth: 150, flex: 1 },
      // {
      //   field: "qrCount",
      //   headerName: "QR Kod Sayısı",
      //   minWidth: 150,
      //   flex: 1,
      //   type: "number",
      // },
    ],
    []
  );
  const [data, setData] = useState([]);
  //const [rowsTotalDatas, setRowsTotalDatas] = useState([]);
  const [loading, setLoading] = useState(false);

  //GET DATA FILTERS
  const [company, setCompany] = useState(null);

  const [companyOption, setCompanyOption] = useState([]);
  useEffect(() => {
    GetAgents().then((resp) =>
      setCompanyOption(
        resp.data["data: "].map((e) => ({ id: e.Id, label: e.Sirket }))
      )
    );
  }, []);
  const HandleSearchClick = useCallback(async () => {
    setLoading(true);
    let response = await GetAgents();
    var datas = response.data["data: "].map(({ DeletedFlag, ...rest }) => rest);
    if (company !== null) {
      datas = datas.filter((e) => e.Id === company.id);
    }
    setData(datas);
    //setRowsTotalDatas([{ headerName: "QR Kod Sayısı", total: 592029 }]);
    // await FakeAgentsMethod(start, end, company)
    //   .then((resp) => {
    //     setData(resp);
    //     setRowsTotalDatas([{ headerName: "QR Kod Sayısı", total: 592029 }]);
    //   })
    //   .finally(() => setLoading(false));
    setLoading(false);
  }, [company]);
  const HandleDeleteClick = useCallback(async () => {
    setCompany(null);
  }, []);

  const [editDeleteSelectedRow, setEditDeleteSelectedRow] = useState({
    operation: "",
    rows: [],
  }); //'operation' değişkeninde delete mi update mi olduğu yazıyor, selectedRow ise seçilen kaydın idsini dönüyor
  const onClose = () => {
    setEditDeleteSelectedRow((prev) => ({
      operation: prev.operation,
      rows: [],
    }));
  };
  const btn = useRef();

  const onDelete = async () => {
    let i = 0;
    while (i < editDeleteSelectedRow.rows.length) {
      let result = await DeleteAgent(editDeleteSelectedRow.rows[i]);
      if (result.data.status === "success" && result.data.Id) {
        i++;
      } else {
        break;
      }
    }
    setSnackbarType("success");
    setSnackbarOpen(true);
    setEditDeleteSelectedRow((prev) => ({
      operation: prev.operation,
      rows: [],
    }));
    btn.current.click();
  };

  const [updateInputs, setUpdateInputs] = useState({
    Acente: "",
    Sirket: "",
  });

  useEffect(() => {
    if (editDeleteSelectedRow.operation === "edit") {
      setUpdateInputs({
        Acente: data.find((e) => e.Id === editDeleteSelectedRow.rows[0])
          ?.Acente,
        Sirket: data.find((e) => e.Id === editDeleteSelectedRow.rows[0])
          ?.Sirket,
      });
    }
  }, [editDeleteSelectedRow, data]);
  const onUpdate = async () => {
    let i = 0;
    while (i < editDeleteSelectedRow.rows.length) {
      let result = await CreateAgencies({
        Id: editDeleteSelectedRow.rows[i],
        Acente: updateInputs.Acente,
        Sirket: updateInputs.Sirket,
        DeletedFlag: false,
      });
      if (result.data.status === "success" && result.data.Id) {
        i++;
      } else {
        break;
      }
    }
    setSnackbarType("success");
    setSnackbarOpen(true);
    setEditDeleteSelectedRow((prev) => ({
      operation: prev.operation,
      rows: [],
    }));
    btn.current.click();
  };
  return (
    <AppContainer breadcrumbs={breadcrumb} addButton={{ name: "agents.add" }}>
      <GetDataArea
        HandleSearchClick={HandleSearchClick}
        HandleDeleteClick={HandleDeleteClick}
        btnRef={btn}
        dateFilter={false}
      >
        <Grid xs={12} lg={2}>
          <Autocomplete
            fullWidth
            size="small"
            options={companyOption}
            value={company}
            onChange={(event, newValue) => {
              setCompany(newValue);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Şirket" />
            )}
          />
        </Grid>
      </GetDataArea>
      <Box
      // sx={{
      //   "& .grid-theme--success": {
      //     bgcolor: (theme) => getBackgroundColor(theme.palette.success.main),
      //     "&:hover": {
      //       bgcolor: (theme) =>
      //         getHoverBackgroundColor(theme.palette.success.main),
      //     },
      //   },
      //   "& .grid-theme--error": {
      //     bgcolor: (theme) => getBackgroundColor(theme.palette.error.main),
      //     "&:hover": {
      //       bgcolor: (theme) =>
      //         getHoverBackgroundColor(theme.palette.error.main),
      //     },
      //   },
      // }}
      >
        <CustomDataGrid
          data={data}
          columns={columns}
          loading={loading}
          // getRowClassName={(params) =>
          //   `grid-theme--${
          //     params.row?.qrCount < 10000
          //       ? "error"
          //       : params.row?.qrCount > 10000
          //       ? "success"
          //       : ""
          //   }`
          // }
          //rowsTotalDatas={rowsTotalDatas}
          getRowId={(row) => row.Id}
          rowsUniqueId="Id"
          setEditDeleteSelectedRow={setEditDeleteSelectedRow}
        />
      </Box>
      <Dialog
        open={
          editDeleteSelectedRow.operation === "delete" &&
          editDeleteSelectedRow.rows.length > 0
            ? true
            : false
        }
        onClose={onClose}
      >
        <DialogTitle>
          <FormattedMessage id="delete_item" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editDeleteSelectedRow.rows.length > 1 ? (
              <FormattedMessage
                id="deleteItemsCountMessage"
                values={{ count: editDeleteSelectedRow.rows.length }}
              />
            ) : (
              <>
                <FormattedMessage id="deleteitemmessage" />
                {
                  data.find((e) => e.Id === editDeleteSelectedRow.rows[0])
                    ?.Acente
                }
                ({data.find((e) => e.Id === editDeleteSelectedRow.rows[0])?.Id}
                )?{" "}
              </>
            )}
            <FormattedMessage id="deleteitemmessage2" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            <FormattedMessage id="cancelbtn" />
          </Button>
          <Button onClick={onDelete} color="secondary" autoFocus>
            <FormattedMessage id="deletebtn" />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={
          editDeleteSelectedRow.operation === "edit" &&
          editDeleteSelectedRow.rows.length > 0
            ? true
            : false
        }
        onClose={onClose}
      >
        <DialogTitle>
          <FormattedMessage id="update_item" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editDeleteSelectedRow.rows.length > 1 ? (
              <FormattedMessage
                id="updateItemsCountMessage"
                values={{ count: editDeleteSelectedRow.rows.length }}
              />
            ) : (
              <>
                <FormattedMessage id="updateitemmessage" />
                {
                  data.find((e) => e.Id === editDeleteSelectedRow.rows[0])
                    ?.Acente
                }
                ({data.find((e) => e.Id === editDeleteSelectedRow.rows[0])?.Id}
                )?{" "}
              </>
            )}
          </DialogContentText>
          <AgentsUpdateDialog
            updateInputs={{
              Acente: data.find((e) => e.Id === editDeleteSelectedRow.rows[0])
                ?.Acente,
              Sirket: data.find((e) => e.Id === editDeleteSelectedRow.rows[0])
                ?.Sirket,
            }}
            setUpdateInputs={setUpdateInputs}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            <FormattedMessage id="cancelbtn" />
          </Button>
          <Button onClick={onUpdate} color="secondary" autoFocus>
            <FormattedMessage id="updatebtn" />
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate(
          editDeleteSelectedRow.operation === "delete"
            ? "deleteSuccess"
            : "updateSuccess"
        )}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(Agents, "agents");
