import React, { useState } from "react";
import BusforusLogo from "../../assets/busforus-logo-white.svg";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { drawerWidth } from "./HeaderMenu";
import PersonIcon from "@mui/icons-material/Person";
import { /*Badge,*/ Box, ListItemIcon, Menu, MenuItem } from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
// import { Link as RouteLink } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { GetAnnouncementsByUserId } from "../../api/api";
import dayjs from "dayjs";
import parse from "html-react-parser";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function Header({ open, handleDrawerOpen }) {
  const { logout } = useAuth();

  const [anchorEl, setAnchorEl] = useState(null);
  const openProfileDropdown = Boolean(anchorEl);
  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  //logout
  const navigate = useNavigate();
  const handleLogout = async () => {
    logout(() => navigate("/login"));
  };
  const handleProfilePage = () => {
    navigate("/profile");
  };

  const { user, role } = useAuth();

  const [announcements, setAnnouncements] = useState([]);

  //announcements
  const [anchorElAnnouncement, setAnchorElAnnouncement] = useState(null);
  const openAnnouncements = Boolean(anchorElAnnouncement);
  const handleClickAnnouncement = (event) => {
    setAnchorElAnnouncement(event.currentTarget);
    GetAnnouncementsByUserId(user[0].Id).then((resp) =>
      setAnnouncements(resp.data.data)
    );
  };
  const handleCloseAnnouncement = () => {
    setAnchorElAnnouncement(null);
  };
  //announcements
  return (
    <>
      <AppBar position="fixed" open={open} sx={{ backgroundColor: "#cb1720" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: { xs: 1, sm: 5 },
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography noWrap component="a" href="/" height={{ xs: 25, sm: 40 }}>
            <img
              src={BusforusLogo}
              alt="Bus For Us logo"
              style={{ height: "inherit" }}
            />
          </Typography>
          <Box
            sx={{
              marginLeft: "auto",
              fontSize: { xs: "10px", sm: "unset" },
            }}
          >
            {user && (
              <b>
                {user[0]?.Name} {user[0]?.Surname}
              </b>
            )}{" "}
            | {role?.roleData[0]?.roleTitle}
          </Box>
          <IconButton
            color="inherit"
            aria-label="notifications"
            sx={{
              marginLeft: 1,
            }}
            onClick={handleClickAnnouncement}
            aria-controls={open ? "announcement-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <NotificationsIcon />
          </IconButton>
          <IconButton
            sx={{ marginLeft: 1 }}
            color="inherit"
            aria-label="settings"
            onClick={handleProfileClick}
            aria-controls={openProfileDropdown ? "settings-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openProfileDropdown ? "true" : undefined}
          >
            <PersonIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        id="announcement-menu"
        anchorEl={anchorElAnnouncement}
        open={openAnnouncements}
        onClose={handleCloseAnnouncement}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {announcements.map((e) => (
          <MenuItem key={e.DuyuruId} onClick={handleCloseAnnouncement}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="body1"
                sx={{ fontSize: "1rem", fontWeight: "bold" }}
              >
                {e.FromName}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "rgba(0,0,0,0.5)", fontSize: ".75rem" }}
              >
                {dayjs(e.CreateDate).format("DD/MM/YY HH:mm")}
              </Typography>
              <Typography
                mt={2}
                sx={{ fontSize: "0.75rem", fontFamily: "monospace" }}
              >
                {parse(e.Message.split("|MESAJAYRACI|")[0])}
                <b>{e.Message.split("|MESAJAYRACI|")[1]}</b>
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={anchorEl}
        id="settings-menu"
        open={openProfileDropdown}
        onClose={handleProfileMenuClose}
        onClick={handleProfileMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleProfilePage}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <FormattedMessage id="profile" />
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <FormattedMessage id="logout" />
        </MenuItem>
      </Menu>
    </>
  );
}

export default Header;
