import {
  Alert,
  Button,
  FormGroup,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import IntlTranslate from "../../helpers/IntlTranslate";
import { LoadingButton } from "@mui/lab";
import DraggableList from "../../components/Draggable/DraggableList";
import withTitle from "../../helpers/hoc/withTitle";
import SnackbarComponent from "../../components/Snackbar";
import { CreateRoutes, CreateStations } from "../../api/api";
function BusStopsAdd() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="busStops" />,
        path: "/bus-routes",
      },
      { name: <FormattedMessage id="busStops.add" />, active: true },
    ],
    []
  );
  const [loading, setLoading] = useState(false);
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  const schema = Yup.object().shape({
    tourName: Yup.string()
      .min(3, IntlTranslate("tourName.min"))
      .required(IntlTranslate("tourName.required")),
    averageTime: Yup.number()
      .min(1, IntlTranslate("averageTime.min"))
      .required(IntlTranslate("averageTime.required")),
  });
  const formik = useFormik({
    initialValues: {
      tourName: "",
      busStops: "",
      averageTime: "",
    },
    validationSchema: schema,
    onSubmit: async (datas, { resetForm }) => {
      setLoading(true);
      let { tourName, averageTime } = datas;
      let response = await CreateRoutes({
        Id: 0,
        Route: tourName,
        Time: averageTime,
      });
      if (response.data.status === "success") {
        let i = 0;
        while (i < items.length) {
          let result = await CreateStations({
            Id: 0,
            RouteId: response.data.Id,
            Station: items[i].name,
          });
          if (result.data.status === "success") {
            i++;
          } else {
            break;
          }
        }
      }
      setLoading(false);
      setSnackbarOpen(true);
      setSnackbarType("success");
      resetForm();
      setItems([]);
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const [items, setItems] = useState([]);

  const onDragEnd = ({ destination, source }) => {
    if (!destination) return;
    const newItems = reorder(items, source.index, destination.index);
    setItems(newItems);
  };

  //durak ekleme işlemleri
  const [stopName, setStopName] = useState("");
  const [stopNameonError, setStopNameonError] = useState(false);
  const [busStopCount, setBusStopCount] = useState(0);
  const handleStopNameChange = (e) => {
    setStopName(e.target.value);
    setStopNameonError(false);
  };
  const AddStop = () => {
    if (stopName === "") {
      setStopNameonError(true);
    } else {
      setItems((prev) => [
        ...prev,
        { id: busStopCount.toString(), name: stopName },
      ]);
      setBusStopCount(busStopCount + 1);
      setStopName("");
    }
  };
  const handleDelete = (id) => {
    let temp = JSON.parse(JSON.stringify(items));
    var ins = temp.findIndex((t) => t.id === id);
    temp.splice(ins, 1);
    setItems(temp);
  };
  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Stack
            sx={{ mt: 1 }}
            spacing={3}
            direction={{ xs: "column", md: "row" }}
          >
            <TextField
              required
              fullWidth
              type="text"
              label={IntlTranslate("tourName")}
              {...getFieldProps("tourName")}
              placeholder="Adalar Turu"
              error={Boolean(touched.tourName && errors.tourName)}
              helperText={touched.tourName && errors.tourName}
            />
            <TextField
              required
              fullWidth
              type="text"
              label={IntlTranslate("averageTime")}
              {...getFieldProps("averageTime")}
              placeholder="3"
              error={Boolean(touched.averageTime && errors.averageTime)}
              helperText={touched.averageTime && errors.averageTime}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FormattedMessage id="hours" />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack sx={{ mt: 1 }}>
            <Typography variant="h6" component="p">
              <FormattedMessage id="manageStops" />
            </Typography>
            <Alert severity="info">
              <FormattedMessage id="manageStops.definition" />
            </Alert>
            <DraggableList
              items={items}
              onDragEnd={onDragEnd}
              size={items.length}
              handleDelete={handleDelete}
            />
            <FormGroup
              row
              sx={{ mt: 1, mb: 2, flexWrap: "nowrap", height: 48 }}
            >
              <TextField
                variant="filled"
                sx={{ width: "80%" }}
                size="small"
                type="text"
                value={stopName}
                onChange={handleStopNameChange}
                error={stopNameonError}
                helperText={
                  stopNameonError ? (
                    <FormattedMessage id="stopNameInvalid" />
                  ) : (
                    ""
                  )
                }
              />
              <Button
                variant="contained"
                size="small"
                color="secondary"
                sx={{ width: "20%" }}
                onClick={AddStop}
              >
                <FormattedMessage id="newStop" />
              </Button>
            </FormGroup>
          </Stack>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="success"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
          >
            <FormattedMessage id="add" />
          </LoadingButton>
        </Form>
      </FormikProvider>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate("routesuccessadd")}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(BusStopsAdd, "busStops.add");

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
