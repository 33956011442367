import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import { FakePerformanceTrackMethod } from "../../api/api";
import CustomDataGrid from "../../components/CustomDataGrid";
import GetDataArea from "../../components/GetDataArea";
import { Autocomplete, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

//temp
const PERSON_NAMES = [
  { id: 1, label: "Ali Veli" },
  { id: 2, label: "Ahmet Mehmet" },
  { id: 3, label: "Ayşe Oya" },
];
function PerformanceTrack() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="reports.performanceTrack" />,
        active: true,
      },
    ],
    []
  );

  const columnsDriver = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        minWidth: 75,
      },
      {
        field: "personelName",
        headerName: "Çalışan Adı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "personType",
        headerName: "Personel Tipi",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "averageWorkTime",
        headerName: "Ortalama Çalışma Süresi",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "averageRoute",
        headerName: "Ortalama Sefer Sayısı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "totalRoute",
        headerName: "Toplam Sefer Sayısı",
        minWidth: 150,
        flex: 1,
      },
    ],
    []
  );
  const columnsCancelRequestPerson = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        minWidth: 75,
      },
      {
        field: "personelName",
        headerName: "Çalışan Adı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "personType",
        headerName: "Personel Tipi",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "averageWorkTime",
        headerName: "Ortalama Çalışma Süresi",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "acceptedTickets",
        headerName: "İptali Onaylanan Bilet Sayısı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "rejectedTickets",
        headerName: "İptali Reddedilen Bilet Sayısı",
        minWidth: 150,
        flex: 1,
      },
    ],
    []
  );
  const columnsFieldStaff = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        minWidth: 75,
      },
      {
        field: "personelName",
        headerName: "Çalışan Adı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "personType",
        headerName: "Personel Tipi",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "averageWorkTime",
        headerName: "Ortalama Çalışma Süresi",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "averageServiceStartPassangerCount",
        headerName: "Ortalama Servisi Başlatılan Yolcu Sayısı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "totalServiceStartPassangerCount",
        headerName: "Toplam Servisi Başlatılan Yolcu Sayısı",
        minWidth: 150,
        flex: 1,
      },
    ],
    []
  );
  const [data, setData] = useState({
    "İptal İstek Personeli": [],
    Şoför: [],
    "Saha Personeli": [],
  });
  const [loading, setLoading] = useState(false);

  //GET DATA FILTERS
  const [personName, setPersonName] = useState(null);
  const HandleSearchClick = useCallback(
    async (start, end) => {
      console.log("person name", personName);
      console.log("start date", start.toDate());
      console.log("end date", end.toDate());
      setLoading(true);
      await FakePerformanceTrackMethod(start, end, personName)
        .then((resp) => {
          let temp = {
            "İptal İstek Personeli": [],
            Şoför: [],
            "Saha Personeli": [],
          };
          resp.forEach((e) => temp[e.personType].push(e));
          setData(temp);
        })
        .finally(() => setLoading(false));
    },
    [personName]
  );
  const HandleDeleteClick = useCallback(async () => {
    setPersonName(null);
  }, []);

  return (
    <AppContainer breadcrumbs={breadcrumb}>
      {/* <GetDataArea
        HandleSearchClick={HandleSearchClick}
        HandleDeleteClick={HandleDeleteClick}
      >
        <Grid xs={12} lg={2}>
          <Autocomplete
            fullWidth
            size="small"
            options={PERSON_NAMES}
            value={personName}
            onChange={(event, newValue) => {
              setPersonName(newValue);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Personel Adı" />
            )}
          />
        </Grid>
      </GetDataArea>
      <CustomDataGrid
        data={data["Şoför"]}
        columns={columnsDriver}
        loading={loading}
        title="Şoför"
        allowEditDelete={false}
      />
      <CustomDataGrid
        data={data["İptal İstek Personeli"]}
        columns={columnsCancelRequestPerson}
        loading={loading}
        title="İptal İstek Personeli"
        allowEditDelete={false}
      />
      <CustomDataGrid
        data={data["Saha Personeli"]}
        columns={columnsFieldStaff}
        loading={loading}
        title="Saha Personeli"
        allowEditDelete={false}
      /> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "40px", height: "40px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            fill="#000000"
            version="1.1"
            id="Layer_1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
          >
            <g>
              <g>
                <g>
                  <path d="M437.019,74.981C388.668,26.629,324.38,0,256,0S123.332,26.629,74.981,74.981C26.629,123.332,0,187.62,0,256     s26.629,132.668,74.981,181.019C123.332,485.371,187.62,512,256,512c64.518,0,126.15-24.077,173.541-67.796l-10.312-11.178     c-44.574,41.12-102.544,63.766-163.229,63.766c-64.317,0-124.786-25.046-170.266-70.527     C40.254,380.786,15.208,320.317,15.208,256S40.254,131.214,85.734,85.735C131.214,40.254,191.683,15.208,256,15.208     s124.786,25.046,170.266,70.527c45.48,45.479,70.526,105.948,70.526,170.265c0,60.594-22.587,118.498-63.599,163.045     l11.188,10.301C487.986,381.983,512,320.421,512,256C512,187.62,485.371,123.332,437.019,74.981z" />
                  <path d="M282.819,263.604h63.415v-15.208h-63.415c-1.619-5.701-5.007-10.662-9.536-14.25l35.913-86.701l-14.049-5.82     l-35.908,86.688c-1.064-0.124-2.142-0.194-3.238-0.194c-15.374,0-27.881,12.508-27.881,27.881s12.507,27.881,27.881,27.881     C268.737,283.881,279.499,275.292,282.819,263.604z M243.327,256c0-6.989,5.685-12.673,12.673-12.673     c6.989,0,12.673,5.685,12.673,12.673c0,6.989-5.685,12.673-12.673,12.673C249.011,268.673,243.327,262.989,243.327,256z" />
                  <path d="M451.168,256c0-107.616-87.552-195.168-195.168-195.168S60.832,148.384,60.832,256S148.384,451.168,256,451.168     S451.168,363.616,451.168,256z M76.04,256c0-99.231,80.73-179.96,179.96-179.96S435.96,156.769,435.96,256     S355.231,435.96,256,435.96S76.04,355.231,76.04,256z" />
                  <rect
                    x="248.396"
                    y="366.511"
                    width="15.208"
                    height="56.776"
                  />
                  <path d="M61.672,336.501l-14.05,5.82c23.059,55.668,66.416,99.026,122.085,122.085l5.82-14.049     C123.61,428.851,83.176,388.416,61.672,336.501z" />
                  <path d="M450.397,175.485l14.049-5.82c-23.059-55.669-66.417-99.027-122.085-122.084l-5.82,14.049     C388.457,83.135,428.891,123.569,450.397,175.485z" />
                  <path d="M256,45.624V30.416c-60.256,0-116.904,23.465-159.512,66.071C53.881,139.095,30.416,195.744,30.416,256h15.208     c0-56.194,21.883-109.024,61.617-148.759C146.976,67.507,199.807,45.624,256,45.624z" />
                  <path d="M481.584,256h-15.208c0,56.193-21.883,109.024-61.617,148.759c-39.734,39.735-92.566,61.617-148.759,61.617v15.208     c60.256,0,116.904-23.465,159.513-66.071C458.119,372.904,481.584,316.256,481.584,256z" />
                  <rect x="248.396" y="88.713" width="15.208" height="56.776" />

                  <rect
                    x="183.845"
                    y="106.019"
                    transform="matrix(0.3827 0.9239 -0.9239 0.3827 226.5988 -111.8865)"
                    width="26.361"
                    height="15.208"
                  />

                  <rect
                    x="301.78"
                    y="390.75"
                    transform="matrix(-0.3827 -0.9239 0.9239 -0.3827 67.4626 841.7849)"
                    width="26.361"
                    height="15.207"
                  />

                  <rect
                    x="100.445"
                    y="189.43"
                    transform="matrix(0.9239 0.3827 -0.3827 0.9239 84.0528 -28.4842)"
                    width="26.36"
                    height="15.207"
                  />

                  <rect
                    x="385.201"
                    y="307.351"
                    transform="matrix(-0.9239 -0.3827 0.3827 -0.9239 645.9084 758.3894)"
                    width="26.361"
                    height="15.207"
                  />

                  <rect
                    x="106.028"
                    y="301.792"
                    transform="matrix(-0.3827 -0.9239 0.9239 -0.3827 -133.8779 540.4901)"
                    width="15.207"
                    height="26.36"
                  />

                  <rect
                    x="390.765"
                    y="183.851"
                    transform="matrix(0.3827 0.9239 -0.9239 0.3827 427.9489 -246.4146)"
                    width="15.207"
                    height="26.361"
                  />

                  <rect
                    x="189.433"
                    y="385.188"
                    transform="matrix(-0.9239 -0.3827 0.3827 -0.9239 226.6226 841.8156)"
                    width="15.208"
                    height="26.361"
                  />

                  <rect
                    x="307.381"
                    y="100.448"
                    transform="matrix(0.9239 0.3827 -0.3827 0.9239 67.4614 -111.8911)"
                    width="15.207"
                    height="26.361"
                  />

                  <rect
                    x="129.389"
                    y="150.181"
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 157.7713 380.9216)"
                    width="56.776"
                    height="15.208"
                  />

                  <rect
                    x="325.825"
                    y="346.62"
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 354.2049 855.1643)"
                    width="56.776"
                    height="15.208"
                  />
                  <rect x="88.713" y="248.396" width="56.776" height="15.208" />
                  <rect
                    x="366.511"
                    y="248.396"
                    width="56.776"
                    height="15.208"
                  />

                  <rect
                    x="150.157"
                    y="325.829"
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 18.8444 716.2403)"
                    width="15.208"
                    height="56.776"
                  />

                  <rect
                    x="346.602"
                    y="129.395"
                    transform="matrix(0.7071 0.7071 -0.7071 0.7071 215.3148 -204.2485)"
                    width="15.208"
                    height="56.776"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
        <h1 style={{ marginLeft: "4px" }}>Çalışma Devam Ediyor...</h1>
      </div>
    </AppContainer>
  );
}

export default withTitle(PerformanceTrack, "reports.performanceTrack");
