import React, { useEffect, useMemo, useState } from "react";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import SendIcon from "@mui/icons-material/Send";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider, Field } from "formik";
import IntlTranslate from "../../helpers/IntlTranslate";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import Grid from "@mui/material/Unstable_Grid2";
import SnackbarComponent from "../../components/Snackbar";
import { CreateAnnouncement, GetAnnouncementGroups } from "../../api/api";
import { useAuth } from "../../context/AuthContext";

function AnnouncementsAdd() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="announcements" />,
        path: "/announcements",
      },
      { name: <FormattedMessage id="announcements.add" />, active: true },
    ],
    []
  );

  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props

  //formik
  const [loading, setLoading] = useState(false);
  const schema = Yup.object().shape({
    to: Yup.string().required(IntlTranslate("to.required")),
    sendMethod: Yup.array().min(1, IntlTranslate("sendMethod.required")),
    message: Yup.string().required(IntlTranslate("message.required")),
  });
  const { user } = useAuth();
  const formik = useFormik({
    initialValues: {
      to: "",
      sendMethod: [],
      message: "",
    },
    validationSchema: schema,
    onSubmit: async (datas, { resetForm }) => {
      setLoading(true);
      let response = await CreateAnnouncement({
        DuyuruId: 0,
        From: user[0].Id,
        To: datas.to,
        Type: datas.sendMethod[0],
        Message: datas.message,
      });
      if (response.data.status === "success" && response.data.Id) {
        resetForm();
        setSnackbarType("success");
        setSnackbarOpen(true);
      }
      setLoading(false);
    },
  });
  const { errors, touched, handleSubmit, getFieldProps, values } = formik;

  const [groups, setGroups] = useState([]);
  useEffect(() => {
    GetAnnouncementGroups().then((resp) => {
      var result = resp.data.data.reduce(function (r, a) {
        r[a.GrupId] = r[a.GrupId] || [];
        r[a.GrupId].push(a);
        return r;
      }, Object.create(null));
      setGroups(
        Object.values(result).map((t) => ({
          id: t[0].GrupId,
          name: t[0].GroupName,
          count: t.length,
        }))
      );
    });
  }, []);
  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Stack sx={{ mt: 1, mb: 2 }} spacing={3}>
            <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
              <TextField
                required
                sx={{ width: { xs: "100%", md: "50%" } }}
                select
                label={IntlTranslate("to")}
                {...getFieldProps("to")}
                error={Boolean(touched.to && errors.to)}
                helperText={touched.to && errors.to}
              >
                {groups.map((t) => (
                  <MenuItem key={t.id} value={t.id}>
                    {t.name}
                    <b>
                      {" ("}
                      {t.count} kişi
                      {")"}
                    </b>
                  </MenuItem>
                ))}
              </TextField>
              <Grid
                container
                spacing={0}
                sx={{ width: { xs: "100%", md: "50%" } }}
              >
                <Grid xs={12} md={4}>
                  <Field
                    type="checkbox"
                    name="sendMethod"
                    value="Push Notification"
                    as={FormControlLabel}
                    control={
                      <Checkbox
                        disabled
                        checked={values.sendMethod.includes(
                          "Push Notification"
                        )}
                      />
                    }
                    label="Push Notification"
                  />
                </Grid>
                <Grid xs={12} md={4}>
                  <Field
                    type="checkbox"
                    name="sendMethod"
                    value="SMS"
                    as={FormControlLabel}
                    control={
                      <Checkbox
                        disabled
                        checked={values.sendMethod.includes("SMS")}
                      />
                    }
                    label="SMS"
                  />
                </Grid>
                <Grid xs={12} md={4}>
                  <Field
                    type="checkbox"
                    name="sendMethod"
                    value="Sadece Ekran"
                    as={FormControlLabel}
                    control={
                      <Checkbox
                        checked={values.sendMethod.includes("Sadece Ekran")}
                      />
                    }
                    label="Sadece Ekran"
                  />
                </Grid>
                {touched.sendMethod && errors.sendMethod && (
                  <Typography
                    component={FormHelperText}
                    error
                    sx={{
                      width: "100%",
                      margin: "3px 14px 0 14px",
                      fontSize: "12px",
                    }}
                  >
                    {errors.sendMethod}
                  </Typography>
                )}
              </Grid>
            </Stack>
            <Stack spacing={2}>
              <TextField
                required
                fullWidth
                multiline
                rows={4}
                label={IntlTranslate("message")}
                {...getFieldProps("message")}
                error={Boolean(touched.message && errors.message)}
                helperText={touched.message && errors.message}
              />
            </Stack>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{
                my: 3,
              }}
              endIcon={<SendIcon />}
              loading={loading}
            >
              <FormattedMessage id="send" />
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate("announcementssuccessadd")}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(AnnouncementsAdd, "announcements.add");
