import React from "react";
import * as Yup from "yup";
import IntlTranslate from "../../helpers/IntlTranslate";
import { useFormik } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

function DevicesUpdateDialog({ updateInputs, setUpdateInputs }) {
  const schema = Yup.object().shape({
    DeviceName: Yup.string().required(IntlTranslate("deviceName.required")),
    DeviceType: Yup.string().required(IntlTranslate("deviceType.required")),
  });
  const formik = useFormik({
    initialValues: {
      DeviceName: updateInputs.DeviceName,
      DeviceType: updateInputs.DeviceType,
    },
    validationSchema: schema,
  });
  const { errors, touched, values, setFieldValue } = formik;
  return (
    <Stack sx={{ mt: 1 }} spacing={3}>
      <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
        <TextField
          required
          fullWidth
          label={IntlTranslate("agentName")}
          name="DeviceName"
          value={values.DeviceName}
          onChange={(e) => {
            setFieldValue("DeviceName", e.target.value);
            setUpdateInputs((prev) => ({
              ...prev,
              DeviceName: e.target.value,
            }));
          }}
          error={Boolean(touched.DeviceName && errors.DeviceName)}
          helperText={touched.DeviceName && errors.DeviceName}
        />
        <FormControl sx={{ width: { xs: "100%", md: "50%" } }}>
          <FormLabel id="device-type">
            <FormattedMessage id="deviceType" />
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="device-type"
            value={values.DeviceType}
            onChange={(event, data) => {
              setFieldValue("DeviceType", data);
              setUpdateInputs((prev) => ({ ...prev, DeviceType: data }));
            }}
          >
            <FormControlLabel
              value="androidPOS"
              control={<Radio />}
              label="Android POS"
            />
            <FormControlLabel
              value="tablet"
              control={<Radio />}
              label="Tablet"
            />
            <FormControlLabel value="brand" control={<Radio />} label="Brand" />
            <FormControlLabel
              value="telefon"
              control={<Radio />}
              label="Telefon"
            />
          </RadioGroup>
          {touched.DeviceType && errors.DeviceType && (
            <FormHelperText
              error={Boolean(touched.DeviceType && errors.DeviceType)}
            >
              {errors.DeviceType}
            </FormHelperText>
          )}
        </FormControl>
      </Stack>
    </Stack>
  );
}

export default DevicesUpdateDialog;
