import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import CustomDataGrid from "../../components/CustomDataGrid";
import withTitle from "../../helpers/hoc/withTitle";
import { useAuth } from "../../context/AuthContext";
import {
  GetUsersByAgentsId,
  GetAllUsers,
  AddUser,
  GetUserRole,
  UserSetAllowedPages,
  UserRemoveAllowedPages,
  DeleteUser,
  GetRoles,
  UserRoleUpdate,
  // CreateAgentUser,
} from "../../api/api";
import SnackbarComponent from "../../components/Snackbar";
import IntlTranslate from "../../helpers/IntlTranslate";
import UsersUpdateDialog from "./UsersUpdateDialog";
function Users() {
  const breadcrumb = useMemo(
    () => [{ name: <FormattedMessage id="users" />, active: true }],
    []
  );
  const columns = useMemo(
    () => [
      { field: "Id", headerName: "ID", minWidth: 75 },
      { field: "Name", headerName: "Ad", minWidth: 150, flex: 1 },
      { field: "Surname", headerName: "Soyad", minWidth: 150, flex: 1 },
      {
        field: "UserName",
        headerName: "Kullanıcı Adı",
        minWidth: 150,
        flex: 1,
      },
      { field: "Email", headerName: "E-Posta", minWidth: 150, flex: 1 },
      { field: "Phone", headerName: "Telefon", minWidth: 150, flex: 1 },
    ],
    []
  );
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  const { role } = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [updateDate, setUpdateDate] = useState(new Date());
  useEffect(() => {
    setLoading(true);
    if (role.isFullAgent) {
      GetUsersByAgentsId(role.agentData.agentId).then((resp) => {
        let filtered = resp.data["data: "]
          .filter(
            (v, i, a) =>
              a.findIndex((v2) => v2.AgencyUserId === v.AgencyUserId) === i
          )
          .map((e) => ({
            Id: e.AgencyUserId,
            Name: e.Name,
            Surname: e.Surname,
            UserName: e.UserName,
            Email: e.Email,
            Phone: e.Phone,
          }));
        setData(filtered);
      });
    } else {
      GetAllUsers().then((resp) =>
        setData(
          resp.map((e) => ({
            Id: e.Id,
            Name: e.Name,
            Surname: e.Surname,
            UserName: e.UserName,
            Email: e.Email,
            Phone: e.Phone,
          }))
        )
      );
    }
    setLoading(false);
  }, [role, updateDate]);

  //START - Update & Delete Actions
  const [editDeleteSelectedRow, setEditDeleteSelectedRow] = useState({
    operation: "",
    rows: [],
  });
  const onClose = () => {
    setEditDeleteSelectedRow((prev) => ({
      operation: prev.operation,
      rows: [],
    }));
  };

  const onDelete = async () => {
    let i = 0;
    while (i < editDeleteSelectedRow.rows.length) {
      let result = await DeleteUser(editDeleteSelectedRow.rows[i]);
      if (result.data.status === "success") {
        i++;
      } else {
        break;
      }
    }
    setSnackbarType("success");
    setSnackbarOpen(true);
    setEditDeleteSelectedRow((prev) => ({
      operation: prev.operation,
      rows: [],
    }));
    setUpdateDate(new Date());
  };

  const [updateInputs, setUpdateInputs] = useState({
    Name: "",
    Surname: "",
    Phone: "",
    Email: "",
    UserName: "",
    Password: "",
    userRole: "",
    // userType: "",
    allowedPages: [],
  });

  useEffect(() => {
    if (
      editDeleteSelectedRow.operation === "edit" &&
      editDeleteSelectedRow.rows.length > 0
    ) {
      GetUserRole(editDeleteSelectedRow.rows[0]).then((resp) => {
        let userAllowedPages = resp.map(({ PageID }) => PageID);
        let getUserRoleOld = [...new Set(resp.map(({ RoleId }) => RoleId))][0];
        setUpdateInputs({
          Name: data.find((e) => e.Id === editDeleteSelectedRow.rows[0])?.Name,
          Surname: data.find((e) => e.Id === editDeleteSelectedRow.rows[0])
            ?.Surname,
          Phone: data.find((e) => e.Id === editDeleteSelectedRow.rows[0])
            ?.Phone,
          Email: data.find((e) => e.Id === editDeleteSelectedRow.rows[0])
            ?.Email,
          UserName: data.find((e) => e.Id === editDeleteSelectedRow.rows[0])
            ?.UserName,
          Password: "",
          userRole: getUserRoleOld,
          allowedPages: userAllowedPages,
        });
      });
    }
  }, [editDeleteSelectedRow, data]);

  const onUpdate = async () => {
    let i = 0;
    while (i < editDeleteSelectedRow.rows.length) {
      let result = await AddUser({
        Id: editDeleteSelectedRow.rows[i],
        Name: updateInputs.Name,
        Surname: updateInputs.Surname,
        Phone: updateInputs.Phone,
        Email: updateInputs.Email,
        UserName: updateInputs.UserName,
        Password: updateInputs.Password,
        IsSuperAdmin: updateInputs.userRole === "1" ? true : false,
      });
      if (result.data.status === "success") {
        await UserRoleUpdate(
          editDeleteSelectedRow.rows[i],
          updateInputs.userRole
        );
        // if (updateInputs.userRole === "2" || updateInputs.userRole === "5") {
        //   var respo = await CreateAgentUser({
        //     Id: 0,
        //     UserId: editDeleteSelectedRow.rows[i],
        //     AcenteId: updateInputs.userType,
        //   });
        // }
        let pagesObj = { add: [], remove: [] };
        await GetUserRole(editDeleteSelectedRow.rows[0]).then(
          (userOldPages) => {
            let userPagesOld = userOldPages.map(({ PageID }) => PageID);
            for (let a = 0; a < updateInputs.allowedPages.length; a++) {
              if (!userPagesOld.includes(updateInputs.allowedPages[a])) {
                pagesObj.add.push(updateInputs.allowedPages[a]);
              }
            }
            for (let b = 0; b < userPagesOld.length; b++) {
              if (!updateInputs.allowedPages.includes(userPagesOld[b])) {
                pagesObj.remove.push(userPagesOld[b]);
              }
            }
          }
        );
        let k = 0;
        while (k < pagesObj.add.length) {
          let resp = await UserSetAllowedPages(
            0,
            editDeleteSelectedRow.rows[i],
            updateInputs.userRole,
            pagesObj.add[k]
          );
          if (resp.status === "success" && resp.UserRoleId) {
            k++;
          } else {
            break;
          }
        }
        k = 0;
        while (k < pagesObj.remove.length) {
          let resp = await UserRemoveAllowedPages(
            editDeleteSelectedRow.rows[i],
            updateInputs.userRole,
            pagesObj.remove[k]
          );
          if (resp.data.status === "success" && resp.data.UserId === 0) {
            k++;
          } else {
            break;
          }
        }
        i++;
      } else {
        break;
      }
    }
    setSnackbarType("success");
    setSnackbarOpen(true);
    setEditDeleteSelectedRow((prev) => ({
      operation: prev.operation,
      rows: [],
    }));
    setUpdateDate(new Date());
  };
  //END - Update & Delete Actions

  const [allRoles, setAllRoles] = useState([]);
  useEffect(() => {
    GetRoles().then((resp) => setAllRoles(resp));
  }, []);
  return (
    <AppContainer breadcrumbs={breadcrumb} addButton={{ name: "users.add" }}>
      <CustomDataGrid
        data={data}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.Id}
        rowsUniqueId="Id"
        setEditDeleteSelectedRow={setEditDeleteSelectedRow}
      />
      <Dialog
        open={
          editDeleteSelectedRow.operation === "delete" &&
          editDeleteSelectedRow.rows.length > 0
            ? true
            : false
        }
        onClose={onClose}
      >
        <DialogTitle>
          <FormattedMessage id="delete_item" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editDeleteSelectedRow.rows.length > 1 ? (
              <FormattedMessage
                id="deleteItemsCountMessage"
                values={{ count: editDeleteSelectedRow.rows.length }}
              />
            ) : (
              <>
                <FormattedMessage id="deleteitemmessage" />
                {
                  data.find((e) => e.Id === editDeleteSelectedRow.rows[0])
                    ?.UserName
                }
                ({data.find((e) => e.Id === editDeleteSelectedRow.rows[0])?.Id}
                )?{" "}
              </>
            )}
            <FormattedMessage id="deleteitemmessage2" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            <FormattedMessage id="cancelbtn" />
          </Button>
          <Button onClick={onDelete} color="secondary" autoFocus>
            <FormattedMessage id="deletebtn" />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={
          editDeleteSelectedRow.operation === "edit" &&
          editDeleteSelectedRow.rows.length > 0
            ? true
            : false
        }
        onClose={onClose}
      >
        <DialogTitle>
          <FormattedMessage id="update_item" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editDeleteSelectedRow.rows.length > 1 ? (
              <FormattedMessage
                id="updateItemsCountMessage"
                values={{ count: editDeleteSelectedRow.rows.length }}
              />
            ) : (
              <>
                <FormattedMessage id="updateitemmessage" />
                {
                  data.find((e) => e.Id === editDeleteSelectedRow.rows[0])
                    ?.UserName
                }
                ({data.find((e) => e.Id === editDeleteSelectedRow.rows[0])?.Id}
                )?{" "}
              </>
            )}
          </DialogContentText>
          <UsersUpdateDialog
            updateInputs={{
              Name: data.find((e) => e.Id === editDeleteSelectedRow.rows[0])
                ?.Name,
              Surname: data.find((e) => e.Id === editDeleteSelectedRow.rows[0])
                ?.Surname,
              Phone: data.find((e) => e.Id === editDeleteSelectedRow.rows[0])
                ?.Phone,
              Email: data.find((e) => e.Id === editDeleteSelectedRow.rows[0])
                ?.Email,
              UserName: data.find((e) => e.Id === editDeleteSelectedRow.rows[0])
                ?.UserName,
              Password: "",
              userRole: updateInputs.userRole,
              allowedPages: updateInputs.allowedPages,
            }}
            setUpdateInputs={setUpdateInputs}
            allRoles={allRoles}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            <FormattedMessage id="cancelbtn" />
          </Button>
          <Button onClick={onUpdate} color="secondary" autoFocus>
            <FormattedMessage id="updatebtn" />
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate(
          editDeleteSelectedRow.operation === "delete"
            ? "deleteSuccess"
            : "updateSuccess"
        )}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(Users, "users");
