import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
//layouts
import AppLayout from "../components/layout/AppLayout";
import AuthLayout from "../components/layout/AuthLayout";
//pages
import Login from "../pages/Auth/Login";
import NotFound from "../pages/NotFound";
import Home from "../pages/Home";
import Agents from "../pages/Agents";
import Cards from "../pages/Cards";
import CancellationRequests from "../pages/CancellationRequests";
import BusStops from "../pages/BusStops";
import BusStopsAdd from "../pages/BusStops/BusStopsAdd";
import Users from "../pages/Users";
import UsersAdd from "../pages/Users/UsersAdd";
import AdditionalServices from "../pages/AdditionalServices";
import Tickets from "../pages/Tickets";
import QRCodeDebit from "../pages/QRCodeDebit";
import AgentsAdd from "../pages/Agents/AgentsAdd";
import Buses from "../pages/Buses";
import BusesAdd from "../pages/Buses/BusesAdd";
import Expeditions from "../pages/Expeditions";
import ExpeditionsAdd from "../pages/Expeditions/ExpeditionsAdd";
import Announcements from "../pages/Announcements";
import Devices from "../pages/Devices";
import DevicesAdd from "../pages/Devices/DevicesAdd";
import AnnouncementsAdd from "../pages/Announcements/AnnouncementsAdd";
import SuperAdmin from "../pages/SuperAdmin";
import AnnouncementsGroups from "../pages/Announcements/AnnouncementsGroups";
import CardsAdd from "../pages/Cards/CardsAdd";
import SellTickets from "../pages/SellTickets";
import Roles from "../pages/Roles";

//reports
import TicketDetail from "../pages/reports/TicketDetail";
import TicketProgress from "../pages/reports/TicketProgress";
import ServiceTrack from "../pages/reports/ServiceTrack";
import LoginLogoutDetail from "../pages/reports/LoginLogoutDetail";
import PerformanceTrack from "../pages/reports/PerformanceTrack";
import CardSales from "../pages/reports/CardSales";

//definitions
import PeriodicTicketPrice from "../pages/PeriodicTicketPrice";
import ActiveVehicles from "../pages/reports/ActiveVehicles";
import DriverStopNotification from "../pages/DriverStopNotification";
import CancellationRequestedTickets from "../pages/reports/CancellationRequestedTickets";
import AdditionalServiceDefinedTickets from "../pages/reports/AdditionalServiceDefinedTickets";
import PassangerTrends from "../pages/reports/PassangerTrends";
import RolesAdd from "../pages/Roles/RolesAdd";
import BusStopsEdit from "../pages/BusStops/BusStopsEdit";
import Profile from "../pages/Profile";
import PeriodicTicketPriceAdd from "../pages/PeriodicTicketPrice/PeriodicTicketPriceAdd";
import CancelReasons from "../pages/CancelReasons";
import TicketLog from "../pages/reports/TicketLog";
import TicketServicesLog from "../pages/reports/TicketServicesLog";
import AdditionalServiceAdd from "../pages/AdditionalServices/AdditionalServiceAdd";
import SellAdditionalService from "../pages/AdditionalServices/SellAdditionalService";

const Pages = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/" replace /> },
      { path: "*", element: <Navigate to="/404" /> },
      {
        path: "/",
        element: (
          <ProtectedRoute id="dashboard">
            <Home />
          </ProtectedRoute>
        ),
      },
      { path: "404", element: <NotFound /> },
    ],
  },
  {
    path: "/profile",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/profile" replace /> },
      {
        path: "/profile",
        element: (
          <ProtectedRoute id="profile">
            <Profile />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/reports",
    element: <AppLayout />,
    children: [
      {
        path: "ticket-detail",
        element: (
          <ProtectedRoute id="reports.ticketDetail">
            <TicketDetail />
          </ProtectedRoute>
        ),
      },
      {
        path: "ticket-progress",
        element: (
          <ProtectedRoute id="reports.ticketProgress">
            <TicketProgress />
          </ProtectedRoute>
        ),
      },
      {
        path: "cancellation-requested-tickets",
        element: (
          <ProtectedRoute id="reports.cancellationRequestedTickets">
            <CancellationRequestedTickets />
          </ProtectedRoute>
        ),
      },
      {
        path: "service-track",
        element: (
          <ProtectedRoute id="reports.serviceTrack">
            <ServiceTrack />
          </ProtectedRoute>
        ),
      },
      {
        path: "login-logout",
        element: (
          <ProtectedRoute id="reports.login-out">
            <LoginLogoutDetail />
          </ProtectedRoute>
        ),
      },
      {
        path: "performance-track",
        element: (
          <ProtectedRoute id="reports.performanceTrack">
            <PerformanceTrack />
          </ProtectedRoute>
        ),
      },
      {
        path: "card-sales",
        element: (
          <ProtectedRoute id="reports.cardSales">
            <CardSales />
          </ProtectedRoute>
        ),
      },
      {
        path: "active-vehicles",
        element: (
          <ProtectedRoute id="reports.activeVehicles">
            <ActiveVehicles />
          </ProtectedRoute>
        ),
      },
      {
        path: "additional-service-tickets",
        element: (
          <ProtectedRoute id="reports.additionalServiceTickets">
            <AdditionalServiceDefinedTickets />
          </ProtectedRoute>
        ),
      },
      {
        path: "passanger-trends",
        element: (
          <ProtectedRoute id="reports.passangerTrends">
            <PassangerTrends />
          </ProtectedRoute>
        ),
      },
      {
        path: "ticket-log",
        element: (
          <ProtectedRoute id="reports.ticketLog">
            <TicketLog />
          </ProtectedRoute>
        ),
      },
      {
        path: "ticket-services-log",
        element: (
          <ProtectedRoute id="reports.ticketServicesLog">
            <TicketServicesLog />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/users",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/users" replace /> },
      {
        path: "/users",
        element: (
          <ProtectedRoute id="users">
            <Users />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute id="users.add">
            <UsersAdd />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/tickets",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/tickets" replace /> },
      {
        path: "/tickets",
        element: (
          <ProtectedRoute id="tickets">
            <Tickets />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/buses",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/buses" replace /> },
      {
        path: "/buses",
        element: (
          <ProtectedRoute id="buses">
            <Buses />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute id="buses.add">
            <BusesAdd />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/qr-debit",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/qr-debit" replace /> },
      {
        path: "/qr-debit",
        element: (
          <ProtectedRoute id="QRDebit">
            <QRCodeDebit />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/agents",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/agents" replace /> },
      {
        path: "/agents",
        element: (
          <ProtectedRoute id="agents">
            <Agents />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute id="agents.add">
            <AgentsAdd />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/cards",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/cards" replace /> },
      {
        path: "/cards",
        element: (
          <ProtectedRoute id="cards">
            <Cards />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute id="cards.add">
            <CardsAdd />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/additional-services",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/additional-services" replace /> },
      {
        path: "/additional-services",
        element: (
          <ProtectedRoute id="additionalServices">
            <AdditionalServices />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute id="additionalServices.add">
            <AdditionalServiceAdd />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/announcements",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/announcements" replace /> },
      {
        path: "/announcements",
        element: (
          <ProtectedRoute id="announcements">
            <Announcements />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute id="announcements.add">
            <AnnouncementsAdd />
          </ProtectedRoute>
        ),
      },
      {
        path: "add-group",
        element: (
          <ProtectedRoute id="groups">
            <AnnouncementsGroups />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/devices",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/devices" replace /> },
      {
        path: "/devices",
        element: (
          <ProtectedRoute id="devices">
            <Devices />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute id="devices.add">
            <DevicesAdd />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/bus-routes",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/bus-routes" replace /> },
      {
        path: "/bus-routes",
        element: (
          <ProtectedRoute id="busStops">
            <BusStops />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute id="busStops.add">
            <BusStopsAdd />
          </ProtectedRoute>
        ),
      },
      {
        path: "edit/:id",
        element: (
          <ProtectedRoute id="busStops.edit">
            <BusStopsEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/expeditions",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/expeditions" replace /> },
      {
        path: "/expeditions",
        element: (
          <ProtectedRoute id="expeditions">
            <Expeditions />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute id="expeditions.add">
            <ExpeditionsAdd />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/cancellation-requests",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/cancellation-requests" replace /> },
      {
        path: "/cancellation-requests",
        element: (
          <ProtectedRoute id="cancellationRequests">
            <CancellationRequests />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/cancellation-reasons",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/cancellation-reasons" replace /> },
      {
        path: "/cancellation-reasons",
        element: (
          <ProtectedRoute id="cancelReasons">
            <CancelReasons />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/periodic-ticket-price",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/periodic-ticket-price" replace /> },
      {
        path: "/periodic-ticket-price",
        element: (
          <ProtectedRoute id="periodicTicketPrice">
            <PeriodicTicketPrice />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute id="periodicTicketPrice.add">
            <PeriodicTicketPriceAdd />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/driver-stop-notification",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/driver-stop-notification" replace /> },
      {
        path: "/driver-stop-notification",
        element: (
          <ProtectedRoute id="driverStopNotification">
            <DriverStopNotification />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/sellTickets",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/sellTickets" replace /> },
      {
        path: "/sellTickets",
        element: (
          <ProtectedRoute id="sellTickets">
            <SellTickets />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/defineAdditionalService",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/defineAdditionalService" replace /> },
      {
        path: "/defineAdditionalService",
        element: (
          <ProtectedRoute id="defineAdditionalService">
            <SellAdditionalService />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/roles",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/roles" replace /> },
      {
        path: "/roles",
        element: (
          <ProtectedRoute id="roles">
            <Roles />
          </ProtectedRoute>
        ),
      },
      {
        path: "add",
        element: (
          <ProtectedRoute id="roles.add">
            <RolesAdd />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: <AuthLayout />,
    children: [
      { element: <Navigate to="/login" replace /> },
      { path: "/login", element: <Login /> },
    ],
  },
  {
    path: "/super-admin",
    element: <AppLayout />,
    children: [
      { element: <Navigate to="/super-admin" replace /> },
      {
        path: "/super-admin",
        element: (
          <ProtectedRoute id="0">
            <SuperAdmin />
          </ProtectedRoute>
        ),
      },
    ],
  },
];
export default function SiteRoutes() {
  return useRoutes([...Pages]);
}
