import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import {
  ActiveVehiclesGetAll,
  BusSelectAll,
  RoutesSelectAll,
} from "../../api/api";
import CustomDataGrid from "../../components/CustomDataGrid";
import GetDataArea from "../../components/GetDataArea";
import Grid from "@mui/material/Unstable_Grid2";
//satırı boyamak için gerekli method ve importlar
import { lighten } from "@mui/material/styles";
import dayjs from "dayjs";
const getBackgroundColor = (color) => lighten(color, 0.25);
const getHoverBackgroundColor = (color) => lighten(color, 0.3);

function ActiveVehicles() {
  const breadcrumb = useMemo(
    () => [
      { name: <FormattedMessage id="reports.activeVehicles" />, active: true },
    ],
    []
  );

  //table
  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "#",
      },
      {
        field: "SeferId",
        headerName: "Sefer Id",
        minWidth: 75,
      },
      {
        field: "SeferDate",
        headerName: "Sefer Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm");
        },
      },
      {
        field: "UpdateDate",
        headerName: "Güncelleme Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm");
        },
      },
      {
        field: "RouteName",
        headerName: "Otobüs Güzergahı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "BusPlate",
        headerName: "Otobüs Plakası",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "BusName",
        headerName: "Otobüs Adı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "EmptySeats",
        headerName: "Boş Koltuk Sayısı",
        minWidth: 150,
        flex: 1,
        cellClassName: (params) => {
          if (params.value && params.value > 0) {
            return "grid-theme--success";
          } else {
            return "-";
          }
        },
      },
      {
        field: "FullSeats",
        headerName: "Dolu Koltuk Sayısı",
        minWidth: 150,
        flex: 1,
        valueGetter: (params) => {
          return (
            70 -
            (params.row.EmptySeats !== "-" ? Number(params.row.EmptySeats) : 0)
          );
        },
      },
      {
        field: "DriverName",
        headerName: "Şoför",
        minWidth: 150,
        flex: 1,
      },

      {
        field: "PrevStationName",
        headerName: "Önceki Durak",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "NextStationName",
        headerName: "Sonraki Durak",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "Note",
        headerName: "Açıklama",
        minWidth: 150,
        flex: 1,
      },
    ],
    []
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //GET DATA FILTERS
  const [busRoute, setBusRoute] = useState(null);
  const [busPlate, setBusPlate] = useState(null);
  const [hasEmptySeats, setHasEmptySeats] = useState(false);

  const [busRoutesOption, setBusRoutesOption] = useState([]);
  const [busPlateOption, setBusPlateOption] = useState([]);

  useEffect(() => {
    RoutesSelectAll().then((response) => {
      const ROUTES = [];
      for (const item of response.data["data: "]) {
        var route = ROUTES.find((r) => r.id === item.Id);
        if (!route) {
          ROUTES.push({
            id: item.Id,
            label: item.Route,
          });
        }
      }
      setBusRoutesOption(ROUTES);
    });

    BusSelectAll().then((resp) =>
      setBusPlateOption(
        resp.data["data: "].map((e) => ({
          id: e.Id,
          label: e.Plate,
          busname: e.Name,
        }))
      )
    );
  }, []);

  const HandleSearchClick = useCallback(
    async (start, end) => {
      setLoading(true);
      await ActiveVehiclesGetAll({
        DateStart: dayjs(start).toISOString(),
        DateEnd: dayjs(end).toISOString(),
        RouteId: busRoute?.id,
        BusPlate: busPlate?.id,
      })
        .then((resp) => {
          if (hasEmptySeats) {
            let filtered = resp.data.data.filter((a) => a?.EmptySeats > 0);
            setData(
              filtered.map((e, i) => ({
                id: i + 1,
                SeferId: e.SeferId,
                SeferDate: e.SeferDate,
                UpdateDate: e.CreateDate,
                RouteName: e.RouteName,
                BusPlate: e.BusPlate,
                BusName: e.BusName,
                EmptySeats: e.EmptySeats ? e.EmptySeats : "-",
                DriverName: `${e.DriverName} ${e.DriverSurname}`,
                PrevStationName: e.PrevStationName ? e.PrevStationName : "-",
                NextStationName: e.NextStationName ? e.NextStationName : "-",
                Note: e.Aciklama,
              }))
            );
          } else {
            setData(
              resp.data.data.map((e, i) => ({
                id: i + 1,
                SeferId: e.SeferId,
                SeferDate: e.SeferDate,
                UpdateDate: e.CreateDate,
                RouteName: e.RouteName,
                BusPlate: e.BusPlate,
                BusName: e.BusName,
                EmptySeats: e.EmptySeats ? e.EmptySeats : "-",
                DriverName: `${e.DriverName} ${e.DriverSurname}`,
                PrevStationName: e.PrevStationName ? e.PrevStationName : "-",
                NextStationName: e.NextStationName ? e.NextStationName : "-",
                Note: e.Aciklama,
              }))
            );
          }
        })
        .finally(() => setLoading(false));
    },
    [busRoute, busPlate, hasEmptySeats]
  );
  const HandleDeleteClick = useCallback(async () => {
    setBusRoute(null);
    setBusPlate(null);
    setHasEmptySeats(false);
  }, []);

  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <GetDataArea
        HandleSearchClick={HandleSearchClick}
        HandleDeleteClick={HandleDeleteClick}
      >
        <Grid xs={12} lg={2}>
          <Autocomplete
            fullWidth
            size="small"
            options={busRoutesOption}
            value={busRoute}
            onChange={(event, newValue) => {
              setBusRoute(newValue);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Otobüs Güzergahı" />
            )}
          />
        </Grid>
        <Grid xs={12} lg={2}>
          <Autocomplete
            fullWidth
            size="small"
            options={busPlateOption}
            value={busPlate}
            onChange={(event, newValue) => {
              setBusPlate(newValue);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Otobüs Plakası" />
            )}
          />
        </Grid>
        <Grid xs={12} lg={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasEmptySeats}
                  onChange={(e) => setHasEmptySeats(e.target.checked)}
                />
              }
              label="Sadece Boş Koltuğu Olanlar"
            />
          </FormGroup>
        </Grid>
      </GetDataArea>
      <Box
        sx={{
          "& .grid-theme--success": {
            bgcolor: (theme) => getBackgroundColor(theme.palette.success.main),
            "&:hover": {
              bgcolor: (theme) =>
                getHoverBackgroundColor(theme.palette.success.main),
            },
          },
        }}
      >
        <CustomDataGrid
          data={data}
          columns={columns}
          loading={loading}
          allowEditDelete={false}
        />
      </Box>
    </AppContainer>
  );
}

export default withTitle(ActiveVehicles, "reports.activeVehicles");
