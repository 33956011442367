import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Unauthorized from "../pages/Unauthorized";
export const ProtectedRoute = ({ id, children }) => {
  const { loggedIn, pages = [] } = useAuth();
  if (!loggedIn) {
    return <Navigate to="/login" />;
  }
  if (id !== "profile" && !pages.includes(id)) {
    return <Unauthorized />;
  }
  return children;
};
