import React from "react";
import * as Yup from "yup";
import IntlTranslate from "../../helpers/IntlTranslate";
import { useFormik } from "formik";
import { Stack, TextField } from "@mui/material";

function AgentsUpdateDialog({ updateInputs, setUpdateInputs }) {
  const schema = Yup.object().shape({
    Acente: Yup.string().required(IntlTranslate("agentName.required")),
    Sirket: Yup.string().required(IntlTranslate("companyName.required")),
  });
  const formik = useFormik({
    initialValues: {
      Acente: updateInputs.Acente,
      Sirket: updateInputs.Sirket,
    },
    validationSchema: schema,
  });
  const { errors, touched, values, setFieldValue } = formik;
  return (
    <Stack sx={{ mt: 1 }} spacing={3}>
      <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
        <TextField
          required
          fullWidth
          label={IntlTranslate("agentName")}
          name="Acente"
          value={values.Acente}
          onChange={(e) => {
            setFieldValue("Acente", e.target.value);
            setUpdateInputs((prev) => ({ ...prev, Acente: e.target.value }));
          }}
          error={Boolean(touched.Acente && errors.Acente)}
          helperText={touched.Acente && errors.Acente}
        />
        <TextField
          required
          fullWidth
          label={IntlTranslate("companyName")}
          name="Sirket"
          value={values.Sirket}
          onChange={(e) => {
            setFieldValue("Sirket", e.target.value);
            setUpdateInputs((prev) => ({ ...prev, Sirket: e.target.value }));
          }}
          error={Boolean(touched.Sirket && errors.Sirket)}
          helperText={touched.Sirket && errors.Sirket}
        />
      </Stack>
    </Stack>
  );
}

export default AgentsUpdateDialog;
