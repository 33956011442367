import React from "react";
import axios from "axios";
import { DeleteAdditionalService } from "../api/api";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

function DeleteDialog(props) {
  const { open, onClose, onDelete, itemName, itemId } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id="delete_item" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id="deleteitemmessage" /> {itemName} ({itemId})?{" "}
          <FormattedMessage id="deleteitemmessage2" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          <FormattedMessage id="cancelbtn" />
        </Button>
        <Button onClick={onDelete} color="secondary" autoFocus>
          <FormattedMessage id="deletebtn" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;
