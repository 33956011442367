import { useFormik, Form, FormikProvider, Field } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import IntlTranslate from "../../helpers/IntlTranslate";
import * as Yup from "yup";
import AppContainer from "../../components/layout/AppContainer";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import withTitle from "../../helpers/hoc/withTitle";
import Grid from "@mui/material/Unstable_Grid2";
import {
  AddUser,
  GetPages,
  UserSetAllowedPages,
  GetRoles,
  CreateAgentUser,
  AgentsGetAll,
} from "../../api/api";
import SnackbarComponent from "../../components/Snackbar";
function UsersAdd() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="users" />,
        path: "/users",
      },
      { name: <FormattedMessage id="users.add" />, active: true },
    ],
    []
  );
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  const [loading, setLoading] = useState(false);
  const schema = Yup.object().shape({
    Name: Yup.string().required(IntlTranslate("name.required")),
    Surname: Yup.string().required(IntlTranslate("surname.required")),
    Phone: Yup.string().required(IntlTranslate("phone.required")),
    Email: Yup.string()
      .email(IntlTranslate("email.invalid"))
      .required(IntlTranslate("email.required")),
    UserName: Yup.string()
      .min(3, IntlTranslate("username.min"))
      .required(IntlTranslate("username.required")),
    Password: Yup.string()
      .min(4, IntlTranslate("password.min"))
      .required(IntlTranslate("password.required")),
    userRole: Yup.string().required(IntlTranslate("role.required")),
    userType: Yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      Id: "0",
      Name: "",
      Surname: "",
      Phone: "",
      Email: "",
      UserName: "",
      Password: "",
      userRole: "2",
      userType: "",
      allowedPages: [],
    },
    validationSchema: schema,
    onSubmit: async (datas, { resetForm }) => {
      setLoading(true);
      var { userRole, userType, allowedPages, ...rest } = datas;
      var response = await AddUser({
        ...rest,
        IsSuperAdmin: userRole === "1" ? true : false,
      });
      if (response.data.status === "success") {
        let i = 0;
        setSnackbarType("success");
        setSnackbarOpen(true);
        while (i < allowedPages.length) {
          let result = await UserSetAllowedPages(
            0,
            response.data.UserId,
            userRole,
            allowedPages[i]
          );
          if (result.status === "success" && result.UserRoleId) {
            i++;
          } else {
            break;
          }
        }
        if (userRole === "2" || userRole === "5") {
          var respo = await CreateAgentUser({
            Id: 0,
            UserId: response.data.UserId,
            AcenteId: userType,
          });
          if (respo.data.status === "success" && respo.data.Id) {
            resetForm();
            setSnackbarType("success");
            setSnackbarOpen(true);
          } else {
            setSnackbarType("error");
            setSnackbarOpen(true);
          }
        }
        resetForm();
      }
      setLoading(false);
    },
  });
  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const RandomPassword = () => {
    setFieldValue("Password", generatePasword());
  };

  const [allPages, setAllPages] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  useEffect(() => {
    GetPages().then((resp) => setAllPages(resp.data["returnValue: "]));
    GetRoles().then((resp) => setAllRoles(resp));
  }, []);

  const [allAgents, setAllAgents] = useState([]);
  useEffect(() => {
    if (values.userRole === "2" && allAgents.length === 0) {
      AgentsGetAll().then((resp) => setAllAgents(resp.data["data: "]));
    }
  }, [values.userRole, allAgents]);

  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Stack sx={{ mt: 1 }} spacing={3}>
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <TextField
                required
                fullWidth
                type="text"
                label={IntlTranslate("name")}
                {...getFieldProps("Name")}
                error={Boolean(touched.Name && errors.Name)}
                helperText={touched.Name && errors.Name}
              />
              <TextField
                required
                fullWidth
                type="text"
                label={IntlTranslate("surname")}
                {...getFieldProps("Surname")}
                error={Boolean(touched.Surname && errors.Surname)}
                helperText={touched.Surname && errors.Surname}
              />
            </Stack>
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <TextField
                required
                fullWidth
                type="tel"
                label={IntlTranslate("phone")}
                {...getFieldProps("Phone")}
                error={Boolean(touched.Phone && errors.Phone)}
                helperText={touched.Phone && errors.Phone}
              />
              <TextField
                required
                fullWidth
                type="email"
                label={IntlTranslate("email")}
                {...getFieldProps("Email")}
                placeholder={IntlTranslate("email.placeholder")}
                error={Boolean(touched.Email && errors.Email)}
                helperText={touched.Email && errors.Email}
              />
            </Stack>
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <Stack sx={{ width: { xs: "100%", lg: "50%" } }}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  label={IntlTranslate("username")}
                  {...getFieldProps("UserName")}
                  error={Boolean(touched.UserName && errors.UserName)}
                  helperText={touched.UserName && errors.UserName}
                />
              </Stack>
              <FormGroup
                row
                sx={{
                  width: { xs: "100%", lg: "50%" },
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  required
                  type={showPassword ? "text" : "password"}
                  label={IntlTranslate("password")}
                  {...getFieldProps("Password")}
                  error={Boolean(touched.Password && errors.Password)}
                  helperText={touched.Password && errors.Password}
                  sx={{ width: "70%" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{ width: "30%", pl: 2 }}>
                  <Button
                    variant="outlined"
                    color="info"
                    fullWidth
                    sx={{ height: "56px" }}
                    onClick={RandomPassword}
                    startIcon={<ShuffleIcon />}
                  >
                    <FormattedMessage id="random" />
                  </Button>
                </Box>
              </FormGroup>
            </Stack>
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <TextField
                required
                fullWidth
                select
                label={IntlTranslate("role")}
                {...getFieldProps("userRole")}
                error={Boolean(touched.userRole && errors.userRole)}
                helperText={touched.userRole && errors.userRole}
              >
                {allRoles.map((item, index) => (
                  <MenuItem key={index} value={item.Id}>
                    <b>{item.RoleTitle}</b>({item.Role})
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                select
                sx={{
                  display:
                    values.userRole === "2" || values.userRole === "5"
                      ? "inline-flex"
                      : "none",
                }}
                label={IntlTranslate("agentName")}
                {...getFieldProps("userType")}
                error={Boolean(touched.userType && errors.userType)}
                helperText={touched.userType && errors.userType}
              >
                {allAgents.map((item, index) => (
                  <MenuItem key={index} value={item.Id}>
                    <b>{item.Sirket}</b>({item.Acente})
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack spacing={2}>
              <Stack direction="row" alignItems="center">
                <Typography variant="h6">
                  <FormattedMessage id="accessiblePages" />{" "}
                </Typography>
                <FormGroup sx={{ ml: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={values.allowedPages.length === allPages.length}
                        indeterminate={
                          values.allowedPages.length !== allPages.length &&
                          values.allowedPages.length > 0
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFieldValue(
                              "allowedPages",
                              allPages.map((t) => t.PageID)
                            );
                          } else {
                            setFieldValue("allowedPages", []);
                          }
                        }}
                      />
                    }
                    label="Tümünü Seç"
                  />
                </FormGroup>
              </Stack>
              <Divider />
              <Grid container spacing={0}>
                {allPages.map((item, index) => (
                  <Grid key={index} xs={12} sm={6} md={4} lg={3}>
                    <Field
                      type="checkbox"
                      name="allowedPages"
                      value={item.PageID}
                      as={FormControlLabel}
                      control={
                        <Checkbox
                          checked={values.allowedPages.includes(item.PageID)}
                        />
                      }
                      label={<FormattedMessage id={item.PageTitle} />}
                    />
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </Stack>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="success"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
          >
            <FormattedMessage id="add" />
          </LoadingButton>
        </Form>
      </FormikProvider>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate("usersuccessadd")}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(UsersAdd, "users.add");

const generatePasword = (length = 8) => {
  var charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var password = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    password += charset.charAt(Math.floor(Math.random() * n));
  }
  return password;
};
