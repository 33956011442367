import {
  Autocomplete,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import IntlTranslate from "../../helpers/IntlTranslate";
import withTitle from "../../helpers/hoc/withTitle";
import SnackbarComponent from "../../components/Snackbar";
import {
  AdditionalServicesSelectAll,
  CreateCards,
  CreateCardsAdditionalServices,
} from "../../api/api";
function AnnouncementsGroups() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="cards" />,
        path: "/cards",
      },
      { name: <FormattedMessage id="cards.add" />, active: true },
    ],
    []
  );

  //formik
  const [loading, setLoading] = useState(false);
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  const schema = Yup.object().shape({
    cardName: Yup.string().required(IntlTranslate("cardName.required")),
    price: Yup.number()
      .min(0, IntlTranslate("price.min"))
      .required(IntlTranslate("price.required")),
    activationTime: Yup.number()
      .min(0, IntlTranslate("activationTime.min"))
      .required(IntlTranslate("activationTime.required")),
    usageTime: Yup.number()
      .min(0, IntlTranslate("usageTime.min"))
      .required(IntlTranslate("usageTime.required")),
  });
  const formik = useFormik({
    initialValues: {
      cardName: "",
      price: "",
      cardColor: "",
      activationTime: 72,
      usageTime: 24,
    },
    validationSchema: schema,
    onSubmit: async (datas, { resetForm }) => {
      setLoading(true);
      let { cardName, price, cardColor, activationTime, usageTime } = datas;
      let response = await CreateCards({
        Id: 0,
        KartAdi: cardName,
        Fiyat: price,
        KartRengi: cardColor,
        AktivasyonSuresi: activationTime,
        HizmetSuresi: usageTime,
      });
      if (response.data.status === "success") {
        let i = 0;
        while (i < additionalServices.length) {
          let result = await CreateCardsAdditionalServices({
            Id: 0,
            KartId: response.data.Id,
            EkHizmetId: additionalServices[i].Id,
          });
          if (result.data.status === "success" && result.data.Id) {
            i++;
          } else {
            break;
          }
        }
      }
      setSnackbarType("success");
      setSnackbarOpen(true);
      setLoading(false);
      resetForm();
      setAdditionalServices([]);
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  const [additionalServices, setAdditionalServices] = useState([]);
  const [serviceData, setServiceData] = useState([]);

  const GetAdditionalServices = async () => {
    setLoading(true);
    let response = await AdditionalServicesSelectAll();
    setServiceData(response.data.data);
    setLoading(false);
  };

  useEffect(() => {
    GetAdditionalServices();
  }, []);

  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Stack sx={{ mt: 1, mb: 2 }} spacing={3}>
            <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
              <TextField
                required
                fullWidth
                label={IntlTranslate("cardName")}
                {...getFieldProps("cardName")}
                error={Boolean(touched.cardName && errors.groupName)}
                helperText={touched.cardName && errors.cardName}
              />
              <Autocomplete
                fullWidth
                multiple
                options={serviceData}
                getOptionLabel={(opt) => opt.EkHizmetAdi}
                isOptionEqualToValue={(option, value) => option.Id === value.Id}
                onChange={(event, data) => setAdditionalServices(data)}
                value={additionalServices}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={IntlTranslate("additionalServices")}
                  />
                )}
              />
            </Stack>
            <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
              <TextField
                required
                fullWidth
                type="number"
                label={IntlTranslate("price")}
                {...getFieldProps("price")}
                error={Boolean(touched.price && errors.price)}
                helperText={touched.price && errors.price}
              />
              <TextField
                required
                fullWidth
                select
                label={IntlTranslate("cardColor")}
                {...getFieldProps("cardColor")}
                error={Boolean(touched.cardColor && errors.cardColor)}
                helperText={touched.cardColor && errors.cardColor}
              >
                <MenuItem value="red">Kırmızı</MenuItem>
                <MenuItem value="blue">Mavi</MenuItem>
                <MenuItem value="green">Yeşil</MenuItem>
                <MenuItem value="orange">Turuncu</MenuItem>
                <MenuItem value="gray">Gri</MenuItem>
              </TextField>
            </Stack>
            <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
              <TextField
                required
                fullWidth
                type="number"
                label={IntlTranslate("activationTime")}
                {...getFieldProps("activationTime")}
                error={Boolean(touched.activationTime && errors.activationTime)}
                helperText={touched.activationTime && errors.activationTime}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FormattedMessage id="hours" />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                required
                fullWidth
                type="number"
                label={IntlTranslate("usageTime")}
                {...getFieldProps("usageTime")}
                error={Boolean(touched.usageTime && errors.usageTime)}
                helperText={touched.usageTime && errors.usageTime}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FormattedMessage id="hours" />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="success"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
            >
              <FormattedMessage id="add" />
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate("cardsuccessadd")}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(AnnouncementsGroups, "cards.add");
