import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import dayjs from "dayjs";
import GroupsIcon from "@mui/icons-material/Groups";
import { DeleteAnnouncement, GetAnnouncements } from "../../api/api";
import DeleteIcon from "@mui/icons-material/Delete";
import SnackbarComponent from "../../components/Snackbar";
import IntlTranslate from "../../helpers/IntlTranslate";
import parse from "html-react-parser";

function Announcements() {
  const breadcrumb = useMemo(
    () => [{ name: <FormattedMessage id="announcements" />, active: true }],
    []
  );
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  const [data, setData] = useState([]);
  const [refreshData, setRefreshData] = useState(new Date().getTime());

  useEffect(() => {
    GetAnnouncements().then((resp) =>
      setData(
        resp.data.data.sort((a, b) => Number(b.DuyuruId) - Number(a.DuyuruId))
      )
    );
  }, [refreshData]);

  const [selectedRow, setSelectedRow] = useState("");
  const [openDialog, setOpenDialog] = useState({
    operation: "",
    open: false,
    data: "",
  });
  const handleClickOpen = (type) => {
    setOpenDialog({ operation: type, open: true });
  };
  const handleCloseDialog = () => {
    setOpenDialog({
      operation: "",
      open: false,
    });
    setSelectedRow("");
  };

  const onDelete = () => {
    DeleteAnnouncement(selectedRow).then((resp) => {
      if (resp.data.status === "success" && resp.data.Id === 0) {
        setSnackbarType("success");
        setSnackbarOpen(true);
        setOpenDialog((prev) => ({
          operation: prev.operation,
          open: false,
        }));
        setSelectedRow("");
        setRefreshData(new Date().getTime());
      }
    });
  };
  return (
    <AppContainer
      breadcrumbs={breadcrumb}
      addButton={{ name: "announcements.add" }}
      extraButton={{
        name: "groups",
        path: "add-group",
        startIcon: <GroupsIcon />,
        variant: "outlined",
      }}
    >
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 400 }}
          aria-label="announcement table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Duyuru Tarihi</TableCell>
              <TableCell>Kimden</TableCell>
              <TableCell>Kime</TableCell>
              <TableCell>Gönderim Türü</TableCell>
              <TableCell>Mesaj</TableCell>
              <TableCell align="right">Seçenekler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.DuyuruId}</TableCell>
                <TableCell>
                  {dayjs(row.CreateDate).format("DD/MM/YYYY HH:mm")}
                </TableCell>
                <TableCell>{row.FromName}</TableCell>
                <TableCell>{row?.GroupName || "-"}</TableCell>
                <TableCell>{row.Type}</TableCell>
                <TableCell>{parse(row.Message.split("|MESAJAYRACI|")[0])}<b>{row.Message.split("|MESAJAYRACI|")[1]}</b></TableCell>
                <TableCell align="right">
                  <IconButton
                    color="error"
                    aria-label="delete"
                    sx={{ ml: 1 }}
                    onClick={() => {
                      handleClickOpen("delete");
                      setSelectedRow(row.DuyuruId);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={
          openDialog.operation === "delete" && selectedRow !== "" ? true : false
        }
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          <FormattedMessage id="delete_item" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id="deleteitemmessage" />
            {data.find((e) => e.DuyuruId === selectedRow)?.GroupName}{" "}
            {data.find((e) => e.DuyuruId === selectedRow)?.DuyuruId}
            ? <FormattedMessage id="deleteitemmessage2" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            <FormattedMessage id="cancelbtn" />
          </Button>
          <Button onClick={onDelete} color="secondary" autoFocus>
            <FormattedMessage id="deletebtn" />
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate("deleteSuccess")}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(Announcements, "announcements");
