import React, { useCallback, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import Stack from "@mui/material/Stack";
import logo from "../../assets/logo.png";
import { ReactComponent as BusforusLogo } from "../../assets/busforus-logo-white.svg";
import iuas from "../../assets/IUAS.svg";
import loginBg from "../../assets/loginBg.jpg";
import IntlTranslate from "../../helpers/IntlTranslate";
import withTitle from "../../helpers/hoc/withTitle";
import {
  fetchLogin,
  GetUserRole,
  GetAgentsUser,
  SetLoginDetails,
} from "../../api/api";
import { useAuth } from "../../context/AuthContext";
import { sha1 } from "crypto-hash";
import { GetFirstPagesPath } from "../../routes/SiteLinks";
import SnackbarComponent from "../../components/Snackbar";
function Copyright(props) {
  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
      sx={{ backgroundColor: "primary.main", color: "#fff" }}
      p={2}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <img src={logo} alt="Smarttek member of Payten logo" height={32} />

        <img src={iuas} alt="IUAS" height={32} />
      </Stack>
      <Typography variant="body2" align="center" {...props}>
        {"Copyright © "}
        <Link color="inherit" href="https://smarttekas.com.tr/">
          Smarttek AŞ
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Stack>
  );
}

function Login() {
  const { login, RolesWrite } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [location, setLocation] = useState({ message: "", coords: "" });
  const navigate = useNavigate();
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  const schema = Yup.object().shape({
    username: Yup.string().required(IntlTranslate("username.required")),
    password: Yup.string()
      .min(4, IntlTranslate("password.min"))
      .required(IntlTranslate("password.required")),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: async (datas) => {
      setLoading(true);
      TryGetLocation();
      if (location.message === "success") {
        let pass = await sha1(datas.password);
        const loginResponse = await fetchLogin({
          UserName: datas.username,
          Password: pass,
        });
        if (loginResponse.data.status === "success") {
          login(loginResponse);
          var roles = await GetUserRole(loginResponse.data.data[0].Id);
          var isAgent = await GetAgentsUser(loginResponse.data.data[0].Id);
          await SetLoginDetails({
            Id: loginResponse.data.data[0].LoginDetailId,
            Location: location.coords,
          });
          RolesWrite(roles, isAgent.data["data: "]);
          if (
            loginResponse.data.data.find((e) => e.PageTitle === "dashboard")
          ) {
            navigate("/");
          } else {
            let path = GetFirstPagesPath(loginResponse.data.data[1].PageTitle);
            navigate(path);
          }
        } else {
          setSnackbarType("error");
          setSnackbarOpen(true);
        }
      } else {
        setSnackbarType("warning");
        setSnackbarOpen(true);
      }
      setLoading(false);
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const TryGetLocation = useCallback(() => {
    if (window.navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({
            message: "success",
            coords: `${latitude},${longitude}`,
          });
        },
        (err) => {
          setLocation({ message: "error", coords: "" });
        }
      );
    } else {
      setLocation({ message: "browser_does_not_support", coords: "" });
    }
  }, []);
  useEffect(() => {
    TryGetLocation();
  }, [TryGetLocation]);
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        sm={12}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{ display: "flex", flexDirection: "column", height: "inherit" }}
      >
        <Box
          boxShadow={1}
          sx={{
            backgroundColor: "primary.main",
            color: "#fff",
            textAlign: "center",
            padding: ".25rem",
          }}
        >
          <BusforusLogo />
        </Box>
        <Box
          sx={{
            mb: 2,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: "1",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#1A428A" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            <FormattedMessage id="login" />
          </Typography>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <Box sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="text"
                  label={IntlTranslate("username")}
                  {...getFieldProps("username")}
                  error={Boolean(touched.username && errors.username)}
                  helperText={touched.username && errors.username}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  label={IntlTranslate("password")}
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label={IntlTranslate("rememberMe")}
                />
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  color="secondary"
                  loading={loading}
                >
                  <FormattedMessage id="login" />
                </LoadingButton>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Link href="#" variant="body2" color="secondary.main">
                      <FormattedMessage id="forgotPassword" />
                    </Link>
                  </Grid>
                  <Grid item>
                    <LanguageSwitcher />
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </FormikProvider>
        </Box>
        <Copyright />
      </Grid>

      <Grid
        item
        sm={false}
        md={7}
        sx={{
          backgroundImage: `url(${loginBg})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <SnackbarComponent
        open={snackbarOpen}
        errorMessage={IntlTranslate("login.error")}
        warningMessage={IntlTranslate("location.warning")}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </Grid>
  );
}
export default withTitle(Login, "login");
