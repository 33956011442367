import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import { RoutesSelectAll, ServiceTrackingGetAll } from "../../api/api";
import CustomDataGrid from "../../components/CustomDataGrid";
import GetDataArea from "../../components/GetDataArea";
import { Autocomplete, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";

function ServiceTrack() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="reports.serviceTrack" />,
        active: true,
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        minWidth: 75,
      },
      {
        field: "Station",
        headerName: "Durak Adı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "SatisAktivasyonSayisi",
        headerName: "Satış Aktivasyon Sayısı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "HizmetKullanimSayisi",
        headerName: "Hizmet Kullanım Sayısı",
        minWidth: 150,
        flex: 1,
      },
    ],
    []
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //GET DATA FILTERS
  const [busStop, setBusStop] = useState(null);
  const [busStopsOption, setBusStopsOption] = useState([]);

  useEffect(() => {
    RoutesSelectAll().then((response) => {
      const STOPS = [];
      for (const item of response.data["data: "]) {
        var stop = STOPS.find((r) => r.label === item.Station);
        if (!stop) {
          STOPS.push({
            id: item.StationId,
            label: item.Station,
          });
        }
      }
      setBusStopsOption(STOPS);
    });
  }, []);

  const HandleSearchClick = useCallback(
    async (start, end) => {
      setLoading(true);
      await ServiceTrackingGetAll({
        DateStart: dayjs(start).toISOString(),
        DateEnd: dayjs(end).toISOString(),
        StationId: busStop?.id,
      })
        .then((resp) =>
          setData(
            resp.data.data.map(({ StationId, ...e }, index) => ({
              id: index,
              ...e,
            }))
          )
        )
        .finally(() => setLoading(false));
    },
    [busStop]
  );
  const HandleDeleteClick = useCallback(async () => {
    setBusStop(null);
  }, []);

  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <GetDataArea
        HandleSearchClick={HandleSearchClick}
        HandleDeleteClick={HandleDeleteClick}
      >
        <Grid xs={12} lg={2}>
          <Autocomplete
            fullWidth
            size="small"
            options={busStopsOption}
            value={busStop}
            onChange={(event, newValue) => {
              setBusStop(newValue);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Durak" />
            )}
          />
        </Grid>
      </GetDataArea>
      <CustomDataGrid
        data={data}
        columns={columns}
        loading={loading}
        allowEditDelete={false}
      />
    </AppContainer>
  );
}

export default withTitle(ServiceTrack, "reports.serviceTrack");
