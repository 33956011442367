import {
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IntlTranslate from "../../helpers/IntlTranslate";
//form imports
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from "formik";
const PAGES = [
  {
    id: 0,
    name: "dashboard",
    path: "/",
    iconComponentName: "DashboardIcon",
  },
  {
    id: 1,
    name: "QRDebit",
    path: "/qr-debit",
    iconComponentName: "QrCode2Icon",
  },
  {
    id: 2,
    name: "agents",
    path: "/agents",
    iconComponentName: "ApartmentIcon",
  },
  {
    id: 3,
    name: "users",
    path: "/users",
    iconComponentName: "PeopleAltIcon",
  },
  {
    id: 4,
    name: "tickets",
    path: "/tickets",
    iconComponentName: "ConfirmationNumberIcon",
  },
  {
    id: 5,
    name: "buses",
    path: "/buses",
    iconComponentName: "DirectionsBusIcon",
  },
  {
    id: 6,
    name: "busStops",
    path: "/bus-routes",
    iconComponentName: "TransferWithinAStationIcon",
  },
  {
    id: 7,
    name: "expeditions",
    path: "/expeditions",
    iconComponentName: "DepartureBoardIcon",
  },
  {
    id: 8,
    name: "cards",
    path: "/cards",
    iconComponentName: "CreditCardIcon",
  },
  {
    id: 9,
    name: "additionalServices",
    path: "/additional-services",
    iconComponentName: "FastfoodIcon",
  },
  {
    id: 10,
    name: "devices",
    path: "/devices",
    iconComponentName: "TabletAndroidIcon",
  },
  {
    id: 11,
    name: "announcements",
    path: "/announcements",
    iconComponentName: "CampaignIcon",
  },
  {
    id: 12,
    name: "cancellationRequests",
    path: "/cancellation-requests",
    iconComponentName: "CancelScheduleSendIcon",
  },
];
function SuperAdmin() {
  const breadcrumb = useMemo(
    () => [{ name: "Super Admin Control Panel", active: true }],
    []
  );

  //pages form
  const [loading, setLoading] = useState(false);
  const schema = Yup.object().shape({
    pageName: Yup.string().required(IntlTranslate("pageName.required")),
    pagePath: Yup.string().required(IntlTranslate("pagePath.required")),
    pageIconName: Yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      pageName: "",
      pagePath: "",
      pageIconName: "",
    },
    validationSchema: schema,
    onSubmit: (datas, { resetForm }) => {
      setLoading(true);
      setLoading(false);
      resetForm();
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <Paper elevation={12} sx={{ p: 2 }}>
        <Typography variant="h2" sx={{ fontSize: "2rem" }}>
          <FormattedMessage id="pages" />
        </Typography>
        <Divider sx={{ mb: 2 }} />

        <Paper sx={{ mb: 2 }}>
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <Stack sx={{ mt: 1, mb: 2 }} spacing={3}>
                <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
                  <TextField
                    required
                    fullWidth
                    label={IntlTranslate("pageName")}
                    {...getFieldProps("pageName")}
                    placeholder="QRDebit"
                    error={Boolean(touched.pageName && errors.pageName)}
                    helperText={touched.pageName && errors.pageName}
                  />
                  <TextField
                    required
                    fullWidth
                    label={IntlTranslate("pagePath")}
                    {...getFieldProps("pagePath")}
                    placeholder="/qr-debit"
                    error={Boolean(touched.pagePath && errors.pagePath)}
                    helperText={touched.pagePath && errors.pagePath}
                  />
                  <TextField
                    fullWidth
                    label={IntlTranslate("pageIconName")}
                    {...getFieldProps("pageIconName")}
                    placeholder="QrCode2Icon"
                    error={Boolean(touched.pageIconName && errors.pageIconName)}
                    helperText={touched.pageIconName && errors.pageIconName}
                  />
                </Stack>
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="success"
                  sx={{ mt: 3, mb: 2 }}
                  loading={loading}
                >
                  <FormattedMessage id="add" />
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </Paper>

        <TableContainer component={Paper}>
          <Table aria-label="pages table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Path</TableCell>
                <TableCell>Material Icon Component Name </TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {PAGES.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.path}</TableCell>
                  <TableCell>{row.iconComponentName}</TableCell>
                  <TableCell align="right">
                    <IconButton color="warning" aria-label="edit">
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      aria-label="delete"
                      sx={{ ml: 1 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </AppContainer>
  );
}

export default withTitle(SuperAdmin, "Super Admin Control Panel", false);
