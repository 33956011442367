import { LanguageProvider } from "./context/LanguageContext";
import { AuthProvider } from "./context/AuthContext";
import SiteRoutes from "./routes/SiteRoutes";
import "./App.css";
//DataGrid hariç kullanmamız gereken Grid componenti(paddingleri daha doğru). AutoImport edilmiyor.
//Import edeceğiniz zaman bu linki kullanabilirsiniz
//import Grid from '@mui/material/Unstable_Grid2';

function App() {
  return (
    <AuthProvider>
      <LanguageProvider>
        <SiteRoutes />
      </LanguageProvider>
    </AuthProvider>
  );
}

export default App;
