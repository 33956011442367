import { Box, Button } from "@mui/material";
import React from "react";
import AppBreadcrumb from "./AppBreadcrumb";

import AddIcon from "@mui/icons-material/Add";
import { Link as RouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
function AppContainer({ breadcrumbs, addButton, extraButton, children }) {
  return (
    <Box component="main" sx={{ px: 3, mt: 10, mb: 3 }}>
      <AppBreadcrumb links={breadcrumbs}>
        {extraButton && (
          <Button
            color="secondary"
            variant={extraButton.variant}
            size="small"
            startIcon={extraButton.startIcon}
            component={RouterLink}
            to={extraButton.path}
            sx={{ ml: "auto" }}
          >
            <FormattedMessage id={extraButton.name} />
          </Button>
        )}
        {addButton && (
          <Button
            color="secondary"
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            component={RouterLink}
            to={addButton?.path || "add"}
            sx={{ ml: extraButton ? 2 : "auto" }}
          >
            <FormattedMessage id={addButton.name} />
          </Button>
        )}
      </AppBreadcrumb>

      {children}
    </Box>
  );
}

export default AppContainer;
