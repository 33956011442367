import { TextField } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import { TicketLogsGetAll } from "../../api/api";
import CustomDataGrid from "../../components/CustomDataGrid";
import GetDataArea from "../../components/GetDataArea";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";
import ThumbDownRoundedIcon from "@mui/icons-material/ThumbDownRounded";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";

function TicketServicesLog() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="reports.ticketServicesLog" />,
        active: true,
      },
    ],
    []
  );

  const ekHizmetColumns = useMemo(
    () => [
      {
        field: "Id",
        headerName: "ID",
        minWidth: 75,
      },
      {
        field: "BiletReferansKodu",
        headerName: "Bilet Numarası",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "SepetReferansKodu",
        headerName: "Sepet Referans Kodu",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "AdSoyad",
        headerName: "Müşteri Ad Soyad",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "EkHizmetAdi",
        headerName: "Ek Hizmet Adı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "CreateDate",
        headerName: "Eklenme Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm");
        },
      },
      {
        field: "ModifyDate",
        headerName: "Kullanım Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          if (params.value) {
            return dayjs(params.value).format("DD.MM.YYYY HH:mm");
          } else {
            return "-";
          }
        },
      },
      {
        field: "IsUsed",
        headerName: "Kullanıldı Mı?",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          return params.value === true ? (
            <ThumbUpRoundedIcon color="success" />
          ) : (
            <ThumbDownRoundedIcon color="error" />
          );
        },
      },
      {
        field: "KartAdi",
        headerName: "Kart Adı",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          return params.value === null ? <i>Ek Hizmet</i> : params.value;
        },
      },
      {
        field: "IsCard",
        headerName: "Karta Tanımlı Standart Hizmet Mi?",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          return params.value === true ? (
            <ThumbUpRoundedIcon color="success" />
          ) : (
            <ThumbDownRoundedIcon color="error" />
          );
        },
      },
    ],
    []
  );

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //GET DATA FILTERS
  const [ticketRefNo, setTicketRefNo] = useState("");
  const [refCode, setRefCode] = useState("");
  const [customerNameSurname, setCustomerNameSurname] = useState("");

  const HandleSearchClick = useCallback(async () => {
    setLoading(true);
    await TicketLogsGetAll({
      ...(ticketRefNo !== "" && {
        BiletReferansKodu: ticketRefNo,
      }),
      ...(refCode !== "" && {
        SepetReferansKodu: refCode,
      }),
      ...(customerNameSurname !== "" && {
        MusteriAdSoyad: customerNameSurname,
      }),
      Filter: "BiletEkHizmetHareketleri",
    })
      .then((resp) => {
        setData(
          resp.data.data.map(
            ({ EkHizmetId, ModifyUserId, ModifyUser, KartId, ...rest }) => rest
          )
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [ticketRefNo, refCode, customerNameSurname]);

  const HandleDeleteClick = useCallback(async () => {
    setTicketRefNo("");
    setRefCode("");
    setCustomerNameSurname("");
  }, []);
  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <GetDataArea
        HandleSearchClick={HandleSearchClick}
        HandleDeleteClick={HandleDeleteClick}
        dateFilter={false}
      >
        <Grid xs={12} lg={2}>
          <TextField
            fullWidth
            size="small"
            label="Bilet Numarası"
            value={ticketRefNo}
            onChange={(e) => setTicketRefNo(e.target.value)}
          />
        </Grid>
        <Grid xs={12} lg={2}>
          <TextField
            fullWidth
            size="small"
            label="Referans Kodu"
            value={refCode}
            onChange={(e) => setRefCode(e.target.value)}
          />
        </Grid>
        <Grid xs={12} lg={2}>
          <TextField
            fullWidth
            size="small"
            label="Müşteri Ad-Soyad"
            value={customerNameSurname}
            onChange={(e) => setCustomerNameSurname(e.target.value)}
          />
        </Grid>
      </GetDataArea>
      <CustomDataGrid
        data={data}
        columns={ekHizmetColumns}
        loading={loading}
        allowEditDelete={false}
        getRowId={(row) => row.Id}
        rowsUniqueId="Id"
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "CreateDate",
                sort: "desc",
              },
            ],
          },
        }}
      />
    </AppContainer>
  );
}

export default withTitle(TicketServicesLog, "reports.ticketServicesLog");
