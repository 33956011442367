import { Autocomplete, TextField } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import { CardSalesSelectAll, GetCards } from "../../api/api";
import CustomDataGrid from "../../components/CustomDataGrid";
import GetDataArea from "../../components/GetDataArea";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";

function CardSales() {
  const breadcrumb = useMemo(
    () => [{ name: <FormattedMessage id="reports.cardSales" />, active: true }],
    []
  );

  const columns = useMemo(
    () => [
      {
        field: "KartId",
        headerName: "Kart ID",
        minWidth: 75,
      },
      {
        field: "KartAdi",
        headerName: "Kart Adı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "KartRengi",
        headerName: "Kart Rengi",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "SatilanBilet",
        headerName: "Satış Sayısı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "AktiveEdilenBilet",
        headerName: "Aktifleştirme Sayısı",
        minWidth: 150,
        flex: 1,
      },
    ],
    []
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //GET DATA FILTERS
  const [cardType, setCardType] = useState(null);

  const [cardsOption, setCardsOption] = useState([]);
  useEffect(() => {
    GetCards().then((resp) =>
      setCardsOption(
        resp.data.data.map((e) => ({ id: e.Id, label: e.KartAdi }))
      )
    );
  }, []);

  const HandleSearchClick = useCallback(
    async (start, end) => {
      setLoading(true);
      await CardSalesSelectAll({
        DateStart: dayjs(start).toISOString(),
        DateEnd: dayjs(end).toISOString(),
        KartId: cardType?.id,
      })
        .then((resp) => {
          let filtered = resp.data.data.filter(
            (t) => t.KartId !== null && t.KartAdi !== null
          );
          setData(filtered);
        })
        .finally(() => setLoading(false));
    },
    [cardType]
  );
  const HandleDeleteClick = useCallback(async () => {
    setCardType([]);
  }, []);
  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <GetDataArea
        HandleSearchClick={HandleSearchClick}
        HandleDeleteClick={HandleDeleteClick}
      >
        <Grid xs={12} lg={2}>
          <Autocomplete
            fullWidth
            size="small"
            options={cardsOption}
            value={cardType}
            onChange={(event, newValue) => {
              setCardType(newValue);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Kart Tipi" />
            )}
          />
        </Grid>
      </GetDataArea>
      <CustomDataGrid
        data={data}
        columns={columns}
        loading={loading}
        allowEditDelete={false}
        getRowId={(row) => row.KartId}
        rowsUniqueId="KartId"
      />
    </AppContainer>
  );
}

export default withTitle(CardSales, "reports.cardSales");
