import { useFormik, Field, FormikProvider } from "formik";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import IntlTranslate from "../../helpers/IntlTranslate";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import Grid from "@mui/material/Unstable_Grid2";
import { AgentsGetAll, GetPages } from "../../api/api";
function UsersUpdateDialog({ updateInputs, setUpdateInputs, allRoles }) {
  const schema = Yup.object().shape({
    Name: Yup.string().required(IntlTranslate("name.required")),
    Surname: Yup.string().required(IntlTranslate("surname.required")),
    Phone: Yup.string().required(IntlTranslate("phone.required")),
    Email: Yup.string()
      .email(IntlTranslate("email.invalid"))
      .required(IntlTranslate("email.required")),
    UserName: Yup.string()
      .min(3, IntlTranslate("username.min"))
      .required(IntlTranslate("username.required")),
    Password: Yup.string().min(4, IntlTranslate("password.min")),
    userRole: Yup.string().required(IntlTranslate("role.required")),
    userType: Yup.string().when("userRole", {
      is: "2",
      then: Yup.string().required(IntlTranslate("agentName.required")),
    }),
  });
  const formik = useFormik({
    initialValues: {
      Name: updateInputs.Name,
      Surname: updateInputs.Surname,
      Phone: updateInputs.Phone,
      Email: updateInputs.Email,
      UserName: updateInputs.UserName,
      Password: "",
      userRole: updateInputs.userRole,
      // userType: updateInputs.userType,
      userType: "",
      allowedPages: updateInputs.allowedPages,
    },
    validationSchema: schema,
    enableReinitialize: true,
  });
  //allowed pages ilk seferde boş geliyor onu düzelt
  const { errors, touched, setFieldValue, values } = formik;
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const RandomPassword = () => {
    setFieldValue("Password", generatePasword());
  };

  const [allPages, setAllPages] = useState([]);
  useEffect(() => {
    GetPages().then((resp) => setAllPages(resp.data["returnValue: "]));
  }, []);

  const [allAgents, setAllAgents] = useState([]);
  useEffect(() => {
    if (allAgents.length === 0) {
      AgentsGetAll().then((resp) => setAllAgents(resp.data["data: "]));
    }
  }, [values.userRole, allAgents]);

  useEffect(() => {
    setUpdateInputs((prev) => ({ ...prev, allowedPages: values.allowedPages }));
  }, [values.allowedPages, setUpdateInputs]);
  return (
    <FormikProvider value={formik}>
      <Stack sx={{ mt: 1 }} spacing={3}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <TextField
            required
            fullWidth
            type="text"
            label={IntlTranslate("name")}
            name="Name"
            value={values.Name}
            onChange={(e) => {
              setFieldValue("Name", e.target.value);
              setUpdateInputs((prev) => ({ ...prev, Name: e.target.value }));
            }}
            error={Boolean(touched.Name && errors.Name)}
            helperText={touched.Name && errors.Name}
          />
          <TextField
            required
            fullWidth
            type="text"
            label={IntlTranslate("surname")}
            name="Surname"
            value={values.Surname}
            onChange={(e) => {
              setFieldValue("Surname", e.target.value);
              setUpdateInputs((prev) => ({ ...prev, Surname: e.target.value }));
            }}
            error={Boolean(touched.Surname && errors.Surname)}
            helperText={touched.Surname && errors.Surname}
          />
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <TextField
            required
            fullWidth
            type="tel"
            label={IntlTranslate("phone")}
            name="Phone"
            value={values.Phone}
            onChange={(e) => {
              setFieldValue("Phone", e.target.value);
              setUpdateInputs((prev) => ({ ...prev, Phone: e.target.value }));
            }}
            error={Boolean(touched.Phone && errors.Phone)}
            helperText={touched.Phone && errors.Phone}
          />
          <TextField
            required
            fullWidth
            type="email"
            label={IntlTranslate("email")}
            name="Email"
            value={values.Email}
            onChange={(e) => {
              setFieldValue("Email", e.target.value);
              setUpdateInputs((prev) => ({ ...prev, Email: e.target.value }));
            }}
            placeholder={IntlTranslate("email.placeholder")}
            error={Boolean(touched.Email && errors.Email)}
            helperText={touched.Email && errors.Email}
          />
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <Stack sx={{ width: { xs: "100%", lg: "50%" } }}>
            <TextField
              required
              fullWidth
              type="text"
              label={IntlTranslate("username")}
              name="UserName"
              value={values.UserName}
              onChange={(e) => {
                setFieldValue("UserName", e.target.value);
                setUpdateInputs((prev) => ({
                  ...prev,
                  UserName: e.target.value,
                }));
              }}
              error={Boolean(touched.UserName && errors.UserName)}
              helperText={touched.UserName && errors.UserName}
            />
          </Stack>
          <FormGroup
            row
            sx={{
              width: { xs: "100%", lg: "50%" },
              justifyContent: "space-between",
            }}
          >
            <TextField
              type={showPassword ? "text" : "password"}
              label={IntlTranslate("password")}
              name="Password"
              value={values.Password}
              onChange={(e) => {
                setFieldValue("Password", e.target.value);
                setUpdateInputs((prev) => ({
                  ...prev,
                  Password: e.target.value,
                }));
              }}
              error={Boolean(touched.Password && errors.Password)}
              helperText={touched.Password && errors.Password}
              sx={{ width: "70%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ width: "30%", pl: 2 }}>
              <Button
                variant="outlined"
                color="info"
                fullWidth
                sx={{ height: "56px" }}
                onClick={RandomPassword}
                startIcon={<ShuffleIcon />}
              >
                <FormattedMessage id="random" />
              </Button>
            </Box>
          </FormGroup>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <TextField
            required
            fullWidth
            select
            label={IntlTranslate("role")}
            name="userRole"
            value={values.userRole}
            onChange={(e) => {
              setFieldValue("userRole", e.target.value);
              setUpdateInputs((prev) => ({
                ...prev,
                userRole: e.target.value,
              }));
            }}
            error={Boolean(touched.userRole && errors.userRole)}
            helperText={touched.userRole && errors.userRole}
          >
            {allRoles.map((item, index) => (
              <MenuItem key={index} value={item.Id}>
                <b>{item.RoleTitle}</b>({item.Role})
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            sx={{
              display:
                values.userRole === "2" || values.userRole === "5"
                  ? "inline-flex"
                  : "none",
            }}
            label={IntlTranslate("agentName")}
            name="userType"
            value={values.userType}
            onChange={(e) => {
              setFieldValue("userType", e.target.value);
              setUpdateInputs((prev) => ({
                ...prev,
                userType: e.target.value,
              }));
            }}
            error={Boolean(touched.userType && errors.userType)}
            helperText={touched.userType && errors.userType}
          >
            {allAgents.map((item, index) => (
              <MenuItem key={index} value={item.Id}>
                <b>{item.Sirket}</b>({item.Acente})
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack spacing={2}>
          <Typography variant="h6">
            <FormattedMessage id="accessiblePages" />
          </Typography>
          <Divider />
          <Grid container spacing={0}>
            {allPages.length > 0 &&
              allPages.map((item, index) => (
                <Grid key={index} xs={12} sm={6} md={4} lg={3}>
                  <Field
                    type="checkbox"
                    name="allowedPages"
                    value={item.PageID}
                    as={FormControlLabel}
                    control={
                      <Checkbox
                        checked={values.allowedPages.includes(
                          item.PageID.toString()
                        )}
                      />
                    }
                    label={<FormattedMessage id={item.PageTitle} />}
                  />
                </Grid>
              ))}
          </Grid>
        </Stack>
      </Stack>
    </FormikProvider>
  );
}

export default UsersUpdateDialog;
const generatePasword = (length = 8) => {
  var charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var password = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    password += charset.charAt(Math.floor(Math.random() * n));
  }
  return password;
};
