import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import HeaderMenu from "./HeaderMenu";
export default function AppLayout() {
  return (
    <HeaderMenu>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          flexGrow: 1,
        }}
      >
        <Outlet />
        <Footer />
      </Box>
    </HeaderMenu>
  );
}
