import IntlTranslate from "../IntlTranslate";

const withTitle =
  (Component, title, translate = true) =>
  ({ ...props }) => {
    if (title !== "") {
      var newtitle = title;
      if (translate) {
        newtitle = IntlTranslate(title);
      }
      document.title = `Bus For Us - ${newtitle}`;
    } else {
      document.title = "Bus For Us";
    }
    return <Component {...props} />;
  };
export default withTitle;
