import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import WeeklySales from "./Dashboard/WeeklySales";
import Grid from "@mui/material/Unstable_Grid2";
import SalesInformation from "./Dashboard/SalesInformation";
import { Box, Card, Typography } from "@mui/material";
import RouteUsingRatio from "./Dashboard/RouteUsingRatio";
import CardSales from "./Dashboard/CardSales";
import dayjs from "dayjs";
import { GetDashboardData } from "../../api/api";
import { useAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
function Home() {
  const breadcrumb = useMemo(
    () => [{ name: <FormattedMessage id="dashboard" />, active: true }],
    []
  );

  const [dateFilters, setDateFilters] = useState("allTime");

  const [data, setData] = useState({});
  useEffect(() => {
    var dates = { start: dayjs("1970-01-01"), end: dayjs("9999-12-31") };
    switch (dateFilters) {
      case "day":
        dates.start = dayjs()
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .toISOString();
        dates.end = dayjs()
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .toISOString();
        break;
      case "week":
        dates.start = dayjs(getWeekDates().start)
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .toISOString();
        dates.end = dayjs(getWeekDates().end)
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .toISOString();
        break;
      case "month":
        dates.start = dayjs(getMonthDates().start)
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .toISOString();
        dates.end = dayjs(getMonthDates().end)
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .toISOString();
        break;
      case "year":
        dates.start = dayjs(getYearDates().start)
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .toISOString();
        dates.end = dayjs(getYearDates().end)
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .toISOString();
        break;
      case "allTime":
        dates = { start: dayjs("1970-01-01"), end: dayjs("9999-12-31") };
        break;
      default:
        dates = { start: dayjs("1970-01-01"), end: dayjs("9999-12-31") };
        break;
    }
    GetDashboardData(dates.start, dates.end).then((resp) => setData(resp.data));
  }, [dateFilters]);
  const { pages } = useAuth();
  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <Grid container spacing={3}>
        {pages.includes("defineAdditionalService") && (
          <Grid xs={12} px={0}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6} lg={3}>
                <Card
                  variant="outlined"
                  component={Link}
                  to="/defineAdditionalService"
                  sx={{
                    display: "block",
                    textDecoration: "none",
                    padding: "1rem 1.5rem",
                    position: "relative",
                    backgroundColor: "success.main",
                    boxShadow:
                      "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12);",
                  }}
                >
                  <MonetizationOnIcon
                    sx={{
                      position: "absolute",
                      left: "5%",
                      top: "50%",
                      fontSize: "6rem",
                      color: "#fff",
                      opacity: "0.5",
                      transform: "translate(0,-50%) rotate(25deg)",
                    }}
                  />
                  <Box
                    sx={{
                      position: "relative",
                      fontSize: "1.5rem",
                      fontWeight: "500",
                      color: "#fff",
                      textShadow: "0px 2px 2px rgba(0,0,0, 0.4)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormattedMessage id="defineAdditionalService" />
                    <OpenInNewIcon />
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid xs={12} px={0}>
          <SalesInformation
            dateFilters={dateFilters}
            setDateFilters={setDateFilters}
            data={{
              0: data?.ToplamSatis?.ToplamSatisMiktari || 0,
              1: data?.ToplamSatis?.ToplamSatisFiyati || 0,
              2: data?.ToplamIptalIstek?.ToplamIptalIstegi || 0,
              3: data?.ToplamSefer?.ToplamSeferSayisi || 0,
            }}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <Typography variant="body1" align="center">
            Haftalık Bilet Satış Miktarı
          </Typography>
          <WeeklySales data={data?.HaftalikSatis || []} />
        </Grid>
        <Grid xs={12} md={6}>
          <Typography variant="body1" align="center">
            Güzergah Kullanım Oranı
          </Typography>
          <RouteUsingRatio data={data?.GuzergahHareket || []} />
        </Grid>
        <Grid xs={12} md={12}>
          <CardSales />
        </Grid>
        <Grid xs={12} md={6}></Grid>
      </Grid>
    </AppContainer>
  );
}

export default withTitle(Home, "", false);

function getWeekDates() {
  var curr = new Date();
  var firstday = new Date(
    curr.setDate(
      curr.getDate() - curr.getDay() + (curr.getDay() === 0 ? -6 : 1)
    )
  );
  var lastday = new Date(
    curr.setDate(
      curr.getDate() - curr.getDay() + 6 + (curr.getDay() === 0 ? -6 : 1)
    )
  );
  return { start: firstday, end: lastday };
}

function getMonthDates() {
  var curr = new Date();
  var firstday = new Date(curr.getFullYear(), curr.getMonth(), 1);
  var lastday = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);
  return { start: firstday, end: lastday };
}
function getYearDates() {
  var curr = new Date();
  var firstday = new Date(curr.getFullYear(), 0, 1);
  var lastday = new Date(curr.getFullYear(), 11, 31);
  return { start: firstday, end: lastday };
}
