import React, { useEffect, useMemo, useState } from "react";
import { Chip, IconButton, Table, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import DeleteDialog from "../../components/DeleteDialog";
import {
  AdditionalServicesSelectAll,
  CreateAdditionalServices,
  DeleteAdditionalService,
} from "../../api/api";
import UpdateDialog from "../../components/UpdateDialog";
const COLORS = [
  {
    bg: "#32a852",
    text: "#fff",
  },
  {
    bg: "#212121",
    text: "#fff",
  },
  {
    bg: "#5B3A29",
    text: "#fff",
  },
  {
    bg: "#1D1E33",
    text: "#fff",
  },
  {
    bg: "#C51D34",
    text: "#fff",
  },
  {
    bg: "#102C54",
    text: "#fff",
  },
  {
    bg: "#E55137",
    text: "#fff",
  },
];

function AdditionalServices() {
  const breadcrumb = useMemo(
    () => [
      { name: <FormattedMessage id="additionalServices" />, active: true },
    ],
    []
  );

  // Delete Dialog Props
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [itemName, setItemName] = useState("");
  const [itemSalable, setItemSalable] = useState("");
  const [itemAgentCanSell, setItemAgentCanSell] = useState("");
  const [itemAgentCanUse, setItemAgentCanUse] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [itemExtraTime, setItemExtraTime] = useState("");
  const [itemId, setItemId] = useState("");
  const handleDelete = async () => {
    await DeleteAdditionalService(itemId)
      .then((res) => {
        setDeleteOpen(false);
        GetAdditionalServices();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCloseDialog = () => {
    if (deleteOpen) {
      setDeleteOpen(false);
    } else if (updateOpen) {
      setUpdateOpen(false);
    }
  };
  // Delete Dialog Props

  // Update Dialog Props
  const [updateOpen, setUpdateOpen] = useState(false);

  const handleUpdate = async () => {
    await CreateAdditionalServices({
      Id: itemId,
      EkHizmetAdi: itemName,
      Satilabilir: itemSalable,
      EkHizmetFiyati: itemPrice,
      EkSure: itemExtraTime,
      AcenteSatabilir: itemAgentCanSell,
      AcenteEkHizmetKullandirabilir: itemAgentCanUse,
    });
    setUpdateOpen(false);
    GetAdditionalServices();
  };

  const handleChange = (e) => {
    setItemName(e.target.value);
  };
  const handleChangeSalable = (e) => {
    setItemSalable(e.target.checked);
  };
  const handleAgentCanSell = (e) => {
    setItemAgentCanSell(e.target.checked);
  };
  const handleAgentCanUse = (e) => {
    setItemAgentCanUse(e.target.checked);
  };
  const handleChangePrice = (e) => {
    setItemPrice(e.target.value);
  };
  const handleChangeExtraTime = (e) => {
    setItemExtraTime(e.target.value);
  };
  // Update Dialog Props

  const [data, setData] = useState([]);

  const GetAdditionalServices = async () => {
    let response = await AdditionalServicesSelectAll();
    setData(response.data.data);
  };

  useEffect(() => {
    GetAdditionalServices();
  }, []);

  return (
    <AppContainer
      breadcrumbs={breadcrumb}
      addButton={{ name: "additionalServices.add" }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 400 }} aria-label="additional services table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Hizmet Adı</TableCell>
              <TableCell>Bilete Tanımlanabilir Mi?</TableCell>
              <TableCell>Acente Satabilir Mi?</TableCell>
              <TableCell>Acente Kullandırabilir Mi?</TableCell>
              <TableCell>Hizmet Ücreti</TableCell>
              <TableCell>Karta Eklenecek Süre</TableCell>
              <TableCell align="right">Seçenekler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.Id}</TableCell>
                <TableCell>
                  <Chip
                    label={row.EkHizmetAdi}
                    sx={{
                      px: 2,
                      backgroundColor: COLORS[index % COLORS.length].bg,
                      color: COLORS[index % COLORS.length].text,
                      fontSize: "1rem",
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      color: row.Satilabilir ? "success.main" : "error.main",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    {row.Satilabilir ? "Evet" : "Hayır"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      color: row.AcenteSatabilir
                        ? "success.main"
                        : "error.main",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    {row.AcenteSatabilir ? "Evet" : "Hayır"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      color: row.AcenteEkHizmetKullandirabilir
                        ? "success.main"
                        : "error.main",
                      fontWeight: "bold",
                      fontSize: "1rem",
                    }}
                  >
                    {row.AcenteEkHizmetKullandirabilir ? "Evet" : "Hayır"}
                  </Typography>
                </TableCell>
                <TableCell>
                  {row.EkHizmetFiyati ? `${row.EkHizmetFiyati}TL` : "-"}
                </TableCell>
                <TableCell>{row.EkSure ? `${row.EkSure} saat` : "-"}</TableCell>
                <TableCell align="right">
                  <IconButton
                    color="warning"
                    aria-label="edit"
                    onClick={() => {
                      setItemId(row.Id);
                      setItemName(row.EkHizmetAdi);
                      setItemSalable(row.Satilabilir);
                      setItemAgentCanSell(row.AcenteSatabilir);
                      setItemAgentCanUse(row.AcenteEkHizmetKullandirabilir);
                      setItemPrice(row.EkHizmetFiyati);
                      setItemExtraTime(row.EkSure);
                      setUpdateOpen(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    sx={{ ml: 1 }}
                    onClick={() => {
                      setItemId(row.Id);
                      setItemName(row.EkHizmetAdi);
                      setDeleteOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteDialog
        open={deleteOpen}
        onClose={handleCloseDialog}
        onDelete={handleDelete}
        itemName={itemName}
        itemId={itemId}
      />
      <UpdateDialog
        open={updateOpen}
        onClose={handleCloseDialog}
        onUpdate={handleUpdate}
        onChange={handleChange}
        onChangeSalable={handleChangeSalable}
        onChangePrice={handleChangePrice}
        onChangeExtraTime={handleChangeExtraTime}
        onChangeAgentCanSell={handleAgentCanSell}
        onChangeAgentCanUse={handleAgentCanUse}
        itemName={itemName}
        itemId={itemId}
        itemSalable={itemSalable}
        itemAgentCanSell={itemAgentCanSell}
        itemAgentCanUse={itemAgentCanUse}
        itemPrice={itemPrice}
        itemExtraTime={itemExtraTime}
      />
    </AppContainer>
  );
}

export default withTitle(AdditionalServices, "additionalServices");
