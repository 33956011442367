import React, { useMemo } from "react";
import { useAuth } from "../../context/AuthContext";
import AppContainer from "../../components/layout/AppContainer";
import { FormattedMessage } from "react-intl";
import withTitle from "../../helpers/hoc/withTitle";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
function Profile() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="profile" />,
        active: true,
      },
    ],
    []
  );
  var { user, role } = useAuth();
  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="profile">
          <TableBody>
            <TableRow>
              <TableCell>Ad Soyad</TableCell>
              <TableCell>
                <Box
                  sx={{
                    fontWeight: 900,
                    letterSpacing: "1px",
                  }}
                >{`${user[0].Name} ${user[0].Surname}`}</Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kullanıcı Adı</TableCell>
              <TableCell>
                <Box>{user[0].UserName}</Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Telefon</TableCell>
              <TableCell>
                <Box>{user[0].Phone}</Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>E-Posta</TableCell>
              <TableCell>
                <Box>{user[0].Email}</Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id="accountCreateDate" />
              </TableCell>
              <TableCell>
                <Box>
                  {dayjs(user[0].CreateDate).format("DD.MM.YYYY HH:mm")}
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Rol</TableCell>
              <TableCell>{role.roleData[0].roleTitle}</TableCell>
            </TableRow>
            {role.agentData.agent && (
              <>
                <TableRow>
                  <TableCell>Şirket</TableCell>
                  <TableCell>{role.agentData.company}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Acente</TableCell>
                  <TableCell>{role.agentData.agent}</TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </AppContainer>
  );
}

export default withTitle(Profile, "profile");
