import {
  Box,
  IconButton,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import FlagIcon from "@mui/icons-material/Flag";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import withTitle from "../../helpers/hoc/withTitle";
import { RoutesSelectAll, DeleteRoutes } from "../../api/api";
import { useNavigate } from "react-router-dom";
import DeleteDialog from "../../components/DeleteDialog";
function BusStops() {
  const breadcrumb = useMemo(
    () => [{ name: <FormattedMessage id="busStops" />, active: true }],
    []
  );

  const [stops, setStops] = useState([]);

  // DELETE
  const [openDelete, setOpenDelete] = useState(false);
  const [itemName, setItemName] = useState("");
  const [itemId, setItemId] = useState("");

  const handleClose = () => {
    setOpenDelete(false);
  };

  const handleDelete = async () => {
    await DeleteRoutes(itemId);
    setOpenDelete(false);
    ParseData();
  };

  // DELETE

  const navigate = useNavigate();

  const ParseData = useCallback(() => {
    RoutesSelectAll().then((response) => {
      const STOPS = [];
      for (const stop of response.data["data: "]) {
        const route = STOPS.find((r) => r.id === stop.Id);
        if (!route) {
          STOPS.push({
            id: stop.Id,
            name: stop.Route,
            stops: [stop.Station],
            totalHour: stop.Time,
          });
        } else {
          route.stops.push(stop.Station);
        }
      }
      setStops(STOPS);
    });
  }, []);
  useEffect(() => {
    ParseData();
  }, [ParseData]);

  return (
    <AppContainer breadcrumbs={breadcrumb} addButton={{ name: "busStops.add" }}>
      {stops.map((item, index) => (
        <Paper key={index} elevation={6} sx={{ mb: 2, px: 2, pt: 3, pb: 1 }}>
          <Box
            sx={{
              mb: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="p"
              component="h3"
              sx={{
                fontWeight: "400",
                position: "relative",
                display: "inline-block",
                "&:hover": {
                  cursor: "pointer",
                  "&:after": {
                    width: "100%",
                  },
                },
                "&:after": {
                  width: "25%",
                  height: "2px",
                  backgroundColor: "secondary.main",
                  content: "''",
                  position: "absolute",
                  bottom: "-2px",
                  left: "0",
                  transition: "all .25s ease-in-out",
                  borderRadius: "1rem",
                },
              }}
            >
              {item.name}
            </Typography>
            <Box>
              <Tooltip title="Edit" color="warning">
                <IconButton
                  aria-label="edit"
                  size="small"
                  onClick={() => navigate("/bus-routes/edit/" + item.id)}
                >
                  <BorderColorIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" color="error" sx={{ ml: 1 }}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    setOpenDelete(true);
                    setItemName(item.name);
                    setItemId(item.id);
                  }}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Stepper
            activeStep={0}
            alternativeLabel
            sx={{
              flexWrap: "wrap",
              overflow: "hidden",
            }}
          >
            <Step>
              <StepLabel icon={<DirectionsBusIcon color="info" />}>
                {item.stops[0]}
              </StepLabel>
            </Step>
            {item.stops.slice(1, -1).map((stop, ins) => (
              <Step key={ins}>
                <StepLabel>{stop}</StepLabel>
              </Step>
            ))}
            <Step>
              <StepLabel icon={<FlagIcon color="success" />}>
                {item.stops.slice(-1)[0]}
              </StepLabel>
            </Step>
          </Stepper>
          <Box sx={{ opacity: 0.8, mt: 1 }}>
            Ortalama Süre:
            <Typography
              variant="p"
              component="h3"
              sx={{
                display: "inline-block",
                ml: 1,
              }}
            >
              {item.totalHour}
              <sup style={{ fontSize: "12px" }}> saat</sup>
            </Typography>
          </Box>
        </Paper>
      ))}
      <DeleteDialog
        open={openDelete}
        onClose={handleClose}
        onDelete={handleDelete}
        itemName={itemName}
        itemId={itemId}
      />
    </AppContainer>
  );
}

export default withTitle(BusStops, "busStops");
