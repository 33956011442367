import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

function UpdateDialog2(props) {
  const {
    open,
    onClose,
    onUpdate,
    nameChange,
    infoChange,
    itemName,
    itemInfo,
    itemId,
  } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id="update_item" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id="updateitemmessage" /> {itemName} / {itemInfo} (
          {itemId})?
        </DialogContentText>
        <TextField
          value={itemName}
          onChange={nameChange}
          style={{ width: "100%", marginTop: "1rem" }}
        />
        <TextField
          value={itemInfo}
          onChange={infoChange}
          style={{ width: "100%", marginTop: "1rem" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          <FormattedMessage id="cancelbtn" />
        </Button>
        <Button onClick={onUpdate} color="secondary" autoFocus>
          <FormattedMessage id="updatebtn" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateDialog2;
