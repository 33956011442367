import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import CountUp from "react-countup";
import {
  Box,
  Button,
  Card,
  CardContent,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const DATE_NAMES = {
  day: "Günlük",
  week: "Haftalık",
  month: "Aylık",
  year: "Yıllık",
  allTime: "Toplam",
};
function SalesInformation({ data, dateFilters, setDateFilters }) {
  //tarih seçimi
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectDate = (selectedDate) => {
    setDateFilters(selectedDate);
    setAnchorEl(null);
  };
  //tarih seçimi
  return (
    <Grid container spacing={3}>
      <Grid xs={12} textAlign="right" py={0}>
        <Button
          id="choose-date"
          aria-controls={open ? "date-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          color="primary"
          variant="outlined"
          onClick={handleClick}
          size="small"
          endIcon={<MoreVertIcon />}
        >
          Zaman Filtresi
        </Button>
        <Menu
          id="date-menu"
          MenuListProps={{
            "aria-labelledby": "choose-date",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={() => selectDate("day")}>Günlük</MenuItem>
          <MenuItem onClick={() => selectDate("week")}>Haftalık</MenuItem>
          <MenuItem onClick={() => selectDate("month")}>Aylık</MenuItem>
          <MenuItem onClick={() => selectDate("year")}>Yıllık</MenuItem>
          <MenuItem onClick={() => selectDate("allTime")}>Tümü</MenuItem>
        </Menu>
      </Grid>
      <Grid xs={12} md={6} lg={3}>
        <CountUpCard
          name={`${DATE_NAMES[dateFilters]} Satılan Bilet`}
          background="linear-gradient(to right, #00f260, #0575e6)"
        >
          <CountUp end={data[0]} duration={1.5} />
        </CountUpCard>
      </Grid>
      <Grid xs={12} md={6} lg={3}>
        <CountUpCard
          name={`${DATE_NAMES[dateFilters]} Gelir`}
          background="linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6)"
        >
          <CountUp
            end={data[1]}
            duration={1.5}
            decimals={2}
            decimal=","
            suffix=" ₺"
          />
        </CountUpCard>
      </Grid>
      <Grid xs={12} md={6} lg={3}>
        <CountUpCard
          name={`${DATE_NAMES[dateFilters]} İptal Edilen Bilet`}
          background="linear-gradient(to right, #fc4a1a, #f7b733)"
        >
          <CountUp end={data[2]} duration={1.5} />
        </CountUpCard>
      </Grid>
      <Grid xs={12} md={6} lg={3}>
        <CountUpCard
          name={`${DATE_NAMES[dateFilters]} Sefer Sayısı`}
          background="linear-gradient(to right, #7f00ff, #e100ff)"
        >
          <CountUp end={data[3]} duration={1.5} />
        </CountUpCard>
      </Grid>
    </Grid>
  );
}

export default SalesInformation;

const CountUpCard = ({ name, background, children }) => {
  return (
    <Card
      sx={{
        background: background,
        color: "white",
        height: "100%",
      }}
      component={Paper}
      elevation={12}
    >
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="p"
            component="h4"
            sx={{
              fontSize: "24px",
              fontWeight: "400",
              textShadow: "0px 0px 5px rgba(150, 150, 150, 1)",
            }}
          >
            {name}
          </Typography>
        </Box>
        <Typography
          variant="p"
          component="div"
          sx={{
            mt: 2,
            fontSize: "1.25rem",
            fontWeight: "bold",
            textShadow: "0px 0px 5px rgba(0, 0, 0, .6)",
          }}
        >
          {children}
        </Typography>
      </CardContent>
    </Card>
  );
};
