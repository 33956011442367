import {
  Autocomplete,
  Chip,
  Dialog,
  DialogContent,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import CustomDataGrid from "../../components/CustomDataGrid";
import AppContainer from "../../components/layout/AppContainer";
import CheckIcon from "@mui/icons-material/Check";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import BlockIcon from "@mui/icons-material/Block";
import withTitle from "../../helpers/hoc/withTitle";
import GetDataArea from "../../components/GetDataArea";
import {
  AgentsGetAll,
  CancelReasonsSelectAll,
  CanceledTicketsGetAll,
  GetAllUsers,
} from "../../api/api";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";
const StatusTypes = {
  cancellationAccepted: {
    label: <FormattedMessage id="status.accepted" />,
    color: "success",
    icon: <CheckIcon />,
  },
  cancellationPending: {
    label: <FormattedMessage id="status.pending" />,
    color: "warning",
    icon: <HourglassBottomIcon />,
  },
  cancellationRejected: {
    label: <FormattedMessage id="status.denied" />,
    color: "error",
    icon: <BlockIcon />,
  },
};

function CancellationRequestedTickets() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="reports.cancellationRequestedTickets" />,
        active: true,
      },
    ],
    []
  );
  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", minWidth: 75 },
      {
        field: "referanceNum",
        headerName: "Bilet Referans Numarası",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "createDate",
        headerName: "Oluşturma Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm");
        },
      },
      {
        field: "updateDate",
        headerName: "İşlem Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return params.value !== "-"
            ? dayjs(params.value).format("DD.MM.YYYY HH:mm")
            : "-";
        },
      },
      { field: "name", headerName: "Müşteri Adı", minWidth: 150, flex: 1 },
      {
        field: "requestedCompanyPerson",
        headerName: "Talebi oluşturan",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "assessingCompanyPerson",
        headerName: "Değerlendiren",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "OdemeTipi",
        headerName: "Ödeme Tipi",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "cancelReason",
        headerName: "İptal Nedeni",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "Dokuman",
        headerName: "Döküman",
        hideable: false,
      },
      {
        field: "status",
        headerName: "Durum",
        minWidth: 150,
        flex: 1,
        align: "center",
        renderCell: (params) => {
          if (params.value && StatusTypes[params.value]) {
            return (
              <Chip
                sx={{ width: "100%" }}
                label={StatusTypes[params.value].label}
                size="small"
                color={StatusTypes[params.value].color}
                icon={StatusTypes[params.value].icon}
              />
            );
          } else {
            return "-";
          }
        },
      },
    ],
    []
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //GET DATA FILTERS
  const [status, setStatus] = useState("all");
  const [paymentType, setPaymentType] = useState("all");
  const [cancellationRequestReason, setCancellationRequestReason] =
    useState("all");
  const [requestedPerson, setRequestedPerson] = useState(null);
  const [assessingPerson, setAssessingPerson] = useState(null);

  const [companiesOption, setCompaniesOption] = useState([]);
  const [usersOption, setUsersOption] = useState([]);

  const [cancelReasonOption, setCancelReasonOption] = useState([]);
  useEffect(() => {
    CancelReasonsSelectAll().then((resp) =>
      setCancelReasonOption(resp.data.data)
    );
  }, []);

  useEffect(() => {
    AgentsGetAll().then((resp) =>
      setCompaniesOption(
        resp.data["data: "].map((e) => ({
          id: e.Id,
          label: e.Sirket,
        }))
      )
    );
    GetAllUsers().then((resp) =>
      setUsersOption(resp.map((e) => ({ id: e.Id, label: e.UserName })))
    );
  }, []);
  const HandleSearchClick = useCallback(
    async (start, end) => {
      setLoading(true);
      await CanceledTicketsGetAll({
        DateStart: dayjs(start).toISOString(),
        DateEnd: dayjs(end).toISOString(),
        ...(status !== "all" && { Status: status }),
        ...(paymentType !== "all" && { PaymentType: paymentType }),
        ...(cancellationRequestReason !== "all" && {
          CancelReason: cancellationRequestReason,
        }),
        TalepOlusturan: requestedPerson?.id,
        assessingPerson: assessingPerson?.id,
      })
        .then((resp) =>
          setData(
            resp.data.data.map((e) => ({
              id: e.IptalId,
              referanceNum: e.TicketReferanceNum,
              createDate: e.CreateDate,
              updateDate: e.UpdateDate !== null ? e.UpdateDate : "-",
              name: e.MusteriAdi,
              requestedCompanyPerson: e.TalepOlusturan,
              assessingCompanyPerson: e.TalepDegerlendiren,
              OdemeTipi: e.OdemeTipi,
              cancelReason: e.IptalNedeni,
              status: e.Durum,
              Dokuman: e.Dokuman,
            }))
          )
        )
        .finally(() => setLoading(false));
    },
    [
      status,
      paymentType,
      cancellationRequestReason,
      requestedPerson,
      assessingPerson,
    ]
  );
  const HandleDeleteClick = useCallback(async () => {
    setStatus("all");
    setPaymentType("all");
    setCancellationRequestReason("all");
    setRequestedPerson(null);
    setAssessingPerson(null);
  }, []);

  const [doc, setDoc] = useState("");
  //dialog settings
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const rightClickMenus = useMemo(
    () => [
      {
        translateName: "seeDocument",
        clickMethod: async (selectedRow) => {
          setDoc(data.find((e) => e.id === selectedRow.id)?.Dokuman);
          handleOpen();
        },
      },
    ],
    [data]
  );

  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <GetDataArea
        HandleSearchClick={HandleSearchClick}
        HandleDeleteClick={HandleDeleteClick}
      >
        <Grid xs={12} lg={2}>
          <TextField
            fullWidth
            size="small"
            select
            label="Durum"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value="all" sx={{ fontStyle: "italic" }}>
              <FormattedMessage id="all" />
            </MenuItem>
            <MenuItem value="accepted">
              <FormattedMessage id="status.accepted" />
            </MenuItem>
            <MenuItem value="pending">
              <FormattedMessage id="status.pending" />
            </MenuItem>
            <MenuItem value="denied">
              <FormattedMessage id="status.denied" />
            </MenuItem>
          </TextField>
        </Grid>
        <Grid xs={12} lg={2}>
          <TextField
            fullWidth
            size="small"
            select
            label="Ödeme Tipi"
            value={paymentType}
            onChange={(e) => setPaymentType(e.target.value)}
          >
            <MenuItem value="all" sx={{ fontStyle: "italic" }}>
              <FormattedMessage id="all" />
            </MenuItem>
            <MenuItem value="creditCard">Kredi Kartı</MenuItem>
            <MenuItem value="cash">Nakit</MenuItem>
            <MenuItem value="mobile3d">Mobil 3D</MenuItem>
            <MenuItem value="gift">Hediye Kodu</MenuItem>
          </TextField>
        </Grid>
        <Grid xs={12} lg={2}>
          <TextField
            fullWidth
            size="small"
            select
            label="İptal Nedeni"
            value={cancellationRequestReason}
            onChange={(e) => setCancellationRequestReason(e.target.value)}
          >
            <MenuItem value="all" sx={{ fontStyle: "italic" }}>
              <FormattedMessage id="all" />
            </MenuItem>
            {cancelReasonOption.map((e) => (
              <MenuItem value={e.Id} key={e.Id}>
                {e.Message}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid xs={12} lg={2}>
          <Autocomplete
            fullWidth
            size="small"
            options={companiesOption}
            value={requestedPerson}
            onChange={(event, newValue) => {
              setRequestedPerson(newValue);
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                label="Talebi Oluşturan Kurum"
              />
            )}
          />
        </Grid>
        <Grid xs={12} lg={2}>
          <Autocomplete
            fullWidth
            size="small"
            options={usersOption}
            value={assessingPerson}
            onChange={(event, newValue) => {
              setAssessingPerson(newValue);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Değerlendiren Kişi" />
            )}
          />
        </Grid>
      </GetDataArea>
      <CustomDataGrid
        data={data}
        columns={columns}
        loading={loading}
        allowEditDelete={false}
        customRightClickMenus={rightClickMenus}
        initialState={{
          columns: {
            columnVisibilityModel: {
              Dokuman: false,
            },
          },
        }}
      />
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogContent>
          <img
            src={`data:image/jpeg;base64,${doc}`}
            alt="document"
            className="responsiveImg"
          />
        </DialogContent>
      </Dialog>
    </AppContainer>
  );
}

export default withTitle(
  CancellationRequestedTickets,
  "reports.cancellationRequestedTickets"
);
