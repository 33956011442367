import {
  Box,
  Button,
  Menu,
  MenuItem,
  TablePagination,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridPrintExportMenuItem,
  trTR,
  enUS,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnDefinitionsSelector,
} from "@mui/x-data-grid";
import React, { useState, useCallback, useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GetCurrentLanguage } from "../../context/LanguageContext";
import { FormattedMessage } from "react-intl";
import InitFilterObj from "../../helpers/InitFilterObj";
import Filter from "../../components/DataGridColumnFilter";
//excel export
import XLSX from "sheetjs-style";
import { saveAs } from "file-saver";
function TicketsDataGrid({
  data,
  columns,
  loading,
  OpenEditModal,
  setModalOpen,
  rowsUniqueId = "referanceNum",
  title = "",
  ...rest
}) {
  const currentLang = GetCurrentLanguage();
  //sorting
  const [sortModel, setSortModel] = useState({ field: "", sort: "" });
  const handleSortModelChange = useCallback((sortModel) => {
    setSortModel({
      field: sortModel[0]?.field ?? "",
      sort: sortModel[0]?.sort ?? "",
    });
  }, []);
  //selection
  const [selectionModel, setSelectionModel] = useState([]);
  //right click context menu
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedRow, setSelectedRow] = useState();
  //filters
  const [filters, setFilters] = useState(InitFilterObj(columns));
  const FiltersSet = useCallback((filterData) => {
    setFilters(filterData);
  }, []);
  const ClearFilterInputs = useCallback(
    (e) => {
      e.stopPropagation();
      setFilters(InitFilterObj(columns));
    },
    [columns]
  );
  //pdf export
  const exportPDF = (apiRef) => {
    //set data for pdf format
    // Select rows and columns
    let filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleColumns = gridVisibleColumnDefinitionsSelector(apiRef);
    var columnsWithoutCheckbox = visibleColumns.filter(
      (e) => e.field !== "__check__"
    );
    if (selectionModel.length > 0) {
      filteredSortedRowIds = JSON.parse(
        JSON.stringify(
          filteredSortedRowIds.filter((e) => selectionModel.includes(e))
        )
      );
    }
    const exportData = filteredSortedRowIds.map((id) => {
      const row = [];
      columnsWithoutCheckbox.forEach((item) => {
        row.push(apiRef.current.getCellParams(id, item.field).value);
      });
      return row;
    });
    //pdf settings
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";
    const marginXaxis = 40;
    //init pdf
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(16);

    const title = "Datagrid PDF export sample";
    const headers = [columnsWithoutCheckbox.map((e) => e.headerName)];
    let content = {
      startY: 50,
      showHead: "firstPage",
      head: headers,
      body: exportData,
    };

    doc.text(title, marginXaxis, marginXaxis);
    doc.autoTable(content);
    doc.save("rapor.pdf");
  };

  const PDFExportMenuItem = ({ hideMenu }) => {
    const apiRef = useGridApiContext();

    return (
      <MenuItem
        onClick={() => {
          exportPDF(apiRef);
          hideMenu?.();
        }}
      >
        <FormattedMessage id="exportPDF" />
      </MenuItem>
    );
  };

  //excel export
  const exportExcel = (apiRef) => {
    //set data for pdf format
    // Select rows and columns
    let filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleColumns = gridVisibleColumnDefinitionsSelector(apiRef);
    var columnsWithoutCheckbox = visibleColumns.filter(
      (e) => e.field !== "__check__"
    );
    if (selectionModel.length > 0) {
      filteredSortedRowIds = JSON.parse(
        JSON.stringify(
          filteredSortedRowIds.filter((e) => selectionModel.includes(e))
        )
      );
    }
    const exportData = filteredSortedRowIds.map((id) => {
      const row = {};
      columnsWithoutCheckbox.forEach((item) => {
        row[item.headerName] = apiRef.current.getCellParams(
          id,
          item.field
        ).value;
      });
      return row;
    });
    //export
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "rapor.xlsx");
  };
  const ExcelExportMenuItem = ({ hideMenu }) => {
    const apiRef = useGridApiContext();

    return (
      <MenuItem
        onClick={() => {
          exportExcel(apiRef);
          hideMenu?.();
        }}
      >
        <FormattedMessage id="exportExcel" />
      </MenuItem>
    );
  };
  //edit selected button
  const EditSelectedTickets = () => {
    return (
      <Button
        onClick={OpenEditModal("multiple", selectionModel)}
        size="small"
        sx={{ ml: 2 }}
        color="secondary"
      >
        <FormattedMessage id="edit" />
        {` (${selectionModel.length})`}
      </Button>
    );
  };

  const CustomExportButton = () => (
    <GridToolbarExportContainer>
      <ExcelExportMenuItem />
      <PDFExportMenuItem />
      <GridPrintExportMenuItem />
    </GridToolbarExportContainer>
  );

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <CustomExportButton />
      {selectionModel.length > 1 && <EditSelectedTickets />}
      <Box sx={{ flexGrow: 1 }} />
    </GridToolbarContainer>
  );
  //right click event
  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(
      data.find(
        (e) =>
          e[rowsUniqueId]?.toString() ===
          event.currentTarget.getAttribute("data-id")
      )
    );
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };
  const handleClose = () => {
    setContextMenu(null);
  };
  const CopyRow = () => {
    navigator.clipboard.writeText(JSON.stringify(selectedRow, null, 2));
    handleClose();
  };

  //pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function CustomPagination() {
    return (
      <TablePagination
        rowsPerPageOptions={[50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton
        showLastButton
        labelRowsPerPage={
          currentLang.code === "tr" ? "Sayfa başına satır:" : "Rows per page:"
        }
        labelDisplayedRows={({ from, to, count }) => {
          return currentLang.code === "tr"
            ? `${
                count !== -1 ? `${count} taneden` : `${to} üzeri`
              } ${from}–${to} arası`
            : `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
        }}
      />
    );
  }
  //filtered DATAS
  const [filtered, setFiltered] = useState(data);

  useEffect(() => {
    let temp = JSON.parse(JSON.stringify(data));
    //filter
    temp = temp.filter((item) => {
      return Object.keys(item).every((key) => {
        return item[key]
          ?.toString()
          .toLowerCase()
          .startsWith(filters.find((e) => e.name === key).value.toLowerCase());
      });
    });
    setPage(0);
    setFiltered(temp);
  }, [filters, data]);
  useEffect(() => {
    let temp = JSON.parse(JSON.stringify(data));
    //sorting
    function dynamicSort(field, sort) {
      var sortOrder = sort === "asc" ? 1 : -1;
      return function (a, b) {
        var result = a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0;
        return result * sortOrder;
      };
    }
    if (sortModel.sort !== "") {
      temp.sort(dynamicSort(sortModel.field, sortModel.sort));
    }

    //pagination
    let result = temp.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    setFiltered(result);
  }, [page, rowsPerPage, data, sortModel]);
  return (
    <>
      <Box sx={{ width: "100%", mt: 3 }}>
        {title !== "" && <Typography variant="h6">{title}</Typography>}
        <DataGrid
          columns={columns}
          rows={filtered}
          components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
          }}
          componentsProps={{
            row: {
              onContextMenu: handleContextMenu,
              style: { cursor: "context-menu" },
            },
          }}
          sx={{ height: 480, backgroundColor: "#fff" }}
          checkboxSelection
          disableSelectionOnClick
          disableColumnFilter
          selectionModel={selectionModel}
          onSelectionModelChange={(model) => setSelectionModel(model)}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          localeText={
            currentLang.code === "tr"
              ? trTR.components.MuiDataGrid.defaultProps.localeText
              : enUS.components.MuiDataGrid.defaultProps.localeText
          }
          loading={loading}
          {...rest}
        />
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          componentsProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
              },
            },
          }}
          MenuListProps={{
            "aria-labelledby": "row-context-menu",
          }}
        >
          <MenuItem onClick={CopyRow}>
            <FormattedMessage id="copyRow" />
          </MenuItem>
        </Menu>
      </Box>
      <Filter
        filters={filters}
        setFilters={FiltersSet}
        ClearFilterInputs={ClearFilterInputs}
      />
    </>
  );
}

export default TicketsDataGrid;
