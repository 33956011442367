import { useFormik, Form, FormikProvider } from "formik";
import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import IntlTranslate from "../../helpers/IntlTranslate";
import * as Yup from "yup";
import AppContainer from "../../components/layout/AppContainer";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import withTitle from "../../helpers/hoc/withTitle";
import SnackbarComponent from "../../components/Snackbar";
import { CreateDevices } from "../../api/api";

function DevicesAdd() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="devices" />,
        path: "/devices",
      },
      { name: <FormattedMessage id="devices.add" />, active: true },
    ],
    []
  );
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  const [loading, setLoading] = useState(false);
  const schema = Yup.object().shape({
    deviceName: Yup.string().required(IntlTranslate("deviceName.required")),
    deviceType: Yup.string().required(IntlTranslate("deviceType.required")),
  });
  const formik = useFormik({
    initialValues: {
      deviceName: "",
      deviceType: "",
    },
    validationSchema: schema,
    onSubmit: async (datas, { resetForm }) => {
      setLoading(true);
      let response = await CreateDevices({
        CihazId: 0,
        DeviceName: datas.deviceName,
        DeviceType: datas.deviceType,
      });
      if (response.data.status === "success") {
        setSnackbarType("success");
        setSnackbarOpen(true);
        resetForm();
      } else {
        setSnackbarType("error");
        setSnackbarOpen(true);
      }
      setLoading(false);
    },
  });
  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;
  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Stack sx={{ mt: 1 }} spacing={3}>
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
              <TextField
                required
                sx={{ width: { xs: "100%", md: "50%" } }}
                type="text"
                label={IntlTranslate("deviceName")}
                {...getFieldProps("deviceName")}
                error={Boolean(touched.deviceName && errors.deviceName)}
                helperText={touched.deviceName && errors.deviceName}
              />
              <FormControl sx={{ width: { xs: "100%", md: "50%" } }}>
                <FormLabel id="device-type">
                  <FormattedMessage id="deviceType" />
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="device-type"
                  value={values.deviceType}
                  onChange={(event, data) => setFieldValue("deviceType", data)}
                >
                  <FormControlLabel
                    value="androidPOS"
                    control={<Radio />}
                    label="Android POS"
                  />
                  <FormControlLabel
                    value="tablet"
                    control={<Radio />}
                    label="Tablet"
                  />
                  <FormControlLabel
                    value="brand"
                    control={<Radio />}
                    label="Brand"
                  />
                  <FormControlLabel
                    value="telefon"
                    control={<Radio />}
                    label="Telefon"
                  />
                </RadioGroup>
                {touched.deviceType && errors.deviceType && (
                  <FormHelperText
                    error={Boolean(touched.deviceType && errors.deviceType)}
                  >
                    {errors.deviceType}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>
          </Stack>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="success"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
          >
            <FormattedMessage id="add" />
          </LoadingButton>
        </Form>
      </FormikProvider>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate("devicesuccessadd")}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(DevicesAdd, "devices.add");
