import React from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import IntlTranslate from "../helpers/IntlTranslate";
import { useState } from "react";

function UpdateDialog(props) {
  const {
    open,
    onClose,
    onUpdate,
    onChange,
    onChangeSalable,
    onChangePrice,
    onChangeExtraTime,
    onChangeAgentCanSell,
    onChangeAgentCanUse,
    itemName,
    itemId,
    itemSalable = false,
    itemAgentCanSell = false,
    itemAgentCanUse = false,
    itemPrice = "",
    itemExtraTime = "",
  } = props;

  const [isStandard, setIsStandard] = useState(true);
  const handleChangeStandard = (e) => {
    if (e.target.value === "true") {
      setIsStandard(true);
      onChangePrice({ target: { value: null } });
      onChangeExtraTime({ target: { value: null } });
    } else {
      setIsStandard(false);
    }
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id="update_item" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id="updateitemmessage" /> {itemName} ({itemId})?
        </DialogContentText>
        <FormControl sx={{ mt: 2 }}>
          <FormLabel id="serviceOrProductTitle" sx={{ color: "#000" }}>
            <FormattedMessage id="selectServiceOrProduct" />
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="serviceOrProductTitle"
            value={isStandard}
            onChange={handleChangeStandard}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label={<FormattedMessage id="standardService" />}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label={<FormattedMessage id="additionalService" />}
            />
          </RadioGroup>
        </FormControl>
        <TextField
          label={IntlTranslate("additionalServiceName")}
          value={itemName}
          onChange={onChange}
          sx={{ width: "100%", mt: 2 }}
        />
        <FormControlLabel
          sx={{ width: "100%", mt: 2 }}
          control={
            <Checkbox
              checked={Boolean(itemSalable)}
              onChange={onChangeSalable}
            />
          }
          label={IntlTranslate("isSalable")}
        />
        <FormControlLabel
          sx={{ width: "100%", mt: 2 }}
          control={
            <Checkbox
              checked={Boolean(itemAgentCanSell)}
              onChange={onChangeAgentCanSell}
            />
          }
          label={IntlTranslate("isSalableAgent")}
        />
        <FormControlLabel
          sx={{ width: "100%", mt: 2 }}
          control={
            <Checkbox
              checked={Boolean(itemAgentCanUse)}
              onChange={onChangeAgentCanUse}
            />
          }
          label={IntlTranslate("isUsableAgent")}
        />
        {!isStandard && (
          <>
            <TextField
              label={IntlTranslate("productPrice")}
              value={itemPrice}
              onChange={onChangePrice}
              sx={{ width: "100%", mt: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">TL</InputAdornment>
                ),
              }}
            />
            <TextField
              label={IntlTranslate("additionalTime")}
              value={itemExtraTime}
              onChange={onChangeExtraTime}
              sx={{ width: "100%", mt: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <FormattedMessage id="hours" />
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          <FormattedMessage id="cancelbtn" />
        </Button>
        <Button onClick={onUpdate} color="secondary" autoFocus>
          <FormattedMessage id="updatebtn" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateDialog;
