import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import {
  BusSelectAll,
  CreateExpedition,
  DeleteExpedition,
  ExpeditionsSelectAll,
  RoutesSelectAll,
  UserRolesSelectByUserRoleID,
} from "../../api/api";
import CustomDataGrid from "../../components/CustomDataGrid";
import GetDataArea from "../../components/GetDataArea";
import Grid from "@mui/material/Unstable_Grid2";
import IntlTranslate from "../../helpers/IntlTranslate";
import SnackbarComponent from "../../components/Snackbar";
import ExpeditionsUpdateDialog from "./ExpeditionsUpdateDialog";
import dayjs from "dayjs";

function Expeditions() {
  const breadcrumb = useMemo(
    () => [{ name: <FormattedMessage id="expeditions" />, active: true }],
    []
  );
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  //table
  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", minWidth: 75 },
      {
        field: "date",
        headerName: "Sefer Tarihi",
        minWidth: 150,
        flex: 1,
        type: "date",
        renderCell: (params) => {
          return dayjs(params.value).format("DD.MM.YYYY");
        },
      },
      {
        field: "hour",
        headerName: "Sefer Saati",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return dayjs(params.value).format("HH:mm");
        },
      },
      {
        field: "busRoute",
        headerName: "Otobüs Güzergahı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "busName",
        headerName: "Otobüs Adı",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "busPlate",
        headerName: "Otobüs Plakası",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "userDriver",
        headerName: "Şoför",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "personels",
        headerName: "Personeller",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "notes",
        headerName: "Notlar",
        minWidth: 150,
        flex: 1,
      },
    ],
    []
  );
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [routes, setRoutes] = useState([]);
  const [buses, setBuses] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [personels, setPersonels] = useState([]);
  useEffect(() => {
    RoutesSelectAll().then((response) => {
      const ROUTES = [];
      for (const item of response.data["data: "]) {
        var route = ROUTES.find((r) => r.id === item.Id);
        if (!route) {
          ROUTES.push({
            id: item.Id,
            label: item.Route,
          });
        }
      }
      setRoutes(ROUTES);
      BusSelectAll().then((resp) =>
        setBuses(
          resp.data["data: "].map((e) => ({
            id: e.Id,
            label: `${e.Plate} ${e.Name}`,
          }))
        )
      );
    });

    UserRolesSelectByUserRoleID(3).then((resp) => {
      var drivers = resp.map((e) => ({
        id: e.Id,
        label: `${e.Name} ${e.Surname}`,
      }));
      const ids = drivers.map((o) => o.id);
      const filtered = drivers.filter(
        ({ id }, index) => !ids.includes(id, index + 1)
      );
      setDrivers(filtered);
    });

    UserRolesSelectByUserRoleID(4).then((resp) => {
      var drivers = resp.map((e) => ({
        id: e.Id,
        label: `${e.Name} ${e.Surname}`,
      }));
      const ids = drivers.map((o) => o.id);
      const filtered = drivers.filter(
        ({ id }, index) => !ids.includes(id, index + 1)
      );
      setPersonels(filtered);
    });
  }, []);

  //GET DATA FILTERS
  const [busRouteFilter, setBusRouteFilter] = useState(null);
  const HandleSearchClick = useCallback(
    async (start, end) => {
      setLoading(true);
      let response = await ExpeditionsSelectAll({
        DateStart: dayjs(start).toISOString(),
        DateEnd: dayjs(end).toISOString(),
        RouteId: busRouteFilter?.id,
      });
      var newdata = response.data.data.map((item) => ({
        id: item.SeferId,
        date: item.Date,
        hour: item.Date,
        busName: item.BusName,
        busRoute: item.Route,
        busPlate: item.BusPlate,
        userDriver: item.Driver,
        personels: item.Employee,
        notes: item.Aciklama,
      }));
      var rawdata = response.data.data.map((item) => ({
        id: item.SeferId,
        date: item.Date,
        busRoute: item.RouteId,
        busPlate: item.BusPlateId,
        userDriver: item.DriverId,
        personels: item.EmployeeId,
      }));
      setData(newdata);
      setRawData(rawdata);
      setLoading(false);
    },
    [busRouteFilter]
  );
  const HandleDeleteClick = useCallback(async () => {
    setBusRouteFilter(null);
  }, []);

  //START - Update & Delete Actions
  const [editDeleteSelectedRow, setEditDeleteSelectedRow] = useState({
    operation: "",
    rows: [],
  });
  const onClose = () => {
    setEditDeleteSelectedRow((prev) => ({
      operation: prev.operation,
      rows: [],
    }));
  };
  const btn = useRef();

  const onDelete = async () => {
    let i = 0;
    while (i < editDeleteSelectedRow.rows.length) {
      let result = await DeleteExpedition(editDeleteSelectedRow.rows[i]);
      if (result.data.status === "success") {
        i++;
      } else {
        break;
      }
    }
    setSnackbarType("success");
    setSnackbarOpen(true);
    setEditDeleteSelectedRow((prev) => ({
      operation: prev.operation,
      rows: [],
    }));
    btn.current.click();
  };

  const [updateInputs, setUpdateInputs] = useState({
    busPlate: "",
    busRoute: "",
    date: "",
    personels: "",
    userDriver: "",
  });

  useEffect(() => {
    if (editDeleteSelectedRow.operation === "edit") {
      let currentRow = rawData.find(
        (e) => e.id === editDeleteSelectedRow.rows[0]
      );
      setUpdateInputs({
        busPlate: currentRow?.busPlate,
        busRoute: currentRow?.busRoute,
        date: currentRow?.date,
        personels: currentRow?.personels,
        userDriver: currentRow?.userDriver,
      });
    }
  }, [editDeleteSelectedRow, rawData]);
  const onUpdate = async () => {
    let i = 0;
    while (i < editDeleteSelectedRow.rows.length) {
      let result = await CreateExpedition({
        SeferId: editDeleteSelectedRow.rows[i],
        BusRoute: updateInputs.busRoute,
        BusPlate: updateInputs.busPlate,
        UserDriver: updateInputs.userDriver,
        Employee: updateInputs.personels,
        Date: dayjs(updateInputs.date).toISOString(),
      });
      if (result.data.status === "success") {
        i++;
      } else {
        break;
      }
    }
    setSnackbarType("success");
    setSnackbarOpen(true);
    setEditDeleteSelectedRow((prev) => ({
      operation: prev.operation,
      rows: [],
    }));
    btn.current.click();
  };
  //END - Update & Delete Actions
  return (
    <AppContainer
      breadcrumbs={breadcrumb}
      addButton={{ name: "expeditions.add" }}
    >
      <GetDataArea
        HandleSearchClick={HandleSearchClick}
        HandleDeleteClick={HandleDeleteClick}
        btnRef={btn}
      >
        <Grid xs={12} lg={2}>
          <Autocomplete
            fullWidth
            size="small"
            options={routes}
            value={busRouteFilter}
            onChange={(event, newValue) => {
              setBusRouteFilter(newValue);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Otobüs Güzergahı" />
            )}
          />
        </Grid>
      </GetDataArea>
      <CustomDataGrid
        data={data}
        columns={columns}
        loading={loading}
        setEditDeleteSelectedRow={setEditDeleteSelectedRow}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
      />
      <Dialog
        open={
          editDeleteSelectedRow.operation === "delete" &&
          editDeleteSelectedRow.rows.length > 0
            ? true
            : false
        }
        onClose={onClose}
      >
        <DialogTitle>
          <FormattedMessage id="delete_item" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editDeleteSelectedRow.rows.length > 1 ? (
              <FormattedMessage
                id="deleteItemsCountMessage"
                values={{ count: editDeleteSelectedRow.rows.length }}
              />
            ) : (
              <>
                <FormattedMessage id="deleteitemmessage" />
                {data.find((e) => e.id === editDeleteSelectedRow.rows[0])?.date}
                ({data.find((e) => e.id === editDeleteSelectedRow.rows[0])?.id}
                )?{" "}
              </>
            )}
            <FormattedMessage id="deleteitemmessage2" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            <FormattedMessage id="cancelbtn" />
          </Button>
          <Button onClick={onDelete} color="secondary" autoFocus>
            <FormattedMessage id="deletebtn" />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={
          editDeleteSelectedRow.operation === "edit" &&
          editDeleteSelectedRow.rows.length > 0
            ? true
            : false
        }
        onClose={onClose}
      >
        <DialogTitle>
          <FormattedMessage id="update_item" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editDeleteSelectedRow.rows.length > 1 ? (
              <FormattedMessage
                id="updateItemsCountMessage"
                values={{ count: editDeleteSelectedRow.rows.length }}
              />
            ) : (
              <>
                <FormattedMessage id="updateitemmessage" />
                {data.find((e) => e.id === editDeleteSelectedRow.rows[0])?.date}
                ({data.find((e) => e.id === editDeleteSelectedRow.rows[0])?.id}
                )?{" "}
              </>
            )}
          </DialogContentText>
          <ExpeditionsUpdateDialog
            updateInputs={{
              busPlate: rawData.find(
                (e) => e.id === editDeleteSelectedRow.rows[0]
              )?.busPlate,
              busRoute: rawData.find(
                (e) => e.id === editDeleteSelectedRow.rows[0]
              )?.busRoute,
              date: rawData.find((e) => e.id === editDeleteSelectedRow.rows[0])
                ?.date,
              personels: rawData.find(
                (e) => e.id === editDeleteSelectedRow.rows[0]
              )?.personels,
              userDriver: rawData.find(
                (e) => e.id === editDeleteSelectedRow.rows[0]
              )?.userDriver,
            }}
            setUpdateInputs={setUpdateInputs}
            routesData={routes}
            busesData={buses}
            driversData={drivers}
            personelsData={personels}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            <FormattedMessage id="cancelbtn" />
          </Button>
          <Button onClick={onUpdate} color="secondary" autoFocus>
            <FormattedMessage id="updatebtn" />
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate(
          editDeleteSelectedRow.operation === "delete"
            ? "deleteSuccess"
            : "updateSuccess"
        )}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(Expeditions, "expeditions");
