import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { CardSalesSelectAll } from "../../../api/api";
import dayjs from "dayjs";
import { Typography } from "@mui/material";

function WeeklySales() {
  const [data, setData] = useState([]);
  useEffect(() => {
    CardSalesSelectAll({
      DateStart: dayjs("0001-01-01").toISOString(),
      DateEnd: dayjs("9999-12-31").toISOString(),
    }).then((resp) =>
      setData(
        resp.data.data
          .filter((e) => e.KartAdi !== null)
          .map((t) => ({
            isim: t.KartAdi,
            "Aktive Edilen Bilet": t.AktiveEdilenBilet,
            "Satılan Bilet": t.SatilanBilet,
            KartRengi: t.KartRengi,
          }))
      )
    );
  }, []);
  return (
    <>
      {data.length > 0 && (
        <>
          <Typography variant="body1" align="center">
            Kart Satış Oranı
          </Typography>

          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="isim" />
              <YAxis type="number" domain={[0, "dataMax + 10"]} />
              <Tooltip />
              <Legend />
              <Bar dataKey="Satılan Bilet" fill="#cb1720">
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.KartRengi ? COLORS[entry.KartRengi] : "#cb1720"}
                  />
                ))}
              </Bar>
              <Bar dataKey="Aktive Edilen Bilet" fill="#cb172080">
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      entry.KartRengi
                        ? `${COLORS[entry.KartRengi]}80`
                        : "#cb172080"
                    }
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </>
  );
}
const COLORS = {
  red: "#ff0000",
  blue: "#0000ff",
  green: "#00ff00",
  orange: "#ffbc00",
  gray: "#f7f7f7",
};
export default React.memo(WeeklySales);
