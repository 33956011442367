import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import * as Yup from "yup";
import IntlTranslate from "../../helpers/IntlTranslate";
import { useFormik, Form, FormikProvider } from "formik";
import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import withTitle from "../../helpers/hoc/withTitle";
import SnackbarComponent from "../../components/Snackbar";
import { CreateAdditionalServices } from "../../api/api";

function AdditionalServiceAdd() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="additionalServices" />,
        path: "/additional-services",
      },
      { name: <FormattedMessage id="additionalServices.add" />, active: true },
    ],
    []
  );
  const [loading, setLoading] = useState(false);
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  const schema = Yup.object().shape({
    additionalServiceName: Yup.string().required(
      IntlTranslate("additionalServiceName.required")
    ),
    isSalable: Yup.bool().required(IntlTranslate("isSalable.required")),
    isSalableAgent: Yup.bool().required(
      IntlTranslate("isSalableAgent.required")
    ),
    isUsableAgent: Yup.bool().required(IntlTranslate("isUsableAgent.required")),
    price: Yup.string(),
    extraTime: Yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      additionalServiceName: "",
      isSalable: true,
      isSalableAgent: false,
      isUsableAgent: false,
      price: "",
      extraTime: "",
    },
    validationSchema: schema,
    onSubmit: async (datas, { resetForm }) => {
      setLoading(true);
      let response = await CreateAdditionalServices({
        Id: 0,
        EkHizmetAdi: datas.additionalServiceName,
        Satilabilir: datas.isSalable,
        AcenteSatabilir: datas.isSalableAgent,
        AcenteEkHizmetKullandirabilir: datas.isUsableAgent,
        EkHizmetFiyati: datas.price === "" ? null : datas.price,
        EkSure: datas.extraTime === "" ? null : datas.extraTime,
      });
      if (response.data.status === "success" && response.data.Id) {
        resetForm();
        setSnackbarType("success");
        setSnackbarOpen(true);
      } else {
        setSnackbarType("error");
        setSnackbarOpen(true);
      }
      setLoading(false);
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Stack sx={{ mt: 1 }} spacing={3}>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
              <TextField
                required
                sx={{ width: { xs: "100%", lg: "50%" } }}
                label={IntlTranslate("additionalServiceName")}
                {...getFieldProps("additionalServiceName")}
                error={Boolean(
                  touched.additionalServiceName && errors.additionalServiceName
                )}
                helperText={
                  touched.additionalServiceName && errors.additionalServiceName
                }
              />
              <FormControlLabel
                sx={{ width: { xs: "100%", lg: "50%" } }}
                control={
                  <Checkbox
                    checked={formik.values.isSalable}
                    onChange={(e) =>
                      formik.setFieldValue("isSalable", e.target.checked)
                    }
                  />
                }
                label={IntlTranslate("isSalable")}
              />
              <FormControlLabel
                sx={{ width: { xs: "100%", lg: "50%" } }}
                control={
                  <Checkbox
                    checked={formik.values.isSalableAgent}
                    onChange={(e) =>
                      formik.setFieldValue("isSalableAgent", e.target.checked)
                    }
                  />
                }
                label={IntlTranslate("isSalableAgent")}
              />
              <FormControlLabel
                sx={{ width: { xs: "100%", lg: "50%" } }}
                control={
                  <Checkbox
                    checked={formik.values.isUsableAgent}
                    onChange={(e) =>
                      formik.setFieldValue("isUsableAgent", e.target.checked)
                    }
                  />
                }
                label={IntlTranslate("isUsableAgent")}
              />
            </Stack>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
              <TextField
                fullWidth
                label={IntlTranslate("productPrice")}
                {...getFieldProps("price")}
                error={Boolean(touched.price && errors.price)}
                helperText={touched.price && errors.price}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">TL</InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                label={IntlTranslate("additionalTime")}
                {...getFieldProps("extraTime")}
                error={Boolean(touched.extraTime && errors.extraTime)}
                helperText={touched.extraTime && errors.extraTime}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <FormattedMessage id="hours" />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="success"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
            >
              <FormattedMessage id="add" />
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate("bussuccessadd")}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(AdditionalServiceAdd, "additionalServices.add");
