import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import CustomDataGrid from "../../components/CustomDataGrid";
import {
  CreateSeasonalCard,
  GetCards,
  SeasonalCardDelete,
  SeasonalCardSelectAll,
} from "../../api/api";
import withTitle from "../../helpers/hoc/withTitle";
import GetDataArea from "../../components/GetDataArea";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";
import SnackbarComponent from "../../components/Snackbar";
import IntlTranslate from "../../helpers/IntlTranslate";
import PeriodicTicketPriceUpdateDialog from "./PeriodicTicketPriceUpdateDialog";

function PeriodicTicketPrice() {
  const breadcrumb = useMemo(
    () => [
      { name: <FormattedMessage id="periodicTicketPrice" />, active: true },
    ],
    []
  );

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", minWidth: 75 },
      { field: "name", headerName: "Dönem Adı", minWidth: 150, flex: 1 },
      {
        field: "startDate",
        headerName: "Başlangıç Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm");
        },
      },
      {
        field: "endDate",
        headerName: "Bitiş Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm");
        },
      },
      { field: "cardType", headerName: "Kart Tipi", minWidth: 150, flex: 1 },
      { field: "price", headerName: "Fiyat", minWidth: 150, flex: 1 },
    ],
    []
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  //GET DATA FILTERS
  const [searchCardType, setSearchCardType] = useState(null);

  const [cardsOption, setCardsOption] = useState([]);

  const [rawData, setRawData] = useState([]);
  useEffect(() => {
    GetCards().then((resp) =>
      setCardsOption(
        resp.data.data.map((e) => ({
          id: e.Id,
          label: e.KartAdi,
        }))
      )
    );
  }, []);
  const HandleSearchClick = useCallback(async () => {
    setLoading(true);
    await SeasonalCardSelectAll()
      .then((resp) => {
        if (searchCardType !== null) {
          let temp = resp.data.data.filter(
            (e) => e.KartId === searchCardType.id
          );
          setData(
            temp.map((e) => ({
              id: e.DonemselBiletId,
              name: e.PeriyotAdi,
              startDate: e.BaslangicTarihi,
              endDate: e.BitisTarihi,
              cardType: e.Kart,
              price: e.Fiyat,
            }))
          );
          let raw = temp.map((e) => ({
            id: e.DonemselBiletId,
            name: e.PeriyotAdi,
            startDate: e.BaslangicTarihi,
            endDate: e.BitisTarihi,
            cardType: e.KartId,
            price: e.Fiyat,
          }));
          setRawData(raw);
        } else {
          setData(
            resp.data.data.map((e) => ({
              id: e.DonemselBiletId,
              name: e.PeriyotAdi,
              startDate: e.BaslangicTarihi,
              endDate: e.BitisTarihi,
              cardType: e.Kart,
              price: e.Fiyat,
            }))
          );
          let raw = resp.data.data.map((e) => ({
            id: e.DonemselBiletId,
            name: e.PeriyotAdi,
            startDate: e.BaslangicTarihi,
            endDate: e.BitisTarihi,
            cardType: e.KartId,
            price: e.Fiyat,
          }));
          setRawData(raw);
        }
      })
      .finally(() => setLoading(false));
  }, [searchCardType]);

  const HandleDeleteClick = useCallback(async () => {
    setSearchCardType(null);
  }, []);

  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props

  //START - Update & Delete Actions
  const [editDeleteSelectedRow, setEditDeleteSelectedRow] = useState({
    operation: "",
    rows: [],
  });
  const onClose = () => {
    setEditDeleteSelectedRow((prev) => ({
      operation: prev.operation,
      rows: [],
    }));
  };
  const btn = useRef();

  const onDelete = async () => {
    let i = 0;
    while (i < editDeleteSelectedRow.rows.length) {
      let result = await SeasonalCardDelete(editDeleteSelectedRow.rows[i]);
      if (result.data.status === "success") {
        i++;
      } else {
        break;
      }
    }
    setSnackbarType("success");
    setSnackbarOpen(true);
    setEditDeleteSelectedRow((prev) => ({
      operation: prev.operation,
      rows: [],
    }));
    btn.current.click();
  };

  const [updateInputs, setUpdateInputs] = useState({
    name: "",
    startDate: "",
    endDate: "",
    cardType: "",
    price: "",
  });

  useEffect(() => {
    if (editDeleteSelectedRow.operation === "edit") {
      let temp = rawData.find((e) => e.id === editDeleteSelectedRow.rows[0]);
      setUpdateInputs({
        periodName: temp?.name,
        startDate: temp?.startDate,
        endDate: temp?.endDate,
        cardType: temp?.cardType,
        price: temp?.price,
      });
    }
  }, [editDeleteSelectedRow, rawData]);

  const onUpdate = async () => {
    let i = 0;
    while (i < editDeleteSelectedRow.rows.length) {
      let result = await CreateSeasonalCard({
        DonemselBiletId: editDeleteSelectedRow.rows[i],
        PeriyotAdi: updateInputs.periodName,
        BiletTipi: updateInputs.cardType,
        Fiyat: updateInputs.price,
        BaslangicTarihi: dayjs(updateInputs.startDate).toISOString(),
        BitisTarihi: dayjs(updateInputs.endDate).toISOString(),
      });
      if (result.data.status === "success") {
        i++;
      } else {
        break;
      }
    }
    setSnackbarType("success");
    setSnackbarOpen(true);
    setEditDeleteSelectedRow((prev) => ({
      operation: prev.operation,
      rows: [],
    }));
    btn.current.click();
  };
  //END - Update & Delete Actions

  return (
    <AppContainer
      breadcrumbs={breadcrumb}
      addButton={{ name: "periodicTicketPrice.add" }}
    >
      <GetDataArea
        HandleSearchClick={HandleSearchClick}
        HandleDeleteClick={HandleDeleteClick}
        dateFilter={false}
        btnRef={btn}
      >
        <Grid xs={12} lg={2}>
          <Autocomplete
            fullWidth
            size="small"
            options={cardsOption}
            value={searchCardType}
            onChange={(event, newValue) => {
              setSearchCardType(newValue);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Kart Tipi" />
            )}
          />
        </Grid>
      </GetDataArea>
      <CustomDataGrid
        data={data}
        columns={columns}
        loading={loading}
        setEditDeleteSelectedRow={setEditDeleteSelectedRow}
      />
      <Dialog
        open={
          editDeleteSelectedRow.operation === "delete" &&
          editDeleteSelectedRow.rows.length > 0
            ? true
            : false
        }
        onClose={onClose}
      >
        <DialogTitle>
          <FormattedMessage id="delete_item" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editDeleteSelectedRow.rows.length > 1 ? (
              <FormattedMessage
                id="deleteItemsCountMessage"
                values={{ count: editDeleteSelectedRow.rows.length }}
              />
            ) : (
              <>
                <FormattedMessage id="deleteitemmessage" />
                {
                  rawData.find((e) => e.id === editDeleteSelectedRow.rows[0])
                    ?.name
                }
                (
                {
                  rawData.find((e) => e.id === editDeleteSelectedRow.rows[0])
                    ?.id
                }
                )?{" "}
              </>
            )}
            <FormattedMessage id="deleteitemmessage2" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            <FormattedMessage id="cancelbtn" />
          </Button>
          <Button onClick={onDelete} color="secondary" autoFocus>
            <FormattedMessage id="deletebtn" />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={
          editDeleteSelectedRow.operation === "edit" &&
          editDeleteSelectedRow.rows.length > 0
            ? true
            : false
        }
        onClose={onClose}
      >
        <DialogTitle>
          <FormattedMessage id="update_item" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editDeleteSelectedRow.rows.length > 1 ? (
              <FormattedMessage
                id="updateItemsCountMessage"
                values={{ count: editDeleteSelectedRow.rows.length }}
              />
            ) : (
              <>
                <FormattedMessage id="updateitemmessage" />
                {
                  rawData.find((e) => e.id === editDeleteSelectedRow.rows[0])
                    ?.name
                }
                (
                {
                  rawData.find((e) => e.id === editDeleteSelectedRow.rows[0])
                    ?.id
                }
                )?{" "}
              </>
            )}
          </DialogContentText>
          <PeriodicTicketPriceUpdateDialog
            updateInputs={{
              periodName: rawData.find(
                (e) => e.id === editDeleteSelectedRow.rows[0]
              )?.name,
              startDate: rawData.find(
                (e) => e.id === editDeleteSelectedRow.rows[0]
              )?.startDate,
              endDate: rawData.find(
                (e) => e.id === editDeleteSelectedRow.rows[0]
              )?.endDate,
              cardType: rawData.find(
                (e) => e.id === editDeleteSelectedRow.rows[0]
              )?.cardType,
              price: rawData.find((e) => e.id === editDeleteSelectedRow.rows[0])
                ?.price,
            }}
            setUpdateInputs={setUpdateInputs}
            cardsData={cardsOption}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            <FormattedMessage id="cancelbtn" />
          </Button>
          <Button onClick={onUpdate} color="secondary" autoFocus>
            <FormattedMessage id="updatebtn" />
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate(
          editDeleteSelectedRow.operation === "delete"
            ? "deleteSuccess"
            : "updateSuccess"
        )}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(PeriodicTicketPrice, "periodicTicketPrice");
