import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import IntlTranslate from "../../helpers/IntlTranslate";
import { Stack, TextField } from "@mui/material";
import { RoleAdd } from "../../api/api";
import SnackbarComponent from "../../components/Snackbar";
function RolesAdd() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="roles" />,
        path: "/roles",
      },
      { name: <FormattedMessage id="roles.add" />, active: true },
    ],
    []
  );
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props

  const [loading, setLoading] = useState(false);
  const schema = Yup.object().shape({
    Role: Yup.string(),
    RoleTitle: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      Role: "",
      RoleTitle: "",
    },
    validationSchema: schema,
    onSubmit: async (datas, { resetForm }) => {
      setLoading(true);
      var response = await RoleAdd({
        ...datas,
        Id: 0,
        DeletedFlag: 0,
      });
      if (response.status === "success" && response.RoleId) {
        resetForm();
        setSnackbarType("success");
        setSnackbarOpen(true);
      } else {
        setSnackbarType("error");
        setSnackbarOpen(true);
      }
      setLoading(false);
    },
  });
  const { handleSubmit, getFieldProps } = formik;

  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Stack sx={{ mt: 1 }} spacing={3}>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
              <TextField
                fullWidth
                label={IntlTranslate("roleName")}
                {...getFieldProps("Role")}
              />
              <TextField
                fullWidth
                label={IntlTranslate("displayName")}
                {...getFieldProps("RoleTitle")}
              />
            </Stack>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="success"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}>
              <FormattedMessage id="add" />
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate("rolesuccessadd")}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(RolesAdd, "roles.add");
