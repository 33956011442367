import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import * as Yup from "yup";
import IntlTranslate from "../../helpers/IntlTranslate";
import { useFormik, Form, FormikProvider } from "formik";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import withTitle from "../../helpers/hoc/withTitle";
import {
  GetCards,
  SoldTicket,
  RoutesSelectAll,
  GetQRList,
} from "../../api/api";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useAuth } from "../../context/AuthContext";
import SnackbarComponent from "../../components/Snackbar";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Unstable_Grid2";
import QRCode from "react-qr-code";

function SellTickets() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="sellTickets" />,
        active: true,
      },
    ],
    []
  );
  const { role, user } = useAuth();
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  const [cards, setCards] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [stops, setStops] = useState([]);
  const [QRCounts, setQRCounts] = useState([]);

  const [updateInputs, setUpdateInputs] = useState(new Date().getTime());
  useEffect(() => {
    GetCards().then((resp) => {
      let cardsTemp = resp.data.data;
      if (role.isFullAgent) {
        let avaibleCards = [...new Set(QRCounts.map((t) => t.BiletTipi))];
        setCards(
          cardsTemp
            .filter((e) => avaibleCards.includes(e.Id))
            .map((t) => ({
              count: 0,
              maxCount: QRCounts.find((s) => s.BiletTipi === t.Id).ZimmetSum,
              ...t,
            }))
        );
      } else {
        setCards(cardsTemp.map((e) => ({ count: 0, ...e })));
      }
    });
  }, [role, QRCounts]);

  useEffect(() => {
    if (role.isFullAgent) {
      GetQRList({
        AcenteId: role.agentData.agentId,
        ...(role.roleData.some((t) => t.roleId === "5") && {
          AcenteUserId: user[0].Id,
        }),
      }).then((resp) => {
        setQRCounts(resp.data["data: "]);
      });
    }
    RoutesSelectAll().then((response) => {
      const STOPS = [];
      const ROUTES = [];
      for (const item of response.data["data: "]) {
        var route = ROUTES.find((r) => r.id === item.Id);
        if (!route) {
          ROUTES.push({
            id: item.Id,
            label: item.Route,
          });
        }
        var stop = STOPS.find((r) => r.label === item.Station);
        if (!stop) {
          STOPS.push({
            id: item.StationId,
            label: item.Station,
          });
        }
      }
      setRoutes(ROUTES);
      setStops(STOPS);
    });
  }, [role, user, updateInputs]);
  //bilet satıldı qrlar penceresi
  const [ticketNums, setTicketNums] = useState([]);
  const [open, setOpen] = useState(false);
  //bilet satıldı qrlar penceresi
  const [loading, setLoading] = useState(false);
  const schema = Yup.object().shape({
    nameSurname: Yup.string().required(IntlTranslate("nameSurname.required")),
    startActivationTime: Yup.bool(),
    payType: Yup.string().required(IntlTranslate("payType.required")),
    route: Yup.object().required(IntlTranslate("route.required")),
    soldStop: Yup.object().nullable(true),
  });
  const [startDate, setStartDate] = useState(
    dayjs().add(7, "day").set("hour", 0).set("minute", 0).set("second", 0)
  );
  const formik = useFormik({
    initialValues: {
      nameSurname: "",
      startActivationTime: false,
      payType: "",
      route: { id: "5", label: "121CS Sultanahmet - Beylerbeyi" },
      soldStop: {
        id: "20",
        label: "Sultanahmet",
      },
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (datas, { resetForm }) => {
      if (!cards.some((a) => Number(a.count) > Number(a.maxCount))) {
        setLoading(true);
        let tempObj = { BiletTipi: [], Fiyat: [], Adet: 0 };
        for (let i = 0; i < cards.length; i++) {
          if (cards[i].count > 0) {
            tempObj.Adet += Number(cards[i].count);
            tempObj.BiletTipi.push(
              ...Array(Number(cards[i].count)).fill(cards[i].Id)
            );
            tempObj.Fiyat.push(
              ...Array(Number(cards[i].count)).fill(
                cards[i].IsSeason !== null
                  ? cards[i].DonemselFiyat
                  : cards[i].Fiyat
              )
            );
          }
        }
        let result = await SoldTicket({
          SatisId: 0,
          ...(role.isFullAgent && { AcenteId: role.agentData.agentId }),
          ...(role.isFullAgent &&
            role.roleData.some((t) => t.roleId === "5") && {
              AcenteUserId: user[0].Id,
            }),
          IsIUAS: !role.isFullAgent,
          BiletTipi: tempObj.BiletTipi,
          Adet: tempObj.Adet,
          Fiyat: tempObj.Fiyat,
          AdSoyad: datas.nameSurname,
          BaslangicTarihi:
            role?.roleData[0].roleId !== "1"
              ? dayjs().toISOString()
              : datas.startActivationTime
              ? dayjs().toISOString()
              : startDate.toISOString(),
          AktivasyonBitisTarihi: null,
          RouteId: datas.route.id,
          StationId: datas?.soldStop?.id || null,
          PaymentType: datas.payType,
          HizmetTarihi: null,
          HizmetBitisTarihi: null,
          SonKullanim: null,
          MusteriFoto: null,
          SatanKisi: user[0].Id,
          AktiflestirenKisi: null,
          Durum: "",
          KisisellestirmeTarihi: null,
        });
        if (result.data.status === "success" && result.data.SepetReferansKodu) {
          setTicketNums(result.data.Sepet.map((t) => t.BiletReferansKodu));
          setOpen(true);
          //modal aç
          setSnackbarType("success");
          setSnackbarOpen(true);
          resetForm();
          setUpdateInputs(new Date().getTime());
        }

        setLoading(false);
        setCards((prev) => prev.map((e) => ({ ...e, count: 0 })));
      } else {
        setSnackbarType("error");
        setSnackbarOpen(true);
      }
    },
  });
  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  const handleChange = (e) => {
    const { name, value } = e.target;
    let temp = JSON.parse(JSON.stringify(cards));
    temp.find((t) => t.Id === name).count = value;
    setCards(temp);
  };

  const handleCountButtonClicked = (type, id) => {
    let temp = JSON.parse(JSON.stringify(cards));
    var prev = temp.find((t) => t.Id === id).count;
    if (type === "-" && prev !== 0) {
      prev = parseInt(prev) - 1;
      temp.find((t) => t.Id === id).count = prev;
    } else if (type === "+") {
      prev = parseInt(prev) + 1;
      temp.find((t) => t.Id === id).count = prev;
    }
    setCards(temp);
  };

  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Stack sx={{ mt: 1 }} spacing={3}>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
              <List sx={{ width: "100%" }}>
                {cards.map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      backgroundColor: "#A7D7E5",
                      mb: 2,
                      borderRadius: "4px",
                      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.25)",
                      border:
                        Number(item.count) > Number(item.maxCount) &&
                        "3px solid red",
                    }}
                    secondaryAction={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {item.maxCount && (
                          <Box sx={{ mr: 2 }}>
                            Satılabilir QR Adedi:{" "}
                            <Typography
                              variant="span"
                              sx={{ fontWeight: "bold" }}
                              color={
                                Number(item.count) > Number(item.maxCount) &&
                                "error"
                              }
                            >
                              {item.maxCount}
                            </Typography>
                          </Box>
                        )}
                        <Box
                          sx={{
                            bgcolor: "#fbfbfb",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "56px",
                            borderRadius: "4px",
                            boxShadow: "0 2px 4px 0 rgba(0,0,0,0.25)",
                          }}
                        >
                          <Button
                            color="secondary"
                            aria-label="decrease"
                            variant="contained"
                            sx={{
                              minHeight: "40px",
                              minWidth: "40px",
                              p: 0,
                              ml: "8px",
                            }}
                            onClick={() =>
                              handleCountButtonClicked("-", item.Id)
                            }
                          >
                            <RemoveIcon />
                          </Button>
                          <TextField
                            name={item.Id}
                            value={item.count}
                            onChange={handleChange}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            error={Number(item.count) > Number(item.maxCount)}
                            size="small"
                            sx={{
                              width: "64px",
                              mx: "4px",
                              "& input": {
                                textAlign: "center",
                              },
                            }}
                          />
                          <Button
                            color="secondary"
                            aria-label="decrease"
                            variant="contained"
                            sx={{
                              minHeight: "40px",
                              minWidth: "40px",
                              p: 0,
                              mr: "8px",
                            }}
                            onClick={() =>
                              handleCountButtonClicked("+", item.Id)
                            }
                          >
                            <AddIcon />
                          </Button>
                        </Box>
                      </Box>
                    }
                  >
                    {item.IsSeason && (
                      <Tooltip
                        title={
                          <FormattedMessage id="periodicTicketPriceDefined" />
                        }
                        placement="bottom"
                      >
                        <OnlinePredictionIcon
                          sx={{ mr: 1 }}
                          color="secondary"
                        />
                      </Tooltip>
                    )}
                    <ListItemText
                      primary={item.KartAdi}
                      secondary={`${
                        item.IsSeason !== null ? item.DonemselFiyat : item.Fiyat
                      } ₺`}
                    />
                  </ListItem>
                ))}
              </List>
            </Stack>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
              <TextField
                sx={{ width: { xs: "100%", md: "50%" } }}
                required
                label={IntlTranslate("nameSurname")}
                {...getFieldProps("nameSurname")}
                error={Boolean(touched.nameSurname && errors.nameSurname)}
                helperText={touched.nameSurname && errors.nameSurname}
              />
              <FormControl sx={{ width: { xs: "100%", md: "50%" } }}>
                <FormLabel id="pay-type">
                  <FormattedMessage id="payType" />
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="pay-type"
                  value={values.payType}
                  onChange={(event, data) => setFieldValue("payType", data)}
                >
                  <FormControlLabel
                    value="nakit"
                    control={<Radio />}
                    label="Nakit"
                  />
                  <FormControlLabel
                    value="kredikarti"
                    control={<Radio />}
                    label="Kredi Kartı"
                  />
                  <FormControlLabel
                    value="mobil3d"
                    control={<Radio />}
                    label="Mobil 3D"
                  />
                </RadioGroup>
                {touched.payType && errors.payType && (
                  <FormHelperText
                    error={Boolean(touched.payType && errors.payType)}
                  >
                    {errors.payType}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
              <Autocomplete
                fullWidth
                options={routes}
                getOptionLabel={(opt) => opt.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, data) => setFieldValue("route", data)}
                value={values.route}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={IntlTranslate("route")}
                    required
                    error={Boolean(touched.route && errors.route)}
                    helperText={touched.route && errors.route}
                  />
                )}
              />
              <Autocomplete
                fullWidth
                options={stops}
                getOptionLabel={(opt) => opt.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, data) => setFieldValue("soldStop", data)}
                value={values.soldStop}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={IntlTranslate("soldStop")}
                    error={Boolean(touched.soldStop && errors.soldStop)}
                    helperText={touched.soldStop && errors.soldStop}
                  />
                )}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", lg: "row" }}
              spacing={2}
              display={role?.roleData[0].roleId !== "1" ? "none" : "flex"}
            >
              <FormGroup sx={{ width: "50%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.startActivationTime}
                      onChange={(e, data) =>
                        setFieldValue("startActivationTime", data)
                      }
                      inputProps={{ "aria-label": "start-activation-time" }}
                    />
                  }
                  label="Aktivasyon Süresini Hemen Başlat"
                />
              </FormGroup>
              {!values.startActivationTime && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label={<FormattedMessage id="activationStartDate" />}
                    mask="__.__.____ __:__"
                    inputFormat="DD.MM.YYYY HH:mm"
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    minDateTime={dayjs()}
                    maxDateTime={dayjs().add(15, "day")}
                    ampm={false}
                    renderInput={(params) => (
                      <TextField sx={{ width: "50%" }} {...params} />
                    )}
                  />
                </LocalizationProvider>
              )}
            </Stack>
            <Box sx={{ width: "100%" }}>
              <List sx={{ p: 0 }}>
                {cards.map((item, index) => {
                  if (item.count > 0) {
                    return (
                      <div key={index}>
                        <ListItem
                          secondaryAction={
                            <>
                              {item.count}
                              <sup>adet</sup>
                              <span> x </span>
                              {item.IsSeason !== null
                                ? item.DonemselFiyat
                                : item.Fiyat}{" "}
                              ₺<span> = </span>
                              <b>
                                {parseInt(item.count) *
                                  parseInt(
                                    item.IsSeason !== null
                                      ? item.DonemselFiyat
                                      : item.Fiyat
                                  )}{" "}
                                ₺
                              </b>
                            </>
                          }
                        >
                          <ListItemText
                            primary={item.KartAdi}
                            secondary={`${
                              item.IsSeason !== null
                                ? item.DonemselFiyat
                                : item.Fiyat
                            } ₺`}
                          />
                        </ListItem>
                        <Divider />
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
                {cards.some((e) => e.count > 0) && (
                  <ListItem
                    sx={{ mt: 3 }}
                    secondaryAction={
                      <Typography variant="h6">
                        <span>TOPLAM </span>
                        <b>
                          {cards.reduce((acc, item) => {
                            let total =
                              parseInt(item.count) *
                              parseInt(
                                item.IsSeason !== null
                                  ? item.DonemselFiyat
                                  : item.Fiyat
                              );
                            return (acc += total);
                          }, 0)}
                          ₺
                        </b>
                      </Typography>
                    }
                  ></ListItem>
                )}
              </List>
            </Box>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="success"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
            >
              <FormattedMessage id="add" />
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate("salessuccessadd")}
        errorMessage={IntlTranslate("invalidCardCount")}
        alertType={snackbarType}
        onClose={handleClose}
      />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={(e, reason) => {
          if (
            reason &&
            (reason === "backdropClick" || reason === "escapeKeyDown")
          ) {
            return;
          }
          setOpen(false);
          setTicketNums([]);
        }}
        scroll="paper"
        aria-labelledby="qrs-dialog-title"
      >
        <DialogTitle id="qrs-dialog-title">
          Biletlere Ait QR Kodları
          <IconButton
            aria-label="close dialog"
            onClick={() => {
              setOpen(false);
              setTicketNums([]);
            }}
            sx={{
              position: "absolute",
              right: 14,
              top: 12,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            {ticketNums.map((e) => (
              <Grid key={e} xs={12} lg={3}>
                <Typography align="center" mb={2}>
                  {e}
                </Typography>
                <QRCode
                  value={e}
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  viewBox={`0 0 256 256`}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </AppContainer>
  );
}

export default withTitle(SellTickets, "sellTickets");
