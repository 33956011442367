import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormattedMessage } from "react-intl";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
//date picker
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import dayjs from "dayjs";
const defaultDates = {
  start: dayjs()
    .subtract(7, "day")
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0),
  end: dayjs().set("hour", 23).set("minute", 59).set("second", 59),
};
function GetDataArea({
  HandleSearchClick,
  HandleDeleteClick,
  timeSelect = false,
  dateFilter = true,
  btnRef,
  children,
}) {
  const [startDate, setStartDate] = useState(defaultDates.start);
  const [endDate, setEndDate] = useState(defaultDates.end);
  return (
    <Box sx={{ width: "100%" }}>
      <Accordion
        defaultExpanded
        sx={{
          backgroundColor: "rgba(26, 66, 138,0.1)",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="get-data-accordion"
          sx={{ pl: 1, pr: 1 }}
        >
          <SearchIcon color="inherit" sx={{ fontSize: 30 }} />
          <Typography
            sx={{ ml: 2, fontSize: "1.25rem" }}
            color="inherit"
            fontWeight={500}
          >
            <FormattedMessage id="search.criters" />
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pl: 1, pr: 1 }}>
          <Grid container spacing={2}>
            {dateFilter &&
              (timeSelect ? (
                <>
                  <Grid xs={12} lg={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label={<FormattedMessage id="startDate" />}
                        mask="__.__.____ __:__"
                        inputFormat="DD.MM.YYYY HH:mm"
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                        ampm={false}
                        maxDateTime={endDate}
                        renderInput={(params) => (
                          <TextField size="small" fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid xs={12} lg={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label={<FormattedMessage id="endDate" />}
                        value={endDate}
                        mask="__.__.____ __:__"
                        inputFormat="DD.MM.YYYY HH:mm"
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                        ampm={false}
                        minDateTime={startDate}
                        renderInput={(params) => (
                          <TextField size="small" fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid xs={12} lg={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={<FormattedMessage id="startDate" />}
                        mask="__.__.____"
                        inputFormat="DD.MM.YYYY"
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                        maxDate={endDate}
                        renderInput={(params) => (
                          <TextField size="small" fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid xs={12} lg={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label={<FormattedMessage id="endDate" />}
                        value={endDate}
                        mask="__.__.____"
                        inputFormat="DD.MM.YYYY"
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                        minDate={startDate}
                        renderInput={(params) => (
                          <TextField size="small" fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </>
              ))}

            {children}
            <Grid
              xs={12}
              lg={2}
              sx={{
                display: "flex",
                alignItems: "strech",
                marginLeft: "auto",
              }}
            >
              <ButtonGroup
                size="small"
                fullWidth
                aria-label="action buttons"
                sx={{ fontSize: { xs: "unset", lg: "10px" } }}
              >
                <Button
                  ref={btnRef}
                  onClick={() => HandleSearchClick(startDate, endDate)}
                  variant="contained"
                  color="secondary"
                  sx={{ width: "50%", fontSize: "inherit" }}
                  startIcon={<SearchIcon />}
                >
                  <FormattedMessage id="search" />
                </Button>
                <Button
                  sx={{ width: "50%", fontSize: "inherit" }}
                  onClick={() => {
                    setStartDate(defaultDates.start);
                    setEndDate(defaultDates.end);
                    HandleDeleteClick();
                  }}
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteIcon />}
                >
                  <FormattedMessage id="clear" />
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default GetDataArea;
