import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Collapse, Tooltip } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { FilteredDefinitionLinks } from "../../routes/SiteLinks";
import Header from "./Header";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import CampaignIcon from "@mui/icons-material/Campaign";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import TabletAndroidIcon from "@mui/icons-material/TabletAndroid";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import TimelineIcon from "@mui/icons-material/Timeline";

//reports
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import SpeedIcon from "@mui/icons-material/Speed";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import FoodBankIcon from "@mui/icons-material/FoodBank";
import HailIcon from "@mui/icons-material/Hail";
//definitions

import PriceChangeIcon from "@mui/icons-material/PriceChange";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import SellIcon from "@mui/icons-material/Sell";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PaymentsIcon from "@mui/icons-material/Payments";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ReceiptIcon from "@mui/icons-material/Receipt";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";

export const drawerWidth = 360;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  paddingLeft: "1rem",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function HeaderMenu({ children }) {
  const { pages } = useAuth();
  const LINKS = React.useMemo(() => {
    return FilteredDefinitionLinks(pages === null ? [] : pages);
  }, [pages]);
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [queryMenuOpen, setQueryMenuOpen] = useState(false);
  const [saleMenuOpen, setSaleMenuOpen] = useState(false);
  const [ticketMenuOpen, setTicketMenuOpen] = useState(false);
  const [adminMenuOpen, setAdminMenuOpen] = useState(false);
  const [dashboardMenuOpen, setDashboardMenuOpen] = useState(false);
  const [saleTrackMenuOpen, setSaleTrackMenuOpen] = useState(false);
  const [ticketCancelMenuOpen, setTicketCancelMenuOpen] = useState(false);
  const [workingScheduleMenuOpen, setWorkingScheduleMenuOpen] = useState(false);
  const [definitionsMenuOpen, setDefinitionsMenuOpen] = useState(false);

  return (
    <Box sx={{ display: "flex" }}>
      <Header open={open} handleDrawerOpen={handleDrawerOpen} />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Typography variant="body1" sx={{ fontWeight: 100 }}>
            <FormattedMessage id="welcome" />
          </Typography>
          <IconButton aria-label="close menu" onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List dense>
          {pages !== null && pages.includes("announcements") && (
            <>
              <Tooltip
                arrow
                title={<FormattedMessage id="announcements" />}
                placement="right"
              >
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      pl: 2.5,
                      "&.active": {
                        backgroundColor: "rgba(144, 202, 249, 0.16);",
                      },
                    }}
                    end
                    component={NavLink}
                    to="/announcements"
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <CampaignIcon />
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                      <FormattedMessage id="announcements" />
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            </>
          )}
          {pages !== null && pages.includes("driverStopNotification") && (
            <>
              <Divider sx={{ my: 1, borderBottomWidth: "medium" }} />
              <Tooltip
                arrow
                title={<FormattedMessage id="driverStopNotification" />}
                placement="right"
              >
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      pl: 2.5,
                      "&.active": {
                        backgroundColor: "rgba(144, 202, 249, 0.16);",
                      },
                    }}
                    end
                    component={NavLink}
                    to="/driver-stop-notification"
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <ShareLocationIcon />
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                      <FormattedMessage id="driverStopNotification" />
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            </>
          )}
          {pages !== null &&
            ["reports.ticketDetail"].some((e) => pages.includes(e)) && (
              <>
                <Divider sx={{ my: 1, borderBottomWidth: "medium" }} />
                <Tooltip
                  arrow
                  title={<FormattedMessage id="queryOperations" />}
                  placement="right"
                >
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      sx={{
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => setQueryMenuOpen(!queryMenuOpen)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <QueryStatsIcon />
                      </ListItemIcon>
                      <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                        <FormattedMessage id="queryOperations" />
                      </ListItemText>
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 0 : "auto",
                          justifyContent: "center",
                          opacity: open ? 1 : 0,
                          width: open ? "unset" : 0,
                        }}
                      >
                        {queryMenuOpen ? <ExpandLess /> : <ExpandMore />}
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
                <Collapse in={queryMenuOpen} timeout="auto" unmountOnExit>
                  <List dense disablePadding>
                    {pages.includes("reports.ticketDetail") && (
                      <Tooltip
                        arrow
                        title={<FormattedMessage id="reports.ticketDetail" />}
                        placement="right"
                      >
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              pl: open ? 4 : 2.5,
                              "&.active": {
                                backgroundColor: "rgba(144, 202, 249, 0.16);",
                              },
                            }}
                            end
                            component={NavLink}
                            to="/reports/ticket-detail"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <ContentPasteSearchIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <FormattedMessage id="reports.ticketDetail" />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                  </List>
                </Collapse>
              </>
            )}
          {pages !== null && ["sellTickets"].some((e) => pages.includes(e)) && (
            <>
              <Divider sx={{ my: 1, borderBottomWidth: "medium" }} />
              <Tooltip
                arrow
                title={<FormattedMessage id="saleOperations" />}
                placement="right"
              >
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    onClick={() => setSaleMenuOpen(!saleMenuOpen)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <MonetizationOnIcon />
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                      <FormattedMessage id="saleOperations" />
                    </ListItemText>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 0 : "auto",
                        justifyContent: "center",
                        opacity: open ? 1 : 0,
                        width: open ? "unset" : 0,
                      }}
                    >
                      {saleMenuOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              </Tooltip>
              <Collapse in={saleMenuOpen} timeout="auto" unmountOnExit>
                <List dense disablePadding>
                  {pages.includes("sellTickets") && (
                    <Tooltip
                      arrow
                      title={<FormattedMessage id="sellTickets" />}
                      placement="right"
                    >
                      <ListItem disablePadding sx={{ display: "block" }}>
                        <ListItemButton
                          sx={{
                            justifyContent: open ? "initial" : "center",
                            pl: open ? 4 : 2.5,
                            "&.active": {
                              backgroundColor: "rgba(144, 202, 249, 0.16);",
                            },
                          }}
                          end
                          component={NavLink}
                          to="/sellTickets"
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            <SellIcon />
                          </ListItemIcon>
                          <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                            <FormattedMessage id="sellTickets" />
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    </Tooltip>
                  )}
                </List>
              </Collapse>
            </>
          )}
          {pages !== null &&
            [
              "reports.ticketLog",
              "reports.ticketServicesLog",
              "defineAdditionalService",
              "tickets",
              "cancellationRequests",
            ].some((e) => pages.includes(e)) && (
              <>
                <Divider sx={{ my: 1, borderBottomWidth: "medium" }} />
                <Tooltip
                  arrow
                  title={<FormattedMessage id="ticketOperations" />}
                  placement="right"
                >
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      sx={{
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => setTicketMenuOpen(!ticketMenuOpen)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <PaymentsIcon />
                      </ListItemIcon>
                      <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                        <FormattedMessage id="ticketOperations" />
                      </ListItemText>
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 0 : "auto",
                          justifyContent: "center",
                          opacity: open ? 1 : 0,
                          width: open ? "unset" : 0,
                        }}
                      >
                        {ticketMenuOpen ? <ExpandLess /> : <ExpandMore />}
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
                <Collapse in={ticketMenuOpen} timeout="auto" unmountOnExit>
                  <List dense disablePadding>
                    {pages.includes("reports.ticketLog") && (
                      <Tooltip
                        arrow
                        title={<FormattedMessage id="reports.ticketLog" />}
                        placement="right"
                      >
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              pl: open ? 4 : 2.5,
                              "&.active": {
                                backgroundColor: "rgba(144, 202, 249, 0.16);",
                              },
                            }}
                            end
                            component={NavLink}
                            to="/reports/ticket-log"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <TimelineIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <FormattedMessage id="reports.ticketLog" />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                    {pages.includes("reports.ticketServicesLog") && (
                      <Tooltip
                        arrow
                        title={
                          <FormattedMessage id="reports.ticketServicesLog" />
                        }
                        placement="right"
                      >
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              pl: open ? 4 : 2.5,
                              "&.active": {
                                backgroundColor: "rgba(144, 202, 249, 0.16);",
                              },
                            }}
                            end
                            component={NavLink}
                            to="/reports/ticket-services-log"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <TimelineIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <FormattedMessage id="reports.ticketServicesLog" />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                    {pages.includes("defineAdditionalService") && (
                      <Tooltip
                        arrow
                        title={
                          <FormattedMessage id="defineAdditionalService" />
                        }
                        placement="right"
                      >
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              pl: open ? 4 : 2.5,
                              "&.active": {
                                backgroundColor: "rgba(144, 202, 249, 0.16);",
                              },
                            }}
                            end
                            component={NavLink}
                            to="/defineAdditionalService"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <MonetizationOnIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <FormattedMessage id="defineAdditionalService" />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                    {pages.includes("tickets") && (
                      <Tooltip
                        arrow
                        title={<FormattedMessage id="tickets" />}
                        placement="right"
                      >
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              pl: open ? 4 : 2.5,
                              "&.active": {
                                backgroundColor: "rgba(144, 202, 249, 0.16);",
                              },
                            }}
                            end
                            component={NavLink}
                            to="/tickets"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <ConfirmationNumberIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <FormattedMessage id="tickets" />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                    {pages.includes("cancellationRequests") && (
                      <Tooltip
                        arrow
                        title={<FormattedMessage id="cancellationRequests" />}
                        placement="right"
                      >
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              pl: open ? 4 : 2.5,
                              "&.active": {
                                backgroundColor: "rgba(144, 202, 249, 0.16);",
                              },
                            }}
                            end
                            component={NavLink}
                            to="/cancellation-requests"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <CancelScheduleSendIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <FormattedMessage id="cancellationRequests" />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                  </List>
                </Collapse>
              </>
            )}

          {pages !== null &&
            [
              "dashboard",
              "reports.login-out",
              "reports.cardSales",
              "reports.serviceTrack",
              "reports.ticketProgress",
              "reports.performanceTrack",
              "reports.additionalServiceTickets",
              "reports.passangerTrends",
              "reports.ticketLog",
              "reports.ticketDetail",
              "cards",
              "additionalServices",
              "devices",
              "buses",
              "busStops",
              "users",
              "roles",
              "agents",
              "QRDebit",
              "reports.cancellationRequestedTickets",
              "cancellationRequests",
              "periodicTicketPrice",
              "reports.activeVehicles",
              "expeditions",
            ].some((e) => pages.includes(e)) && (
              <>
                <Divider sx={{ my: 1, borderBottomWidth: "medium" }} />
                <Tooltip
                  arrow
                  title={<FormattedMessage id="adminScreen" />}
                  placement="right"
                >
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      sx={{
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => setAdminMenuOpen(!adminMenuOpen)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <AdminPanelSettingsIcon />
                      </ListItemIcon>
                      <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                        <FormattedMessage id="adminScreen" />
                      </ListItemText>
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 0 : "auto",
                          justifyContent: "center",
                          opacity: open ? 1 : 0,
                          width: open ? "unset" : 0,
                        }}
                      >
                        {adminMenuOpen ? <ExpandLess /> : <ExpandMore />}
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
                <Collapse in={adminMenuOpen} timeout="auto" unmountOnExit>
                  <List dense disablePadding>
                    {[
                      "dashboard",
                      "reports.login-out",
                      "reports.cardSales",
                      "reports.serviceTrack",
                      "reports.ticketProgress",
                      "reports.performanceTrack",
                      "reports.additionalServiceTickets",
                      "reports.passangerTrends",
                      "reports.ticketLog",
                      "reports.ticketDetail",
                    ].some((e) => pages.includes(e)) && (
                      <>
                        <Tooltip
                          arrow
                          title={<FormattedMessage id="dashboard" />}
                          placement="right"
                        >
                          <ListItem disablePadding sx={{ display: "block" }}>
                            <ListItemButton
                              sx={{
                                justifyContent: open ? "initial" : "center",
                                pl: open ? 4 : 2.5,
                                pr: 2.5,
                              }}
                              onClick={() =>
                                setDashboardMenuOpen(!dashboardMenuOpen)
                              }
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 3 : "auto",
                                  justifyContent: "center",
                                }}
                              >
                                <SummarizeIcon />
                              </ListItemIcon>
                              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                                <FormattedMessage id="dashboard" />
                              </ListItemText>
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 0 : "auto",
                                  justifyContent: "center",
                                  opacity: open ? 1 : 0,
                                  width: open ? "unset" : 0,
                                }}
                              >
                                {dashboardMenuOpen ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </ListItemIcon>
                            </ListItemButton>
                          </ListItem>
                        </Tooltip>
                        <Collapse
                          in={dashboardMenuOpen}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List dense disablePadding>
                            {pages.includes("dashboard") && (
                              <Tooltip
                                arrow
                                title={<FormattedMessage id="dashboard" />}
                                placement="right"
                              >
                                <ListItem
                                  disablePadding
                                  sx={{ display: "block" }}
                                >
                                  <ListItemButton
                                    sx={{
                                      justifyContent: open
                                        ? "initial"
                                        : "center",
                                      pl: open ? 5.5 : 2.5,
                                      "&.active": {
                                        backgroundColor:
                                          "rgba(144, 202, 249, 0.16);",
                                      },
                                    }}
                                    end
                                    component={NavLink}
                                    to="/"
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <DashboardIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{ opacity: open ? 1 : 0 }}
                                    >
                                      <FormattedMessage id="dashboard" />
                                    </ListItemText>
                                  </ListItemButton>
                                </ListItem>
                              </Tooltip>
                            )}
                            {pages.includes("reports.login-out") && (
                              <Tooltip
                                arrow
                                title={
                                  <FormattedMessage id="reports.login-out" />
                                }
                                placement="right"
                              >
                                <ListItem
                                  disablePadding
                                  sx={{ display: "block" }}
                                >
                                  <ListItemButton
                                    sx={{
                                      justifyContent: open
                                        ? "initial"
                                        : "center",
                                      pl: open ? 5.5 : 2.5,
                                      "&.active": {
                                        backgroundColor:
                                          "rgba(144, 202, 249, 0.16);",
                                      },
                                    }}
                                    end
                                    component={NavLink}
                                    to="/reports/login-logout"
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <LockOpenIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{ opacity: open ? 1 : 0 }}
                                    >
                                      <FormattedMessage id="reports.login-out" />
                                    </ListItemText>
                                  </ListItemButton>
                                </ListItem>
                              </Tooltip>
                            )}
                            {[
                              "reports.cardSales",
                              "reports.serviceTrack",
                              "reports.ticketProgress",
                              "reports.performanceTrack",
                            ].some((e) => pages.includes(e)) && (
                              <>
                                <Tooltip
                                  arrow
                                  title={<FormattedMessage id="saleTrack" />}
                                  placement="right"
                                >
                                  <ListItem
                                    disablePadding
                                    sx={{ display: "block" }}
                                  >
                                    <ListItemButton
                                      sx={{
                                        justifyContent: open
                                          ? "initial"
                                          : "center",
                                        pl: open ? 5.5 : 2.5,
                                        pr: 2.5,
                                      }}
                                      onClick={() =>
                                        setSaleTrackMenuOpen(!saleTrackMenuOpen)
                                      }
                                    >
                                      <ListItemIcon
                                        sx={{
                                          minWidth: 0,
                                          mr: open ? 3 : "auto",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <ReceiptIcon />
                                      </ListItemIcon>
                                      <ListItemText
                                        sx={{ opacity: open ? 1 : 0 }}
                                      >
                                        <FormattedMessage id="saleTrack" />
                                      </ListItemText>
                                      <ListItemIcon
                                        sx={{
                                          minWidth: 0,
                                          mr: open ? 0 : "auto",
                                          justifyContent: "center",
                                          opacity: open ? 1 : 0,
                                          width: open ? "unset" : 0,
                                        }}
                                      >
                                        {saleTrackMenuOpen ? (
                                          <ExpandLess />
                                        ) : (
                                          <ExpandMore />
                                        )}
                                      </ListItemIcon>
                                    </ListItemButton>
                                  </ListItem>
                                </Tooltip>
                                <Collapse
                                  in={saleTrackMenuOpen}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List dense disablePadding>
                                    {pages.includes("reports.cardSales") && (
                                      <Tooltip
                                        arrow
                                        title={
                                          <FormattedMessage id="reports.cardSales" />
                                        }
                                        placement="right"
                                      >
                                        <ListItem
                                          disablePadding
                                          sx={{ display: "block" }}
                                        >
                                          <ListItemButton
                                            sx={{
                                              justifyContent: open
                                                ? "initial"
                                                : "center",
                                              pl: open ? 7 : 2.5,
                                              "&.active": {
                                                backgroundColor:
                                                  "rgba(144, 202, 249, 0.16);",
                                              },
                                            }}
                                            end
                                            component={NavLink}
                                            to="/reports/card-sales"
                                          >
                                            <ListItemIcon
                                              sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : "auto",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <LocalAtmIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              sx={{ opacity: open ? 1 : 0 }}
                                            >
                                              <FormattedMessage id="reports.cardSales" />
                                            </ListItemText>
                                          </ListItemButton>
                                        </ListItem>
                                      </Tooltip>
                                    )}
                                    {pages.includes("reports.serviceTrack") && (
                                      <Tooltip
                                        arrow
                                        title={
                                          <FormattedMessage id="reports.serviceTrack" />
                                        }
                                        placement="right"
                                      >
                                        <ListItem
                                          disablePadding
                                          sx={{ display: "block" }}
                                        >
                                          <ListItemButton
                                            sx={{
                                              justifyContent: open
                                                ? "initial"
                                                : "center",
                                              pl: open ? 7 : 2.5,
                                              "&.active": {
                                                backgroundColor:
                                                  "rgba(144, 202, 249, 0.16);",
                                              },
                                            }}
                                            end
                                            component={NavLink}
                                            to="/reports/service-track"
                                          >
                                            <ListItemIcon
                                              sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : "auto",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <TroubleshootIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              sx={{ opacity: open ? 1 : 0 }}
                                            >
                                              <FormattedMessage id="reports.serviceTrack" />
                                            </ListItemText>
                                          </ListItemButton>
                                        </ListItem>
                                      </Tooltip>
                                    )}
                                    {pages.includes(
                                      "reports.ticketProgress"
                                    ) && (
                                      <Tooltip
                                        arrow
                                        title={
                                          <FormattedMessage id="reports.ticketProgress" />
                                        }
                                        placement="right"
                                      >
                                        <ListItem
                                          disablePadding
                                          sx={{ display: "block" }}
                                        >
                                          <ListItemButton
                                            sx={{
                                              justifyContent: open
                                                ? "initial"
                                                : "center",
                                              pl: open ? 7 : 2.5,
                                              "&.active": {
                                                backgroundColor:
                                                  "rgba(144, 202, 249, 0.16);",
                                              },
                                            }}
                                            end
                                            component={NavLink}
                                            to="/reports/ticket-progress"
                                          >
                                            <ListItemIcon
                                              sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : "auto",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <LocalActivityIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              sx={{ opacity: open ? 1 : 0 }}
                                            >
                                              <FormattedMessage id="reports.ticketProgress" />
                                            </ListItemText>
                                          </ListItemButton>
                                        </ListItem>
                                      </Tooltip>
                                    )}
                                    {pages.includes(
                                      "reports.performanceTrack"
                                    ) && (
                                      <Tooltip
                                        arrow
                                        title={
                                          <FormattedMessage id="reports.performanceTrack" />
                                        }
                                        placement="right"
                                      >
                                        <ListItem
                                          disablePadding
                                          sx={{ display: "block" }}
                                        >
                                          <ListItemButton
                                            sx={{
                                              justifyContent: open
                                                ? "initial"
                                                : "center",
                                              pl: open ? 7 : 2.5,
                                              "&.active": {
                                                backgroundColor:
                                                  "rgba(144, 202, 249, 0.16);",
                                              },
                                            }}
                                            end
                                            component={NavLink}
                                            to="/reports/performance-track"
                                          >
                                            <ListItemIcon
                                              sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : "auto",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <SpeedIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              sx={{ opacity: open ? 1 : 0 }}
                                            >
                                              <FormattedMessage id="reports.performanceTrack" />
                                            </ListItemText>
                                          </ListItemButton>
                                        </ListItem>
                                      </Tooltip>
                                    )}
                                  </List>
                                </Collapse>
                              </>
                            )}
                            {pages.includes(
                              "reports.additionalServiceTickets"
                            ) && (
                              <Tooltip
                                arrow
                                title={
                                  <FormattedMessage id="reports.additionalServiceTickets" />
                                }
                                placement="right"
                              >
                                <ListItem
                                  disablePadding
                                  sx={{ display: "block" }}
                                >
                                  <ListItemButton
                                    sx={{
                                      justifyContent: open
                                        ? "initial"
                                        : "center",
                                      pl: open ? 5.5 : 2.5,
                                      "&.active": {
                                        backgroundColor:
                                          "rgba(144, 202, 249, 0.16);",
                                      },
                                    }}
                                    end
                                    component={NavLink}
                                    to="/reports/additional-service-tickets"
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <FoodBankIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{ opacity: open ? 1 : 0 }}
                                    >
                                      <FormattedMessage id="reports.additionalServiceTickets" />
                                    </ListItemText>
                                  </ListItemButton>
                                </ListItem>
                              </Tooltip>
                            )}
                            {pages.includes("reports.passangerTrends") && (
                              <Tooltip
                                arrow
                                title={
                                  <FormattedMessage id="reports.passangerTrends" />
                                }
                                placement="right"
                              >
                                <ListItem
                                  disablePadding
                                  sx={{ display: "block" }}
                                >
                                  <ListItemButton
                                    sx={{
                                      justifyContent: open
                                        ? "initial"
                                        : "center",
                                      pl: open ? 5.5 : 2.5,
                                      "&.active": {
                                        backgroundColor:
                                          "rgba(144, 202, 249, 0.16);",
                                      },
                                    }}
                                    end
                                    component={NavLink}
                                    to="/reports/passanger-trends"
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <HailIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{ opacity: open ? 1 : 0 }}
                                    >
                                      <FormattedMessage id="reports.passangerTrends" />
                                    </ListItemText>
                                  </ListItemButton>
                                </ListItem>
                              </Tooltip>
                            )}
                            {pages.includes("reports.ticketLog") && (
                              <Tooltip
                                arrow
                                title={
                                  <FormattedMessage id="reports.ticketLog" />
                                }
                                placement="right"
                              >
                                <ListItem
                                  disablePadding
                                  sx={{ display: "block" }}
                                >
                                  <ListItemButton
                                    sx={{
                                      justifyContent: open
                                        ? "initial"
                                        : "center",
                                      pl: open ? 5.5 : 2.5,
                                      "&.active": {
                                        backgroundColor:
                                          "rgba(144, 202, 249, 0.16);",
                                      },
                                    }}
                                    end
                                    component={NavLink}
                                    to="/reports/ticket-log"
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <TimelineIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{ opacity: open ? 1 : 0 }}
                                    >
                                      <FormattedMessage id="reports.ticketLog" />
                                    </ListItemText>
                                  </ListItemButton>
                                </ListItem>
                              </Tooltip>
                            )}
                            {pages.includes("reports.ticketDetail") && (
                              <Tooltip
                                arrow
                                title={
                                  <FormattedMessage id="reports.ticketDetail" />
                                }
                                placement="right"
                              >
                                <ListItem
                                  disablePadding
                                  sx={{ display: "block" }}
                                >
                                  <ListItemButton
                                    sx={{
                                      justifyContent: open
                                        ? "initial"
                                        : "center",
                                      pl: open ? 5.5 : 2.5,
                                      "&.active": {
                                        backgroundColor:
                                          "rgba(144, 202, 249, 0.16);",
                                      },
                                    }}
                                    end
                                    component={NavLink}
                                    to="/reports/ticket-detail"
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <ContentPasteSearchIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{ opacity: open ? 1 : 0 }}
                                    >
                                      <FormattedMessage id="reports.ticketDetail" />
                                    </ListItemText>
                                  </ListItemButton>
                                </ListItem>
                              </Tooltip>
                            )}
                          </List>
                        </Collapse>
                      </>
                    )}
                    {pages.includes("cards") && (
                      <Tooltip
                        arrow
                        title={<FormattedMessage id="cards" />}
                        placement="right"
                      >
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              pl: open ? 4 : 2.5,
                              "&.active": {
                                backgroundColor: "rgba(144, 202, 249, 0.16);",
                              },
                            }}
                            end
                            component={NavLink}
                            to="/cards"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <CreditCardIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <FormattedMessage id="cards" />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                    {pages.includes("additionalServices") && (
                      <Tooltip
                        arrow
                        title={<FormattedMessage id="additionalServices" />}
                        placement="right"
                      >
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              pl: open ? 4 : 2.5,
                              "&.active": {
                                backgroundColor: "rgba(144, 202, 249, 0.16);",
                              },
                            }}
                            end
                            component={NavLink}
                            to="/additional-services"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <FastfoodIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <FormattedMessage id="additionalServices" />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                    {pages.includes("devices") && (
                      <Tooltip
                        arrow
                        title={<FormattedMessage id="devices" />}
                        placement="right"
                      >
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              pl: open ? 4 : 2.5,
                              "&.active": {
                                backgroundColor: "rgba(144, 202, 249, 0.16);",
                              },
                            }}
                            end
                            component={NavLink}
                            to="/devices"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <TabletAndroidIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <FormattedMessage id="devices" />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                    {pages.includes("buses") && (
                      <Tooltip
                        arrow
                        title={<FormattedMessage id="buses" />}
                        placement="right"
                      >
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              pl: open ? 4 : 2.5,
                              "&.active": {
                                backgroundColor: "rgba(144, 202, 249, 0.16);",
                              },
                            }}
                            end
                            component={NavLink}
                            to="/buses"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <DirectionsBusIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <FormattedMessage id="buses" />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                    {pages.includes("busStops") && (
                      <Tooltip
                        arrow
                        title={<FormattedMessage id="busStops" />}
                        placement="right"
                      >
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              pl: open ? 4 : 2.5,
                              "&.active": {
                                backgroundColor: "rgba(144, 202, 249, 0.16);",
                              },
                            }}
                            end
                            component={NavLink}
                            to="/bus-routes"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <TransferWithinAStationIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <FormattedMessage id="busStops" />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                    {pages.includes("users") && (
                      <Tooltip
                        arrow
                        title={<FormattedMessage id="users" />}
                        placement="right"
                      >
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              pl: open ? 4 : 2.5,
                              "&.active": {
                                backgroundColor: "rgba(144, 202, 249, 0.16);",
                              },
                            }}
                            end
                            component={NavLink}
                            to="/users"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <PeopleAltIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <FormattedMessage id="users" />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                    {pages.includes("roles") && (
                      <Tooltip
                        arrow
                        title={<FormattedMessage id="roles" />}
                        placement="right"
                      >
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              pl: open ? 4 : 2.5,
                              "&.active": {
                                backgroundColor: "rgba(144, 202, 249, 0.16);",
                              },
                            }}
                            end
                            component={NavLink}
                            to="/roles"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <ContactEmergencyIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <FormattedMessage id="roles" />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                    {pages.includes("agents") && (
                      <Tooltip
                        arrow
                        title={<FormattedMessage id="agents" />}
                        placement="right"
                      >
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              pl: open ? 4 : 2.5,
                              "&.active": {
                                backgroundColor: "rgba(144, 202, 249, 0.16);",
                              },
                            }}
                            end
                            component={NavLink}
                            to="/agents"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <ApartmentIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <FormattedMessage id="agents" />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                    {pages.includes("QRDebit") && (
                      <Tooltip
                        arrow
                        title={<FormattedMessage id="QRDebit" />}
                        placement="right"
                      >
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              pl: open ? 4 : 2.5,
                              "&.active": {
                                backgroundColor: "rgba(144, 202, 249, 0.16);",
                              },
                            }}
                            end
                            component={NavLink}
                            to="/qr-debit"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <QrCode2Icon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <FormattedMessage id="QRDebit" />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                    {[
                      "reports.cancellationRequestedTickets",
                      "cancellationRequests",
                    ].some((e) => pages.includes(e)) && (
                      <>
                        <Tooltip
                          arrow
                          title={
                            <FormattedMessage id="ticketCancelOperations" />
                          }
                          placement="right"
                        >
                          <ListItem disablePadding sx={{ display: "block" }}>
                            <ListItemButton
                              sx={{
                                justifyContent: open ? "initial" : "center",
                                pl: open ? 4 : 2.5,
                                pr: 2.5,
                              }}
                              onClick={() =>
                                setTicketCancelMenuOpen(!ticketCancelMenuOpen)
                              }
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 3 : "auto",
                                  justifyContent: "center",
                                }}
                              >
                                <EventBusyIcon />
                              </ListItemIcon>
                              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                                <FormattedMessage id="ticketCancelOperations" />
                              </ListItemText>
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 0 : "auto",
                                  justifyContent: "center",
                                  opacity: open ? 1 : 0,
                                  width: open ? "unset" : 0,
                                }}
                              >
                                {ticketCancelMenuOpen ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </ListItemIcon>
                            </ListItemButton>
                          </ListItem>
                        </Tooltip>
                        <Collapse
                          in={ticketCancelMenuOpen}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List dense disablePadding>
                            {pages.includes(
                              "reports.cancellationRequestedTickets"
                            ) && (
                              <Tooltip
                                arrow
                                title={
                                  <FormattedMessage id="reports.cancellationRequestedTickets" />
                                }
                                placement="right"
                              >
                                <ListItem
                                  disablePadding
                                  sx={{ display: "block" }}
                                >
                                  <ListItemButton
                                    sx={{
                                      justifyContent: open
                                        ? "initial"
                                        : "center",
                                      pl: open ? 5.5 : 2.5,
                                      "&.active": {
                                        backgroundColor:
                                          "rgba(144, 202, 249, 0.16);",
                                      },
                                    }}
                                    end
                                    component={NavLink}
                                    to="/reports/cancellation-requested-tickets"
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <CancelPresentationIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{ opacity: open ? 1 : 0 }}
                                    >
                                      <FormattedMessage id="reports.cancellationRequestedTickets" />
                                    </ListItemText>
                                  </ListItemButton>
                                </ListItem>
                              </Tooltip>
                            )}
                            {pages.includes("cancellationRequests") && (
                              <Tooltip
                                arrow
                                title={
                                  <FormattedMessage id="cancellationRequests" />
                                }
                                placement="right"
                              >
                                <ListItem
                                  disablePadding
                                  sx={{ display: "block" }}
                                >
                                  <ListItemButton
                                    sx={{
                                      justifyContent: open
                                        ? "initial"
                                        : "center",
                                      pl: open ? 5.5 : 2.5,
                                      "&.active": {
                                        backgroundColor:
                                          "rgba(144, 202, 249, 0.16);",
                                      },
                                    }}
                                    end
                                    component={NavLink}
                                    to="/cancellation-requests"
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <CancelScheduleSendIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{ opacity: open ? 1 : 0 }}
                                    >
                                      <FormattedMessage id="cancellationRequests" />
                                    </ListItemText>
                                  </ListItemButton>
                                </ListItem>
                              </Tooltip>
                            )}
                          </List>
                        </Collapse>
                      </>
                    )}
                    {pages.includes("periodicTicketPrice") && (
                      <Tooltip
                        arrow
                        title={<FormattedMessage id="periodicTicketPrice" />}
                        placement="right"
                      >
                        <ListItem disablePadding sx={{ display: "block" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              pl: open ? 4 : 2.5,
                              "&.active": {
                                backgroundColor: "rgba(144, 202, 249, 0.16);",
                              },
                            }}
                            end
                            component={NavLink}
                            to="/periodic-ticket-price"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            >
                              <PriceChangeIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                              <FormattedMessage id="periodicTicketPrice" />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Tooltip>
                    )}
                    {["reports.activeVehicles", "expeditions"].some((e) =>
                      pages.includes(e)
                    ) && (
                      <>
                        <Tooltip
                          arrow
                          title={<FormattedMessage id="workingSchedule" />}
                          placement="right"
                        >
                          <ListItem disablePadding sx={{ display: "block" }}>
                            <ListItemButton
                              sx={{
                                justifyContent: open ? "initial" : "center",
                                pl: open ? 4 : 2.5,
                                pr: 2.5,
                              }}
                              onClick={() =>
                                setWorkingScheduleMenuOpen(
                                  !workingScheduleMenuOpen
                                )
                              }
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 3 : "auto",
                                  justifyContent: "center",
                                }}
                              >
                                <CalendarMonthIcon />
                              </ListItemIcon>
                              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                                <FormattedMessage id="workingSchedule" />
                              </ListItemText>
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 0 : "auto",
                                  justifyContent: "center",
                                  opacity: open ? 1 : 0,
                                  width: open ? "unset" : 0,
                                }}
                              >
                                {workingScheduleMenuOpen ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </ListItemIcon>
                            </ListItemButton>
                          </ListItem>
                        </Tooltip>
                        <Collapse
                          in={workingScheduleMenuOpen}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List dense disablePadding>
                            {pages.includes("reports.activeVehicles") && (
                              <Tooltip
                                arrow
                                title={
                                  <FormattedMessage id="reports.activeVehicles" />
                                }
                                placement="right"
                              >
                                <ListItem
                                  disablePadding
                                  sx={{ display: "block" }}
                                >
                                  <ListItemButton
                                    sx={{
                                      justifyContent: open
                                        ? "initial"
                                        : "center",
                                      pl: open ? 5.5 : 2.5,
                                      "&.active": {
                                        backgroundColor:
                                          "rgba(144, 202, 249, 0.16);",
                                      },
                                    }}
                                    end
                                    component={NavLink}
                                    to="/reports/active-vehicles"
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <MinorCrashIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{ opacity: open ? 1 : 0 }}
                                    >
                                      <FormattedMessage id="reports.activeVehicles" />
                                    </ListItemText>
                                  </ListItemButton>
                                </ListItem>
                              </Tooltip>
                            )}
                            {pages.includes("expeditions") && (
                              <Tooltip
                                arrow
                                title={<FormattedMessage id="expeditions" />}
                                placement="right"
                              >
                                <ListItem
                                  disablePadding
                                  sx={{ display: "block" }}
                                >
                                  <ListItemButton
                                    sx={{
                                      justifyContent: open
                                        ? "initial"
                                        : "center",
                                      pl: open ? 5.5 : 2.5,
                                      "&.active": {
                                        backgroundColor:
                                          "rgba(144, 202, 249, 0.16);",
                                      },
                                    }}
                                    end
                                    component={NavLink}
                                    to="/expeditions"
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <DepartureBoardIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{ opacity: open ? 1 : 0 }}
                                    >
                                      <FormattedMessage id="expeditions" />
                                    </ListItemText>
                                  </ListItemButton>
                                </ListItem>
                              </Tooltip>
                            )}
                          </List>
                        </Collapse>
                      </>
                    )}
                  </List>
                </Collapse>
              </>
            )}
          {LINKS.length > 0 && (
            <>
              <Divider sx={{ my: 1, borderBottomWidth: "medium" }} />
              <Tooltip
                arrow
                title={<FormattedMessage id="definitions" />}
                placement="right"
              >
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    onClick={() => setDefinitionsMenuOpen(!definitionsMenuOpen)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <AppRegistrationIcon />
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                      <FormattedMessage id="definitions" />
                    </ListItemText>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 0 : "auto",
                        justifyContent: "center",
                        opacity: open ? 1 : 0,
                        width: open ? "unset" : 0,
                      }}
                    >
                      {definitionsMenuOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              </Tooltip>
              <Collapse in={definitionsMenuOpen} timeout="auto" unmountOnExit>
                <List dense disablePadding>
                  {LINKS.map((item, index) => (
                    <Tooltip
                      key={index}
                      arrow
                      title={<FormattedMessage id={item.name} />}
                      placement="right"
                    >
                      <ListItem disablePadding sx={{ display: "block" }}>
                        <ListItemButton
                          sx={{
                            justifyContent: open ? "initial" : "center",
                            pl: open ? 4 : 2.5,
                            "&.active": {
                              backgroundColor: "rgba(144, 202, 249, 0.16);",
                            },
                          }}
                          end
                          component={NavLink}
                          to={item.path}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                            <FormattedMessage id={item.name} />
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    </Tooltip>
                  ))}
                </List>
              </Collapse>
            </>
          )}
        </List>
      </Drawer>
      {children}
    </Box>
  );
}
