import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  AdditionalServicesSelectAll,
  GetAdditionalServicesByTicketNum,
  GetCards,
  SaleUpdate,
  TicketDetailsGetAll,
  UseOrAddService,
} from "../../api/api";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import TicketsDataGrid from "./TicketsDatagrid";
import * as Yup from "yup";
import IntlTranslate from "../../helpers/IntlTranslate";
import { useFormik, Form, FormikProvider } from "formik";
import {
  Button,
  Paper,
  Autocomplete,
  FormGroup,
  MenuItem,
  Stack,
  TextField,
  Typography,
  FormLabel,
  TableContainer,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Chip,
  Tooltip,
  IconButton,
  Divider,
  Dialog,
  DialogContent,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import GetDataArea from "../../components/GetDataArea";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";
import {
  CalculateRemainingTime,
  CalculateBeforeDate,
} from "../../helpers/CalculateRemainingTime";
import { useAuth } from "../../context/AuthContext";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SnackbarComponent from "../../components/Snackbar";
import QRCode from "react-qr-code";

const COLORS = [
  {
    bg: "#32a852",
    text: "#fff",
  },
  {
    bg: "#212121",
    text: "#fff",
  },
  {
    bg: "#5B3A29",
    text: "#fff",
  },
  {
    bg: "#1D1E33",
    text: "#fff",
  },
  {
    bg: "#C51D34",
    text: "#fff",
  },
  {
    bg: "#102C54",
    text: "#fff",
  },
  {
    bg: "#E55137",
    text: "#fff",
  },
];
function Tickets() {
  const breadcrumb = useMemo(
    () => [{ name: <FormattedMessage id="tickets" />, active: true }],
    []
  );
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const [successMessage, setSuccessMessage] = useState("ticketupdate");
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  const { role, user } = useAuth();
  const OpenEditModal = useCallback(
    (type, row) => () => {
      setSelectedTicket({ type: type, data: row });
      setOpen(true);
    },
    []
  );
  const columns = useMemo(
    () => [
      {
        field: "referanceNum",
        headerName: "Referans Numarası",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "nameSurname",
        headerName: "Ad Soyad",
        minWidth: 150,
        flex: 1,
      },
      { field: "cardType", headerName: "Kart Tipi", minWidth: 150, flex: 1 },
      {
        field: "date",
        headerName: "Satın Alma Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm");
        },
      },
      {
        field: "usageStart",
        headerName: "Hizmet Başlangıç Süresi",
        hideable: false,
      },
      {
        field: "activationStart",
        headerName: "Aktivasyon Başlangıç Süresi",
        hideable: false,
      },
      {
        field: "usageTimeRemaining",
        headerName: "Kalan Hizmet Süresi",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "activationTimeRemaining",
        headerName: "Kalan Aktivasyon Süresi",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "actions",
        type: "actions",
        getActions: (params) => [
          <Button
            onClick={OpenEditModal("single", params.row)}
            variant="contained"
            color="secondary"
            size="small"
            disabled={CheckDate(params.row.activationStart)}
          >
            <FormattedMessage id="edit" />
          </Button>,
        ],
      },
    ],
    [OpenEditModal]
  );

  const agentColumns = useMemo(
    () => [
      {
        field: "referanceNum",
        headerName: "Referans Numarası",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "nameSurname",
        headerName: "Ad Soyad",
        minWidth: 150,
        flex: 1,
      },
      { field: "cardType", headerName: "Kart Tipi", minWidth: 150, flex: 1 },
      {
        field: "date",
        headerName: "Satın Alma Tarihi",
        minWidth: 150,
        flex: 1,
        type: "dateTime",
        renderCell: (params) => {
          return dayjs(params.value).format("DD.MM.YYYY HH:mm");
        },
      },
      {
        field: "usageStart",
        headerName: "Hizmet Başlangıç Süresi",
        hideable: false,
      },
      {
        field: "activationStart",
        headerName: "Aktivasyon Başlangıç Süresi",
        hideable: false,
      },
      {
        field: "usageTimeRemaining",
        headerName: "Kalan Hizmet Süresi",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "activationTimeRemaining",
        headerName: "Kalan Aktivasyon Süresi",
        minWidth: 150,
        flex: 1,
      },
    ],
    []
  );

  const [allCards, setAllCards] = useState([]);
  useEffect(() => {
    GetCards().then((resp) =>
      setAllCards(resp.data.data.map((e) => ({ id: e.Id, label: e.KartAdi })))
    );
  }, []);
  const [open, setOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState({});
  const handleClose = () => setOpen(false);
  const [selectedTicketsServicesList, setSelectedTicketsServicesList] =
    useState([]);
  useEffect(() => {
    if (selectedTicket && selectedTicket.type === "single") {
      GetAdditionalServicesByTicketNum(selectedTicket.data.referanceNum).then(
        (resp) => setSelectedTicketsServicesList(resp.data.data)
      );
    }
  }, [selectedTicket]);
  //modal edit
  const [loading, setLoading] = useState(false);
  const schema = Yup.object().shape({
    activationTime: Yup.number().min(0, IntlTranslate("activationTime.min")),
    usageTime: Yup.number().min(0, IntlTranslate("usageTime.min")),
  });

  const formik = useFormik({
    initialValues: {
      activationTime: "",
      usageTime: "",
    },
    validationSchema: schema,
    onSubmit: async (datas, { resetForm }) => {
      setLoading(true);
      datas.activationTime =
        timeSettings.activationTimeFreq === "days"
          ? Number(datas.activationTime) * 24
          : timeSettings.activationTimeFreq === "minutes"
          ? Number(datas.activationTime) / 60
          : Number(datas.activationTime);
      datas.usageTime =
        timeSettings.usageTimeFreq === "days"
          ? Number(datas.usageTime) * 24
          : timeSettings.usageTimeFreq === "minutes"
          ? Number(datas.usageTime) / 60
          : Number(datas.usageTime);

      if (selectedTicket.type === "multiple") {
        let i = 0;
        while (i < selectedTicket.data.length) {
          let result = await SaleUpdate({
            BiletReferansKodu: selectedTicket.data[i],
            ...(datas.activationTime !== 0 && {
              BaslangicTarihi: dayjs()
                .add(datas.activationTime, "hour")
                .toISOString(),
            }),
            ...(datas.usageTime !== 0 && {
              HizmetTarihi: dayjs().add(datas.usageTime, "hour").toISOString(),
            }),
          });
          if (
            result.data.status === "success" &&
            result.data.data === "Updated"
          ) {
            i++;
          } else {
            break;
          }
        }
        resetForm();
        setSuccessMessage("ticketupdate");
        setSnackbarType("success");
        setSnackbarOpen(true);
      } else {
        let result = await SaleUpdate({
          BiletReferansKodu: selectedTicket.data.referanceNum,
          ...(datas.activationTime !== 0 && {
            BaslangicTarihi: dayjs()
              .add(datas.activationTime, "hour")
              .toISOString(),
          }),
          ...(datas.usageTime !== 0 && {
            HizmetTarihi: dayjs().add(datas.usageTime, "hour").toISOString(),
          }),
        });
        if (
          result.data.status === "success" &&
          result.data.data === "Updated"
        ) {
          resetForm();
          setSuccessMessage("ticketupdate");
          setSnackbarType("success");
          setSnackbarOpen(true);
        }
      }
      setLoading(false);
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const [timeSettings, setTimeSettings] = useState({
    activationTimeFreq: "hours",
    usageTimeFreq: "hours",
  });
  const TimeSettingsOnChange = (e) => {
    const { name, value } = e.target;
    setTimeSettings({
      ...timeSettings,
      [name]: value,
    });
  };

  const [additionalServiceOption, setAdditionalServiceOption] = useState([]);
  useEffect(() => {
    AdditionalServicesSelectAll().then((resp) => {
      if (role.isFullAgent) {
        setAdditionalServiceOption(
          resp.data.data.filter((e) => e.AcenteSatabilir === true)
        );
      }
      setAdditionalServiceOption(resp.data.data);
    });
  }, [role.isFullAgent]);
  const [additionalServices, setAdditionalServices] = useState([]);

  const [data, setData] = useState([]);
  const [gridLoading, setGridLoading] = useState(false);

  //GET DATA FILTERS
  const [searchCardType, setSearchCardType] = useState(null);
  const [timeRemainings, setTimeRemainings] = useState([
    { name: "utrMin", value: "" },
    { name: "utrMax", value: "" },
    { name: "atrMin", value: "" },
    { name: "atrMax", value: "" },
  ]);
  const handleNumberInputsChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      let temp = JSON.parse(JSON.stringify(timeRemainings));
      temp.find((t) => t.name === name).value = value;
      setTimeRemainings(temp);
    },
    [timeRemainings]
  );
  const HandleSearchClick = useCallback(
    async (start, end) => {
      setGridLoading(true);
      let response = await TicketDetailsGetAll({
        DateStart: dayjs(start).toISOString(),
        DateEnd: dayjs(end).toISOString(),
        Datefilter: "SatinAlmaTarihi",
        KartId: searchCardType?.id,
        ...(role.isFullAgent && { AcenteId: role.agentData.agentId }),
        ...(timeRemainings[0].value !== "" &&
          timeRemainings[0].value !== "0" && {
            KullanimMin: CalculateBeforeDate(timeRemainings[0].value),
          }),
        ...(timeRemainings[1].value !== "" &&
          timeRemainings[1].value !== "0" && {
            KullanimMax: CalculateBeforeDate(timeRemainings[1].value),
          }),
        ...(timeRemainings[2].value !== "" &&
          timeRemainings[2].value !== "0" && {
            AktivasyonMin: CalculateBeforeDate(timeRemainings[2].value),
          }),
        ...(timeRemainings[3].value !== "" &&
          timeRemainings[3].value !== "0" && {
            AktivasyonMax: CalculateBeforeDate(timeRemainings[3].value),
          }),
      });
      const datas = response.data.data.map((e) => ({
        referanceNum: e.BiletReferansKodu,
        nameSurname: HideNameSurname(e.MusteriAdSoyad),
        cardType: e.KartTipi,
        date: e.SatinAlmaTarihi,
        usageTimeRemaining: e.HizmetTarihi
          ? dayjs(e.HizmetTarihi).unix() < dayjs().unix()
            ? CalculateRemainingTime(e.HizmetBitisTarihi)
            : "hizmet başlamadı"
          : "hizmet başlamadı",
        activationTimeRemaining:
          dayjs(e.AktivasyonTarihi).unix() < dayjs().unix()
            ? CalculateRemainingTime(e.AktivasyonBitisTarihi)
            : "aktivasyon başlamadı",
        activationStart: e.AktivasyonTarihi,
        usageStart: e.HizmetTarihi,
      }));
      setData(datas);
      setGridLoading(false);
    },
    [searchCardType, timeRemainings, role]
  );

  const HandleDeleteClick = useCallback(async () => {
    setSearchCardType(null);
    setTimeRemainings([
      { name: "utrMin", value: "" },
      { name: "utrMax", value: "" },
      { name: "atrMin", value: "" },
      { name: "atrMax", value: "" },
    ]);
  }, []);

  const AddService = (serviceId, serviceName, additionalSerivcesId = "") => {
    setAdditionalServices((prev) => [
      ...prev,
      {
        id: prev.length > 0 ? prev[prev.length - 1].id + 1 : 1,
        additionalSerivcesId,
        serviceId,
        serviceName,
        isUsed: additionalSerivcesId === "" ? false : true,
      },
    ]);
  };
  const ServiceDeleteFromList = (id) => {
    let temp = JSON.parse(JSON.stringify(additionalServices));
    temp.splice(
      temp.findIndex((a) => a.id === id),
      1
    );
    setAdditionalServices(temp);
  };

  const ServiceSetUsed = (id) => {
    let temp = JSON.parse(JSON.stringify(additionalServices));
    temp.find((e) => e.id === id).isUsed = true;
    setAdditionalServices(temp);
  };

  const submitAdditionalServices = async () => {
    if (selectedTicket.type === "multiple") {
      let i = 0;
      while (i < selectedTicket.data.length) {
        let j = 0;
        while (j < additionalServices.length) {
          if (additionalServices[j].additionalSerivcesId) {
            let result = await UseOrAddService({
              Id: additionalServices[j].additionalSerivcesId,
              IsUsed: additionalServices[j].isUsed,
              ModifyUserId: user[0].Id,
            });
            if (result.data.status === "success") {
              j++;
            } else {
              break;
            }
          } else {
            let result = await UseOrAddService({
              Id: 0,
              BiletReferansKodu: selectedTicket.data[i],
              EkHizmetId: additionalServices[j].serviceId,
              IsUsed: additionalServices[j].isUsed,
              ModifyUserId: user[0].Id,
            });
            if (result.data.status === "success") {
              j++;
            } else {
              break;
            }
          }
        }
        i++;
      }
    } else {
      let i = 0;
      while (i < additionalServices.length) {
        if (additionalServices[i].additionalSerivcesId) {
          let result = await UseOrAddService({
            Id: additionalServices[i].additionalSerivcesId,
            EkHizmetId: additionalServices[i].serviceId,
            IsUsed: additionalServices[i].isUsed,
            ModifyUserId: user[0].Id,
          });
          if (result.data.status === "success") {
            i++;
          } else {
            break;
          }
        } else {
          let result = await UseOrAddService({
            Id: 0,
            BiletReferansKodu: selectedTicket.data.referanceNum,
            EkHizmetId: additionalServices[i].serviceId,
            IsUsed: additionalServices[i].isUsed,
            ModifyUserId: user[0].Id,
          });
          if (result.data.status === "success") {
            i++;
          } else {
            break;
          }
        }
      }
    }
    setSuccessMessage("ticketserviceadd");
    setSnackbarType("success");
    setSnackbarOpen(true);
    setAdditionalServices([]);
  };
  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <GetDataArea
        HandleSearchClick={HandleSearchClick}
        HandleDeleteClick={HandleDeleteClick}
        timeSelect={true}
      >
        <Grid xs={12} lg={2}>
          <Autocomplete
            fullWidth
            size="small"
            options={allCards}
            value={searchCardType}
            onChange={(event, newValue) => {
              setSearchCardType(newValue);
            }}
            renderInput={(params) => (
              <TextField size="small" {...params} label="Kart Tipi" />
            )}
          />
        </Grid>
        <Grid
          xs={12}
          lg={2}
          sx={{ position: "relative", pt: { xs: "16px", lg: "8px" } }}
        >
          <Typography
            component={FormLabel}
            variant="span"
            sx={{
              height: "16px",
              fontSize: "12px",
              position: "absolute",
              left: "16px",
              top: { xs: "0", lg: "-8px" },
            }}
          >
            Kullanım Süresi Aralığı
          </Typography>
          <FormGroup sx={{ flexDirection: "row" }}>
            <TextField
              size="small"
              sx={{ width: "50%" }}
              placeholder="min"
              name={timeRemainings[0].name}
              value={timeRemainings[0].value}
              inputProps={{
                max: timeRemainings[1].value,
              }}
              onChange={handleNumberInputsChange}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <TextField
              size="small"
              sx={{ width: "50%" }}
              placeholder="max"
              name={timeRemainings[1].name}
              value={timeRemainings[1].value}
              inputProps={{
                min: timeRemainings[0].value,
              }}
              onChange={handleNumberInputsChange}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </FormGroup>
        </Grid>
        <Grid
          xs={12}
          lg={2}
          sx={{ position: "relative", pt: { xs: "16px", lg: "8px" } }}
        >
          <Typography
            component={FormLabel}
            variant="span"
            sx={{
              height: "16px",
              fontSize: "12px",
              position: "absolute",
              left: "16px",
              top: { xs: "0", lg: "-8px" },
            }}
          >
            Aktivasyon Süresi Aralığı
          </Typography>
          <FormGroup sx={{ flexDirection: "row" }}>
            <TextField
              size="small"
              sx={{ width: "50%" }}
              placeholder="min"
              name={timeRemainings[2].name}
              value={timeRemainings[2].value}
              inputProps={{
                max: timeRemainings[3].value,
              }}
              onChange={handleNumberInputsChange}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <TextField
              size="small"
              sx={{ width: "50%" }}
              placeholder="max"
              name={timeRemainings[3].name}
              value={timeRemainings[3].value}
              inputProps={{
                min: timeRemainings[2].value,
              }}
              onChange={handleNumberInputsChange}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </FormGroup>
        </Grid>
      </GetDataArea>
      <TicketsDataGrid
        data={data}
        columns={role.isFullAgent ? agentColumns : columns}
        loading={gridLoading}
        OpenEditModal={OpenEditModal}
        setModalOpen={setOpen}
        rowsUniqueId="referanceNum"
        getRowId={(row) => row.referanceNum}
        initialState={{
          columns: {
            columnVisibilityModel: {
              usageStart: false,
              activationStart: false,
            },
          },
        }}
      />
      <Dialog
        fullWidth
        maxWidth="xl"
        scroll="paper"
        open={open}
        onClose={handleClose}
        aria-labelledby="ticket-edit"
        aria-describedby="ticket-set-properties"
      >
        <DialogContent>
          <Typography variant="h6" component="h2">
            {selectedTicket.type === "multiple"
              ? `Biletleri Düzenle (${selectedTicket.data.length} adet)`
              : "Bileti Düzenle"}
          </Typography>
          {selectedTicket.type === "single" && (
            <Box sx={{ my: 2 }}>
              <Typography mb={2}>{selectedTicket.data.referanceNum}</Typography>
              <QRCode size={128} value={selectedTicket.data.referanceNum} />
            </Box>
          )}
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit}>
              <Stack sx={{ mt: 1 }} spacing={0}>
                <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
                  <Stack
                    direction={{ xs: "columnn", lg: "row" }}
                    spacing={2}
                    sx={{ width: "100%" }}
                  >
                    <FormGroup row sx={{ width: { xs: "100%", lg: "50%" } }}>
                      <TextField
                        sx={{
                          width: "70%",
                          "& fieldset": {
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderRight: 0,
                          },
                        }}
                        label={IntlTranslate("activationTime")}
                        {...getFieldProps("activationTime")}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        placeholder="72"
                        error={Boolean(
                          touched.activationTime && errors.activationTime
                        )}
                        helperText={
                          touched.activationTime && errors.activationTime
                        }
                      />
                      <TextField
                        select
                        sx={{
                          width: "30%",
                          "& fieldset": {
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderLeft: 0,
                          },
                        }}
                        helperText=" "
                        name="activationTimeFreq"
                        value={timeSettings.activationTimeFreq}
                        onChange={TimeSettingsOnChange}
                      >
                        <MenuItem value="minutes">
                          <FormattedMessage id="minutes" />
                        </MenuItem>
                        <MenuItem value="hours">
                          <FormattedMessage id="hours" />
                        </MenuItem>
                        <MenuItem value="days">
                          <FormattedMessage id="days" />
                        </MenuItem>
                      </TextField>
                    </FormGroup>
                    <FormGroup row sx={{ width: { xs: "100%", lg: "50%" } }}>
                      <TextField
                        sx={{
                          width: "70%",
                          "& fieldset": {
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            borderRight: 0,
                          },
                        }}
                        label={IntlTranslate("usageTime")}
                        {...getFieldProps("usageTime")}
                        placeholder="24"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        error={Boolean(touched.usageTime && errors.usageTime)}
                        helperText={touched.usageTime && errors.usageTime}
                      />
                      <TextField
                        select
                        sx={{
                          width: "30%",
                          "& fieldset": {
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderLeft: 0,
                          },
                        }}
                        helperText=" "
                        name="usageTimeFreq"
                        value={timeSettings.usageTimeFreq}
                        onChange={TimeSettingsOnChange}
                      >
                        <MenuItem value="minutes">
                          <FormattedMessage id="minutes" />
                        </MenuItem>
                        <MenuItem value="hours">
                          <FormattedMessage id="hours" />
                        </MenuItem>
                        <MenuItem value="days">
                          <FormattedMessage id="days" />
                        </MenuItem>
                      </TextField>
                    </FormGroup>
                  </Stack>
                </Stack>
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="success"
                  sx={{ mt: 0, mb: 2 }}
                  loading={loading}
                >
                  <FormattedMessage id="updatebtn" />
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
          <Stack sx={{ mt: 1 }} spacing={3}>
            <Stack spacing={2}>
              <Typography variant="h6" component="h2">
                <FormattedMessage id="additionalServices" />
              </Typography>
              <Divider />
              <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
                {additionalServiceOption.map((e, i) => (
                  <Button
                    key={i}
                    variant="outlined"
                    endIcon={<AddIcon />}
                    onClick={() => AddService(e.Id, e.EkHizmetAdi)}
                    disabled={!Boolean(e.Satilabilir)}
                    color={e.EkHizmetFiyati ? "primary" : "secondary"}
                  >
                    {e.EkHizmetAdi}
                    {e.EkHizmetFiyati !== null && e.EkSure !== null
                      ? ` | ${e.EkHizmetFiyati} TL | +${e.EkSure} saat`
                      : ""}
                  </Button>
                ))}
              </Stack>
              <TableContainer component={Paper}>
                <Table aria-label="additional services table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Hizmet Adı</TableCell>
                      <TableCell align="right">Seçenekler</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedTicketsServicesList.map((e, index) => (
                      <TableRow key={e.Id}>
                        <TableCell>
                          {e.IsCard && (
                            <Tooltip
                              title={
                                <FormattedMessage id="standartCardServices" />
                              }
                              placement="right"
                            >
                              <CreditCardIcon
                                sx={{ verticalAlign: "middle", mr: 1 }}
                              />
                            </Tooltip>
                          )}
                          <Chip
                            disabled={
                              role.isFullAgent
                                ? e.AcenteEkHizmetKullandirabilir
                                  ? false
                                  : true
                                : e.IsUsed
                                ? true
                                : additionalServices.find(
                                    (s) => s.additionalSerivcesId === e.Id
                                  )?.isUsed
                                ? true
                                : false
                            }
                            label={e.EkHizmetAdi}
                            size="small"
                            sx={{
                              px: 2,
                              backgroundColor: COLORS[index % COLORS.length].bg,
                              color: COLORS[index % COLORS.length].text,
                              fontSize: "1rem",
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip
                            placement="left"
                            title={
                              <FormattedMessage
                                id={e.IsUsed ? "usedService" : "useService"}
                              />
                            }
                          >
                            <span>
                              <IconButton
                                color="info"
                                aria-label="Use Service"
                                disabled={
                                  role.isFullAgent
                                    ? e.AcenteEkHizmetKullandirabilir
                                      ? false
                                      : true
                                    : e.IsUsed
                                    ? true
                                    : additionalServices.find(
                                        (s) => s.additionalSerivcesId === e.Id
                                      )?.isUsed
                                    ? true
                                    : false
                                }
                                onClick={() =>
                                  AddService(e.EkHizmetId, e.EkHizmetAdi, e.Id)
                                }
                              >
                                <TouchAppIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                    {additionalServices.map((t, i) => {
                      if (t.additionalSerivcesId === "") {
                        return (
                          <TableRow key={i}>
                            <TableCell>
                              <Chip
                                disabled={t.isUsed}
                                label={t.serviceName}
                                size="small"
                                sx={{
                                  px: 2,
                                  backgroundColor:
                                    COLORS[
                                      (i + selectedTicketsServicesList.length) %
                                        COLORS.length
                                    ].bg,
                                  color:
                                    COLORS[
                                      (i + selectedTicketsServicesList.length) %
                                        COLORS.length
                                    ].text,
                                  fontSize: "1rem",
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Tooltip
                                placement="left"
                                title={
                                  <FormattedMessage
                                    id={t.isUsed ? "usedService" : "useService"}
                                  />
                                }
                              >
                                <span>
                                  <IconButton
                                    color="info"
                                    aria-label="Use Service"
                                    disabled={t.isUsed}
                                    onClick={() => ServiceSetUsed(t.id)}
                                  >
                                    <TouchAppIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                              <Tooltip
                                placement="left"
                                title={<FormattedMessage id="deletebtn" />}
                              >
                                <IconButton
                                  color="error"
                                  aria-label="Delete Service"
                                  onClick={() => ServiceDeleteFromList(t.id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            <LoadingButton
              fullWidth
              variant="contained"
              color="success"
              sx={{ mt: 0, mb: 2 }}
              onClick={submitAdditionalServices}
            >
              <FormattedMessage id="updatebtn" />
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate(successMessage)}
        alertType={snackbarType}
        onClose={handleCloseSnackbar}
      />
    </AppContainer>
  );
}

export default withTitle(Tickets, "tickets");
const HideNameSurname = (nameSurname) => {
  let arr = nameSurname.split(" ");
  for (let i = 0; i < arr.length; i++) {
    let first3Digits = arr[i].slice(0, 3);
    arr[i] = first3Digits.padEnd(arr[i].length, "*");
  }
  return arr.join(" ");
};

const CheckDate = (start) => {
  if (dayjs(start).unix() > dayjs().unix()) {
    return true;
  }
  return false;
};
