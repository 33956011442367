import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import * as Yup from "yup";
import IntlTranslate from "../../helpers/IntlTranslate";
import { useFormik, Form, FormikProvider } from "formik";
import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import withTitle from "../../helpers/hoc/withTitle";
import { CreateAgencies } from "../../api/api";
import SnackbarComponent from "../../components/Snackbar";

function AgentsAdd() {
  const breadcrumb = useMemo(
    () => [
      {
        name: <FormattedMessage id="agents" />,
        path: "/agents",
      },
      { name: <FormattedMessage id="agents.add" />, active: true },
    ],
    []
  );
  const [loading, setLoading] = useState(false);
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  const schema = Yup.object().shape({
    Acente: Yup.string().required(IntlTranslate("agentName.required")),
    Sirket: Yup.string().required(IntlTranslate("companyName.required")),
  });
  const formik = useFormik({
    initialValues: {
      Acente: "",
      Sirket: "",
    },
    validationSchema: schema,
    onSubmit: async (datas, { resetForm }) => {
      setLoading(true);
      var response = await CreateAgencies({
        Id: 0,
        DeletedFlag: false,
        ...datas,
      });
      if (response.data.status === "success" && response.data.Id) {
        setSnackbarOpen(true);
        setSnackbarType("success");
        resetForm();
      } else {
        setSnackbarType("error");
        setSnackbarOpen(true);
      }
      setLoading(false);
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Stack sx={{ mt: 1 }} spacing={3}>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
              <TextField
                required
                fullWidth
                label={IntlTranslate("agentName")}
                {...getFieldProps("Acente")}
                error={Boolean(touched.Acente && errors.Acente)}
                helperText={touched.Acente && errors.Acente}
              />
              <TextField
                required
                fullWidth
                label={IntlTranslate("companyName")}
                {...getFieldProps("Sirket")}
                error={Boolean(touched.Sirket && errors.Sirket)}
                helperText={touched.Sirket && errors.Sirket}
              />
            </Stack>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="success"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}>
              <FormattedMessage id="add" />
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate("agentsuccessadd")}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(AgentsAdd, "agents.add");
