import React from "react";
import * as Yup from "yup";
import IntlTranslate from "../../helpers/IntlTranslate";
import { useFormik } from "formik";
import { Autocomplete, InputAdornment, Stack, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

function PeriodicTicketPriceUpdateDialog({
  updateInputs,
  setUpdateInputs,
  cardsData,
}) {
  const schema = Yup.object().shape({
    periodName: Yup.string().required(IntlTranslate("periodName.required")),
    startDate: Yup.date().required(IntlTranslate("startDate.required")),
    endDate: Yup.date().required(IntlTranslate("endDate.required")),
    price: Yup.number()
      .min(0, IntlTranslate("price.min"))
      .required(IntlTranslate("price.required")),
    cardType: Yup.string().required(IntlTranslate("ticketType.required")),
  });
  const formik = useFormik({
    initialValues: {
      periodName: updateInputs.periodName,
      startDate: updateInputs.startDate,
      endDate: updateInputs.endDate,
      price: updateInputs.price,
      cardType: cardsData.find((e) => e.id === updateInputs.cardType),
    },
    validationSchema: schema,
  });
  const { errors, touched, values, setFieldValue } = formik;

  return (
    <Stack sx={{ mt: 1 }} spacing={3}>
      <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
        <TextField
          fullWidth
          required
          label={IntlTranslate("periodName")}
          name="periodName"
          value={values.periodName}
          onChange={(e) => {
            setFieldValue("periodName", e.target.value);
            setUpdateInputs((prev) => ({
              ...prev,
              periodName: e.target.value,
            }));
          }}
          error={Boolean(touched.periodName && errors.periodName)}
          helperText={touched.periodName && errors.periodName}
        />
        <TextField
          required
          fullWidth
          type="number"
          label={IntlTranslate("price")}
          name="price"
          value={values.price}
          onChange={(e) => {
            setFieldValue("price", e.target.value);
            setUpdateInputs((prev) => ({
              ...prev,
              price: e.target.value,
            }));
          }}
          error={Boolean(touched.price && errors.price)}
          helperText={touched.price && errors.price}
          InputProps={{
            endAdornment: <InputAdornment position="end">₺</InputAdornment>,
          }}
        />
      </Stack>
      <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            required
            label={IntlTranslate("startDate")}
            mask="__.__.____ __:__"
            inputFormat="DD.MM.YYYY HH:mm"
            value={values.startDate}
            onChange={(newValue) => {
              setFieldValue("startDate", newValue);
              setUpdateInputs((prev) => ({
                ...prev,
                startDate: newValue,
              }));
            }}
            ampm={false}
            maxDateTime={dayjs(values.endDate)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={Boolean(touched.startDate && errors.startDate)}
                helperText={
                  touched.startDate
                    ? errors.startDate
                    : params?.inputProps?.placeholder
                }
              />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            required
            label={IntlTranslate("endDate")}
            mask="__.__.____ __:__"
            inputFormat="DD.MM.YYYY HH:mm"
            value={values.endDate}
            onChange={(newValue) => {
              setFieldValue("endDate", newValue);
              setUpdateInputs((prev) => ({
                ...prev,
                endDate: newValue,
              }));
            }}
            ampm={false}
            minDateTime={dayjs(values.startDate)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={Boolean(touched.endDate && errors.endDate)}
                helperText={
                  touched.endDate
                    ? errors.endDate
                    : params?.inputProps?.placeholder
                }
              />
            )}
          />
        </LocalizationProvider>
      </Stack>
      <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
        <Autocomplete
          fullWidth
          options={cardsData}
          getOptionLabel={(opt) => opt.label}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={values.cardType}
          onChange={(event, data) => {
            setFieldValue("cardType", data);
            setUpdateInputs((prev) => ({
              ...prev,
              cardType: data?.id,
            }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={IntlTranslate("ticketType")}
              required
              error={Boolean(touched.cardType && errors.cardType)}
              helperText={touched.cardType && errors.cardType}
            />
          )}
        />
      </Stack>
    </Stack>
  );
}

export default PeriodicTicketPriceUpdateDialog;
