import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const DAYS = [
  "Pazartesi",
  "Sali",
  "Carsamba",
  "Persembe",
  "Cuma",
  "Cumartesi",
  "Pazar",
];
function WeeklySales({ data }) {
  var newdata = adjustDays(data);
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={newdata}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Gun" />
        <YAxis type="number" domain={[0, "dataMax + 10"]} />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="SatisMiktari"
          fill="#1A428A"
          shape={renderShape("salesBar")}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default React.memo(WeeklySales);
const renderShape =
  (key) =>
  ({ height, width, fill, x, y, ...rest }) => {
    return (
      <svg x={x} y={y} fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id={key} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#497bd4" />
            <stop offset="100%" stopColor="#1a428a" />
          </linearGradient>
        </defs>
        <rect fill={`url(#${key})`} width={width} height={height} />
      </svg>
    );
  };

const adjustDays = (data) => {
  for (let i = 0; i < DAYS.length; i++) {
    if (!data.find((t) => t.Gun === DAYS[i])) {
      data.push({ Gun: DAYS[i], SatisMiktari: 0 });
    }
  }
  return data;
};
