import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import CustomDataGrid from "../../components/CustomDataGrid";
import { DevicesSelectAll, DeleteDevices, CreateDevices } from "../../api/api";
import withTitle from "../../helpers/hoc/withTitle";
import GetDataArea from "../../components/GetDataArea";
import Grid from "@mui/material/Unstable_Grid2";
import SnackbarComponent from "../../components/Snackbar";
import IntlTranslate from "../../helpers/IntlTranslate";
import DevicesUpdateDialog from "./DevicesUpdateDialog";

function Devices() {
  const breadcrumb = useMemo(
    () => [{ name: <FormattedMessage id="devices" />, active: true }],
    []
  );
  const columns = useMemo(
    () => [
      { field: "CihazId", headerName: "ID", minWidth: 75 },
      { field: "DeviceName", headerName: "Cihaz Adı", minWidth: 150, flex: 1 },
      { field: "DeviceType", headerName: "Cihaz Tipi", minWidth: 150, flex: 1 },
    ],
    []
  );
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  //GET DATA FILTERS
  const [deviceType, setDeviceType] = useState("all");

  const HandleSearchClick = useCallback(async () => {
    setLoading(true);
    await DevicesSelectAll()
      .then((resp) => {
        if (deviceType !== "all") {
          let temp = resp.data.data.filter((e) => e.DeviceType === deviceType);
          setData(temp);
        } else {
          setData(resp.data.data);
        }
      })
      .finally(() => setLoading(false));
  }, [deviceType]);

  const HandleDeleteClick = useCallback(async () => {
    setDeviceType("all");
  }, []);

  //START - Update & Delete Actions
  const [editDeleteSelectedRow, setEditDeleteSelectedRow] = useState({
    operation: "",
    rows: [],
  });
  const onClose = () => {
    setEditDeleteSelectedRow((prev) => ({
      operation: prev.operation,
      rows: [],
    }));
  };
  const btn = useRef();

  const onDelete = async () => {
    let i = 0;
    while (i < editDeleteSelectedRow.rows.length) {
      let result = await DeleteDevices(editDeleteSelectedRow.rows[i]);
      if (result.data.status === "success") {
        i++;
      } else {
        break;
      }
    }
    setSnackbarType("success");
    setSnackbarOpen(true);
    setEditDeleteSelectedRow((prev) => ({
      operation: prev.operation,
      rows: [],
    }));
    btn.current.click();
  };

  const [updateInputs, setUpdateInputs] = useState({
    DeviceName: "",
    DeviceType: "",
  });

  useEffect(() => {
    if (editDeleteSelectedRow.operation === "edit") {
      setUpdateInputs({
        DeviceName: data.find(
          (e) => e.CihazId === editDeleteSelectedRow.rows[0]
        )?.DeviceName,
        DeviceType: data.find(
          (e) => e.CihazId === editDeleteSelectedRow.rows[0]
        )?.DeviceType,
      });
    }
  }, [editDeleteSelectedRow, data]);
  const onUpdate = async () => {
    let i = 0;
    while (i < editDeleteSelectedRow.rows.length) {
      let result = await CreateDevices({
        CihazId: editDeleteSelectedRow.rows[i],
        DeviceName: updateInputs.DeviceName,
        DeviceType: updateInputs.DeviceType,
      });
      if (result.data.status === "success") {
        i++;
      } else {
        break;
      }
    }
    setSnackbarType("success");
    setSnackbarOpen(true);
    setEditDeleteSelectedRow((prev) => ({
      operation: prev.operation,
      rows: [],
    }));
    btn.current.click();
  };
  //END - Update & Delete Actions
  return (
    <AppContainer breadcrumbs={breadcrumb} addButton={{ name: "devices.add" }}>
      <GetDataArea
        HandleSearchClick={HandleSearchClick}
        HandleDeleteClick={HandleDeleteClick}
        btnRef={btn}
        dateFilter={false}
      >
        <Grid xs={12} lg={2}>
          <TextField
            fullWidth
            size="small"
            select
            label="Cihaz Tipi"
            value={deviceType}
            onChange={(e) => setDeviceType(e.target.value)}
          >
            <MenuItem value="all" sx={{ fontStyle: "italic" }}>
              <FormattedMessage id="all" />
            </MenuItem>
            <MenuItem value="androidPOS">Android POS</MenuItem>
            <MenuItem value="tablet">Tablet</MenuItem>
            <MenuItem value="brand">Brand</MenuItem>
            <MenuItem value="telefon">Telefon</MenuItem>
          </TextField>
        </Grid>
      </GetDataArea>
      <CustomDataGrid
        data={data}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.CihazId}
        rowsUniqueId="CihazId"
        setEditDeleteSelectedRow={setEditDeleteSelectedRow}
      />
      <Dialog
        open={
          editDeleteSelectedRow.operation === "delete" &&
          editDeleteSelectedRow.rows.length > 0
            ? true
            : false
        }
        onClose={onClose}
      >
        <DialogTitle>
          <FormattedMessage id="delete_item" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editDeleteSelectedRow.rows.length > 1 ? (
              <FormattedMessage
                id="deleteItemsCountMessage"
                values={{ count: editDeleteSelectedRow.rows.length }}
              />
            ) : (
              <>
                <FormattedMessage id="deleteitemmessage" />
                {
                  data.find((e) => e.CihazId === editDeleteSelectedRow.rows[0])
                    ?.DeviceName
                }
                (
                {
                  data.find((e) => e.CihazId === editDeleteSelectedRow.rows[0])
                    ?.CihazId
                }
                )?{" "}
              </>
            )}
            <FormattedMessage id="deleteitemmessage2" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            <FormattedMessage id="cancelbtn" />
          </Button>
          <Button onClick={onDelete} color="secondary" autoFocus>
            <FormattedMessage id="deletebtn" />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={
          editDeleteSelectedRow.operation === "edit" &&
          editDeleteSelectedRow.rows.length > 0
            ? true
            : false
        }
        onClose={onClose}
      >
        <DialogTitle>
          <FormattedMessage id="update_item" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editDeleteSelectedRow.rows.length > 1 ? (
              <FormattedMessage
                id="updateItemsCountMessage"
                values={{ count: editDeleteSelectedRow.rows.length }}
              />
            ) : (
              <>
                <FormattedMessage id="updateitemmessage" />
                {
                  data.find((e) => e.CihazId === editDeleteSelectedRow.rows[0])
                    ?.DeviceName
                }
                (
                {
                  data.find((e) => e.CihazId === editDeleteSelectedRow.rows[0])
                    ?.CihazId
                }
                )?{" "}
              </>
            )}
          </DialogContentText>
          <DevicesUpdateDialog
            updateInputs={{
              DeviceName: data.find(
                (e) => e.CihazId === editDeleteSelectedRow.rows[0]
              )?.DeviceName,
              DeviceType: data.find(
                (e) => e.CihazId === editDeleteSelectedRow.rows[0]
              )?.DeviceType,
            }}
            setUpdateInputs={setUpdateInputs}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            <FormattedMessage id="cancelbtn" />
          </Button>
          <Button onClick={onUpdate} color="secondary" autoFocus>
            <FormattedMessage id="updatebtn" />
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate(
          editDeleteSelectedRow.operation === "delete"
            ? "deleteSuccess"
            : "updateSuccess"
        )}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(Devices, "devices");
