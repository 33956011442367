import React from "react";
import { Link as RouteLink } from "react-router-dom";
import { Breadcrumbs, Typography, Link, Box } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import IntlTranslate from "../../helpers/IntlTranslate";
export default function AppBreadcrumb({ links = [], children }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 3, height: "2rem" }}>
      {links.length > 0 && (
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              title={IntlTranslate("home")}
              underline="none"
              color="inherit"
              sx={{ display: "flex", alignItems: "center" }}
              component={RouteLink}
              to="/"
            >
              <HomeIcon />
            </Link>
            {links.map((item, index) => {
              if (item.active) {
                return (
                  <Typography key={index} color="text.primary">
                    {item.name}
                  </Typography>
                );
              } else {
                return (
                  <Link
                    key={index}
                    underline="hover"
                    color="inherit"
                    component={RouteLink}
                    to={item.path}
                  >
                    {item.name}
                  </Link>
                );
              }
            })}
          </Breadcrumbs>
        </div>
      )}
      {children}
    </Box>
  );
}
