import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppContainer from "../../components/layout/AppContainer";
import withTitle from "../../helpers/hoc/withTitle";
import { LoadingButton } from "@mui/lab";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import IntlTranslate from "../../helpers/IntlTranslate";
import { Autocomplete, MenuItem, Stack, TextField } from "@mui/material";
import SnackbarComponent from "../../components/Snackbar";
import {
  BusSelectAll,
  CreateAnnouncement,
  SoforDurakInsertUpdate,
  SoforDurakSelectByUserDriverId,
} from "../../api/api";
import { useAuth } from "../../context/AuthContext";
import dayjs from "dayjs";

function DriverStopNotification() {
  const breadcrumb = useMemo(
    () => [
      { name: <FormattedMessage id="driverStopNotification" />, active: true },
    ],
    []
  );
  // Snackbar Props
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  // Snackbar Props

  const { user } = useAuth();
  const [busStops, setBusStops] = useState([]);
  const [driverRoutes, setDriverRoutes] = useState([]);
  const [buses, setBuses] = useState([]);
  useEffect(() => {
    BusSelectAll().then((resp) => setBuses(resp.data["data: "]));
  }, []);

  useEffect(() => {
    SoforDurakSelectByUserDriverId(user[0].Id).then((resp) => {
      var driversRoutesResponse = resp.data.data;
      let temp = [];
      for (let i = 0; i < driversRoutesResponse.length; i++) {
        let current = temp.find(
          (e) => e.SeferId === driversRoutesResponse[i].SeferId
        );
        if (!current) {
          temp.push({
            SeferId: driversRoutesResponse[i].SeferId,
            SeferTarihi: driversRoutesResponse[i].SeferTarihi,
            RouteId: driversRoutesResponse[i].RouteId,
            RouteName: driversRoutesResponse[i].RouteName,
            BusName: driversRoutesResponse[i].BusName,
            BusId: driversRoutesResponse[i].BusId,
            BusPlate: driversRoutesResponse[i].BusPlate,
          });
        }
      }
      let stations = [];
      for (let i = 0; i < temp.length; i++) {
        let stationsTemp = driversRoutesResponse
          .filter((t) => t.RouteId === temp[i].RouteId)
          .map((s) => ({ id: s.StationId, label: s.Station }));
        stations.push({ SeferId: temp[i].SeferId, stops: stationsTemp });
      }
      setBusStops(stations);
      setDriverRoutes(temp);
    });
  }, [user]);
  const [loading, setLoading] = useState(false);
  const schema = Yup.object().shape({
    busStopName: Yup.string().required(IntlTranslate("busStopName.required")),
    emptySeatsCount: Yup.number()
      .min(0, IntlTranslate("emptySeatsCount.min"))
      .required(IntlTranslate("emptySeatsCount.required")),
    usersroute: Yup.string().required(),
  });
  const formik = useFormik({
    initialValues: {
      busStopName: "",
      emptySeatsCount: "",
      usersroute: driverRoutes[0]?.SeferId || "",
      message: "",
      buses: driverRoutes[0]?.BusId || "",
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (datas, { resetForm }) => {
      setLoading(true);
      var response = await SoforDurakInsertUpdate({
        Id: 0,
        CurrentStationId: datas.busStopName,
        EmptySeats: datas.emptySeatsCount,
        SeferId: datas.usersroute,
        Aciklama: datas.message,
        OtobusId: datas.buses,
      });
      if (response.data.status === "success") {
        let tempRoute = driverRoutes.find(
          (t) => t.SeferId === datas.usersroute
        );
        let tempStation = busStops[0]?.stops.find(
          (t) => t.id === datas.busStopName
        ).label;
        let tempBusName = buses.find((e) => e.Id === datas.buses).Name;
        await CreateAnnouncement({
          DuyuruId: 0,
          From: user[0].Id,
          To: "796397",
          Type: "Sadece Ekran",
          Message: `
          Güzergah: <b>${tempRoute.RouteName}</b><br/>
          Araç Kodu: <b>${tempBusName}</b><br/>
          Sefer Saati: <b>${dayjs(tempRoute.SeferTarihi).format(
            "DD.MM.YY HH:mm"
          )}</b><br/>
          Boş Koltuk Sayısı: <b>${datas.emptySeatsCount}</b><br/>
          Mevcut Durak: <b>${tempStation}</b><br/>
          Açıklama: |MESAJAYRACI|${datas.message}`,
        });
        resetForm();
        setSnackbarType("success");
        setSnackbarOpen(true);
      }
      setLoading(false);
    },
  });
  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  return (
    <AppContainer breadcrumbs={breadcrumb}>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Stack sx={{ mt: 1 }} spacing={3}>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
              <TextField
                fullWidth
                select
                label={IntlTranslate("route")}
                {...getFieldProps("usersroute")}
              >
                {driverRoutes.map((e, i) => (
                  <MenuItem key={i} value={e.SeferId}>
                    <b>{dayjs(e.SeferTarihi).format("HH:mm")} | </b>
                    {e.RouteName} <b> | {e.BusName}</b>
                  </MenuItem>
                ))}
              </TextField>
              <Autocomplete
                fullWidth
                options={
                  busStops.find((t) => t.SeferId === values.usersroute)
                    ?.stops || []
                }
                getOptionLabel={(opt) => opt.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, data) =>
                  setFieldValue("busStopName", data.id)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={IntlTranslate("busStopName")}
                    required
                    error={Boolean(touched.busStopName && errors.busStopName)}
                    helperText={touched.busStopName && errors.busStopName}
                  />
                )}
              />
            </Stack>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
              <TextField
                required
                fullWidth
                type="number"
                label={IntlTranslate("emptySeatsCount")}
                {...getFieldProps("emptySeatsCount")}
                error={Boolean(
                  touched.emptySeatsCount && errors.emptySeatsCount
                )}
                helperText={touched.emptySeatsCount && errors.emptySeatsCount}
              />
              <TextField
                fullWidth
                multiline
                label={IntlTranslate("note")}
                {...getFieldProps("message")}
              />
            </Stack>
            <Stack direction={{ xs: "column", lg: "row" }} spacing={2}>
              <TextField
                fullWidth
                select
                label={IntlTranslate("bus")}
                {...getFieldProps("buses")}
              >
                {buses.map((e, i) => (
                  <MenuItem key={i} value={e.Id}>
                    <b>{e.Name} </b>
                    {e.Plate}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="success"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}
            >
              <FormattedMessage id="add" />
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      <SnackbarComponent
        open={snackbarOpen}
        successMessage={IntlTranslate("driverstopnotificationsuccessadd")}
        alertType={snackbarType}
        onClose={handleClose}
      />
    </AppContainer>
  );
}

export default withTitle(DriverStopNotification, "driverStopNotification");
