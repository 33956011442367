import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { FormattedMessage } from "react-intl";
function DataGridColumnFilter({ filters, setFilters, ClearFilterInputs }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    let temp = JSON.parse(JSON.stringify(filters));
    temp.find((t) => t.name === name).value = value;
    setFilters(temp);
  };
  return (
    <Box sx={{ width: "100%", mb: 2 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="filter-accordion"
          sx={{
            pl: 1,
            pr: 1,
            height: "72px",
            "& .MuiAccordionSummary-content": {
              alignItems: "center",
            },
          }}
        >
          <Badge
            badgeContent={filters.filter((e) => e.value !== "").length}
            color="primary"
          >
            <FilterAltIcon color="primary" />
          </Badge>
          <Typography sx={{ ml: 2 }} color="primary" fontWeight={500}>
            <FormattedMessage id="filterResults" />
          </Typography>
          {filters.some((e) => e.value !== "") && (
            <Button
              size="small"
              sx={{ ml: 2 }}
              variant="outlined"
              onClick={ClearFilterInputs}
              color="warning"
              startIcon={<ClearAllIcon />}
            >
              <FormattedMessage id="filters.clear" />
            </Button>
          )}
        </AccordionSummary>
        <AccordionDetails sx={{ pl: 1, pr: 1 }}>
          <Grid container spacing={2}>
            {filters.map((item, index) => (
              <Grid key={index} item xs={12} md={12 / filters.length}>
                <TextField
                  fullWidth
                  size="small"
                  label={item.label}
                  name={item.name}
                  value={item.value}
                  variant="standard"
                  type="text"
                  onChange={handleChange}
                />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default DataGridColumnFilter;
